// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visa-card-image {
    height: 30px;
    width: 50px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.visa-card-wrapper {
    position: relative;
    padding: 16px;
    border-radius: 10px;
    background: white;
}

.visa-card-wrapper input {
    border: none;
    flex: 1 1;
    font-size: 16px;
    height: 100%;
    width: 100%;
    background: transparent;
}

.visa-card-wrapper input:focus {
    outline: none;
    border: none;
}

.add-payment-card-button:hover {
    background: var(--primary-color-hover);
}

.payment-card-input {
    padding: 16px;
}

.payment-card-error {
    font-size: 14px;
}

.add-payment-card-loading-button {
    padding: 14px;
    width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/payment-detail-step/add-new-card/AddNewCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,SAAO;IACP,eAAe;IACf,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".visa-card-image {\n    height: 30px;\n    width: 50px;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    position: absolute;\n}\n\n.visa-card-wrapper {\n    position: relative;\n    padding: 16px;\n    border-radius: 10px;\n    background: white;\n}\n\n.visa-card-wrapper input {\n    border: none;\n    flex: 1;\n    font-size: 16px;\n    height: 100%;\n    width: 100%;\n    background: transparent;\n}\n\n.visa-card-wrapper input:focus {\n    outline: none;\n    border: none;\n}\n\n.add-payment-card-button:hover {\n    background: var(--primary-color-hover);\n}\n\n.payment-card-input {\n    padding: 16px;\n}\n\n.payment-card-error {\n    font-size: 14px;\n}\n\n.add-payment-card-loading-button {\n    padding: 14px;\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
