// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-others-wrapper {
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;
}

.sign-in-item {
    padding: 12px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #ebebeb;
}

.sign-in-item:hover {
    background: #e0e0e0;
}

.sign-in-other{
    margin-bottom: 20px;
    text-align: center;
}

.line {
    width: 25%;
    height: 0;
    border: 1px solid #989898;
    margin: 3px;
    display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/sign-in-others/SignInOthers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,QAAQ;IACR,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,yBAAyB;IACzB,WAAW;IACX,qBAAqB;AACzB","sourcesContent":[".sign-in-others-wrapper {\n    display: flex;\n    gap: 24px;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.sign-in-item {\n    padding: 12px;\n    display: flex;\n    gap: 8px;\n    justify-content: center;\n    align-items: center;\n    border-radius: 8px;\n    background: #ebebeb;\n}\n\n.sign-in-item:hover {\n    background: #e0e0e0;\n}\n\n.sign-in-other{\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.line {\n    width: 25%;\n    height: 0;\n    border: 1px solid #989898;\n    margin: 3px;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
