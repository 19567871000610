// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery-options-container {
    display: flex;
    padding: 20px 56px;
    gap: 20px;
    justify-content: space-around;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.delivery-options-column-2 {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .delivery-options-container {
        padding: 20px;
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/delivery-options/DeliveryOptions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,6BAA6B;IAC7B,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;IAC1B;AACJ","sourcesContent":[".delivery-options-container {\n    display: flex;\n    padding: 20px 56px;\n    gap: 20px;\n    justify-content: space-around;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.delivery-options-column-2 {\n    display: flex;\n    gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .delivery-options-container {\n        padding: 20px;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
