// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tip-buttons-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tip-button {
    padding: 8px 16px;
    border-radius: 24px;
    border: 1px solid transparent;
    background: var(--grey-color-hover);
}

.tip-button-selected {
    background: var(--primary-light-color);
    border: 1px solid var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/order-total/tip-buttons/tip-buttons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,6BAA6B;IAC7B,mCAAmC;AACvC;;AAEA;IACI,sCAAsC;IACtC,sCAAsC;AAC1C","sourcesContent":[".tip-buttons-wrapper {\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap;\n}\n\n.tip-button {\n    padding: 8px 16px;\n    border-radius: 24px;\n    border: 1px solid transparent;\n    background: var(--grey-color-hover);\n}\n\n.tip-button-selected {\n    background: var(--primary-light-color);\n    border: 1px solid var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
