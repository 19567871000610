import './OffersPage.css';
import React, {useState} from "react";
import OfferTypeOverview from "./OfferTypesOverview/OfferTypeOverview";
import DiscountOfferForm from "./discount-offer-form/DiscountOfferForm";
import TailoredOffers from "./tailored-offers/TailoredOffers";
import DiscountOffer from "../../../model/offers/DiscountOffer";
import DeleteOfferModal from "./delete-offer-modal/DeleteOfferModal";
import Bogof from "../../../model/offers/Bogof";
import SpendAndGetFreeOffer from "../../../model/offers/SpendAndGetFreeOffer";
import {useDispatch, useSelector} from "react-redux";
import {selectChef} from "../../../core/redux/slices/chefSlice";
import ChefService from "../../../core/services/ChefService";
import {selectToken} from "../../../core/redux/slices/loginSlice";
import store from "../../../core/redux/store";
import {Icon} from "@iconify/react";
import BogofOfferForm from "./bogof-offer-form/BogofOfferForm";
import {useTranslation} from "react-i18next";

enum ContentShowType {
    DISCOVER = 'discover',
    LIST_OFFERS = 'list'
}

const OffersPage = () => {

    const [offerCustomize, setOfferCustomize] = useState('');
    const [contentShowType, setContentShowType] = useState(ContentShowType.DISCOVER);
    const [isOfferEditOn, setIsOfferEditOn] = useState(false);
    const [offer, setOffer] = useState<DiscountOffer|Bogof|SpendAndGetFreeOffer>();
    const [offerType, setOfferType] = useState('');
    const [isOfferDeleteModalOpen, setIsDeleteOfferModalOpen] = useState(false);
    const [offerToDelete, setOfferToDelete] = useState<Bogof | DiscountOffer | SpendAndGetFreeOffer>();
    const chef = useSelector(selectChef);
    const { t } = useTranslation('ChefDashboard');
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [offerDeleted, setOfferDeleted] = useState<'discount'|'bogof'|'spendAndGetFreeOffer'>();

    const customizeOfferType = (offerType: string) => {
        setOfferCustomize(offerType);
    }

    const cancelOfferSelection = () => {
        setOfferCustomize('');
        setIsOfferEditOn(false);
    }

    const confirmOfferDelete = (offer: DiscountOffer | Bogof | SpendAndGetFreeOffer, offerType: string) => {
        setIsDeleteOfferModalOpen(true);
        setOfferToDelete(offer);
        setOfferType(offerType);
    }

    const handleOfferDelete = async () => {

        try {
            apiDispatch(ChefService.deleteOffer({ userId: chef.id as string, offerId: offerToDelete?.id as string, offerType: offerType }))
                .unwrap()
                .then(response => {
                    setOfferDeleted('discount');
                    setTimeout(()=>{setOfferDeleted(undefined)},2000);
                })
                .catch((rejectedValue) => {
                    console.error('Handled error:', rejectedValue.errorMessage);
                });
        }catch (error: any){
            console.log(error.message);
        }
    }

    const closeOfferDeleteModal = () => {
        setIsDeleteOfferModalOpen(false);
    }

    const editDiscountOffer = (offer?: DiscountOffer | Bogof | SpendAndGetFreeOffer) => {
        setOffer(offer);
        setIsOfferEditOn(true);
    }

    const isDiscountOffer = (offer: any): offer is DiscountOffer => {
        return (offer as DiscountOffer).discountWorth !== undefined;
    }

    function isBogof(offer: any): offer is Bogof {
        return (offer as Bogof).purchaseItemsQuantity !== undefined;
    }

    function isSpendAndGetFree(offer: any): offer is SpendAndGetFreeOffer {
        return (offer as SpendAndGetFreeOffer).discountWorth !== undefined;
    }

    return (
        <div className='offers-page'>
            {(!offerCustomize && !isOfferEditOn) &&
                <div>
                    <div className='flex gap-20 offers-page-heading box-card-shadow'>
                        <h2 className={`${contentShowType === 'discover' ? 'cursor selected-content' : 'cursor'}`}
                            onClick={() => setContentShowType(ContentShowType.DISCOVER)}>{t('offer-heading.explore')}</h2>
                        <h2 className={`${contentShowType === 'list' ? 'cursor selected-content' : 'cursor'}`}
                            onClick={() => setContentShowType(ContentShowType.LIST_OFFERS)}>{t('offer-heading.tailored')}</h2>
                    </div>
                    {contentShowType === 'discover' ?
                        <OfferTypeOverview customizeOfferType={customizeOfferType}/> :
                        <TailoredOffers editDiscountOffer={editDiscountOffer} handleOfferDelete={confirmOfferDelete}
                                        offerDeleted={offerDeleted === 'discount'} onExploreOffersClick={() => setContentShowType(ContentShowType.DISCOVER)}/>
                    }
                </div>
            }
            {offerCustomize === 'discount' && <DiscountOfferForm isEditModeOn={false} cancelOfferSelection={cancelOfferSelection}/>}
            {isOfferEditOn &&
                <>
                    {isDiscountOffer(offer) && <DiscountOfferForm isEditModeOn={true} cancelOfferSelection={cancelOfferSelection} discountOffer={offer}/>}
                    {isBogof(offer) && <BogofOfferForm isEditModeOn={true} cancelOfferSelection={cancelOfferSelection} offer={offer}/>}
                </>
            }
            {offerCustomize === 'bogof' && <BogofOfferForm isEditModeOn={false} cancelOfferSelection={cancelOfferSelection}/> }
            <DeleteOfferModal closeDeleteModal={closeOfferDeleteModal} isDeleteModalOpen={isOfferDeleteModalOpen} deleteMenuItem={handleOfferDelete} />
        </div>
    )
}

export default OffersPage;
