import './ChefPersonalDetails.css';
import React from "react";
import Chef from "../../../model/Chef";
import {Icon} from "@iconify/react";
import {Skeleton} from "@mui/material";
import useImageLoader from "../../../custom-hooks/useImageLoader";
import {imageUrlConverter} from "../../../core/utils/ImageUrlConverter";
import AvailableChefLocation from "../../available-chefs/available-chef-location/AvailableChefLocation";

type ChefPersonalDetailsProps = {
    chef?: Chef,
}

const ChefPersonalDetails: React.FC<ChefPersonalDetailsProps>= ({chef}) => {

    const profileImageUrl = chef?.profileImageUrl + '?' + process.env.REACT_APP_BLOB_SAS_TOKEN;
    const menuItemUrl = imageUrlConverter(chef?.featuredDishUrl as string);
    const isMenuItemLoaded = useImageLoader(menuItemUrl);
    const isImageLoaded = useImageLoader(profileImageUrl);

    return (
        <div>
            <div className='business-image-wrapper'>
                {isMenuItemLoaded ? <img src={menuItemUrl} alt='business' className='business-image'/> : <Skeleton variant='rectangular' className='business-image'/>}
                {isImageLoaded ? <img src={profileImageUrl} alt='chef-logo' className='chef-profile-pic' /> : <Skeleton variant="rectangular" className='chef-profile-pic'/>}
            </div>
            <div className='chef-personal-details'>
                <div className='rows-wrapper'>
                    <h2 className='heading'>{chef?.businessDetails?.businessName}</h2>
                    <div className='flex gap-10 flex-wrap'>
                        <div className='chef-reviews element-center'>
                            <Icon icon='emojione:star' fontSize={20} />
                            <p>{chef?.reviews ? chef.reviews.length : 0}</p>
                            <p className='reviews'>{chef?.reviews ? chef.reviews.length : '0 Reviews'}</p>
                        </div>
                        <span className='row-divide'>|</span>
                        <div className='chef-meals element-center'>
                            <Icon icon='ph:bowl-food-light' fontSize={20} />
                            <p>{chef?.mealsPrepared ? `${chef.mealsPrepared} meals prepared` : '0 meals prepared'}</p>
                        </div>
                        <span className='row-divide'>|</span>
                        <div className='cuisines-list'>
                            {chef?.specialityCuisines.map((cuisine, index) => (
                                <div key={index} className='element-center'>
                                    <p className='cuisine'>{cuisine}</p>
                                    {index !== chef?.specialityCuisines.length-1 && <Icon icon='radix-icons:dot-filled' fontSize={24} className='dot-icon'/>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <AvailableChefLocation chef={chef} />
            </div>
        </div>
    )
}

export default ChefPersonalDetails;
