import './FrequentlyAskedQuestionsCard.css';
import React, {useState} from "react";
import {Icon} from "@iconify/react";
import { motion, AnimatePresence } from 'framer-motion';
import {QuestionsAndAnswers} from "../../../../model/QuestionAndAnswers";

const FrequentlyAskedQuestionsCard: React.FC<QuestionsAndAnswers>= (props) => {

    const [showAnswer, setShowAnswer] = useState(false);

    const variants = {
        initial: { opacity: 0, height: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0, height: 0 }
    };

    const transition = { duration: 0.25, ease: "easeIn" };

    const handleShowAnswer = () => {
        setShowAnswer(prevState => !prevState);
    }

    return(
        <div className='frequently-asked-questions-card' onClick={handleShowAnswer}>
            <div className='questions-row'>
                <p className='question'>{props.question}</p>
                <Icon icon={`${showAnswer ? 'ic:baseline-minus' : 'ic:outline-plus'}`} className='frequently-asked-questions-card-icon'/>
            </div>
            <AnimatePresence>{showAnswer && (
                    <motion.div
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={variants}
                        transition={transition}
                        className='answer-row'>
                        {props.answer}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default FrequentlyAskedQuestionsCard;
