import './AvailableChefCard.css';
import Chef from "../../../model/Chef";
import React, {useState} from "react";
import {Icon} from "@iconify/react";
import {addFavoriteChef, removeFavoriteChef} from "../../../core/redux/slices/customerSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useImageLoader from "../../../custom-hooks/useImageLoader";
import AvailableChefCardSkeleton from "./AvailableChefCardSkeleton/AvailableChefCardSkeleton";
import {selectChosenAddress} from "../../../core/redux/slices/profileManagementSlice";

const AvailableChefCard = (chef: Chef) => {

    const ratings = chef.reviews?.reduce(
        (acc, review) => review.rating ? +review.rating + acc : acc,0);

    const profileImageUrl = chef.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const featuredDishUrl = chef.featuredDishUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const [isChefLiked, setIsChefLiked] = useState(false);
    const customerAddress = useSelector(selectChosenAddress);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const isFeaturedDishImageLoaded = useImageLoader(featuredDishUrl);
    const isProfileImageLoaded = useImageLoader(profileImageUrl);

    const handleChefLiked = ()=> {

        if(isChefLiked) {
            dispatch(removeFavoriteChef(chef.emailAddress));
        }else{
            dispatch(addFavoriteChef(chef.emailAddress));
        }

        setIsChefLiked(!isChefLiked);
    }

    function calculateDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
        const radiusOfEarthInKms = 6371;
        const dLat = degToRad(lat2 - lat1);
        const dLon = degToRad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return radiusOfEarthInKms * c;
    }

    function degToRad(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    const openChefBusinessPage = () => {
        navigateTo(`/business-page/${chef.id}`);
    }

    return (
        <div className='available-chef-card cursor' onClick={openChefBusinessPage}>
            {
                (isFeaturedDishImageLoaded && isProfileImageLoaded) ?
                <>
                    <img src={featuredDishUrl}
                         alt='available-chef' className='chef-business-image'/>
                    <div onClick={handleChefLiked}>
                        {isChefLiked ? <Icon icon='carbon:favorite-filled' fontSize={36} className='favorite'/> :
                            <Icon icon='carbon:favorite' fontSize={36} className='favorite'/>}
                    </div>
                    <div className='chef-details-container'>
                        <div className='details-first-row'>
                            <img src={`${chef.profileImageUrl}?${process.env.REACT_APP_BLOB_SAS_TOKEN}`}
                                 alt='profile' className='available-chef-profile-image'/>
                            <div className='details-column-2'>
                                <h4>Chef {chef.name.slice(0,1).toUpperCase()+chef.name.slice(1)}</h4>
                                {chef.reviews &&
                                    <div className='chef-rating-container'>
                                        <Icon icon='emojione:star' fontSize={24}/>
                                        <p className='rating'>{ratings}</p>
                                        <p>{chef.reviews.length} Reviews</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <p>{calculateDistance(customerAddress?.latitude, customerAddress?.longitude, chef.location.coordinates[1], chef.location.coordinates[0]).toFixed(2)} kms</p>
                        <div className='cuisines-details'>
                            {chef.specialityCuisines.map((cuisine,index) => (
                                <div className='cuisine-details-item'>
                                    <p>{cuisine}</p>
                                    {index !== chef.specialityCuisines.length - 1 && <Icon icon='radix-icons:dot-filled' fontSize={24}
                                    className='dot-icon'/>}
                                </div>
                            ))}
                        </div>
                    </div>
                </> : <AvailableChefCardSkeleton />
            }
        </div>
    )
}

export default AvailableChefCard;
