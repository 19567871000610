import React from 'react';
import './ChangeAvailability.css';

interface ChangeAvailabilityProps {
    onToggle: (isAvailable: boolean) => void;
    isAvailable: boolean;
}

const ChangeAvailability: React.FC<ChangeAvailabilityProps> = ({ onToggle, isAvailable }) => {

    const handleToggle = () => {
        onToggle(!isAvailable);
    };

    return (
        <div className={`toggle-switch ${isAvailable ? 'toggled' : ''}`} onClick={handleToggle}>
            <div className="toggle-knob"></div>
        </div>
    );
};

export default ChangeAvailability;
