// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-summary-section {
    border-bottom: 1px solid var(--border-light);
}

.checkout-items-count {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-light);
}

.order-summary-items-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.detail-summary-items {
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/order-items-summary/OrderSummary.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD;;AAEA;IACI,oBAAoB;IACpB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".order-summary-section {\n    border-bottom: 1px solid var(--border-light);\n}\n\n.checkout-items-count {\n    padding-bottom: 10px;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.order-summary-items-list {\n    display: flex;\n    gap: 10px;\n    flex-wrap: wrap;\n}\n\n.detail-summary-items {\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
