import './BecomeChefWelcomeContainer.css';
import {useTranslation} from "react-i18next";

const BecomeChefWelcomeContainer = () => {

    const { t } = useTranslation('BecomeAChef');

    const handleNavigate = () => {
        window.location.href='#chef-registration-form';
    }

    return (
        <div className='become-chef-welcome-container'>
            <div className='become-chef-content heading-font-large'> {t('heading')} </div>
            <div className='become-chef-content heading-font-large'> {t('registration-heading')}</div>
            <div className='become-chef-content summary'>{t('summary')}</div>
            <button className='getting-started-button' onClick={handleNavigate}>{t('join-button')}</button>
        </div>
    )
}

export default BecomeChefWelcomeContainer;
