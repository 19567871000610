import './FrequentlyAskedQuestionsList.css';
import FrequentlyAskedQuestionsCard from "./frequently-asked-questions-card/FrequentlyAskedQuestionsCard";
import {useTranslation} from "react-i18next";
import {QuestionsAndAnswers} from "../../../model/QuestionAndAnswers";

const FrequentlyAskedQuestionsList = () => {

    const { t } = useTranslation('FrequentlyAskedQuestionsList');

    const faqs: QuestionsAndAnswers[] = t('faqs', { returnObjects: true });

    return (
        <>
            <div className='heading-font-large text-center questions-heading'>{t('heading')}</div>
            <div className='frequently-asked-questions-list'>
                {faqs.map((faq, index) => (
                    <FrequentlyAskedQuestionsCard key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </>
    )
}

export default FrequentlyAskedQuestionsList;
