// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.menu-item-card {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/menu-item-checklist/MenuItemCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".menu-item-image {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n}\n\n.menu-item-card {\n    display: flex;\n    gap: 20px;\n    justify-content: space-between;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
