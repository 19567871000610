import './OrderSummary.css';
import {Icon} from "@iconify/react";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCartItemsCount, selectCheckoutCart} from "../../../../core/redux/slices/customerSlice";
import BriefSummaryItemCard from "./brief-summary-item-card/BriefSummaryItemCard";
import DetailSummaryItemCard from "./detail-summary-item-card/DetailSummaryItemCard";

const OrderSummary = () => {

    const navigateTo = useNavigate();
    const cartItemsCount = useSelector(selectCartItemsCount);
    const checkoutCart = useSelector(selectCheckoutCart);
    const [isExpandSummaryItems, setIsExpandSummaryItems] = useState(false);

    const handleExpandSummaryItems = () => {
        setIsExpandSummaryItems(true);
    }

    const handleCollapseSummaryItems = () => {
        setIsExpandSummaryItems(false);
    }

    return (
        <div className='checkout-step-wrapper order-summary-section'>
            <div className='checkout-step-heading-wrapper flex-space-between'>
                <p className='step-heading'>Order summary</p>
               <p onClick={() => navigateTo(`/business-page/${checkoutCart.chef?.id}`)} className='checkout-step-button cursor flex-space-between'><Icon icon='ic:outline-add' fontSize={24}/>Add Items</p>
            </div>
            <div className='content'>
                <div className='checkout-items-count flex-space-between'>
                    <p>{cartItemsCount} items</p>
                    {isExpandSummaryItems ? <Icon icon='ic:baseline-keyboard-arrow-up' fontSize={24} onClick={handleCollapseSummaryItems} className='cursor'/> : <Icon icon='ic:baseline-keyboard-arrow-down' fontSize={24} onClick={handleExpandSummaryItems} className='cursor'/>}
                </div>
                <div className={`order-summary-items-list ${isExpandSummaryItems ? 'detail-summary-items' : 'brief-summary-items'}`}>
                    {checkoutCart.cartItems.map((menuItem, index) => (
                        !isExpandSummaryItems ?
                                <BriefSummaryItemCard cartItem={menuItem} handleExpandSummaryItems={handleExpandSummaryItems} key={index}/> :
                                <DetailSummaryItemCard cartItem={menuItem} key={index}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OrderSummary;
