import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Customer from "../../../model/Customer";
import Chef from "../../../model/Chef";
import LoginService, {ResponseType} from "../../services/LoginService";
import {RootState} from "../store";
import CustomerService, {SignupResponseType} from "../../services/CustomerService";
import ChefPublicService from "../../services/ChefPublicService";

interface State {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | undefined;
    tokens: {
        accessToken?: string;
        refreshToken?: string;
    };
    userType: string;
    currentUser?: Chef | Customer
}

const initialState: State = {
    loading: 'idle',
    error: undefined,
    userType: '',
    tokens: {},
    currentUser: undefined,
};

const updateLoginAndSignupAuthenticationState = (state: State, action: PayloadAction<SignupResponseType|ResponseType>) => {
    state.loading = 'succeeded';
    state.currentUser = action.payload.user;
    state.userType = action.payload.userType;
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = undefined;
            state.loading = 'idle';
        },
        updateTokens: (state, action: PayloadAction<{accessToken: string, refreshToken: string}>) => {
            state.tokens.accessToken = action.payload.accessToken;
            state.tokens.refreshToken = action.payload.refreshToken;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginService.validateLogin.pending, (state) => {
                state.loading = 'pending';
                state.error = undefined;
            })
            .addCase(LoginService.validateLogin.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.payload?.message
            })
            .addCase(LoginService.validateLogin.fulfilled, (state, action: PayloadAction<ResponseType>) => {
                updateLoginAndSignupAuthenticationState(state,action);
            })
            .addCase(CustomerService.signUp.fulfilled, (state,action: PayloadAction<SignupResponseType>) => {
                updateLoginAndSignupAuthenticationState(state,action);
            })
            .addCase(CustomerService.signUp.rejected, (state,action) => {
                state.loading = 'failed';
                state.error = 'Unable to register';
            })
            .addCase(ChefPublicService.register.fulfilled, (state, action) => {
                updateLoginAndSignupAuthenticationState(state,action);
            })
            .addCase(CustomerService.validateGoogleLogin.fulfilled, (state, action) => {
                updateLoginAndSignupAuthenticationState(state,action);
            })
    },
});

export const selectUser = (state: RootState) => state.login;

export const selectError = (state: RootState) => state.login.error;

export const selectToken = (state: RootState) => state.login.tokens;

export const selectLoading = (state: RootState) => state.login.loading;

export const {setError, clearError, updateTokens} = userSlice.actions;

export const logout = () => ({
    type: 'logout',
});


export default userSlice.reducer;

