import './MeetChefButton.css';
import {useTranslation} from "react-i18next";
import React from "react";
import {HashLink} from "react-router-hash-link";

interface moreOptionProps {
    backgroundColor: string
}

const MeetChefButton: React.FC<moreOptionProps> = ({backgroundColor}) => {

    const { t } = useTranslation('MeetChefs');

    return (
        <HashLink smooth to='/#user-address'
                  className = {backgroundColor !== 'white' ? 'meet-chef-button button-primary link-black'
                      : 'meet-chef-button button-white link-black'}>
            {t('action-button')}
        </HashLink>
    )
}

export default MeetChefButton;
