// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-chef-filter-container {
    display: flex;
}

.filter-button-wrapper {
    display: flex;
    height: 56px;
    gap: 8px;
    border-radius: 10px;
    align-items: center;
    padding: 15px;
    align-self: flex-end;
    background: var(--primary-light-color);
}

.filter-button-wrapper:hover {
    cursor: pointer;
    background: #fdf3d7;
}

@media screen and (max-width: 600px) {
    .available-chef-filter-container {
        flex: 1 1;
    }
    .filter-button-wrapper {
        flex: 1 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/delivery-options/filter-button/FilterButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,QAAQ;IACR,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;IACpB,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI;QACI,SAAO;IACX;IACA;QACI,SAAO;IACX;AACJ","sourcesContent":[".available-chef-filter-container {\n    display: flex;\n}\n\n.filter-button-wrapper {\n    display: flex;\n    height: 56px;\n    gap: 8px;\n    border-radius: 10px;\n    align-items: center;\n    padding: 15px;\n    align-self: flex-end;\n    background: var(--primary-light-color);\n}\n\n.filter-button-wrapper:hover {\n    cursor: pointer;\n    background: #fdf3d7;\n}\n\n@media screen and (max-width: 600px) {\n    .available-chef-filter-container {\n        flex: 1;\n    }\n    .filter-button-wrapper {\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
