import React from "react";
import {CustomizableMealItem} from "../../../../../../model/meal-plans/CustomizableMeal";
import AddedCustomizableMealItemCard from "./added-customizable-meal-item-card/AddedCustomizableMealItemCard";


type CustomizableMealItemsProps = {
    mealItems: CustomizableMealItem;
}

const AddedCustomizableMealItems: React.FC<CustomizableMealItemsProps> = ({mealItems}) => {

    const style = {marginBottom: '10px', display: 'inline-block'}

    return (
        <div className='added-meal-plan-items padding-10'>
            <sub style={style}>Customer can choose {mealItems.maxItems} items among following</sub>
            <div className='flex gap-20'>
                {mealItems.menuItems.map((mealItem, index) =>
                    <AddedCustomizableMealItemCard menuItem={mealItem} key={index}/>
                )}
            </div>
        </div>
    )
}


export default AddedCustomizableMealItems;
