import './ScheduleForm.css';
import React, {useEffect} from 'react';
import { DayAvailability } from '../../../model/Schedule';
import './ScheduleForm.css';
import {useTranslation} from "react-i18next";
import AvailabilityDateCard from "./availability-date-card/AvailabilityDateCard";
import ChefService from "../../../core/services/ChefService";
import {selectChef, selectSchedule, setSchedule} from "../../../core/redux/slices/chefSlice";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../core/redux/store";
import {selectToken} from "../../../core/redux/slices/loginSlice";
import {unwrapResult} from "@reduxjs/toolkit";


const ScheduleForm: React.FC = () => {
    const schedule = useSelector(selectSchedule);
    const token = useSelector(selectToken).accessToken;
    const id = useSelector(selectChef).id;
    const { t } = useTranslation('ChefDashboard');
    const apiDispatch = useDispatch<typeof store.dispatch>();

    useEffect(() => {
        const fetchData = async () => {

            try {
                const resultAction = await apiDispatch(ChefService.getChefSchedule({id: id as string, token}));
                const schedule: DayAvailability[] = unwrapResult(resultAction);
                apiDispatch(setSchedule(schedule));
            }catch (error) {
                console.log('errpr');
            }
        }

        fetchData();

    }, [])


    return (
        <div className="schedule-wrapper">
            <div className='schedule-heading'>
                <h2 className='sub-black'>{t('schedule.heading')}</h2>
                <div className='schedule-description'>
                    <div><p className='availability-label available'>{t('schedule.available')}</p></div>
                    <div><p className='not-availability-label'>{t('schedule.unavailable')}</p></div>
                </div>
            </div>
            <div className="grid-container schedule-form">
                {schedule?.map((availability, index) => (
                    <AvailabilityDateCard dayAvailability={availability} key={index}/>
                ))}
            </div>
        </div>
    );
};

export default ScheduleForm;
