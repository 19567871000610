import { useState, useEffect } from 'react';


function useErrorMessage(duration: number = 3000): [string, (message: string) => void] {
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (errorMessage) {
            timer = setTimeout(() => {
                setErrorMessage("");
            }, duration);
        }

        return () => clearTimeout(timer);
    }, [errorMessage, duration]);

    return [errorMessage, setErrorMessage];
}

export default useErrorMessage;
