import './HireChef.css';
import {useTranslation} from "react-i18next";
import hireChefImage from "../../../../assets/images/hire-chef.png";

const HireChef = () => {

    const { t } = useTranslation('common');

    return (
        <div className='hire-chefs-content'>
            <div className='content-description-wrapper'>
                <span className='heading-font-large'>{t('hire-chef.heading')}</span>
                <span className='description'>{t('hire-chef.description')}</span>
                <button className='primary-button hire-chef-button'>{t('coming-soon')}</button>
            </div>
            <div className='content-image'>
                <img src={hireChefImage} alt='hire-chef'/>
            </div>
        </div>
    )
}

export default HireChef;
