import './MealItemCard.css';
import MenuItem from "../../../../../model/MenuItem";
import React from "react";
import {imageUrlConverter} from "../../../../../core/utils/ImageUrlConverter";
import {Icon} from "@iconify/react";

type MenuItemCardProps = {
    mealItem: MenuItem,
    quantity: number,
    onSelectMenuItem: (menuItem: MenuItem, quantity: number) => void;
}

const MealItemCard: React.FC<MenuItemCardProps> = ({mealItem, onSelectMenuItem, quantity}) => {

    const imageUrl = imageUrlConverter(mealItem.imageUrl as string);

    const handleDecreaseQuantityStaged = () => {
        if (quantity > 0) {
            onSelectMenuItem(mealItem, quantity - 1);
        }
    }

    const handleAddQuantityStaged = () => {
        onSelectMenuItem(mealItem, quantity + 1);
    }

    return (
        <div className='element-center column gap-10'>
            <img src={imageUrl} alt='menu-item' className='menu-item-image'/>
            <p>{mealItem.name}</p>
            <div className='add-meal-item-buttons'>
                <p className='add-meal-item-button'>
                    <Icon icon={'ic:baseline-minus'} fontSize={24} onClick={handleDecreaseQuantityStaged}/>
                </p>
                <p>{quantity}</p>
                <p className='add-meal-item-button' onClick={handleAddQuantityStaged}>
                    <Icon icon='ic:outline-add' fontSize={24}/>
                </p>
            </div>
        </div>
    )
}

export default MealItemCard;
