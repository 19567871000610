import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Chef, {Address, Meals} from '../../../model/Chef';
import { RootState } from '../store';
import Credentials from '../../../model/Credentials';
import ChefService from '../../services/ChefService';
import MenuItem from '../../../model/MenuItem';
import { DayAvailability } from '../../../model/Schedule';
import FixedMeal from '../../../model/meal-plans/FixedMeal';
import CustomizableMeal from "../../../model/meal-plans/CustomizableMeal";
import ChefPublicService from "../../services/ChefPublicService";

interface ChefSliceState {
    chef: Chef;
    filteredMenu: MenuItem[];
}

const initialState: ChefSliceState = {
    chef: {
        credentials: undefined,
        name: '',
        emailAddress: undefined,
        addresses: [],
        location: {
            type: 'Point',
            coordinates: [],
        },
        profileImageUrl: undefined,
        chefLikedBy: [],
        phoneNumber: undefined,
        menu: [],
        bio: undefined,
        businessDetails: {
            businessName: undefined,
            businessPhone: undefined,
            businessEmail: undefined
        },
        foodHandlingCertificateProgress: {
            isMissing: true,
            isVerifying: false,
            isVerified: false,
            foodHandlingCertificateUrl: undefined,
        },
        specialityCuisines: [],
        schedule: [],
        isOnboardingCompleted: false,
        meals: {
            fixed: [],
            customizable: []
        }
    },
    filteredMenu: []
};

const chefSlice = createSlice({
    name: 'chefSave',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(ChefPublicService.register.fulfilled, (state, action) => {
            return { ...state, chef: { ...state.chef, ...action.payload.user } };
        });
        builder.addCase(ChefService.save.fulfilled, (state, action) => {
            return { ...state, chef: { ...state.chef, ...action.payload } };
        });
        builder.addCase(ChefService.addMenuItem.fulfilled, (state, action) => {
            return {
                ...state,
                chef: { ...state.chef, menu: action.payload },
                filteredMenu: action.payload,
            };
        });
        builder.addCase(ChefService.editMenuItem.fulfilled, (state, action) => {
            return {
                ...state,
                chef: { ...state.chef, menu: action.payload },
                filteredMenu: action.payload,
            };
        });
        builder.addCase(ChefService.getOffers.fulfilled, (state, action) => {
            return { ...state, chef: { ...state.chef, offers: action.payload } };
        });
        builder.addCase(ChefService.deleteOffer.fulfilled, (state, action) => {
            return { ...state, chef: { ...state.chef, offers: action.payload } };
        });
    },
    reducers: {
        setChefEmail: (state, action: PayloadAction<string>) => {
            state.chef.emailAddress = action.payload;
        },
        setChefName: (state, action: PayloadAction<string>) => {
            state.chef.name = action.payload;
        },
        setChefPhoneNumber: (state, action: PayloadAction<string>) => {
            state.chef.phoneNumber = action.payload;
        },
        addChefAddress: (state, action: PayloadAction<Address>) => {
            state.chef.addresses = [...state.chef.addresses, action.payload];
        },
        setChefAddresses: (state, action: PayloadAction<Address[]>) => {
            state.chef.addresses = action.payload;
        },
        setChefCredentials: (state, action: PayloadAction<Credentials>) => {
            state.chef.credentials = action.payload;
        },
        setLocation: (state, action: PayloadAction<{ longitude: number; latitude: number }>) => {
            if (!state.chef.location) {
                state.chef.location = {
                    type: 'Point',
                    coordinates: [action.payload.longitude, action.payload.latitude],
                };
            } else {
                state.chef.location.coordinates = [action.payload.longitude, action.payload.latitude];
            }
        },
        setChefProfileUrl: (state, action: PayloadAction<string>) => {
            state.chef.profileImageUrl = action.payload;
        },
        setBio: (state, action: PayloadAction<string>) => {
            state.chef.bio = action.payload;
        },
        setBusinessDetails: (state, action) => {
            state.chef.businessDetails = action.payload;
        },
        setBusinessName: (state, action: PayloadAction<string | undefined>) => {
            state.chef.businessDetails = state.chef.businessDetails ?? {};
            state.chef.businessDetails.businessName = action.payload;
        },
        setBusinessPhone: (state, action: PayloadAction<string>) => {
            state.chef.businessDetails = state.chef.businessDetails ?? {};
            state.chef.businessDetails.businessPhone = action.payload;
        },
        setFeaturedDishUrl: (state, action: PayloadAction<string>) => {
            state.chef.featuredDishUrl = action.payload;
        },
        setBusinessEmail: (state, action: PayloadAction<string>) => {
            state.chef.businessDetails = state.chef.businessDetails ?? {};
            state.chef.businessDetails.businessEmail = action.payload;
        },
        setSocialMediaAccount: (state, action) => {
            state.chef.socialNetwork = action.payload;
        },
        setFoodHandlingCertificateUrl: (state, action: PayloadAction<string>) => {
            state.chef.foodHandlingCertificateProgress = state.chef.foodHandlingCertificateProgress ?? {};
            state.chef.foodHandlingCertificateProgress.foodHandlingCertificateUrl = action.payload;
            state.chef.foodHandlingCertificateProgress.isMissing = false;
        },
        setChef: (state, action: PayloadAction<Chef>) => {
            return {
                ...state,
                chef: action.payload
            };
        },
        setSpecialityCuisines: (state, action: PayloadAction<string[]>) => {
            state.chef.specialityCuisines = action.payload;
        },
        setMenu: (state, action: PayloadAction<MenuItem[]>) => {
            state.chef.menu = action.payload;
            state.filteredMenu = action.payload; // Update filteredMenu as well
        },
        setFoodCategories: (state, action: PayloadAction<{ id: string; name: string }[]>) => {
            state.chef.foodCategories = action.payload;
        },
        setChefOnboarded: (state, action: PayloadAction<boolean>) => {
            state.chef.isOnboardingCompleted = action.payload;
        },
        setSchedule: (state, action: PayloadAction<DayAvailability[]>) => {
            state.chef.schedule = action.payload;
        },
        setMeals: (state, action: PayloadAction<Meals>) => {

            state.chef.meals = state.chef.meals ?? {fixed: [], customizable: []};
            state.chef.meals = action.payload;
        },
        setFixedMeals: (state, action: PayloadAction<FixedMeal[]>) => {
            state.chef.meals = state.chef.meals ?? {fixed: [], customizable: []};
            state.chef.meals.fixed = action.payload;
        },
        setCustomizableMeals: (state, action: PayloadAction<CustomizableMeal[]>) => {
            state.chef.meals = state.chef.meals ?? {fixed: [], customizable: []};
            state.chef.meals.customizable = action.payload;
        }
    },
});

export const {
    setChef,
    setChefCredentials,
    setChefName,
    setChefPhoneNumber,
    addChefAddress,
    setChefAddresses,
    setLocation,
    setChefEmail,
    setBusinessEmail,
    setBusinessName,
    setBusinessPhone,
    setChefProfileUrl,
    setFoodHandlingCertificateUrl,
    setFeaturedDishUrl,
    setSpecialityCuisines,
    setMenu,
    setBusinessDetails,
    setSocialMediaAccount,
    setFoodCategories,
    setChefOnboarded,
    setSchedule,
    setFixedMeals,
    setCustomizableMeals,
    setMeals,
} = chefSlice.actions;

export const selectChef = (state: RootState) => state.chef.chef;
export const selectChefName = (state: RootState) => state.chef.chef.name;
export const selectCuisines = (state: RootState) => state.chef.chef.specialityCuisines;
export const selectChefLocation = (state: RootState) => state.chef.chef.location;
export const selectIsMealsPresent = (state: RootState) => {
    console.log(state.chef.chef.meals);
    if (state.chef.chef.meals) {
        if (state.chef.chef.meals.fixed && state.chef.chef.meals.fixed.length > 0) {
            console.log('true is true');
            return true;
        }
        if (state.chef.chef.meals.customizable && state.chef.chef.meals.customizable.length > 0) {
            console.log('true is true 2');
            return true;
        }
    }
}

export const selectChefAddress = (state: RootState) => state.chef.chef.addresses[state.chef.chef.addresses.length - 1];
export const selectChefCredentials = (state: RootState) => state.chef.chef.credentials;
export const selectSchedule = (state: RootState) => state.chef.chef.schedule;
export const selectFilteredMenu = (state: RootState) => state.chef.filteredMenu;

export default chefSlice.reducer;
