import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Chef from "../../../model/Chef";
import { RootState } from "../store";
import ChefPublicService from "../../services/ChefPublicService";

type initialStateTypes = {
    foodTypeFilter?: string;
    dishTypeFilter?: string;
    cuisineTypeFilter: string | 'All Cuisines';
    availableChefs: Chef[];
    filteredChefs: Chef[];
    chefListLoaded: 'successfully' | 'failed' | 'idle';
}

const initialState: initialStateTypes = {
    availableChefs: [],
    filteredChefs: [],
    cuisineTypeFilter: "All Cuisines",
    chefListLoaded: 'idle',
};

const chefListSlice = createSlice({
    name: 'availableChefList',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(ChefPublicService.retrieveChefs.fulfilled, (state, action: PayloadAction<Chef[]>) => {
            state.availableChefs = action.payload;
            state.filteredChefs = action.payload;
            state.chefListLoaded = 'successfully';
        });
        builder.addCase(ChefPublicService.retrieveChefs.rejected, (state) => {
            state.chefListLoaded = 'failed';
        });
    },
    reducers: {
        setFoodTypeFilter: (state, action: PayloadAction<string>) => {
            state.foodTypeFilter = action.payload;
        },
        setDishTypeFilter: (state, action: PayloadAction<string>) => {
            state.dishTypeFilter = action.payload;
        },
        setCuisineTypeFilter: (state, action: PayloadAction<string>) => {
            if (action.payload === 'All Cuisines') {
                state.filteredChefs = state.availableChefs;
            } else {
                state.filteredChefs = state.availableChefs.filter((chef) => chef.specialityCuisines.includes(action.payload));
            }
            state.cuisineTypeFilter = action.payload;
        },
        setRemoveAllFilters: (state) => {
            state.cuisineTypeFilter = 'All Cuisines';
            state.foodTypeFilter = undefined;
            state.dishTypeFilter = undefined;
            state.filteredChefs = state.availableChefs;
        }
    }
});

export const selectAvailableChefs = (state: RootState) => state.availableChefList.availableChefs;
export const selectChefs = (state: RootState) => state.availableChefList.filteredChefs;
export const selectChefList = (state: RootState) => state.availableChefList;

export const selectCuisineFilter = (state: RootState) => state.availableChefList.cuisineTypeFilter;

export const { setFoodTypeFilter, setDishTypeFilter, setCuisineTypeFilter, setRemoveAllFilters } = chefListSlice.actions;

export default chefListSlice.reducer;
