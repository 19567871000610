import './EditFeaturedDishPhotoModal.css';
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDispatch} from "react-redux";
import {Icon} from "@iconify/react";
import {EditProfilePictureModalProps} from "../../edit-profile-picture-modal/EditProfilePictureModal";
import ChefService from "../../../../../core/services/ChefService";
import {setFeaturedDishUrl} from "../../../../../core/redux/slices/chefSlice";
import ProfilePhoto from "../../profile-photo/ProfilePhoto";
import chefBusinessLogo from '../../../../../assets/images/chef-business-logo.png';
import store from "../../../../../core/redux/store";
import {unwrapResult} from "@reduxjs/toolkit";

const EditFeaturedDishPhotoModal: React.FC<EditProfilePictureModalProps> = ({isModalOpen, closeModal, chef}) => {

    const dispatch = useDispatch();
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [fileToSave, setFileToSave] = useState<File>();
    const [businessPhotoUrl, setBusinessPhotoUrl] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isPhotoSaved, setPhotoSaved] = useState(false);
    const [isPhotoDeleted, setPhotoDeleted] = useState(false);

    useEffect(() => {
        setBusinessPhotoUrl(chef.featuredDishUrl ? chef.featuredDishUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN : '');
    },[chef.featuredDishUrl]);

    const closeEditPhotoModal = () => {
        if(!chef.profileImageUrl) {
            setBusinessPhotoUrl('');
        }
        closeModal();
    }

    const setSavedTimeout = (actionType: string) => {
        setTimeout(() => {
            if(actionType === 'saved'){
                setPhotoSaved(false);
            }else if(actionType === 'deleted'){
                setPhotoDeleted(false);
            }
        },2000)
    }

    const handleProfilePhotoSave = async () => {
        if(fileToSave){

            const formData = new FormData();
            formData.append('image', fileToSave);

            try {
                const savePhotoResponse = unwrapResult(await apiDispatch(ChefService.savePhoto({
                    image: formData,
                    imageType: 'business',
                    id: chef.id
                })));

                dispatch(setFeaturedDishUrl(savePhotoResponse.featuredDishUrl as string));
                setPhotoSaved(true);
                closeModal();
                URL.revokeObjectURL(businessPhotoUrl);
            }
            catch (error) {

            }

        }
    }

    const handleProfilePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setFileToSave(file);
            const imageUrl = URL.createObjectURL(file);
            setBusinessPhotoUrl(imageUrl);
        }
    }

    const handleConfirmDelete = () => {
        setDeleteConfirmation(true);
    }

    const handleDeletePhoto = async () => {

        const deletePhotoResponse  = await ChefService.deletePhoto('business', chef.credentials?.username);
        if(deletePhotoResponse){
            dispatch(setFeaturedDishUrl(''));
        }
        setDeleteConfirmation(false);
        closeModal();
    }

    return (
        <Modal isOpen={isModalOpen} onRequestClose={closeEditPhotoModal} className='basic-modal edit-profile-picture-modal'>
            <div>
                <div className='change-profile-image-modal-heading'>
                    <p>Change photo</p>
                    <Icon icon='material-symbols-light:close' fontSize={24} onClick={closeEditPhotoModal} className='close-profile-image-change-modal'/>
                </div>
                <div className='photo-update-container'>
                    <div className='photo-drop-zone'>
                        <ProfilePhoto imageUrl={businessPhotoUrl ? businessPhotoUrl : chefBusinessLogo} containerSize='large'/>
                    </div>
                    {!deleteConfirmation &&
                        <>
                            <label className='upload-photo-button'>
                                <Icon icon='typcn:cloud-storage' fontSize={24}/>
                                <p>Add a photo</p>
                                <input type='file' onChange={handleProfilePhotoUpload} style={{display: 'none'}}
                                       accept='.image/png,.jpg'/>
                            </label>
                            <button className='delete-photo-button' disabled={!chef.featuredDishUrl} onClick={handleConfirmDelete}>
                                <Icon icon='material-symbols-light:delete-outline' fontSize={24} />
                                <p>Delete</p>
                            </button>
                        </>
                    }
                </div>
                {deleteConfirmation &&
                    <div className='delete-confirmation-container'>
                        <p><Icon icon='material-symbols:warning-outline' fontSize={24} className='delete-warning-icon'/>
                            Are you sure you want to delete the photo?</p>
                        <sub>This action cannot be undone.</sub>
                    </div>
                }
                <div className='modal-action-button'>
                    {!deleteConfirmation ?
                        <>
                            <button className='save-profile-photo-button' onClick={handleProfilePhotoSave}>Save</button>
                            <button className='cancel-profile-photo-button' onClick={closeEditPhotoModal}>Cancel</button>
                        </> :
                        <>
                            <button className='delete-profile-photo-confirmation' onClick={handleDeletePhoto}>Delete</button>
                            <button className='cancel-profile-photo-button' onClick={() => setDeleteConfirmation(false)}>Cancel</button>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default EditFeaturedDishPhotoModal;
