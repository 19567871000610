import {Icon} from "@iconify/react";
import React, {useState} from "react";
import {selectChef} from "../../../core/redux/slices/chefSlice";
import {useSelector} from "react-redux";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import './Profile.css';
import FoodSafetyDetails from "./food-safety-details/FoodSafetyDetails";
import BusinessDetails from "./business-details/BusinessDetails";
import FoodHandlingCertificateDetails from "./food-handling-certificate-details/FoodHandlingCertificateDetails";
import chefProfileImage from '../../../assets/images/chef-profile.png';
import EditProfilePictureModal from "./edit-profile-picture-modal/EditProfilePictureModal";
import FeaturedDish from "./featured-dish/FeaturedDish";
import SocialMedia from "./social-media/SocialMedia";
import ConnectBankAccount from "./connect-bank-account/ConnectBankAccount";

const Profile = () => {

    const chef = useSelector(selectChef);
    const chefProfileImageUrl = chef.profileImageUrl ? chef.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN : chef.profileImageUrl;
    const [isEditProfileImageModalOpen, setIsEditProfileImageModalOpen] = useState(false);


    const closeEdiProfileImageModal = () => {
        setIsEditProfileImageModalOpen(false);
    }


    return (
        <div className='profile-container'>
            <ConnectBankAccount />
            <div className='chef-main-details-row box-card-shadow'>
                <div className='chef-main-details'>
                    <div className='profile-image-container'>
                        <label className='chef-image-upload-icon cursor' onClick={() => setIsEditProfileImageModalOpen(true)}>
                            <Icon icon='ion:camera-outline' fontSize={24} />
                        </label>
                        {chefProfileImageUrl ?
                            <img src={chefProfileImageUrl} alt='chef' className='profile-image'/>
                            : <img src={chefProfileImage} className='profile-image profile-image-icon' alt='profile'/>}
                    </div>
                    <EditProfilePictureModal isModalOpen={isEditProfileImageModalOpen} closeModal={closeEdiProfileImageModal} chef={chef}/>
                    <div className='main-details-description'>
                        <h3>{chef.name.at(0)?.toUpperCase()}{chef.name.slice(1)}</h3>
                        <div className='chef-address'><Icon icon='carbon:location' fontSize={24}/> {chef.addresses[0]?.streetAddress}</div>
                    </div>
                </div>
                <div className='right-column'>
                    <div className='chef-label'>Chef</div>
                </div>
            </div>
            <PersonalDetails />
            <FeaturedDish />
            <FoodSafetyDetails />
            <BusinessDetails />
            <SocialMedia />
            <FoodHandlingCertificateDetails />
        </div>
    )
}

export default Profile;
