// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roadmap-card {
    padding: 20px;
    max-width: 590px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    border-radius: 10px;
    background: white;
}

.roadmap-card img {
    width: 100%;
    height: 300px;
    border-radius: 6px;
}
.roadmap-icon-number {
    background: var(--primary-color);
    padding: 4px 6px;
    height:48px;
    width: 48px;
    text-align: center;
    border-radius: 50%;
}

.roadmap-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/roadmap/roadmap-card/RoadmapCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".roadmap-card {\n    padding: 20px;\n    max-width: 590px;\n    display: flex;\n    flex-direction: column;\n    gap: 28px;\n    border-radius: 10px;\n    background: white;\n}\n\n.roadmap-card img {\n    width: 100%;\n    height: 300px;\n    border-radius: 6px;\n}\n.roadmap-icon-number {\n    background: var(--primary-color);\n    padding: 4px 6px;\n    height:48px;\n    width: 48px;\n    text-align: center;\n    border-radius: 50%;\n}\n\n.roadmap-row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
