import './AddNewItem.css';
import React, {useCallback, useEffect, useState} from "react";
import DishAvailability from "./availability/DishAvailability";
import {Icon} from "@iconify/react";
import {useTranslation} from "react-i18next";
import MenuItem from "../../../../model/MenuItem";
import useFormInput from "../../../../custom-hooks/useFormInput";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setMenu} from "../../../../core/redux/slices/chefSlice";
import ChefService from "../../../../core/services/ChefService";
import store from "../../../../core/redux/store";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import ErrorMessage from "../../profile/save-error-message/ErrorMessage";
import {validatePositiveNumber} from "../../../../core/utils/InputManipulation";
import FoodTypeSelector from "./food-type-selector/FoodTypeSelector";
import AddCategoryModal from "./add-category-modal/AddCategoryModal";
import LoadingButton from "../../../../components/shared/loader-button/LoadingButton";
import menuItem from "../../../../model/MenuItem";

type AddNewItemProps = {
    isEditModeOn: boolean,
    item?: MenuItem,
    handleCloseAddNewItem : ()=> void,
}

const AddNewItem: React.FC<AddNewItemProps> = ({ item, handleCloseAddNewItem, isEditModeOn}) => {

    const name = useFormInput(item?.name, true);
    const [foodType, setFoodType] = useState(item?.foodType || 'Main');
    const price = useFormInput(item?.price?.toString(), true);
    const description = useFormInput(item?.description, true);
    const numberOfServings = useFormInput(item?.numberOfServings?.toString(), true);
    const [itemImageMissing, setImageMissing] = useState(false);
    const [itemImage, setItemImage] = useState<File|undefined>(undefined);
    const [isReadyToPublish, setIsReadyToPublish] = useState(true);
    const { t } = useTranslation(['ChefDashboard', 'common']);
    const chef = useSelector(selectChef);
    const [apiError, setApiError] = useState(false);
    const [negativeNumberError, setNegativeNumberError] = useState<{priceError: string, servingsError: string}>({priceError: '', servingsError: ''})
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [availability, setAvailability] = useState<string[]>([]);
    const [selectedImage, setSelectedImage] = useState<string>();
    const [isImageChanged, setImageChanged] = useState(false);
    const [addCategoryModalOpen, setAddCategoryModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);

    useEffect(() => {
        if(item){
            setSelectedImage(`${item?.imageUrl}?${process.env.REACT_APP_BLOB_SAS_TOKEN}`);
        }
    },[item])

    const handleSelectedDaysChange = useCallback((selectedDays: string[]) => {
        setAvailability(selectedDays);
    }, []);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setItemImage(file);
            setImageMissing(false);
            setImageChanged(true);
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    }

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        price.onChange(event);
        setNegativeNumberError(prevState => ({ ...prevState, priceError: ''}));
    }

    const handleServingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        numberOfServings.onChange(event);
        setNegativeNumberError(prevState => ({ ...prevState, servingsError: ''}));
    }

    const handleSaveDraft = () => {
        setIsReadyToPublish(false);
        handleItemPublish(false).catch(error => {
            alert(error);
        });
    }

    const handleSelectFoodType = (foodType: string) => {
        setFoodType(foodType);
    }

    const validateForm = () => {

        name.validate(name.value);
        price.validate(price.value);
        numberOfServings.validate(numberOfServings.value);
        description.validate(description.value);

        return name.value && price.value && description.value && numberOfServings.value;
    };

    const handleItemPublish = async (isReadyToPublish: boolean) => {

        if (!validateForm() || (!itemImage && !selectedImage) ) {
            if(!itemImage && !selectedImage){
                setImageMissing(true);
            }
            setFormError(true);
            setTimeout(() => setFormError(false), 2000);
            return;
        }

        if (!validatePositiveNumber(price.value) || !validatePositiveNumber(numberOfServings.value)){
            if (!validatePositiveNumber(price.value)){
                setNegativeNumberError(prevState => ({ ...prevState, priceError: 'Please enter valid price' }));
            }
            if (!validatePositiveNumber(numberOfServings.value)){
                setNegativeNumberError(prevState => ({ ...prevState, servingsError: 'Please enter valid number of servings' }));
            }
            return;
        }

        try {

            setIsLoading(true);

            const formData = new FormData();

            const menuItem: MenuItem = {
                id: item?.id || '',
                name : name.value,
                price : price.value,
                availability: availability,
                foodType: foodType || '',
                description: description.value,
                numberOfServings: numberOfServings.value,
                readyToPublish: isReadyToPublish,
                imageUrl: !isImageChanged && item?.imageUrl ? item.imageUrl : ''
            };

            if (isImageChanged && itemImage) {
                formData.append('image', itemImage);
            }

            formData.append('menuItem', JSON.stringify(menuItem));

            const chefId = chef.id;

            if (isEditModeOn) {
                await apiDispatch(ChefService.editMenuItem({chefId, formData}));
            } else {
                await apiDispatch(ChefService.addMenuItem({chefId, formData}));
            }
            handleCloseAddNewItem();
        } catch (error) {
            setApiError(true);
            setTimeout(() => {setApiError(false)}, 3000);
            console.error('Upload failed:', error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleRemoveImage = () => {
        setSelectedImage('');
    }

    const handleAddCategory = () => {
        setAddCategoryModal(true);
    }

    const closeAddNewCategoryModal = () => {
        setAddCategoryModal(false);
    }

    return (
        <div className='add-new-item'>
            <div className='add-new-item-heading box-card-shadow'>
                <Icon icon='ion:chevron-back' fontSize={28} className='cursor back-to-added-items' onClick={handleCloseAddNewItem}/>
                <h3>{!item ? 'Add New Menu Item' : `Edit ${item.name} dish`}</h3>
            </div>
            <div className={`add-item-container box-card-shadow ${formError ? 'shake-animation' : ''}`} >
                <div className='details-column'>
                    <div>
                        <div className='add-item-label'>Name</div>
                        <input type='text' value={name.value} onChange={name.onChange} className='add-item-input'/>
                        {name.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    <div>
                        <div className='add-item-label'>Price</div>
                        <input type='number' value={price.value} onChange={handlePriceChange} className='add-item-input' min={0}/>
                        {price.isMissing && <div className='error-message'>This field is required</div>}
                        {negativeNumberError.priceError && <div className='error-message'>{negativeNumberError.priceError}</div>}
                    </div>
                    <div>
                        <div className='add-item-label'>Number of servings</div>
                        <input type='number' value={numberOfServings.value} onChange={handleServingsChange} className='add-item-input' min={1} />
                        {numberOfServings.isMissing && <div className='error-message'>This field is required</div>}
                        {negativeNumberError.servingsError && <div className='error-message'>{negativeNumberError.servingsError}</div>}
                    </div>
                    <DishAvailability onSelectedDaysChange={handleSelectedDaysChange} menuItem={item}/>
                    <div className='food-type-wrapper'>
                        <label className='add-item-label'>Food Type</label>
                        <FoodTypeSelector  foodType={foodType} setFoodType={handleSelectFoodType} handleAddCategory={handleAddCategory} />
                    </div>
                </div>
                <div className='details-column'>
                    <div className='new-item-image-upload'>
                        <div className='add-item-label'>Image</div>
                        {!selectedImage  ?
                            <label className='new-item-image-container cursor'>
                                <input type='file' style={{display: 'none'}}
                                       accept='.png,.jpg' onChange={handleImageChange}/>
                                <Icon icon='lucide:hard-drive-upload' fontSize={24}/>
                                Upload Image
                            </label> :
                            <div className='new-item-image-container-edit'>
                                <div className='item-image-edit-row'>
                                    <img src={selectedImage} className='edit-selected-image' alt='edit-menu-item'/>
                                    {item ? <p className='menu-item-card-image-heading'>{item?.imageUrl?.split('/').at(-1)}</p> :
                                        <p>{itemImage?.name}</p>
                                    }
                                </div>
                                <Icon icon='iconoir:cancel' fontSize={24} className='remove-selected-image-button' onClick={handleRemoveImage}/>
                            </div>
                        }
                        {itemImageMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    <div className='description-wrapper'>
                        <div className='add-item-label'>Description</div>
                        <textarea value={description.value} onChange={description.onChange} className='add-item-description' rows={8} placeholder={t('new-dish-description-placeholder')}/>
                        {description.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    {isLoading ?
                        <div className='add-menu-item-loading-button'><LoadingButton /></div> :
                        <div className='new-item-action-buttons'>
                            <div className='cancel-changes-button' onClick={handleCloseAddNewItem}>Cancel</div>
                            <div className='draft-button' onClick={handleSaveDraft}>Save Draft</div>
                            <div className='primary-button' onClick={()=>handleItemPublish(isReadyToPublish)}>Publish</div>
                        </div>
                    }
                </div>
            </div>
            {apiError && <div className='error-wrapper'><ErrorMessage message={t('common:api-error-message')} /></div>}
            <AddCategoryModal isAddCategoryModalOpen={addCategoryModalOpen} closeModal={closeAddNewCategoryModal} setFoodType={handleSelectFoodType}/>
        </div>
    )
}

export default AddNewItem;
