
class StripeService {

    static attachPaymentCard = async  ( customerData:{id: string, token?: string, payLoad: any }) => {

        try {
            const attachPaymentMethodResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/stripe/customer/attach-payment-card/${customerData.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${customerData.token}`
                    },
                    body: JSON.stringify(customerData.payLoad)
                }
            );

            if (attachPaymentMethodResponse.ok) {

                return await attachPaymentMethodResponse.json();
            }
        } catch (error) {
            return {message: 'Unable to save payment card'}
        }
    }
}


export default StripeService;
