import './CheckoutPage.css';
import CheckoutSteps from "./checkout-steps/CheckoutSteps";
import {useSelector} from "react-redux";
import {selectCartItemsCount} from "../../core/redux/slices/customerSlice";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import OrderTotal from "./order-total/OrderTotal";

const CheckoutPage = () => {

    const checkoutItemsCounts = useSelector(selectCartItemsCount);
    const navigateTo = useNavigate();

    const [paymentMethodId, setPaymentMethodId] = useState('');

    const handleCardSelect = (paymentMethodId: string) => {
        setPaymentMethodId(paymentMethodId);
    }

    useEffect(() => {
        if (checkoutItemsCounts === 0) {
            navigateTo('/availableChefs', {replace: true});
        }
    }, [checkoutItemsCounts])

    return (
        <div className='checkout-page'>
            <p className='heading'>Checkout</p>
            <div className='checkout-page-details'>
                <CheckoutSteps onCardSelectClick={handleCardSelect} paymentCardSelected={paymentMethodId}/>
                <OrderTotal paymentMethodId={paymentMethodId}/>
            </div>
        </div>
    )
}

export default CheckoutPage;
