import {Icon} from "@iconify/react";
import './SavedProfileMessage.css';

const SavedProfileMessage = () => {

    return (
        <div className='saved-profile-container'>
            <Icon icon='ep:success-filled' fontSize={16} />
            <p>Saved</p>
        </div>
    )
}

export default SavedProfileMessage;
