import React, { useState, useRef } from 'react';
import PasswordTooltip from './PasswordTooltip';

interface PasswordFormProps {
    children: React.ReactNode;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ children }) => {
    const [password, setPassword] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFocus = () => {
        setShowTooltip(true);
    };

    const handleBlur = () => {
        setShowTooltip(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };


    return (
        <div style={{width: '100%'}}>
            <div
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handlePasswordChange}
            >
                <PasswordTooltip password={password} open={showTooltip}>
                    {React.cloneElement(children as React.ReactElement, { ref: inputRef, value: password })}
                </PasswordTooltip>
            </div>
        </div>
    );
};

export default PasswordForm;
