// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-type-selector {
    padding: 12px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, .css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 0 !important;
}

.add-category-option-button {
    border-top: 1px solid var(--border-light) !important;
    color: var(--primary-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/add-new-item/food-type-selector/FoodTypeSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oDAAoD;IACpD,sCAAsC;AAC1C","sourcesContent":[".food-type-selector {\n    padding: 12px;\n}\n\n.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, .css-6hp17o-MuiList-root-MuiMenu-list {\n    padding: 0 !important;\n}\n\n.add-category-option-button {\n    border-top: 1px solid var(--border-light) !important;\n    color: var(--primary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
