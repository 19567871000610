// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-offers-container {
    gap: 16px;
    display: flex;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-offer-image {
    width: 300px;
    height: 200px;
}

@media screen and (max-width: 600px) {
    .empty-offers-container {
        padding: 0 30px 30px 30px;
        height: 100vh;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/empty-offers/EmptyOffers.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI;QACI,yBAAyB;QACzB,aAAa;IACjB;AACJ","sourcesContent":[".empty-offers-container {\n    gap: 16px;\n    display: flex;\n    height: 100vh;\n    overflow: hidden;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.empty-offer-image {\n    width: 300px;\n    height: 200px;\n}\n\n@media screen and (max-width: 600px) {\n    .empty-offers-container {\n        padding: 0 30px 30px 30px;\n        height: 100vh;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
