import './OrderTotal.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectCheckoutCart, selectCustomer, selectSubTotal} from "../../../core/redux/slices/customerSlice";
import TipButtons from "./tip-buttons/tip-buttons";
import CustomerService from "../../../core/services/CustomerService";
import store from "../../../core/redux/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import LoadingButton from "../../../components/shared/loader-button/LoadingButton";
import Order from "../../../model/order/Order";
import DeliveryTypeEnum from "../../../model/order/DeliveryTypeEnum";
import {selectChosenAddress} from "../../../core/redux/slices/profileManagementSlice";
import {useNavigate} from "react-router-dom";

type OrderTotalProps = {
    paymentMethodId: string,
}

const OrderTotal: React.FC<OrderTotalProps> = ({paymentMethodId}) => {

    const subTotal = useSelector(selectSubTotal);
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const qst = ((9.975/100)*subTotal).toFixed(2);
    const gst = ((5/100)*subTotal).toFixed(2);
    const [tipOptionSelected, setTipOptionSelected] = useState(15);
    const orderAmount = subTotal + +gst + +qst + 3.75;
    const tipSelected = ((tipOptionSelected/100) * orderAmount).toFixed(2);
    const customer = useSelector(selectCustomer);
    const chefId = useSelector(selectCheckoutCart).chef?.id;
    const customerAddress = useSelector(selectChosenAddress);
    const orderItems = useSelector(selectCheckoutCart).cartItems;
    const stripe = useStripe();
    const navigateTo = useNavigate();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const handleTipOptionChange = (percentage: number) => {
        setTipOptionSelected(percentage);
    }

    const [showButton, setShowButton] = useState(!paymentMethodId);

    useEffect(() => {
        const handleScroll = () => {

            if (window.innerWidth <= 600) {
                const seventyFivePercentScreen = window.innerHeight * 0.75;
                const hasScrolledSeventyFivePercent = window.scrollY > seventyFivePercentScreen;
                setShowButton(hasScrolledSeventyFivePercent);
            }
        };

        window.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handlePlaceOrder = async () => {

        if (customer.id && paymentMethodId && stripe && elements && chefId) {
            setLoading(true);

            const order: Order = {
                chefId: chefId,
                customerId: customer.id,
                items: orderItems,
                totalPrice: orderAmount,
                orderDate: new Date(),
                scheduledDate: new Date(),
                paymentMethodId: paymentMethodId,
                orderDeliveryOptions: DeliveryTypeEnum.DELIVERY,
                deliveryAddress: customerAddress,
            }

            try {
                const clientSecret = unwrapResult(await apiDispatch(CustomerService.processOrder({order: order})));

                const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethodId
                });
                if(paymentIntent?.status === 'succeeded') {
                    navigateTo('/confirmation');
                }
                if (error) {
                    alert(error.message);
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div className='order-total'>
            <div className='checkout-step-heading-wrapper'>
                <p className='step-heading'>Order Total</p>
            </div>
            <div className='content'>
                <div className='payment-summary'>
                    <div className='flex-space-between'>
                        <p>Subtotal</p>
                        <p>${subTotal.toFixed(2)}</p>
                    </div>
                    <div className='flex-space-between'>
                        <p>Delivery Fee</p>
                        <p>$3.75</p>
                    </div>
                    <div className='flex-space-between'>
                        <p>GST</p>
                        <p>${gst}</p>
                    </div>
                    <div className='flex-space-between'>
                        <p>QST</p>
                        <p>${qst}</p>
                    </div>
                </div>
                <div className='tip-detail'>
                    <div className='flex-space-between'>
                        <p>Tip the driver</p>
                        <p>${tipSelected}</p>
                    </div>
                    <TipButtons onTipClick={handleTipOptionChange} tipOptionSelected={tipOptionSelected} />
                </div>
                <div className='flex-space-between'>
                    <h3>Total</h3>
                    <h3>${(orderAmount+ +tipSelected).toFixed(2)}</h3>
                </div>
                <div className='place-order-button-wrapper element-center' style={{ visibility: showButton ? 'visible' : 'hidden' }}>
                    {loading ?
                        <LoadingButton /> :
                        <div role='button' className='place-order-button primary-button'
                                    onClick={handlePlaceOrder}>Place order</div>
                    }

                </div>
            </div>
        </div>
    )
}

export default OrderTotal;
