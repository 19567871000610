import './EditAddressModal.css';
import Modal from "react-modal";
import AutoCompleteAddress from "../../../../../components/autocomplete-address-search/AutoCompleteAddress";
import React, {useEffect, useState} from "react";
import AddressSuggestions from "../../../../home-page/address-suggestions/AddressSuggestions";
import {customStyles} from "../../../../chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerAddresses} from "../../../../../core/redux/slices/customerSlice";
import {Icon} from "@iconify/react";
import CommonService from "../../../../../core/services/CommonService";
import {selectChosenAddress, setAddress} from "../../../../../core/redux/slices/profileManagementSlice";
import {selectUser} from "../../../../../core/redux/slices/loginSlice";
import {Address} from "../../../../../model/Chef";
import {unwrapResult} from "@reduxjs/toolkit";
import store, {RootState} from "../../../../../core/redux/store";
import {useTranslation} from "react-i18next";
import {setChefAddresses} from "../../../../../core/redux/slices/chefSlice";
import ErrorMessage from "../../../../chef-dashboard/profile/save-error-message/ErrorMessage";
import {selectAddressSuggestions} from "../../../../../core/redux/slices/addressSuggestionSlice";
import AddressList from "./address-list/AddressList";

type EditAddressModalProps = {
    isEditAddressModalOpen: boolean;
    closeEditAddressModal: () => void;
}

const EditAddressModal: React.FC<EditAddressModalProps> = ({isEditAddressModalOpen, closeEditAddressModal}) => {

    const activeUserAddress = useSelector(selectChosenAddress);
    const [oldAddress] = useState(activeUserAddress);
    const activeUser = useSelector(selectUser);
    const dispatch = useDispatch<typeof store.dispatch>();
    const [error, setError] = useState('');
    const {t} = useTranslation('common');
    const addressSuggestion = useSelector(selectAddressSuggestions);
    const user = useSelector((state: RootState) => activeUser.userType === 'chef' ? state.chef.chef : state.customerReducer.customer);

    useEffect(  () => {
        const fetchAddressList = async () => {
            try {
                const addressList: Address[] = unwrapResult(await dispatch(CommonService.getAddressList({
                    userType: activeUser.userType, id: activeUser.currentUser?.id as string, token: activeUser.tokens.accessToken
                })));

                if (activeUser.userType === 'customer'){
                    dispatch(setCustomerAddresses(addressList));
                }else if (activeUser.userType === 'chef') {
                    dispatch(setChefAddresses(addressList));
                }
            } catch (error) {
                handleError();
            }
        };

        if (isEditAddressModalOpen){
            fetchAddressList();
        }
    },[isEditAddressModalOpen])

    useEffect(() => {

        const handleAddress = async () => {
            if(activeUserAddress !== oldAddress){
                await saveAddress();
            }
        }

        handleAddress();

    }, [activeUserAddress]);

    const saveAddress = async () => {

        const addressToSave: Address = {
            streetAddress: activeUserAddress?.streetAddress as string,
            city: activeUserAddress?.city as string,
            postalCode: activeUserAddress?.postalCode as string,
            longitude: activeUserAddress?.longitude,
            latitude: activeUserAddress?.latitude,
            province: activeUserAddress?.province,
        }

        if (user.addresses.some(address => address.streetAddress === addressToSave.streetAddress)) {
            setAddress(addressToSave);
            closeEditAddressModal();
            return;
        }

        try {
            const addressList: Address[] = unwrapResult(await dispatch(CommonService.addAddress({
                userType: activeUser.userType, address: addressToSave,
                id: activeUser.currentUser?.id as string, token: activeUser.tokens.accessToken
            })));

            if (activeUser.userType === 'chef') {
                dispatch(setChefAddresses(addressList));
            }else if(activeUser.userType === 'customer') {
                dispatch(setCustomerAddresses(addressList));
            }
            closeEditAddressModal();
        } catch (error){
            dispatch(setAddress(oldAddress as Address));
            handleError();
        }
    }

    const handleError = () => {
        setError(t('api-error-message'));
        setTimeout(() => setError(''), 4000);
    }


    return (
        <Modal isOpen={isEditAddressModalOpen} className='basic-modal modal-2' style={customStyles} onRequestClose={closeEditAddressModal}>
            <div className='modal-heading element-center'>
                <Icon icon='material-symbols:close' fontSize={30} onClick={closeEditAddressModal}/>
            </div>
            <div className='modal-content column'>
                <h2>Deliver To</h2>
                <div className='address-change-input'>
                    <AutoCompleteAddress />
                </div>
                {addressSuggestion.length > 0 ?
                    <AddressSuggestions isPositionRelative={true}/> :
                    <AddressList user={user} closeEditAddressModal={closeEditAddressModal}/>
                }
            </div>
            <div className='cancel-edit-address-button primary-button' onClick={closeEditAddressModal}>Done</div>
            {error && <div className='edit-address-error'><ErrorMessage message={error} /></div>}
        </Modal>
    )
}

export default EditAddressModal;
