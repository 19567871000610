
export const CustomizeCarouselResponsiveness1 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 200 },
        items: 1,
        slidesToSlide: 1,
    }
};

export const CustomizeCarouselResponsiveness2 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 200 },
        items: 1,
        slidesToSlide: 1,
    }
};


export const CustomizeCuisineCarouselResponsiveness = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8,
        slidesToSlide: 1,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 4,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 200 },
        items: 2,
        slidesToSlide: 1,
    }
};

export const AvailableChefsResponsiveness = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 767, min: 200 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 40
    }
}

export const MealPlanItemCarousel = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 767, min: 200 },
        items: 1,
        slidesToSlide: 1,
    }
}
