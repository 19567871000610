// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-meal-item-modal {
    padding: 0 20px 20px 20px;
}

.delete-meal-item-modal .delete-menu-item-button {
    display: flex;
    padding-top: 20px;
}

.delete-meal-item-modal .delete-menu-item-button > div {
    flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/manage-meals/ManageMeals.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,SAAO;AACX","sourcesContent":[".delete-meal-item-modal {\n    padding: 0 20px 20px 20px;\n}\n\n.delete-meal-item-modal .delete-menu-item-button {\n    display: flex;\n    padding-top: 20px;\n}\n\n.delete-meal-item-modal .delete-menu-item-button > div {\n    flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
