import './DeliveryAddress.css';
import {useDispatch, useSelector} from "react-redux";
import store from "../../../../core/redux/store";
import { Icon } from "@iconify/react";
import AutoCompleteAddress from "../../../../components/autocomplete-address-search/AutoCompleteAddress";
import AddressSuggestions from "../../../home-page/address-suggestions/AddressSuggestions";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import AddressSelected from "../../../../components/shared/address-selected/AddressSelected";
import {selectChosenAddress} from "../../../../core/redux/slices/profileManagementSlice";
import ChefPublicService from "../../../../core/services/ChefPublicService";

const DeliveryAddress = () => {

    const selectedAddress = useSelector(selectChosenAddress);
    const [isChangeAddressModalOpen, setIsChangeAddressModalOpen] = useState(false);
    const [isAddressChanged, setIsAddressChanged] = useState(false);
    const dispatchApi = useDispatch<typeof store.dispatch>();

    const closeModal = () => {
        setIsAddressChanged(false);
        setIsChangeAddressModalOpen(false);
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    useEffect(() => {
        const lat = selectedAddress?.latitude;
        const lng = selectedAddress?.longitude;

        dispatchApi(ChefPublicService.retrieveChefs({latitude: lat, longitude: lng, radius: 50}));
    },[dispatchApi, selectedAddress])

    return (
        <div className='delivery-address-container'>
            <h4>Delivery Address</h4>
            <div className='selected-address-container' onClick={() => setIsChangeAddressModalOpen(true)}>
                <p>{selectedAddress?.streetAddress}</p>
                <Icon icon='ion:location-outline' fontSize={24} className='selected-location-icon'/>
            </div>
            <Modal isOpen={isChangeAddressModalOpen} className='basic-modal modal-2' style={customStyles}>
                <div className='modal-heading element-center'>
                    <Icon icon='material-symbols:close' fontSize={30} className='cursor' onClick={closeModal}/>
                </div>
                <div className='modal-content column'>
                    <h2>Change Address</h2>
                    {!isAddressChanged && <div className='change-address-container'>
                        <div className='large-input-container'>
                            <AddressSelected />
                        </div>
                        <div className='address-change-button primary-button cursor' onClick={() => setIsAddressChanged(true)}>Change</div>
                    </div>}
                    {isAddressChanged &&
                        <div className='change-address-container'>
                            <div className='large-input-container'>
                                <AutoCompleteAddress />
                            </div>
                            <AddressSuggestions isPositionRelative={true} closeChangeAddressModal={closeModal}/>
                            <div className='address-change-button primary-button cursor' onClick={closeModal}>Done</div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default DeliveryAddress;
