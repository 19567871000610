// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal-item {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.meal-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-container {
    height: 100%;
    width: 50%;
    position: relative;
    overflow: hidden;
}

.carousel-container .react-multi-carousel-list , .carousel-container .react-multi-carousel-track , .carousel-container .react-multi-carousel-item {
    height: 100%;
}

.carousel-container .react-multi-carousel-list {
    width: 100% !important;
}

.meal-item-quantity {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--primary-color);
}

.meal-item-carousel-button.right-button {
    right: 5px;
}

.meal-item-carousel-button.left-button {
    left: 5px;
}

.meal-item-carousel-button {
    min-height: 16px;
    min-width: 16px;
    position: absolute;
    top: 50%;
    color: white;
    background: rgb(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: background-color 0.3s ease;
    transform: translateY(-50%);
}

.meal-item-carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.85);
    transition: background-color 0.3s ease;
}

`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/manage-meals/meal-card/meal-items-carousel/MealItemsCarousel.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;IAClB,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,qCAAqC;IACrC,sCAAsC;AAC1C","sourcesContent":[".meal-item {\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n}\n\n.meal-item img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.carousel-container {\n    height: 100%;\n    width: 50%;\n    position: relative;\n    overflow: hidden;\n}\n\n.carousel-container .react-multi-carousel-list , .carousel-container .react-multi-carousel-track , .carousel-container .react-multi-carousel-item {\n    height: 100%;\n}\n\n.carousel-container .react-multi-carousel-list {\n    width: 100% !important;\n}\n\n.meal-item-quantity {\n    position: absolute;\n    left: 10px;\n    top: 10px;\n    height: 24px;\n    width: 24px;\n    border-radius: 50%;\n    background: var(--primary-color);\n}\n\n.meal-item-carousel-button.right-button {\n    right: 5px;\n}\n\n.meal-item-carousel-button.left-button {\n    left: 5px;\n}\n\n.meal-item-carousel-button {\n    min-height: 16px;\n    min-width: 16px;\n    position: absolute;\n    top: 50%;\n    color: white;\n    background: rgb(0, 0, 0, 0.5);\n    border-radius: 50%;\n    transition: background-color 0.3s ease;\n    transform: translateY(-50%);\n}\n\n.meal-item-carousel-button:hover {\n    background-color: rgba(0, 0, 0, 0.85);\n    transition: background-color 0.3s ease;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
