import Order from "../../../../../model/order/Order";
import React from "react";
import './CustomerDetails.css';

type CustomerDetailsProps = {
    order?: Order,
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({order}) => {

    return (
        <div className='customer-details'>
            <div className='customer-details-row'>
                <p className='grey-light-text'>Name</p>
                {/*<p>{order?.customerDetails?.name}</p>*/}
            </div>
            <div className='customer-details-row'>
                <p className='grey-light-text'>Street Address</p>
                {/*<p>{order?.customerDetails?.address.street}</p>*/}
            </div>
            <div className='customer-details-row'>
                <p className='grey-light-text'>City</p>
                {/*<p>{order?.customerDetails?.address.city}</p>*/}
            </div>
            <div className='customer-details-row'>
                <p className='grey-light-text'>Postal Code</p>
                {/*<p>{order?.customerDetails?.address.postalCode}</p>*/}
            </div>
        </div>
    )
}

export default CustomerDetails;
