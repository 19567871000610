import './DeleteMenuItemModal.css';
import Modal from "react-modal";
import React, {useState} from "react";
import MenuItem from "../../../../model/MenuItem";

type DeleteMenuItemModalProps = {
    closeDeleteModal: () => void;
    itemToDelete?: MenuItem,
    isDeleteModalOpen: boolean,
    deleteMenuItem: (menuItem?: MenuItem) => void;
}

const DeleteMenuItemModal:React.FC<DeleteMenuItemModalProps>  = ({closeDeleteModal,isDeleteModalOpen, itemToDelete, deleteMenuItem}) => {

    const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
    const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

    const handleDeleteMenuItem = () => {
        setIsDeleteButtonClicked(true);
        deleteMenuItem(itemToDelete);
        closeDeleteModal();
    }

    const handleAnimationEnd = () => {
        setIsDeleteButtonClicked(false);
        setIsCancelButtonClicked(false);
    }

    const handleCancelDeleteMenuItem = () => {
        setIsCancelButtonClicked(true);
        closeDeleteModal();
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    return (
        <Modal isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} style={customStyles} className='modal delete-item-modal'>
            <div className='roboto-slab delete-menu-item-heading'>Delete Dish</div>
            <p className='delete-item-confirmation-text'>Are you sure to delete your {itemToDelete?.name} dish. If you deleted it this will not be restore</p>
            <div className='delete-menu-item-button'>
                <div className={`animated-primary-button cancel-button ${isCancelButtonClicked ? 'animate-secondary' : ''}`} onClick={handleCancelDeleteMenuItem} onAnimationEnd={handleAnimationEnd}>Cancel</div>
                <div className={`animated-primary-button delete-button ${isDeleteButtonClicked ? 'animate' : ''}`} onClick={handleDeleteMenuItem} onAnimationEnd={handleAnimationEnd}>Delete</div>
            </div>
        </Modal>
    )
}

export default DeleteMenuItemModal;
