import './ChefDashboard.css';
import DashboardMenu from "./dashboard-menu/DashboardMenu";
import {Route, Routes} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import Orders from "./orders/Orders";
import ChefMenu from "./chef-menu/ChefMenu";
import ChefNavbar from "./chef-navbar/ChefNavbar";
import Modal from "react-modal";
import Profile from "./profile/Profile";
import Onboarding from "./onboarding/Onboarding";
import OffersPage from "./offers-page/OffersPage";
import SchedulePage from "./schedule/schedule-page/SchedulePage";
import MealPlans from "./meal-plans/MealPlans";


const ChefDashboard = () => {

    const [isDashboardOpen, setIsDashboardOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [animationIn, setAnimationIn] = useState(false);

    useEffect(() => {
        if(window.innerWidth <= 600){
            setIsMobile(true);
        }
    },[]);

    const toggleBodyScroll = (shouldEnableScroll: boolean) => {
        document.body.classList.toggle('body-no-scroll', !shouldEnableScroll);
    };

    const handleChefDashboardMenu = useCallback(() => {
        setIsDashboardOpen(true);
        setTimeout(() => setAnimationIn(true), 10);
        toggleBodyScroll(false);
    },[]);

    const closeDashboardMenu = () => {
        setAnimationIn(false);
        setTimeout(() => setIsDashboardOpen(false), 500);
        toggleBodyScroll(true);
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.75)',
            position: 'fixed' as 'fixed',
        },
    };

    return (
        <div className='chef-dashboard'>
            <ChefNavbar handleChefDashboardMenu={handleChefDashboardMenu}/>
            {!isMobile ?
                <DashboardMenu  isMobileVersion={false}/> :

                <Modal isOpen={isDashboardOpen} className={`chef-dashboard-modal ${animationIn ? 'chef-dashboard-slide-in' : 'chef-dashboard-slide-out'}`} onRequestClose={closeDashboardMenu} style={customStyles}>
                        <DashboardMenu isMobileVersion={true} closeDashboardMenu={closeDashboardMenu}/>
                </Modal>
            }
            <Routes>
                <Route path='/' element={<Profile />}/>
                <Route path='orders' element={<Orders />}/>
                <Route path='menu' element={<ChefMenu />}/>
                <Route path='onboarding' element={<Onboarding />} />
                <Route path='offers' element={<OffersPage />} />
                <Route path='schedule' element={<SchedulePage />} />
                <Route path='meals' element={<MealPlans />} />
            </Routes>

        </div>
    )
}

export default ChefDashboard;
