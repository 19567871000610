import React,{useState} from 'react';
import './ActivateLocation.css';
import Modal from 'react-modal';
import GeoLocation from "../../../components/Geo-location/GeoLocation";
import {Icon} from "@iconify/react";

const ActivateLocation = () => {
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [getLocation, setLocation] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const activateGeoLocation = () => {
       setIsModalOpen(true);
    }

    const allowLocation = () => {
        setLocation(true);
        setIsModalOpen(false);
    };

    const blockLocation = () => {
        alert('blocking location');
    }

    return (
        <div>
            <Icon icon='material-symbols:my-location-outline' fontSize={20} onClick={activateGeoLocation} className='sub-black activate-location-icon'/>
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} className='modal location-modal' ariaHideApp={false}>
                <h3>HomeEatz would like to know your location</h3>
                <div className='location-permission'>
                    <div onClick={allowLocation} className='allow-button'><span>Allow</span></div>
                    <div onClick={blockLocation} className='block-button'><span>Block</span></div>
                </div>
            </Modal>
            {getLocation && <GeoLocation />}
        </div>
    )
}


export default ActivateLocation;
