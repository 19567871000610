// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/login-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-container {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center;
    background-size: cover;
    position: relative;
    display: flex;
    padding-top: 50px;
    padding-bottom: 100px;
    justify-content: center;
}

.login-wrapper{
    flex: 1 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login-page/LoginPage.css"],"names":[],"mappings":";AACA;IACI,0DAAkE;IAClE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":["\n.login-container {\n    background: url('../../assets/images/login-background.png') center;\n    background-size: cover;\n    position: relative;\n    display: flex;\n    padding-top: 50px;\n    padding-bottom: 100px;\n    justify-content: center;\n}\n\n.login-wrapper{\n    flex: 1;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
