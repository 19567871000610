// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-form {
    display: flex;
    flex: 2 1;
    flex-direction: column;
    gap: 30px;
}

.contact-us-name-row {
    display: flex;
    gap: 10px;
}

.contact-us-name-row > div {
    flex: 1 1;
}

.send-message-button {
    padding: 10px 30px;
    width: fit-content;
    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    .contact-us-name-row {
        flex-direction: column;
        gap: 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/contact-us-page/contact-us-form/ContactUsForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,sBAAsB;QACtB,SAAS;IACb;AACJ","sourcesContent":[".contact-us-form {\n    display: flex;\n    flex: 2;\n    flex-direction: column;\n    gap: 30px;\n}\n\n.contact-us-name-row {\n    display: flex;\n    gap: 10px;\n}\n\n.contact-us-name-row > div {\n    flex: 1;\n}\n\n.send-message-button {\n    padding: 10px 30px;\n    width: fit-content;\n    border-radius: 8px;\n}\n\n@media screen and (max-width: 600px) {\n    .contact-us-name-row {\n        flex-direction: column;\n        gap: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
