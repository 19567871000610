import './FixedMealCard.css';
import FixedMeal, {FixedMealItem, FixedMealItems} from "../../../../../../model/meal-plans/FixedMeal";
import React, {useState} from "react";
import MealItemsCarousel from "../meal-items-carousel/MealItemsCarousel";
import {Icon} from "@iconify/react";
import CustomMUITooltip from "../../../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import {MealType} from "../../../create-meal-plan/meal-plan-form/MealPlanForm";


type MealCardProps = {
    fixedMeal: FixedMeal;
    editMealItem: (mealItem: FixedMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => void;
    deleteMealItem: (mealItem: FixedMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => void;
}

const FixedMealCard: React.FC<MealCardProps> = ({fixedMeal, editMealItem, deleteMealItem}) => {


    const filters = (): (keyof FixedMealItems)[] => {
        let filters: (keyof FixedMealItems)[] = [];

        if (fixedMeal.mealItems.main && fixedMeal.mealItems.main.length > 0) {
            filters = [...filters, 'main'];
        }

        if (fixedMeal.mealItems.sides && fixedMeal.mealItems.sides.length > 0) {
            filters = [...filters, 'sides'];
        }
        if (fixedMeal.mealItems.beverages && fixedMeal.mealItems.beverages.length > 0) {
            filters = [...filters, 'beverages'];
        }
        if (fixedMeal.mealItems.dessert && fixedMeal.mealItems.dessert.length > 0) {
            filters = [...filters, 'dessert'];
        }
        return filters;
    }

    const [mealItems, setMealItems] = useState<FixedMealItem[]>(() => {
        const firstFilter = filters()[0];
        return fixedMeal.mealItems[firstFilter] || [];
    });


    const [allFilters] = useState(filters);
    const [activeFilter, setActiveFilter] = useState(allFilters[0]);

    const changeFilter = (filter: keyof FixedMealItems) => {
        setActiveFilter(filter);
        setMealItems(fixedMeal.mealItems[filter] as FixedMealItem[]);
    }

    return (
        <div>
            <div className='category-filter-buttons'>
                {allFilters.map((filter, index) => (
                    <div className={`category-filter-button cursor ${activeFilter === filter ? 'current-filter' : ''}`}
                         key={index} onClick={() => changeFilter(filter)}>
                        {filter.at(0)?.toUpperCase()+filter.slice(1, filter.length)}
                    </div>
                ))}
            </div>
            <div className='added-item-card box-card-shadow cursor fixed-meal-card' onClick={(event) => editMealItem(fixedMeal, event, MealType.FIXED)}>
                <MealItemsCarousel mealItems={mealItems as FixedMealItem[]}/>
                <div className='fixed-meal-card-content'>
                    <h4>{fixedMeal.title}</h4>
                    <p>${fixedMeal?.price}</p>
                </div>
                <CustomMUITooltip
                    title={
                        <div className='menu-item-actions-labels'>
                            <button onClick={(event) => { event.stopPropagation(); editMealItem(fixedMeal, event, MealType.FIXED); }}>Edit</button>
                            <button onClick={(event) => { event.stopPropagation(); deleteMealItem(fixedMeal, event, MealType.FIXED); }}>Delete</button>
                        </div>
                    }
                    disableInteractive={false}
                    enterTouchDelay={0}
                    placement="bottom-end"
                    arrow>
                    <div className='element-center padding-10 fixed-meal-card-actions'>
                        <Icon icon='ph:dots-three-circle-vertical' fontSize={24} onClick={(event) => event.stopPropagation()}>⋮</Icon>
                    </div>
                </CustomMUITooltip>
            </div>
        </div>
    )
}

export default FixedMealCard;
