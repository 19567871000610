import './CheckoutCartMobile.css';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCartItemsCount, selectCheckoutCart} from "../../../../core/redux/slices/customerSlice";
import Modal from "react-modal";
import CheckoutItems from "../checkout-items/CheckoutItems";
import {toggleBodyScroll} from "../../../../pages/available-chefs/delivery-options/filter-button/FilterButton";
import { customStyles } from "../../../../pages/chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {Icon} from "@iconify/react";
import {useLocation} from "react-router-dom";

const CheckoutCartMobile = () => {

    const checkoutCart = useSelector(selectCheckoutCart);
    const cartItemsCount = useSelector(selectCartItemsCount);
    const location = useLocation();
    const [modalAnimation, setModalAnimation] = useState('');
    const [isCheckoutCartModalOpen, setIsCheckoutCartModalOpen] = useState(false);
    const hideOnPaths = ['/login', '/signup', '/checkout'];
    const isDashboardPath = location.pathname.startsWith('/chef/dashboard');

    const showCart = !hideOnPaths.includes(location.pathname) && !isDashboardPath;

    useEffect(() => {
        if(cartItemsCount === 0) {
            toggleBodyScroll(true);
        }
    },[cartItemsCount])

    if (cartItemsCount === 0 || window.innerWidth > 600 || !showCart) return null;

    const openModal = () => {
        setModalAnimation('modal-enter');
        setIsCheckoutCartModalOpen(true);
        toggleBodyScroll(false);
    }

    const closeModal = (event: React.MouseEvent) => {
        event.stopPropagation();
        setModalAnimation('modal-exit');
        setTimeout(() => {
            toggleBodyScroll(true);
            setIsCheckoutCartModalOpen(false);
        }, 500);
    }

    return (
        <div className='checkout-cart-mobile' onClick={openModal}>
            <img src={`${checkoutCart.chef?.profileImageUrl}?${process.env.REACT_APP_BLOB_SAS_TOKEN}`} className='checkout-cart-profile-image' alt='profile logo'/>
            <div>
                <sub>View Order</sub>
                <p className='chef-name'>{checkoutCart.chef?.name}</p>
            </div>
            <div className='cart-items'>
                <p>{cartItemsCount}</p>
            </div>
            <Modal isOpen={isCheckoutCartModalOpen} className={`checkout-cart-modal ${modalAnimation}`} style={customStyles} onRequestClose={closeModal}>
                <Icon icon='mingcute:close-fill' fontSize={24} className='close-checkout-cart-icon' onClick={closeModal}/>
                <CheckoutItems closeModal={closeModal}/>
            </Modal>
        </div>
    )
}

export default CheckoutCartMobile;
