import {Box, Card, Skeleton} from "@mui/material";
import React from "react";


const AvailableChefCardSkeleton = () => {
    return (
        <Card sx={{padding: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={200}/>
            <Box sx={{ marginTop: 2, paddingX: 1 }}>
                <Skeleton variant="circular" width={80} height={80} />
            </Box>
            <Box sx={{ marginTop: 2, paddingX: 1 }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="50%" />
                <Skeleton variant="text" width="100%" />
            </Box>
        </Card>
    )
}

export default AvailableChefCardSkeleton;
