// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-media-wrapper {
    background: white;
    border-radius: 10px;
}

.social-media-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 30px;
    margin-top: 20px;
}

.social-media-heading {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/social-media/SocialMedia.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,4CAA4C;AAChD","sourcesContent":[".social-media-wrapper {\n    background: white;\n    border-radius: 10px;\n}\n\n.social-media-form {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 15px 30px;\n    margin-top: 20px;\n}\n\n.social-media-heading {\n    padding: 15px 30px;\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1px solid var(--border-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
