import './UserRoadmap.css';
import UserRoadmapCard from "./user-roadmap-card/UserRoadmapCard";
import stepOneImage from '../../../assets/images/user-roadmap-step-1.png';
import stepTwoImage from '../../../assets/images/user-roadmap-step-2.png';
import stepThreeImage from '../../../assets/images/user-roadmap-step-3.png';
import {useTranslation} from "react-i18next";

const UserRoadmap = () => {

    const { t } = useTranslation('common');

    return (
        <div className='user-roadmap-wrapper'>
            <div style={{textAlign: 'center', width: '100%', marginTop: '50px'}} className='heading-font-large description-heading'>{t('customer-roadmap-heading')}</div>
            <div className='description-container'>
                <UserRoadmapCard stepNumber={1} heading={t('customer-roadmap-step-1.heading')} stepDescription={t('customer-roadmap-step-1.description')} imageSource={stepOneImage}/>
                <UserRoadmapCard stepNumber={2} heading={t('customer-roadmap-step-2.heading')} stepDescription={t('customer-roadmap-step-2.description')} imageSource={stepTwoImage}/>
                <UserRoadmapCard stepNumber={3} heading={t('customer-roadmap-step-3.heading')} stepDescription={t('customer-roadmap-step-3.description')} imageSource={stepThreeImage}/>
            </div>
        </div>
    )
}

export default UserRoadmap;
