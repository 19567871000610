import './ChefBusinessPage.css';
import ChefPersonalDetails from "./chef-personal-details/ChefPersonalDetails";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import store from "../../core/redux/store";
import Chef from "../../model/Chef";
import ChefLoadError from "./chef-load-error/ChefLoadError";
import PageChangeRouter from "../../components/shared/loaders/PageChangeLoader/PageChangeRouter";
import ChefMenuItems from "./chef-menu-items/ChefMenuItems";
import MenuSubCategories from "./menu-sub-categories/MenuSubCategories";
import MenuItem from "../../model/MenuItem";
import ChefPublicService from "../../core/services/ChefPublicService";

type ChefBusinessPageParams = {
    chefId: string,
}

const ChefBusinessPage = () => {

    const { chefId } = useParams<ChefBusinessPageParams>();
    const dispatchApi = useDispatch<typeof store.dispatch>();
    const [selectedChef, setSelectedChef] = useState<Chef>();
    const [loadingData, setLoadingData] = useState(false);
    const [chefLoadError, setChefLoadError] = useState(false);
    const [sortedMenu, setSortedMenu] = useState<Record<string, MenuItem[]>>({});
    const foodTypes = Object.keys(sortedMenu);
    const [activeFoodType, setActiveFoodType] = useState<string>(foodTypes[0]);
    const refsMap = useRef<Record<string, HTMLDivElement | null>>({});

    const sortMenuByFoodType = (menu: MenuItem[]): Record<string, MenuItem[]> => {
        return menu.reduce((acc, item) => {
            if (!acc[item.foodType]) {
                acc[item.foodType] = [];
            }
            acc[item.foodType].push(item);
            return acc;
        }, {} as Record<string, MenuItem[]>);
    };

    useEffect(() => {
        setLoadingData(true);
        dispatchApi(ChefPublicService.getChef(chefId as string))
            .unwrap()
            .then((response) => {
                setSelectedChef(response);
                setSortedMenu(sortMenuByFoodType(response.menu as MenuItem[]))
            })
            .catch((error) => {
                setChefLoadError(true);
            }).finally(() => {setTimeout(()=>setLoadingData(false),1500)});
    }, [dispatchApi, chefId]);

    return (
        <div className='chef-business-page'>
            {loadingData ? <PageChangeRouter /> :
                chefLoadError ?
                    <ChefLoadError />
                 :  <>
                        <ChefPersonalDetails chef={selectedChef}/>
                        <MenuSubCategories
                            foodTypes={foodTypes}
                            activeFoodType={activeFoodType}
                            setActiveFoodType={setActiveFoodType}
                            refsMap={refsMap}
                        />
                        <ChefMenuItems chef={selectedChef} activeFoodType={activeFoodType} menuItemsRecord={sortedMenu} refsMap={refsMap}/>
                    </>
            }
        </div>
    )
}

export default ChefBusinessPage;
