import findChef from '../../../../assets/images/find-chef.png';
import { useTranslation } from 'react-i18next';
import MeetChefButton from "./meet-chef-button/MeetChefButton";

const MeetChefs = () => {

    const { t } = useTranslation('MeetChefs');

    return (
        <div className='meet-chefs-content'>
            <div className='content-image'>
                <img src={findChef} alt='meet'/>
            </div>
            <div className='content-description-wrapper'>
                <span className='heading-font-large'>{t('heading')}</span>
                <span className='description'>{t('description')}</span>
                <MeetChefButton backgroundColor='primary'/>
            </div>
        </div>
    )
}

export default MeetChefs;
