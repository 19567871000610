// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-category-modal-heading {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid var(--border-light);
    justify-content: space-between;
}

.add-category-content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .add-new-category-modal {
        max-width: 90%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/add-new-item/add-category-modal/AddCategoryModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,4CAA4C;IAC5C,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".add-category-modal-heading {\n    display: flex;\n    padding: 15px;\n    border-bottom: 1px solid var(--border-light);\n    justify-content: space-between;\n}\n\n.add-category-content {\n    display: flex;\n    padding: 20px;\n    flex-direction: column;\n    gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .add-new-category-modal {\n        max-width: 90%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
