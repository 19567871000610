import MenuItem from "../../../../../model/MenuItem";
import MealItemCard from "../../fixed-meal-plan/menu-item-card/MealItemCard";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import './MealPlanItemList.css';
import {FixedMealItem} from "../../../../../model/meal-plans/FixedMeal";

type MealPlanItemListProps = {
    onApplyMealItemsClicked: (mealItemType: string, mealItems: FixedMealItem[]) => void;
    menu?: MenuItem[],
    mealItemType: string,
    mealItems: FixedMealItem[]
}

const MealPlanItemList: React.FC<MealPlanItemListProps> = ({onApplyMealItemsClicked, mealItems, menu, mealItemType}) => {

    const [mealPlanItems, setMealPlanItems] = useState<FixedMealItem[]>(mealItems);

    const onSelectMealItems = (menuItem: MenuItem, quantity: number) => {
        const itemInArrayIndex = mealPlanItems.findIndex(mealItem => mealItem.menuItem.id === menuItem.id);

        if (quantity === 0) {
            return setMealPlanItems(mealPlanItems.filter(mealItem => mealItem.menuItem !== menuItem));
        }

        if (itemInArrayIndex > -1) {
            const newMealItems = [...mealPlanItems];
            newMealItems[itemInArrayIndex] = { menuItem, quantity };
            setMealPlanItems(newMealItems);
        } else {
            setMealPlanItems([...mealPlanItems, { menuItem, quantity }]);
        }
    }

    const itemQuantity = (menuItem: MenuItem) : number => {

        const itemInArrayIndex = mealPlanItems.findIndex(mealItem => mealItem.menuItem.id === menuItem.id);

        return itemInArrayIndex > -1 ? mealPlanItems[itemInArrayIndex].quantity : 0;
    }

    return (
        <div className='box-card-shadow padding-20 meal-plan-items-wrapper'>
            {
                menu?.length && menu?.length > 0 ?
                    <div className='meal-plans-items-list'>
                        {menu?.map((menuItem: MenuItem, index) => (
                            <MealItemCard mealItem={menuItem} onSelectMenuItem={onSelectMealItems}
                                          quantity={itemQuantity(menuItem)} key={index}/>
                        ))}
                    </div> :
                    <div className='empty-menu-items'>
                        <p>Unable to find the menu items</p>
                        <NavLink to='/chef/dashboard/menu'><button className='add-menu-button'>Add menu items</button></NavLink>
                    </div>
            }
            <div className='primary-button-right-end-wrapper'>
                <div className={`primary-button apply-meal-items-button`} onClick={() => onApplyMealItemsClicked(mealItemType, mealPlanItems)}>Apply</div>
            </div>
        </div>
    )
}

export default MealPlanItemList;
