import './WhyUs.css';
import WhyUsCard from "./why-us-card/WhyUsCard";
import {useTranslation} from "react-i18next";

const WhyUs = () => {

    const { t } = useTranslation('BecomeAChef');

    return (
        <div className='why-us-container'>
            <div className='heading-font-large why-us-heading'>Why US</div>
            <div className='why-us-details'>
                <WhyUsCard icon={'material-symbols-light:business-center-outline'} heading={t('why-us-2.heading')} information={t('why-us-2.description')} />
                <WhyUsCard icon={'material-symbols-light:food-bank-outline-rounded'} heading={t('why-us-3.heading')} information={t('why-us-3.description')} />
                <WhyUsCard icon={'ph:money'} heading={t('why-us-1.heading')} information={t('why-us-1.description')} />
                <WhyUsCard icon={'material-symbols-light:sentiment-very-satisfied-outline'} heading={t('why-us-4.heading')} information={t('why-us-4.description')} />
            </div>
        </div>
    )
}

export default WhyUs;
