import './NewChefOfferModal.css';
import {useEffect, useState} from "react";
import Modal from "react-modal";
import newChefOfferImage from '../../../assets/images/chef-special-offer.png';
import {customStyles} from "../../chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {Icon} from "@iconify/react";
import {HashLink} from "react-router-hash-link";

const NewChefOfferModal = () => {

        const [showOffer, setShowOffer] = useState(false);

        useEffect(() => {
            const checkFirstVisit = () => {
                const hasVisited = localStorage.getItem('hasVisitedBecomeAChefPage');

                if (!hasVisited) {
                    setShowOffer(true);
                    localStorage.setItem('hasVisitedBecomeAChefPage', 'true');
                }
            };

            checkFirstVisit();
        }, []);

        const closeModal = () => {
            setShowOffer(false);
        }

        return (
            <Modal isOpen={showOffer} style={customStyles} className='basic-modal new-chef-offer-modal' onRequestClose={closeModal}>
                <div className='close-new-offer-modal element-center cursor' onClick={closeModal}><Icon icon='material-symbols:close' fontSize={24}/></div>
                <img src={newChefOfferImage}  className='new-chef-offer-image' alt='chef-offer'/>
                <div className='new-chef-offer-detail'>
                    <div className='row-1'>
                        <p className='heading-font-large main-heading-wrapper'>Receive a <span className='new-offer-main-heading'>$200</span></p>
                        <p>bonus upon completing your first 30 orders</p>
                    </div>
                    <HashLink smooth to='#chef-registration-form' role='button' className='link-black primary-button register-now-button' onClick={closeModal}>Register now</HashLink>
                </div>
            </Modal>
        );
}

export default NewChefOfferModal;
