// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delivery-date-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1;
}

.date-picker-container {
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #E4E4E4;
    justify-content: space-between;
}

.react-datepicker-wrapper, .react-datepicker-wrapper input {
    width: 100%;
}

.date-picker-container input {
    border: none;
    position: relative;
    z-index: 1;
    background: transparent;
    font-size: 16px;
}

.date-picker-container input:focus {
    outline: none;
}

.date-picker-container input:hover {
    cursor: pointer;
}

.delivery-date:hover {
    border: 1px solid var(--primary-color);
}
.delivery-date:focus {
    outline: none;
}

.datepicker-icon {
    position: absolute;
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/delivery-options/delivery-date/DeliveryDate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sCAAsC;AAC1C;AACA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".delivery-date-container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    flex: 1;\n}\n\n.date-picker-container {\n    display: flex;\n    position: relative;\n    align-items: center;\n    border-radius: 10px;\n    padding: 15px;\n    border: 1px solid #E4E4E4;\n    justify-content: space-between;\n}\n\n.react-datepicker-wrapper, .react-datepicker-wrapper input {\n    width: 100%;\n}\n\n.date-picker-container input {\n    border: none;\n    position: relative;\n    z-index: 1;\n    background: transparent;\n    font-size: 16px;\n}\n\n.date-picker-container input:focus {\n    outline: none;\n}\n\n.date-picker-container input:hover {\n    cursor: pointer;\n}\n\n.delivery-date:hover {\n    border: 1px solid var(--primary-color);\n}\n.delivery-date:focus {\n    outline: none;\n}\n\n.datepicker-icon {\n    position: absolute;\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
