// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-safety-container {
    background: white;
    border-radius: 10px;
}

.food-safety-heading-container {
    display: flex;
    padding: 15px 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-light);
}

.checkbox-wrapper {
    padding: 15px 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/food-safety-details/FoodSafetyDetails.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,4CAA4C;AAChD;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".food-safety-container {\n    background: white;\n    border-radius: 10px;\n}\n\n.food-safety-heading-container {\n    display: flex;\n    padding: 15px 30px;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.checkbox-wrapper {\n    padding: 15px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
