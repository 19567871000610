import './BestChefs.css';
import {useTranslation} from "react-i18next";
import BestChefList from "./best-chef-list/BestChefList";

const BestChefs = () => {

    const { t } = useTranslation();

    return (
        <div className='best-chef-wrapper'>
            <p className='heading-font-large' style={{textAlign: 'center'}}>{t('best-chef-heading')}</p>
            <BestChefList />
        </div>
    )
}

export default BestChefs;
