// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    display: flex;
    gap: 20px;
    flex: 1 1;
    align-items: center;
    flex-direction: column;
}

.card-image-wrapper > * {
    color: var(--primary-color);
}

.card-image-wrapper {
    padding: 20px;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background-color: #F6F6F6;
}

.card-information {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .card-container {
        gap: 10px;
    }
    .card-information {

    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/why-us/why-us-card/WhyUsCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,SAAO;IACP,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,SAAS;IACb;IACA;;IAEA;AACJ","sourcesContent":[".card-container {\n    display: flex;\n    gap: 20px;\n    flex: 1;\n    align-items: center;\n    flex-direction: column;\n}\n\n.card-image-wrapper > * {\n    color: var(--primary-color);\n}\n\n.card-image-wrapper {\n    padding: 20px;\n    border-radius: 50%;\n    height: 90px;\n    width: 90px;\n    background-color: #F6F6F6;\n}\n\n.card-information {\n    text-align: center;\n}\n\n@media screen and (max-width: 600px) {\n    .card-container {\n        gap: 10px;\n    }\n    .card-information {\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
