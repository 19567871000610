// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-documents-container {
    background: var(--grey2);
    margin: 0 auto;
    padding: 50px 100px;
}

.legal-documents-heading {
    padding-bottom: 30px;
}

@media screen and (max-width: 600px) {
    .legal-documents-container {
        padding: 50px 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/legal-document-pages/LegalDocument.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".legal-documents-container {\n    background: var(--grey2);\n    margin: 0 auto;\n    padding: 50px 100px;\n}\n\n.legal-documents-heading {\n    padding-bottom: 30px;\n}\n\n@media screen and (max-width: 600px) {\n    .legal-documents-container {\n        padding: 50px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
