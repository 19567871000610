// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-detail-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgba(246, 246, 246, 0.33);
    padding: 50px 100px;
    gap: 40px;
}

.contact-us-column {
    background: white;
    padding: 20px 30px;
    flex: 1 1;
    width: 100%;
}

.column-image {
    height: 150px;
    width: 200px;
}

.contact-us-column .row {
    display: flex;
    gap: 10px;
}

.contact-column-detail {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width: 600px){
    .contact-us-detail-container {
        flex-direction: column;
        padding: 30px;
    }

}
`, "",{"version":3,"sources":["webpack://./src/pages/contact-us-page/contact-us-detail/ContactUsDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,qCAAqC;IACrC,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,SAAO;IACP,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;QACtB,aAAa;IACjB;;AAEJ","sourcesContent":[".contact-us-detail-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    background: rgba(246, 246, 246, 0.33);\n    padding: 50px 100px;\n    gap: 40px;\n}\n\n.contact-us-column {\n    background: white;\n    padding: 20px 30px;\n    flex: 1;\n    width: 100%;\n}\n\n.column-image {\n    height: 150px;\n    width: 200px;\n}\n\n.contact-us-column .row {\n    display: flex;\n    gap: 10px;\n}\n\n.contact-column-detail {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n@media screen and (max-width: 600px){\n    .contact-us-detail-container {\n        flex-direction: column;\n        padding: 30px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
