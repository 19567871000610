import {createAsyncThunk} from "@reduxjs/toolkit";
import Chef from "../../model/Chef";
import {ResponseError} from "./CustomerService";


class ChefPublicService {

    static register = createAsyncThunk(
        'registerChef',
        async (chef: Chef) => {

            console.log(chef);
            const saveChefResponse = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/chef/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(chef),
            });

            if (saveChefResponse.ok) {
                return await saveChefResponse.json();
            }
        }
    )

    static getChef = createAsyncThunk<Chef, string, { rejectValue: ResponseError }>(
        'getChef',
        async (chefId, {rejectWithValue, dispatch}) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/chef/business-page/${chefId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    const error: ResponseError = await response.json();
                    return rejectWithValue(error);
                }

                const data: Chef = await response.json();

                return data;
            } catch (error) {
                return rejectWithValue({message: 'Unable to save'} as ResponseError);
            }
        }
    );

    static verifyEmailAvailable = async (email: string) => {

        const isEmailExistsResponse = await fetch(
            `${process.env.REACT_APP_HOME_EATZ_API_URL}/chef/email/availability?email=${email}`, {method: 'GET'});

        if (isEmailExistsResponse.ok) {
            const responseData = await isEmailExistsResponse.json();
            return responseData.emailAvailable;
        } else {
            throw new Error('Unable to verify email');
        }
    }

    static retrieveChefs = createAsyncThunk<Chef[], {latitude?: number, longitude?: number, radius: number},  {rejectValue: ResponseError}> (
        'chefRetrieval',
        async (payload) => {

            const chefRetrievalListResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/chefList/getChefs?latitude=${payload.latitude}&longitude=${payload.longitude}&radius=${payload.radius*1000}`, {
                    method: 'Get',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!chefRetrievalListResponse.ok){
                const error: ResponseError = await chefRetrievalListResponse.json();
            }
            if(chefRetrievalListResponse.ok){
                return await chefRetrievalListResponse.json();
            }
        }
    )
}

export default ChefPublicService;