import './ChefMenu.css';
import SortMenu from "./sort-menu/SortMenu";
import {Icon} from "@iconify/react";
import React, {useCallback, useEffect, useState} from "react";
import AddedItems from "./added-items/AddedItems";
import AddNewItem from "./add-new-item/AddNewItem";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setMenu} from "../../../core/redux/slices/chefSlice";
import MenuItem from "../../../model/MenuItem";
import EmptyChefMenu from "./empty-menu-list/EmptyChefMenu";
import ChefService from "../../../core/services/ChefService";
import {useTranslation} from "react-i18next";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../core/redux/store";
import {selectToken} from "../../../core/redux/slices/loginSlice";
import CategoryFilterButtons from "./added-items/category-filter-buttons/CategoryFilterButtons";

const ChefMenu = () => {

    const [addItem, setAddItem] = useState(false);
    const chef = useSelector(selectChef);
    const reduxMenu = useSelector(selectChef).menu;
    const [menu, setChefMenu] = useState(reduxMenu);
    const [editItem, setEditItem] = useState<MenuItem>();
    const { t } = useTranslation('common');
    const apiDispatch = useDispatch<typeof store.dispatch>();

    const handleEditItem = useCallback((menuItem: MenuItem) => {
        setEditItem(menuItem);
    }, []);

    useEffect(  () => {

        const fetchMenu = async () => {
            try {
                const resultAction = await apiDispatch(ChefService.getMenu({
                    id: chef.id as string
                }));
                const menu: MenuItem[] = unwrapResult(resultAction);
                apiDispatch(setMenu(menu))
                setChefMenu(menu);
            } catch (error) {
                console.log(error);
            }
        }
        fetchMenu();
    },[])

    useEffect(() => {
        setChefMenu(reduxMenu);
    }, [reduxMenu])

    const handleFoodCategoryFilter = (menu: MenuItem[]) => {
        setChefMenu(menu);
    }


    const handleCloseAddNewItem = useCallback(()=>{
        if(addItem){
            setAddItem(false);
        }
        if(editItem){
            setEditItem(undefined);
        }
    },[addItem, editItem]);

    const addItemToMenu = () => {
        setAddItem(true);
    }

    return (
        <div className='chef-menu-container'>
            { (!addItem && !editItem) &&
                <>
                    <div className='chef-menu-heading'>
                        <div className={`${addItem ? '' : 'selected-heading'}`}>
                            <h2>List</h2>
                        </div>
                        <div className='chef-menu-add-column'>
                            {(chef.menu?.length ?? 0) > 0 &&
                                <SortMenu/>
                            }
                            <div className='add-item-button cursor' onClick={addItemToMenu}>
                                <Icon icon='material-symbols:add' fontSize={24}/>
                                Add New
                            </div>
                        </div>
                    </div>
                    {chef.menu?.length === 0 && <EmptyChefMenu />}

                    {(chef.menu?.length ?? 0) > 0 &&
                        <>
                            <CategoryFilterButtons onFilterMenu={handleFoodCategoryFilter}/>
                            <AddedItems handleEditItem={handleEditItem} menu={menu as MenuItem[]}/>
                        </>
                    }
                </>
            }
            {addItem && <AddNewItem isEditModeOn={false} handleCloseAddNewItem={handleCloseAddNewItem}/>}
            {editItem && <AddNewItem isEditModeOn={true} item={editItem} handleCloseAddNewItem={handleCloseAddNewItem}/>}
        </div>
    )
}

export default ChefMenu;
