// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklist-container {
    background: white;
    border-radius: 8px;
    flex: 1 1;
    margin-top: 20px;
    padding-bottom: 80px;
}

.checklist-heading {
    padding: 20px;
    border-bottom: 1px solid var(--border-light);
}

.checklist-row {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-light);
}

.checklist-row:hover {
    background: var(--grey2);
    cursor: pointer;
}

.checklist-column1 {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.checklist-column1 sub {
    color: #605E5C;
}

.checklist-row-icon {
    color: green;
}

.checklist-row-heading {
    display: flex;
    gap: 16px;
    align-items: center;
}

.checklist-step-icon {
    color: #EEB113;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
}

@media screen and (max-width: 600px) {
    .checklist-row {
        gap: 10px;
    }
    .right-arrow-icon {
        min-width: 24px;
        min-height: 24px;
    }
    .checklist-column1 {
        gap: 10px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/onboarding/checklist/ChefCheckList.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,SAAO;IACP,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,4CAA4C;AAChD;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI;QACI,SAAS;IACb;IACA;QACI,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,SAAS;IACb;AACJ","sourcesContent":[".checklist-container {\n    background: white;\n    border-radius: 8px;\n    flex: 1;\n    margin-top: 20px;\n    padding-bottom: 80px;\n}\n\n.checklist-heading {\n    padding: 20px;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.checklist-row {\n    display: flex;\n    padding: 15px;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.checklist-row:hover {\n    background: var(--grey2);\n    cursor: pointer;\n}\n\n.checklist-column1 {\n    display: flex;\n    gap: 16px;\n    flex-direction: column;\n}\n\n.checklist-column1 sub {\n    color: #605E5C;\n}\n\n.checklist-row-icon {\n    color: green;\n}\n\n.checklist-row-heading {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n}\n\n.checklist-step-icon {\n    color: #EEB113;\n    border-radius: 50%;\n    border: 1px solid var(--primary-color);\n}\n\n@media screen and (max-width: 600px) {\n    .checklist-row {\n        gap: 10px;\n    }\n    .right-arrow-icon {\n        min-width: 24px;\n        min-height: 24px;\n    }\n    .checklist-column1 {\n        gap: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
