import './FilterButton.css';
import {Icon} from "@iconify/react";
import {useState} from "react";
import FilterChefsModal from "./filter-chefs-modal/FilterChefsModal";

export const toggleBodyScroll = (shouldEnableScroll: boolean) => {
    document.body.classList.toggle('body-no-scroll', !shouldEnableScroll);
};

const FilterButton = () => {

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const openModal = () => {
        toggleBodyScroll(false);
        setIsFilterModalOpen(true);
    }

    const closeModal = () => {
        toggleBodyScroll(true);
        setIsFilterModalOpen(false);
    }

    return (
        <div className='available-chef-filter-container'>
            <div className='filter-button-wrapper' onClick={openModal}>
                <Icon icon='lets-icons:filter' fontSize={24}/>
                <p>Filter By</p>
            </div>
            <FilterChefsModal isFilterChefsModalOpen={isFilterModalOpen} closeFilterChefsModal={closeModal} />
        </div>
    )
}

export default FilterButton;
