import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface modalState {
    isModalOpen: boolean;
    isNewChefGuidelinesModalOpen: boolean;
}

const initialState: modalState = {
    isModalOpen : false, isNewChefGuidelinesModalOpen: false,
};

const modalSlice = createSlice({
    name: 'loginModal',
    initialState,
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
        openNewChefGuidelinesModal: (state) => {
            state.isNewChefGuidelinesModalOpen = true;
        },
        closeNewChefGuidelinesModal: (state) => {
            state.isNewChefGuidelinesModalOpen = false;
        }
    },
});

export const { openModal, closeModal, openNewChefGuidelinesModal, closeNewChefGuidelinesModal } = modalSlice.actions;
export default modalSlice.reducer;

export const isNewChefGuidelinesModalOpen = (state: RootState) => state.modal.isNewChefGuidelinesModalOpen;
