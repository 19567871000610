// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location{
    background-color: var(--primary-light-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 6px;
    margin-inline-start: 6px;
}

.location:hover{
    background: var(--background);
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 2px 4px 8px rgb(7, 7, 7);
}

.modal:focus{
    outline: none;
}

.location-permission{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 36px 0 24px 0;
}

.allow-button{
    flex: 0.45 1;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    border: 1px black solid;
    background-color: var(--primary-color);
}

.allow-button:hover{
    cursor: pointer;
    background-color: var(--primary-color-hover);
}

.block-button{
    flex: 0.45 1;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    border: 1px var(--primary-color) solid;
}

.block-button:hover{
    cursor: pointer;
    background-color: gainsboro;
}

@media screen and (max-width: 600px) {
    .location-modal {
        width: 90%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/activate-location/ActivateLocation.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,sCAAsC;IACtC,kBAAkB;IAClB,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA;IACI,YAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;IACvB,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,4CAA4C;AAChD;;AAEA;IACI,YAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".location{\n    background-color: var(--primary-light-color);\n    border: 1px solid var(--primary-color);\n    border-radius: 4px;\n    padding: 6px;\n    margin-inline-start: 6px;\n}\n\n.location:hover{\n    background: var(--background);\n    cursor: pointer;\n}\n\n.modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 12px;\n    box-shadow: 2px 4px 8px rgb(7, 7, 7);\n}\n\n.modal:focus{\n    outline: none;\n}\n\n.location-permission{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    margin: 36px 0 24px 0;\n}\n\n.allow-button{\n    flex: 0.45;\n    padding: 8px;\n    border-radius: 8px;\n    text-align: center;\n    border: 1px black solid;\n    background-color: var(--primary-color);\n}\n\n.allow-button:hover{\n    cursor: pointer;\n    background-color: var(--primary-color-hover);\n}\n\n.block-button{\n    flex: 0.45;\n    padding: 8px;\n    border-radius: 8px;\n    text-align: center;\n    border: 1px var(--primary-color) solid;\n}\n\n.block-button:hover{\n    cursor: pointer;\n    background-color: gainsboro;\n}\n\n@media screen and (max-width: 600px) {\n    .location-modal {\n        width: 90%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
