
import MenuItem from "../../../../../../../model/MenuItem";
import {imageUrlConverter} from "../../../../../../../core/utils/ImageUrlConverter";
import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {customCheckboxProps, customLabelProps} from "../../../../../../../custom-tooltips-dropdowns/CustomMUITooltip";

type CustomizableMealItemCardProps = {
    mealItem: MenuItem,
    isChecked: boolean,
    onSelectMenuItem: (menuItem: MenuItem) => void;
}

const CustomizableMealItemCard: React.FC<CustomizableMealItemCardProps> = ({mealItem, onSelectMenuItem, isChecked}) => {

    const imageUrl = imageUrlConverter(mealItem.imageUrl as string);

    const handleMenuItemStaged = () => {
        onSelectMenuItem(mealItem);
    }

    return (
        <div className='element-center column gap-10'>
            <img src={imageUrl} alt='menu-item' className='menu-item-image'/>
            <FormControlLabel control={<Checkbox checked={isChecked} sx={customCheckboxProps} onClick={handleMenuItemStaged}/>}
                              label={mealItem.name}  sx={customLabelProps} />
        </div>
    )
}

export default CustomizableMealItemCard;
