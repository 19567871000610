import './OrderTable.css';
import React, {useCallback, useState} from 'react';
import OrderPreviewModal from "../order-preview-modal/OrderPreviewModal";
import Order from "../../../../model/order/Order";



const orders: Order[] = [];

const OrderTable = () => {

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [orderForPreview, setOrderToPreview] = useState<Order>();
    const [animationClass, setAnimationClass] = useState('');

    const handleChangeStatus = (order: Order) => {

    }

    const handleOpenOrderPreviewModal = (order: Order) => {
        setIsPreviewModalOpen(true);
        setOrderToPreview(order);
        setAnimationClass('fade-in')
        document.body.classList.add('body-no-scroll');
    }

    const handleClosePreviewModal = useCallback(() => {
        setIsPreviewModalOpen(false);
        setAnimationClass('fade-out')
        document.body.classList.remove('body-no-scroll');
    },[]);

    return (
        <div className='order-table'>
            {orders.map((order) => (
                <div key={order.id} onClick = {() => handleOpenOrderPreviewModal(order)} className='cursor order-table-row box-card-shadow'>
                    {/*<div className='order-row-column'>*/}
                    {/*    <p><span className='grey-light-text'>#{order.id} - </span>{order.customerName}</p>*/}
                    {/*    <div className={order.orderSchedule === 'Future' ? 'order-status-schedule' : ''}>*/}
                    {/*        <p className={`status ${order.status.toLowerCase()}`}>{order.status}</p>*/}
                    {/*        {(order.orderSchedule === 'Future' && order.status === 'New') &&*/}
                    {/*            <p className='future-order-flag'><Icon icon='raphael:future' fontSize={20}/>Future Order</p>}*/}
                    {/*    </div>*/}
                    {/*    <p className='button-primary open-preview-button' onClick={() => handleOpenOrderPreviewModal(order)}>Show Details</p>*/}
                    {/*</div>*/}

                    {/*<div className='carousel-data' onClick={(e) => e.stopPropagation()}>*/}
                    {/*    <OrderItemsCarousel orderList={order.orderItems} />*/}
                    {/*</div>*/}
                    {/*<div className='order-row-date-column'>*/}
                    {/*    <p>{order.dateOfOrder.toDateString()}</p>*/}
                    {/*    <p>Total Price: ${order.total}</p>*/}
                    {/*</div>*/}

                    {/*<div onClick={(e) => e.stopPropagation()} className='action-container'>*/}
                    {/*    <CustomMUITooltip*/}
                    {/*        title={*/}
                    {/*            <div className='order-table-row-action'>*/}
                    {/*                <h4>Change Status</h4>*/}
                    {/*                <hr />*/}
                    {/*                <p onClick={()=>handleChangeStatus(order)}>Ready for delivery</p>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        enterTouchDelay={0}*/}
                    {/*        placement="bottom-end"*/}
                    {/*        arrow>*/}
                    {/*        <IconButton>*/}
                    {/*            <MoreHoriz />*/}
                    {/*        </IconButton>*/}
                    {/*    </CustomMUITooltip>*/}
                    {/*</div>*/}
                </div>
            ))}
            <OrderPreviewModal isPreviewModalOpen={isPreviewModalOpen} handleCloseModal={handleClosePreviewModal} order={orderForPreview} animationClass={animationClass}/>
        </div>
    );
};

export default OrderTable;

