// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cuisines-selector-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cuisine-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.cuisine-heading-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.speciality-cuisine-heading {
    font-size: 20px;
    font-weight: 500;
}

.cuisine-button {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px;
    background: var(--primary-light-color);
    border-radius: 10px;
}

.cuisine-button.selected-cuisine {
    border: 1px solid var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/new-chef-guidelines/cuisine-list/CuisineList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,aAAa;IACb,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".cuisines-selector-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.cuisine-list {\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n}\n\n.cuisine-heading-wrapper {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.speciality-cuisine-heading {\n    font-size: 20px;\n    font-weight: 500;\n}\n\n.cuisine-button {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    padding: 15px;\n    background: var(--primary-light-color);\n    border-radius: 10px;\n}\n\n.cuisine-button.selected-cuisine {\n    border: 1px solid var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
