import './AccountManager.css';
import ProfilePhotoPlaceholder
    from "../../../../pages/chef-dashboard/profile/edit-profile-picture-modal/profile-photo-placeholder/ProfilePhotoPlaceholder";
import React, {useState} from "react";
import Customer from "../../../../model/Customer";
import Chef from "../../../../model/Chef";
import {ClickAwayListener} from "@mui/material";
import {useDispatch} from "react-redux";
import store from "../../../../core/redux/store";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../../core/redux/slices/loginSlice";

type ProfileSignoutPlaceholderProps = {
    user?: Chef | Customer,
}

const AccountManager: React.FC<ProfileSignoutPlaceholderProps> = ({user}) => {

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const dispatch = useDispatch<typeof store.dispatch>();
    const navigateTo = useNavigate();

    const handleProfileOptions = () => {
        setIsTooltipOpen(true);
    }

    const closeTooltip = () => {
        setIsTooltipOpen(false);
    }

    const handleSignOut = async () => {
        dispatch({type: 'logout'});
        navigateTo('/login');
    }

    return (
        <ClickAwayListener onClickAway={closeTooltip}>
            <div onClick={handleProfileOptions} className='account-manager-container cursor'>
                {user?.profileImageUrl ? <img src={user?.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN} className='profile-photo' alt='profile'/>
                    :
                    <div className='photo-placeholder-wrapper'>
                        <ProfilePhotoPlaceholder containerSize={'extra-small-size'} name={user?.name as string}/>
                    </div>
                }
                {isTooltipOpen &&
                    <div className='account-info-tootlip'>
                        <div onClick={handleSignOut} className='signout-button'>
                            <p>Sign out</p>
                        </div>
                    </div>
                }
            </div>
        </ClickAwayListener>

    )
}

export default AccountManager;
