import './ContactUsPage.css';
import ContactUsForm from "./contact-us-form/ContactUsForm";
import ContactUsDetail from "./contact-us-detail/ContactUsDetail";
import React from "react";
import {useTranslation} from "react-i18next";

const ContactUsPage = () => {

    const { t } = useTranslation('common');

    return (
        <div className='contact-us-page'>
            <div className='contact-form-details'>
                <p className='heading-font-large text-center'>{t('contact-us.heading')}</p>
                <p className='text-center'>{t('contact-us.description')}</p>
                <ContactUsForm />
            </div>
            <ContactUsDetail />
        </div>
    )
}

export default ContactUsPage;
