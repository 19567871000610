// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sort-menu {
    padding: 8px;
    border: 1px solid var(--border-light);
    border-radius: 8px;
    width: 100px;
}

.sort-menu p {
    gap: 4px;
}

.sort-menu-container {
    position: relative;
}

.sort-menu-options {
    position: absolute;
    top: calc(100% + 5px);
    border-radius: 8px;
    background: white;
}

.sort-menu-options p {
    gap: 10px;
    padding: 10px 20px;
    display: flex;
}

.sort-menu-options p:hover {
    cursor: pointer;
    background: var(--primary-light-color);
}

.sort-menu:focus {
    outline: none;
}

@media screen and (max-width: 600px) {

}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/sort-menu/SortMenu.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,aAAa;AACjB;;AAEA;;AAEA","sourcesContent":["\n.sort-menu {\n    padding: 8px;\n    border: 1px solid var(--border-light);\n    border-radius: 8px;\n    width: 100px;\n}\n\n.sort-menu p {\n    gap: 4px;\n}\n\n.sort-menu-container {\n    position: relative;\n}\n\n.sort-menu-options {\n    position: absolute;\n    top: calc(100% + 5px);\n    border-radius: 8px;\n    background: white;\n}\n\n.sort-menu-options p {\n    gap: 10px;\n    padding: 10px 20px;\n    display: flex;\n}\n\n.sort-menu-options p:hover {\n    cursor: pointer;\n    background: var(--primary-light-color);\n}\n\n.sort-menu:focus {\n    outline: none;\n}\n\n@media screen and (max-width: 600px) {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
