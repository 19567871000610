import React from 'react';
import {Card, CardContent, Skeleton} from '@mui/material';
import MenuItem from "../../../../../model/MenuItem";
import useImageLoader from "../../../../../custom-hooks/useImageLoader";
import CustomMUITooltip from "../../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import {imageUrlConverter} from "../../../../../core/utils/ImageUrlConverter";
import {Icon} from "@iconify/react";

interface MenuItemProps {
    menuItem: MenuItem;
    handleEditMenuItem: (menuItem: MenuItem, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
    handleOpenDeleteModal: (menuItem: MenuItemProps['menuItem'], event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    itemDeleted: boolean;
    itemToDelete?: MenuItem;
    daysOfWeek: string[];
}

const MenuItemCard: React.FC<MenuItemProps> = ({menuItem, handleEditMenuItem, handleOpenDeleteModal, itemDeleted, itemToDelete, daysOfWeek}) => {

    const imageLoaded = useImageLoader(`${menuItem.imageUrl}?${process.env.REACT_APP_BLOB_SAS_TOKEN}`);
    const imageUrl = imageUrlConverter(menuItem.imageUrl as string);

    return (
        <div className='added-item-card box-card-shadow cursor' onClick={(event) => handleEditMenuItem(menuItem, event)}>
            {imageLoaded ?
                <div className='added-item-card-image-wrapper'>
                    <p className='added-item-status'>{menuItem.readyToPublish ? 'Active' : 'Draft'}</p>
                    <img src={imageUrl} alt='menu item'  className='added-item-card-image'/>
                </div> :
                <Card>
                    <Skeleton variant="rectangular" width="100%" height={200}/>
                    <CardContent>
                        <Skeleton width="60%"/>
                        <Skeleton width="80%"/>
                        <Skeleton width="40%"/>
                    </CardContent>
                </Card>
            }
            <div className='added-item-card-content'>
                <h4>{menuItem.name}</h4>
                <p className='grey-light-text menu-item-description'>{menuItem.description}</p>
                <p>${menuItem.price}</p>
            </div>
             <CustomMUITooltip
                 title={
                     <div className='menu-item-actions-labels'>
                         <button onClick={(event) => { event.stopPropagation(); handleEditMenuItem(menuItem, event); }}>Edit</button>
                         <button onClick={(event) => { event.stopPropagation(); handleOpenDeleteModal(menuItem, event); }}>Delete</button>
                     </div>
                 }
                 disableInteractive={false}
                 enterTouchDelay={0}
                 placement="bottom-end"
                 arrow>
                 <div className='element-center padding-10'>
                     <Icon icon='ph:dots-three-circle-vertical' fontSize={24} onClick={(event) => event.stopPropagation()}>⋮</Icon>
                 </div>
             </CustomMUITooltip>
        </div>
    )
}

export default MenuItemCard;
