import './DeliveryDetailsStep.css';
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../../../core/redux/slices/loginSlice";
import useFormInput from "../../../../custom-hooks/useFormInput";
import {selectCustomer} from "../../../../core/redux/slices/customerSlice";
import {Icon} from "@iconify/react";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import EditAddressModal from "./edit-address-modal/EditAddressModal";
import React, {useState} from "react";
import {selectChosenAddress} from "../../../../core/redux/slices/profileManagementSlice";
import {setSuggestions} from "../../../../core/redux/slices/addressSuggestionSlice";
import OrderDeliveryType from "./order-delivery-type/OrderDeliveryType";
import {TextField} from "@mui/material";
import {customInputField} from "../payment-detail-step/PaymentDetailStep";


const DeliveryDetailsStep = () => {

    const userType = useSelector(selectUser).userType;
    const customer = useSelector(selectCustomer);
    const chef = useSelector(selectChef);
    const customerData = userType === 'chef' ? chef : customer;
    const selectedAddress = useSelector(selectChosenAddress);
    const name = useFormInput(customerData.name,true);
    const specialInstructions = useFormInput('',true);
    const [addDeliveryInstructions, setAddDeliveryInstructions] = useState(false);
    const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
    const dispatch = useDispatch();

    const handleEditAddress = () => {
        document.body.classList.add('body-no-scroll');
        setIsEditAddressModalOpen(true);
    }


    const closeEditAddressModal = () => {
        document.body.classList.remove('body-no-scroll');
        setIsEditAddressModalOpen(false);
        dispatch(setSuggestions([]));
    }

    return (
        <div className='checkout-step-wrapper'>
            <div className='checkout-step-heading-wrapper flex-space-between'>
                <p className='step-heading'>Delivery Details</p>
                <OrderDeliveryType />
            </div>
            <div className='content'>
                <div className='checkout-address-wrapper'>
                    <label className='checkout-form-input-label'>Address</label>
                    <div className='selected-address-row flex-space-between'>
                        <div className='selected-address'>
                            <Icon icon='carbon:location-filled' className='address-location-icon' />
                            <div className='selected-address-column'>
                                <p className='street-address'>{selectedAddress?.streetAddress}</p>
                                <p>{`${selectedAddress?.city}, ${selectedAddress?.postalCode}`}</p>
                            </div>
                        </div>
                        <div className='edit-checkout-button' onClick={handleEditAddress}>Edit</div>
                    </div>
                </div>
                <div className='checkout-add-icon-container cursor' onClick={() => setAddDeliveryInstructions(!addDeliveryInstructions)}>
                    {!addDeliveryInstructions ?
                        <div className='checkout-add-icon-wrapper'><Icon icon='material-symbols:add' className='checkout-add-icon'/></div>
                        : <div className='checkout-add-icon-wrapper'> <Icon icon='ic:baseline-minus' className='checkout-add-icon'/></div>
                    }
                    <label>Add delivery instructions</label>
                </div>
                {addDeliveryInstructions &&
                    <div className='delivery-instructions-wrapper'>
                        <TextField fullWidth={true} id="standard-basic" label="Special instructions" variant="standard"
                                   multiline={true} onChange={specialInstructions.onChange}  sx={customInputField} inputProps={{style: {padding: '5px 10px 5px 0'}}}/>
                        <div className='checkout-step-button save-delivery-instructions-button cursor'>Save</div>
                    </div>
                }
            </div>
            <EditAddressModal isEditAddressModalOpen={isEditAddressModalOpen} closeEditAddressModal={closeEditAddressModal} />
        </div>
    )
}

export default DeliveryDetailsStep;
