import React, {Suspense} from 'react';
import './App.css';
import "@fontsource/fira-code";
import NavBar from "./components/shared/nav-bar/NavBar";
import store, { persistor } from "./core/redux/store";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home-page/HomePage";
import BecomeAChef from "./pages/become-chef-page/BecomeAChef";
import LoginPage from "./pages/login-page/LoginPage";
import AvailableChefs from "./pages/available-chefs/AvailableChefs";
import { PersistGate } from "redux-persist/integration/react";
import MenuModal from "./components/shared/menu-modal/MenuModal";
import Footer from "./components/shared/footer/Footer";
import ChefDashboard from "./pages/chef-dashboard/ChefDashboard";
import SignupPage from "./pages/signup-page/SignupPage";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTopOnPathChange from "./core/utils/ScrollToTopOnPathChange";
import ForgotPassword from "./components/shared/password-change/ForgotPassword";
import AppsComingSoon from "./components/shared/coming-soon-banners/apps-coming-soon/AppsComingSoon";
import PageChangeRouter from "./components/shared/loaders/PageChangeLoader/PageChangeRouter";
import ChefBusinessPage from "./pages/chef-business-page/ChefBusinessPage";
import CheckoutCartMobile from "./components/shared/checkout-cart/checkout-cart-mobile/CheckoutCartMobile";
import ProtectedRoute from "./pages/login-page/ProtectedRoute";
import CheckoutPage from "./pages/checkout-page/CheckoutPage";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import ErrorBoundaryWithNavigate from "./error-handling/error-page/ErrorBoundary";
import ErrorPage from "./error-handling/error-page/ErrorPage";
import TermsAndConditions from "./pages/legal-document-pages/terms-and-conditions/TermsAndConditions";
import PrivacyPolicies from "./pages/legal-document-pages/privacy-policy/PrivacyPolicies";
import ContactUsPage from "./pages/contact-us-page/ContactUsPage";
import AboutUs from "./pages/about-us/AboutUs";
import ErrorNotification from "./error-handling/common-error-notification/ErrorNotification";
import {ServerNotReachableError} from "./error-handling/server-not-reachable-error/ServerNotReachableError";
import Confirmation from "./pages/confirmation/Confirmation";

function App() {

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY || '');

    return (
        <Provider store={store} >
            <ErrorNotification />
                <Elements stripe={stripePromise}>
                    <GoogleOAuthProvider clientId={clientId}>
                        <PersistGate persistor={persistor} >
                            <Router>
                                <ScrollToTopOnPathChange />
                                <ErrorBoundaryWithNavigate>
                                    <Suspense fallback={<PageChangeRouter />}>
                                        <NavBar/>
                                        <CheckoutCartMobile />
                                        <MenuModal />
                                        <ServerNotReachableError />
                                        <Routes>
                                            <Route path='/' element={<HomePage/>} />
                                            <Route path='/becomeChef' element={<BecomeAChef />} />
                                            <Route path='/signup' element={<SignupPage />} />
                                            <Route path='/login' element={<LoginPage />} />
                                            <Route path='forgot-password' element={<ForgotPassword />} />
                                            <Route path='/availableChefs' element={<AvailableChefs />} />
                                            <Route element = {<ProtectedRoute />}>
                                                <Route path='/checkout' element={<CheckoutPage />}/>
                                            </Route>
                                            <Route path='/business-page/:chefId' element={<ChefBusinessPage />} />
                                            <Route path='/coming-soon/apps' element={<AppsComingSoon />} />
                                            <Route element={<ProtectedRoute checkChefLoggedIn={true}/>}>
                                                <Route path='/chef/dashboard/*' element={<ChefDashboard />}/>
                                            </Route>
                                            <Route path='/error-page' element={<ErrorPage />} />
                                            <Route path='legal/terms-of-service' element={<TermsAndConditions />} />
                                            <Route path='privacy-policy' element={<PrivacyPolicies />} />
                                            <Route path='contact-us' element={<ContactUsPage />} />
                                            <Route path='about-us' element={<AboutUs />} />
                                            <Route path='/confirmation' element={<Confirmation />} />
                                        </Routes>
                                        <Footer />
                                    </Suspense>
                                </ErrorBoundaryWithNavigate>
                            </Router>
                        </PersistGate>
                    </GoogleOAuthProvider>
                </Elements>
        </Provider>
    );
}

export default App;
