import React, {useState} from "react";
import {Icon} from "@iconify/react";
import CustomMUITooltip from "../../../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import CustomizableMeal, {
    CustomizableMealItem,
    CustomizableMealItems
} from "../../../../../../model/meal-plans/CustomizableMeal";
import CustomizableMealItemsCarousel from "../customizable-meal-items-carousel/CustomizableMealItemsCarousel";
import {MealType} from "../../../create-meal-plan/meal-plan-form/MealPlanForm";
import './CustomizableMealCard.css';

type MealCardProps = {
    customizableMeal: CustomizableMeal;
    editMealItem: (mealItem: CustomizableMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => void;
    deleteMealItem: (mealItem: CustomizableMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => void;
}

const CustomizableMealCard: React.FC<MealCardProps> = ({customizableMeal, editMealItem, deleteMealItem}) => {


    const filters = (): (keyof CustomizableMealItems)[] => {
        let filters: (keyof CustomizableMealItems)[] = [];

        if (customizableMeal.mealItems.main && customizableMeal.mealItems.main.menuItems.length > 0) {
            filters = [...filters, 'main'];
        }

        if (customizableMeal.mealItems.sides && customizableMeal.mealItems.sides.menuItems.length > 0) {
            filters = [...filters, 'sides'];
        }
        if (customizableMeal.mealItems.beverages && customizableMeal.mealItems.beverages.menuItems.length > 0) {
            filters = [...filters, 'beverages'];
        }
        if (customizableMeal.mealItems.dessert && customizableMeal.mealItems.dessert.menuItems.length > 0) {
            filters = [...filters, 'dessert'];
        }
        return filters;
    }

    const [mealItems, setMealItems] = useState<CustomizableMealItem>(() => {
        const firstFilter = filters()[0];
        return customizableMeal.mealItems[firstFilter] as CustomizableMealItem;
    });


    const [allFilters] = useState<(keyof CustomizableMealItems)[]>(filters);
    const [activeFilter, setActiveFilter] = useState<keyof CustomizableMealItems>(allFilters[0]);

    const changeFilter = (filter: keyof CustomizableMealItems) => {
        setActiveFilter(filter);
        setMealItems(customizableMeal.mealItems[filter] as CustomizableMealItem);
    }

    return (
        <div>
            <div className='category-filter-buttons'>
                {allFilters.map((filter, index) => (
                    <div className={`category-filter-button cursor ${activeFilter === filter ? 'current-filter' : ''}`}
                         key={index} onClick={() => changeFilter(filter)}>
                        {filter.at(0)?.toUpperCase()+filter.slice(1, filter.length)}
                    </div>
                ))}
            </div>
            <div className='added-item-card box-card-shadow cursor fixed-meal-card' onClick={(event) => editMealItem(customizableMeal, event, MealType.CUSTOMIZABLE)}>
                <p className='max-items element-center'>Max items: {mealItems.maxItems}</p>
                <CustomizableMealItemsCarousel mealItem={mealItems}/>
                <div className='fixed-meal-card-content'>
                    <h4>{customizableMeal.title}</h4>
                    <p>${customizableMeal?.price}</p>
                </div>
                <CustomMUITooltip
                    title={
                        <div className='menu-item-actions-labels'>
                            <button onClick={(event) => { event.stopPropagation(); editMealItem(customizableMeal, event, MealType.CUSTOMIZABLE); }}>Edit</button>
                            <button onClick={(event) => { event.stopPropagation(); deleteMealItem(customizableMeal, event, MealType.CUSTOMIZABLE); }}>Delete</button>
                        </div>
                    }
                    disableInteractive={false}
                    enterTouchDelay={0}
                    placement="bottom-end"
                    arrow>
                    <div className='element-center padding-10 fixed-meal-card-actions'>
                        <Icon icon='ph:dots-three-circle-vertical' fontSize={24} onClick={(event) => event.stopPropagation()}>⋮</Icon>
                    </div>
                </CustomMUITooltip>
            </div>
        </div>
    )
}

export default CustomizableMealCard;

