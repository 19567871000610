
const convertLocationToLongitudeAndLatitude = (postalCode: string, address: string): Promise<{latitude: number, longitude: number}> => {
    return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': `${address}, ${postalCode}` }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
                const location = results[0].geometry.location;
                const latitude = location.lat();
                const longitude = location.lng();
                resolve({latitude, longitude});
            } else {
                console.error("Geocode was not successful for the following reason:", status);
                reject('Please enter a valid postal code');
            }
        });
    });
};

export default convertLocationToLongitudeAndLatitude;
