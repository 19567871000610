import {Box, Skeleton} from "@mui/material";
import React from "react";


const CuisineCardSkeleton = () => {
    return (
        <>
            <Box sx={{ marginTop: 2, paddingX: 1 }}>
                <Skeleton variant="circular" width={80} height={64} />
                <Skeleton variant="text" width="100%" />
            </Box>
        </>
    )
}

export default CuisineCardSkeleton;
