// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-message-icon, .success-message-container p {
    color: green;
}

.success-message-container {
    display: flex;
    gap: 10px;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/success-message/SuccessMessage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".success-message-icon, .success-message-container p {\n    color: green;\n}\n\n.success-message-container {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
