import './ChefCheckList.css';
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setChefOnboarded} from "../../../../core/redux/slices/chefSlice";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import {useEffect, useState} from "react";
import ChefService from "../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../../core/redux/store";
import Chef from "../../../../model/Chef";

const ChefCheckList = () => {

    const chef = useSelector(selectChef);
    const navigateTo = useNavigate();
    const dispatch = useDispatch<typeof store.dispatch>();
    const profileImageUrl = chef.profileImageUrl;
    const featuredDishUrl = chef.featuredDishUrl;
    const foodHandlingCertificate = chef.foodHandlingCertificateProgress?.foodHandlingCertificateUrl;
    const menu = chef.menu && chef.menu.length > 0;
    const businessDetails = !!(chef.businessDetails?.businessName && chef.businessDetails?.businessEmail && chef.businessDetails?.businessPhone);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if(!!profileImageUrl && !!featuredDishUrl && menu && businessDetails){
            completeOnboarding()
        }

    },[profileImageUrl, featuredDishUrl, foodHandlingCertificate, menu, businessDetails]);

    const completeOnboarding = async () => {

        console.log('dispatching action');

        try {
            const response: Chef | undefined = await dispatch(ChefService.completeOnboarding({
                id: chef.id as string,
            })).then(unwrapResult);

            if (response){
                dispatch(setChefOnboarded(response.isOnboardingCompleted));
            }
        }catch (error){
            setErrorMessage('Something went wrong');
        }
    };

    return (
        <div className='checklist-container box-card-shadow'>
            <h3 className='checklist-heading'>To Do</h3>
            <div className='checklist-row'>
                <div className='checklist-row-heading'>
                    <Icon icon='lets-icons:done-round-duotone' fontSize={24} className='checklist-row-icon'/>
                    <p>Registration</p>
                </div>

            </div>
            <div className='checklist-row' onClick={() => navigateTo('/chef/dashboard')}>
                <div className='checklist-column1'>
                    <div className='checklist-row-heading'>
                        {chef.profileImageUrl ?
                            <Icon icon='lets-icons:done-round-duotone' fontSize={24} className='checklist-row-icon'/> :
                            <Icon icon='mdi:number-2-circle' fontSize={24} className='checklist-step-icon'/>
                        }
                        <p>Upload profile picture</p>
                    </div>
                    <sub>
                        Profile picture lets customers know who's behind the delicious dishes
                    </sub>
                </div>
                <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} className='right-arrow-icon'/>
            </div>
            <HashLink smooth to='/chef/dashboard/#featured-dish' className='checklist-row link-black'>
                <div className='checklist-column1'>
                    <div className='checklist-row-heading'>
                        {chef.featuredDishUrl ?
                            <Icon icon='lets-icons:done-round-duotone' fontSize={24} className='checklist-row-icon'/> :
                            <Icon icon='mdi:number-3-circle' fontSize={24} className='checklist-step-icon'/>
                        }
                        <p>Upload featured dish photo</p>
                    </div>
                    <sub>
                        Featured dish gives customers a taste of what to expect
                    </sub>
                </div>
                <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} className='right-arrow-icon'/>
            </HashLink>
            <div className='checklist-row' onClick={() => navigateTo('/chef/dashboard/menu')}>
                <div className='checklist-column1'>
                    <div className='checklist-row-heading'>
                        {chef.menu && chef.menu?.length > 0 ?
                            <Icon icon='lets-icons:done-round-duotone' fontSize={24} className='checklist-row-icon'/> :
                            <Icon icon='mdi:number-4-circle' fontSize={24} className='checklist-step-icon'/>
                        }
                        <p>Create menu</p>
                    </div>
                    <sub>
                        Menu items visually showcase your specialties and invite customers to savor the flavors of your kitchen
                    </sub>
                </div>
                <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} className='right-arrow-icon'/>
            </div>
            <HashLink smooth to='/chef/dashboard/#business-details' className='checklist-row link-black'>
                <div className='checklist-column1'>
                    <div className='checklist-row-heading'>
                        {businessDetails ?
                            <Icon icon='lets-icons:done-round-duotone' fontSize={24} className='checklist-row-icon'/> :
                            <Icon icon='mdi:number-5-circle' fontSize={24} className='checklist-step-icon'/>
                        }
                        <p>Add business info</p>
                    </div>
                    <sub>
                        This information will be displayed on your business page for potential buyers to see
                    </sub>
                </div>
                <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} className='right-arrow-icon'/>
            </HashLink>
        </div>
    )
}

export default ChefCheckList;
