import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectUser} from "../../core/redux/slices/loginSlice";

interface ProtectedRouteProps {
    redirectPath?: string;
    checkChefLoggedIn?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = '/login', checkChefLoggedIn }) => {
    const isLoggedIn = useSelector(selectUser).loading === 'succeeded';
    const isChefLoggedIn = useSelector(selectUser).userType === 'chef';
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to={redirectPath} state={{ from: location.pathname }} replace />;
    }

    if (checkChefLoggedIn && !isChefLoggedIn) {
        return <Navigate to='/' state={{ from: location.pathname }} replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
