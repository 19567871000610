import {configureStore, Middleware} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {rootReducer} from './reducers';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {clearError, setError} from "./slices/errorSlice";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['modal', 'error']
};

const persistedReducer = persistReducer(persistConfig, rootReducer );

const errorMiddleware: Middleware = store => next => action => {
    if (action.type === setError.type) {
        setTimeout(() => {
            store.dispatch(clearError());
        }, 4000);
    }
    return next(action);
};

const store = configureStore({
    devTools: true,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ['customerSave.address.geometry.location', 'profileManagement']
            }
        }).concat(errorMiddleware),
})


export type RootState = ReturnType<typeof store.getState>

export default store;

export const persistor = persistStore(store);
