import './BriefSummaryItemCard.css';
import MenuItem from "../../../../../model/MenuItem";
import React from "react";
import useImageLoader from "../../../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";

type BriefSummaryItemCardProps = {
    handleExpandSummaryItems: () => void,
    cartItem: {
        count: number,
        menuItem: MenuItem,
    }
}

const BriefSummaryItemCard: React.FC<BriefSummaryItemCardProps> = ({cartItem, handleExpandSummaryItems}) => {

    const menuItemUrl = cartItem.menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;

    const isImageLoaded = useImageLoader(menuItemUrl);

    return (
        <div>
            {isImageLoaded ?
                <div className='brief-summary-card-wrapper cursor' onClick={handleExpandSummaryItems}>
                    <span className='count-number element-center'>{cartItem.count}</span>
                    <img src={menuItemUrl} className='' alt='cart item'/>
                </div> :
                <Skeleton variant="rectangular" width={100} height={100}/>
            }
        </div>
    )
}

export default BriefSummaryItemCard;
