export interface ResponseError {
    message: string;
    status?: number;
}

export const handleApiError = async (response: Response | null): Promise<ResponseError> => {
    if (!response) {
        return {
            message: "Network error: Unable to reach the server. Please try again later.",
            status: 500,
        };
    }

    let errorMessage = "An error occurred";
    try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
    } catch (error) {
        errorMessage = "Not proper JSON format";
    }

    return {
        message: errorMessage,
        status: response.status,
    };
};
