// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-logo-row {
    gap: 8px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.menu-logo-row img{
    width: 200px;
    height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/menu-modal/user-menu-container/LoginMenuContainer.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".menu-logo-row {\n    gap: 8px;\n    padding: 10px;\n    align-items: center;\n    justify-content: space-between;\n    display: flex;\n}\n\n.menu-logo-row img{\n    width: 200px;\n    height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
