import Customer from "../../model/Customer";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {setCustomer, setPaymentMethod} from "../redux/slices/customerSlice";
import Chef from "../../model/Chef";
import Credentials from "../../model/Credentials";
import PaymentMethod from "../../model/PaymentMethod";
import protectedApiClient from "./ProtectedApiClient";
import {ResponseDTO} from "./ChefService";
import {setError} from "../redux/slices/errorSlice";
import Order from "../../model/order/Order";

export type SignupResponseType = {
    userType: string,
    user: Customer | Chef,
    token: {accessToken: string, refreshToken: string},
}

export type ResponseError = {
    message: string;
}

class CustomerService {

    static signUp = createAsyncThunk<SignupResponseType, Customer, {rejectValue: { errorMessage: string }}>(
        'customerSignUp',
        async (customer, {dispatch, rejectWithValue}) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/customer/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(customer),
                });

                if (!response.ok) {
                    return rejectWithValue({ errorMessage: 'Failed to sign up.' });
                }

                const data: SignupResponseType = await response.json();
                if(data.userType === 'customer'){
                    dispatch(setCustomer(data.user as Customer));
                }
                return data;
            } catch (error) {

                return rejectWithValue({ errorMessage: 'An unknown error occurred' });
            }
        }
    );

    static verifyEmailExists = async (email : string) => {

        const isEmailExistsResponse = await fetch(
            `${process.env.REACT_APP_HOME_EATZ_API_URL}/customer/verifyEmail?email=${email}`, {method: 'POST'});

        if(isEmailExistsResponse.ok){
            const responseData = await isEmailExistsResponse.json();
            return responseData.emailExists;
        }
    }

    static changePassword = async (credentials: Credentials) => {
        const passwordUpdateResponse = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/customer/updatePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
        });
        return await passwordUpdateResponse.json();
    }

    static validateGoogleLogin = createAsyncThunk<SignupResponseType, string, { rejectValue: ResponseError }>(
    'customerGoogleLogin',
    async (tokenId, {rejectWithValue}) => {
        const googleLoginResponse = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/customer/googleLogin?tokenId=${tokenId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!googleLoginResponse.ok) {
            const error: ResponseError = await googleLoginResponse.json();
            return rejectWithValue(error);
        }

        const data: SignupResponseType = await googleLoginResponse.json();

        return data;
    })

    static getPaymentMethods = createAsyncThunk(
        'getPaymentMethods',
        async (userData: { id: string }, { rejectWithValue, dispatch }) => {
            const addressListResponse: ResponseDTO<PaymentMethod[]> = await protectedApiClient(`/customer/profile/payment-methods/${userData.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (addressListResponse.status !== 'OK') {
                dispatch(setError({message: 'unable to get payment methods'}));
                return rejectWithValue({message: 'unable to get payment methods'});
            }

            dispatch(setPaymentMethod(addressListResponse.data));
            return addressListResponse.data;
        }
    )

    static processOrder = createAsyncThunk<string, { order: Order }, { rejectValue: ResponseError }>(
        'processOrder',
        async (data, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<{ clientSecret: string }> = await protectedApiClient(
                    `/customer/profile/order`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data.order),
                    }
                );
                const {clientSecret} = response.data;
                return clientSecret;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

}

export default CustomerService;
