import './DeliveryOptions.css';
import DeliveryAddress from "./delivery-address/DeliveryAddress";
import DeliveryDate from "./delivery-date/DeliveryDate";
import DeliveryType from "./delivery-type/DeliveryType";
import FilterButton from "./filter-button/FilterButton";

const DeliveryOptions = () => {
    return (
        <div className='delivery-options-container'>
            <DeliveryAddress />
            <DeliveryDate />
            <div className='delivery-options-column-2'>
                <DeliveryType />
                <FilterButton />
            </div>
        </div>
    )
}

export default DeliveryOptions;
