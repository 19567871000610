// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.business-details {
    background: white;
    border-radius: 10px;
}

.business-heading-container {
    display: flex;
    padding: 15px 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-light);
}

.business-details-wrapper {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.business-details-form {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.business-details-wrapper {
    padding: 15px 30px;
}


@media screen and (max-width: 600px) {
    .business-details-wrapper {
        flex-direction: column;
    }

}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/business-details/BusinessDetails.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI;QACI,sBAAsB;IAC1B;;AAEJ","sourcesContent":["\n.business-details {\n    background: white;\n    border-radius: 10px;\n}\n\n.business-heading-container {\n    display: flex;\n    padding: 15px 30px;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.business-details-wrapper {\n    display: flex;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.business-details-form {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.business-details-wrapper {\n    padding: 15px 30px;\n}\n\n\n@media screen and (max-width: 600px) {\n    .business-details-wrapper {\n        flex-direction: column;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
