
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import ChefService from "../../../../../core/services/ChefService";
import { useDispatch, useSelector } from "react-redux";
import { selectChef } from "../../../../../core/redux/slices/chefSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import store from "../../../../../core/redux/store";
import {selectToken} from "../../../../../core/redux/slices/loginSlice";
import MenuItem from "../../../../../model/MenuItem";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import SimpleErrorMessage from "../../../../../error-handling/error-message/SimpleErrorMessage";
import { CustomizableMealItem, CustomizableMealItems } from "../../../../../model/meal-plans/CustomizableMeal";
import CustomizableMealItemList from "./customizable-meal-item-list/CustomizableMealItemList";
import AddedCustomizableMealItems from "./added-customizable-meal-items/AddedCustomizableMealItems";

interface CustomizableMealPlanItemsProps {
    customizableMealItemsToEdit?: CustomizableMealItems;
}

const CustomizableMealPlanItems = forwardRef<{ validateMeals: () => CustomizableMealItems; resetItemsForm: () => void }, CustomizableMealPlanItemsProps>(({customizableMealItemsToEdit}, ref) => {

    const id = useSelector(selectChef).id;
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [menu, setMenu] = useState<MenuItem []>();
    const { t } = useTranslation('ChefDashboard');
    const {t: tCommon} = useTranslation('common');
    const [sideItemsTooltipOpen, setSideItemsTooltipOpen] = useState(false);
    const [mainCourseTooltipOpen, setMainCourseTooltipOpen] = useState(false);
    const [beveragesTooltipOpen, setBeveragesTooltipOpen] = useState(false);
    const [dessertTooltipOpen, setDessertTooltipOpen] = useState(false);
    const [sideItems, setSideItems] = useState<CustomizableMealItem>({menuItems: [], maxItems: 0});
    const [mainCourse, setMainCourse] = useState<CustomizableMealItem>({menuItems: [], maxItems: 0});
    const [beverages, setBeverages] = useState<CustomizableMealItem>({menuItems: [], maxItems: 0});
    const [desserts, setDesserts] = useState<CustomizableMealItem>({menuItems: [], maxItems: 0});
    const [mealsMissing, setMealsMissing] = useState(false);

    useEffect(() => {

        const fetchMenu = async () => {
            try {
                const resultAction = await apiDispatch(ChefService.getMenu({
                    id: id as string
                }));
                const menu: MenuItem[] = unwrapResult(resultAction);
                setMenu(menu);
            }catch (error) {
                console.log(error);
            }
        }

        fetchMenu();

        if (customizableMealItemsToEdit) {
            if (customizableMealItemsToEdit.main) {
                setMainCourse(customizableMealItemsToEdit.main);
            }
            if (customizableMealItemsToEdit.sides) {
                setSideItems(customizableMealItemsToEdit.sides);
            }
            if (customizableMealItemsToEdit.dessert) {
                setDesserts(customizableMealItemsToEdit.dessert);
            }
            if (customizableMealItemsToEdit.beverages) {
                setBeverages(customizableMealItemsToEdit.beverages);
            }
        }
    }, []);

    const resetItemsForm = () => {
        setSideItems({menuItems: [], maxItems: 0});
        setMainCourse({menuItems: [], maxItems: 0});
        setBeverages({menuItems: [], maxItems: 0});
        setDesserts({menuItems: [], maxItems: 0});
    }

    useImperativeHandle(ref, () => ({
        validateMeals,
        resetItemsForm
    }));

    const validateMeals = () => {
        const mealItems: CustomizableMealItems = {};

        if (mainCourse?.menuItems && mainCourse?.menuItems?.length > 0) {
            mealItems.main = mainCourse;
        }
        if (sideItems?.menuItems && sideItems?.menuItems?.length > 0) {
            mealItems.sides = sideItems;
        }
        if (beverages?.menuItems && beverages?.menuItems?.length > 0) {
            mealItems.beverages = beverages;
        }
        if (desserts?.menuItems && desserts?.menuItems?.length > 0) {
            mealItems.dessert = desserts;
        }

        const hasItems = Object.keys(mealItems).length > 0;
        setMealsMissing(!hasItems);

        return mealItems;
    };


    const onApplyMealItemsClicked = (mealItemType: string, mealItems: CustomizableMealItem) => {

        switch (mealItemType) {
            case 'main' :
                setMainCourse(mealItems);
                setMainCourseTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'sides':
                setSideItems(mealItems);
                setSideItemsTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'beverages':
                setBeverages(mealItems);
                setBeveragesTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'dessert':
                setDesserts(mealItems);
                setDessertTooltipOpen(false);
                setMealsMissing(false);
                break;
        }
    }

    return (
        <div className='flex column gap-20 meal-plan-form-detail light-border-bottom'>
            <div className='flex-space-between'>
                <h3>Meal Plan Items</h3>
                {mealsMissing && <SimpleErrorMessage message={tCommon('required-field-error')} />}
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.sides-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${sideItemsTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setSideItemsTooltipOpen(!sideItemsTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.sides-heading')}</p>
                        {sideItemsTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {sideItemsTooltipOpen && <CustomizableMealItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                               menu={menu} mealItems={sideItems as CustomizableMealItem} mealItemType='sides'/>}
                    {(sideItems.menuItems.length > 0 && !sideItemsTooltipOpen) && <AddedCustomizableMealItems mealItems={sideItems} /> }
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.main-course-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${mainCourseTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setMainCourseTooltipOpen(!mainCourseTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.main-course-heading')}</p>
                        {mainCourseTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {mainCourseTooltipOpen && <CustomizableMealItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                                menu={menu} mealItems={mainCourse as CustomizableMealItem} mealItemType='main'/>}
                    {(mainCourse.menuItems.length > 0 && !mainCourseTooltipOpen) && <AddedCustomizableMealItems mealItems={mainCourse} /> }
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.beverages-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${beveragesTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setBeveragesTooltipOpen(!beveragesTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.beverages-heading')}</p>
                        {beveragesTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {beveragesTooltipOpen && <CustomizableMealItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                               menu={menu} mealItems={beverages as CustomizableMealItem} mealItemType='beverages'/>}
                    {(beverages.menuItems.length > 0 && !beveragesTooltipOpen) && <AddedCustomizableMealItems mealItems={beverages} /> }
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.dessert-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${dessertTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setDessertTooltipOpen(!dessertTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.dessert-heading')}</p>
                        {dessertTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {dessertTooltipOpen && <CustomizableMealItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                             menu={menu} mealItems={desserts as CustomizableMealItem} mealItemType='dessert'/>}
                    {(desserts.menuItems.length > 0 && !dessertTooltipOpen) && <AddedCustomizableMealItems mealItems={desserts} /> }
                </div>
            </div>
        </div>
    )
})

export default CustomizableMealPlanItems;
