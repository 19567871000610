// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-meal-plan-page {
    flex: 1 1;
}

.create-meal-plan-heading {
    font-size: 24px;
    padding-bottom: 20px;
    font-weight: 500;
}

.create-meal-form {
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .create-meal-plan-page {
        padding: 0;
    }

    .create-meal-plan-heading {
        display: none;
    }

    .create-meal-form {
        margin: 16px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/create-meal-plan/CreateMealPlan.css"],"names":[],"mappings":"AAAA;IACI,SAAO;AACX;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".create-meal-plan-page {\n    flex: 1;\n}\n\n.create-meal-plan-heading {\n    font-size: 24px;\n    padding-bottom: 20px;\n    font-weight: 500;\n}\n\n.create-meal-form {\n    margin: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .create-meal-plan-page {\n        padding: 0;\n    }\n\n    .create-meal-plan-heading {\n        display: none;\n    }\n\n    .create-meal-form {\n        margin: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
