import './SimpleErrorMessage.css';
import errorIcon from '../../assets/images/error-icon.svg';
import React from "react";

type SimpleErrorMessageProps = {
    message: string,
}
const SimpleErrorMessage: React.FC<SimpleErrorMessageProps> = ({message}) => {

    return (
        <div className='element-center gap-10 simple-error-message'>
            <img src={errorIcon} alt='error icon'/>
            <p className='error-message'>{message}</p>
        </div>
    )
}


export default SimpleErrorMessage
