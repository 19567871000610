// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-meal-item-buttons {
    display: flex;
    gap: 4px;
    color: black;
    align-items: center;
    border-radius: 16px;
    background: var(--grey-button-color);
}

.add-meal-item-button {
    display: flex;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    align-items: center;
    padding: 6px;
}

.add-meal-item-button:hover {
    cursor: pointer;
    background: rgba(189, 188, 188, 0.55);
}

.menu-item-card-icon, .menu-item-card-remove-icon , .menu-item-card-add-icon {
    min-height: 24px;
    min-width: 24px;
}

.menu-item-card-remove-icon , .menu-item-card-add-icon {
    padding: 6px 6px;
}

.menu-item-card-remove-icon:hover, .menu-item-card-add-icon:hover {
    cursor: pointer;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.11);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/fixed-meal-plan/menu-item-card/MealItemCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,qCAAqC;AACzC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,+BAA+B;AACnC","sourcesContent":[".add-meal-item-buttons {\n    display: flex;\n    gap: 4px;\n    color: black;\n    align-items: center;\n    border-radius: 16px;\n    background: var(--grey-button-color);\n}\n\n.add-meal-item-button {\n    display: flex;\n    min-width: 24px;\n    min-height: 24px;\n    border-radius: 50%;\n    align-items: center;\n    padding: 6px;\n}\n\n.add-meal-item-button:hover {\n    cursor: pointer;\n    background: rgba(189, 188, 188, 0.55);\n}\n\n.menu-item-card-icon, .menu-item-card-remove-icon , .menu-item-card-add-icon {\n    min-height: 24px;\n    min-width: 24px;\n}\n\n.menu-item-card-remove-icon , .menu-item-card-add-icon {\n    padding: 6px 6px;\n}\n\n.menu-item-card-remove-icon:hover, .menu-item-card-add-icon:hover {\n    cursor: pointer;\n    border-radius: 50%;\n    background: rgba(0, 0, 0, 0.11);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
