// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-card-food-image {
    object-fit: cover;
    width: 100%;
    height: 230px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.chef-card-wrapper {
    display: flex;
    margin: 0 20px;
    position: relative;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    flex-direction: column;
}

.chef-card-wrapper:hover {
    cursor: pointer;
    border: 1px solid var(--primary-color);
}

.chef-profile-information {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
}

.chef-card-profile-photo {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.chef-profile-wrapper {
    padding: 20px 0 20px 20px;
    display: flex;
}

.chef-rating-section {
    display: flex;
    gap: 6px;
    margin-top: 10px;
}

.rating-light {
    color: #858585
}

.like-icon-wrapper {
    display: flex;
    width: 70px;
    gap: 6px;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 5px;
    padding: 6px;
    background: var(--secondary-light-color);
}

.featured-dish-name {
    padding: 20px 0 0 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/chef-card/ChefCard.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,WAAW;IACX,QAAQ;IACR,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;AAC5C;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".chef-card-food-image {\n    object-fit: cover;\n    width: 100%;\n    height: 230px;\n    border-top-right-radius: 10px;\n    border-top-left-radius: 10px;\n}\n\n.chef-card-wrapper {\n    display: flex;\n    margin: 0 20px;\n    position: relative;\n    border-radius: 10px;\n    border: 1px solid #E4E4E4;\n    flex-direction: column;\n}\n\n.chef-card-wrapper:hover {\n    cursor: pointer;\n    border: 1px solid var(--primary-color);\n}\n\n.chef-profile-information {\n    margin-left: 16px;\n    display: flex;\n    flex-direction: column;\n}\n\n.chef-card-profile-photo {\n    height: 80px;\n    width: 80px;\n    border-radius: 50%;\n}\n\n.chef-profile-wrapper {\n    padding: 20px 0 20px 20px;\n    display: flex;\n}\n\n.chef-rating-section {\n    display: flex;\n    gap: 6px;\n    margin-top: 10px;\n}\n\n.rating-light {\n    color: #858585\n}\n\n.like-icon-wrapper {\n    display: flex;\n    width: 70px;\n    gap: 6px;\n    position: absolute;\n    right: 16px;\n    top: 16px;\n    border-radius: 5px;\n    padding: 6px;\n    background: var(--secondary-light-color);\n}\n\n.featured-dish-name {\n    padding: 20px 0 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
