// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-dashboard {
    position: relative;
    display: flex;
    background: var(--primary-light-color);
}

.dashboard-content-card {
    background-color: white;
    border-radius: 8px;
}

.chef-dashboard-modal:focus {
    outline: none;
}

.dashboard-heading {
    padding: 16px;
    font-size: 24px;
}

.box-card-shadow {
    box-shadow: rgba(0, 0, 0, 0.133) 0 2px 4px 0, rgba(0, 0, 0, 0.11) 0 0.3px 1px 0;
}

.details-input:enabled, .password-details-input, .edit-cuisine-item {
    font-size: 16px;
}

.chef-dashboard-slide-in {
    transform: translateX(0%);
    transition: transform 500ms ease-in;
}

.chef-dashboard-slide-out {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}

.dashboard-page-heading {
    display: flex;
    padding: 20px;
    background: white;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .chef-dashboard {
        position: relative;
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/ChefDashboard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,+EAA+E;AACnF;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;IACI,4BAA4B;IAC5B,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI;QACI,kBAAkB;QAClB,sBAAsB;IAC1B;AACJ","sourcesContent":[".chef-dashboard {\n    position: relative;\n    display: flex;\n    background: var(--primary-light-color);\n}\n\n.dashboard-content-card {\n    background-color: white;\n    border-radius: 8px;\n}\n\n.chef-dashboard-modal:focus {\n    outline: none;\n}\n\n.dashboard-heading {\n    padding: 16px;\n    font-size: 24px;\n}\n\n.box-card-shadow {\n    box-shadow: rgba(0, 0, 0, 0.133) 0 2px 4px 0, rgba(0, 0, 0, 0.11) 0 0.3px 1px 0;\n}\n\n.details-input:enabled, .password-details-input, .edit-cuisine-item {\n    font-size: 16px;\n}\n\n.chef-dashboard-slide-in {\n    transform: translateX(0%);\n    transition: transform 500ms ease-in;\n}\n\n.chef-dashboard-slide-out {\n    transform: translateX(-100%);\n    transition: transform 500ms ease-in-out;\n}\n\n.dashboard-page-heading {\n    display: flex;\n    padding: 20px;\n    background: white;\n    align-items: center;\n    justify-content: space-between;\n}\n\n@media screen and (max-width: 600px) {\n    .chef-dashboard {\n        position: relative;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
