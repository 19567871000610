// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-page {
    margin: 50px 100px;
}

.checkout-page .heading {
    font-size: 36px;
    padding: 0 0 20px 20px;
    font-weight: 500;
}

.checkout-page-details {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .checkout-page {
        padding: 20px 0;
        margin: 0;
    }

    .checkout-page-details {
        gap: 3px;
        flex-direction: column;
    }

    .checkout-page .heading {
        font-size: 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/CheckoutPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,eAAe;QACf,SAAS;IACb;;IAEA;QACI,QAAQ;QACR,sBAAsB;IAC1B;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".checkout-page {\n    margin: 50px 100px;\n}\n\n.checkout-page .heading {\n    font-size: 36px;\n    padding: 0 0 20px 20px;\n    font-weight: 500;\n}\n\n.checkout-page-details {\n    display: flex;\n    gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .checkout-page {\n        padding: 20px 0;\n        margin: 0;\n    }\n\n    .checkout-page-details {\n        gap: 3px;\n        flex-direction: column;\n    }\n\n    .checkout-page .heading {\n        font-size: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
