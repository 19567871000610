import './DetailSummaryItemCard.css';
import MenuItem from "../../../../../model/MenuItem";
import useImageLoader from "../../../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";
import React from "react";
import MenuItemQuickViewModal from "../../../../../components/shared/menu-item-quick-view/MenuItemQuickViewModal";
import useModal from "../../../../../custom-hooks/useModal";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../core/redux/store";
import {selectCheckoutItem} from "../../../../../core/redux/slices/customerSlice";

type DetailSummaryItemCardProps = {
    cartItem: {
        count: number,
        menuItem: MenuItem,
    }
}

const DetailSummaryItemCard: React.FC<DetailSummaryItemCardProps> = ({cartItem}) => {

    const menuItemUrl = cartItem.menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const {isOpen, openModal, closeModal} = useModal();
    const isImageLoaded = useImageLoader(menuItemUrl);
    const itemInCart = useSelector((state: RootState) => selectCheckoutItem(state, cartItem.menuItem.id as string));

    return (
        <>
            <div className='detail-summary-card-wrapper cursor' onClick={openModal}>
                <div className='detail-summary-column'>
                    <span className='count-number element-center'>{cartItem.count}</span>
                    {isImageLoaded ?
                        <img src={menuItemUrl} alt='cart item'/> :
                        <Skeleton variant="rectangular" width={100} height={100} style={{borderRadius: '10px'}}/>
                    }
                </div>
                <div className='detail-summary-column-2'>
                    <p className='item-name'>{cartItem.menuItem.name}</p>
                    <p className='item-price'>${+cartItem.menuItem.price * cartItem.count}</p>
                    <p className='light-grey-text speciality-detail'>{itemInCart?.specialInstructions}</p>
                </div>
            </div>
            <MenuItemQuickViewModal isMenuItemQuickViewModalOpen={isOpen} closeMenuItemQuickViewModal={closeModal} menuItem={cartItem.menuItem} />
        </>

    )
}

export default DetailSummaryItemCard;
