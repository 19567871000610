import './ConnectBankAccount.css';
import React, {useState} from "react";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../../core/redux/store";
import ConnectStripeLinkResponse from "../../../../model/ConnectStripeLinkResponse";
import ChefService from "../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import useErrorMessage from "../../../../custom-hooks/useErrorMessage";
import Modal from "react-modal";
import useModal from "../../../../custom-hooks/useModal";
import bankAccountCreatedImage from '../../../../assets/images/stripe-account-created.png';

const ConnectBankAccount = () => {

    const dispatch = useDispatch<typeof store.dispatch>();
    const chef = useSelector(selectChef);
    const token = useSelector(selectToken);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useErrorMessage(2000);
    const modal = useModal();

    const handleConnectStripe = async () => {

        setIsLoading(true);

        try {
            const response: ConnectStripeLinkResponse | undefined = await dispatch(ChefService.getStripeLink({
                id: chef.id as string,
                token: token.accessToken
            })).then(unwrapResult);

            if (response?.onboardingStatus === 'Complete'){
                modal.openModal();
                return;
            }
            window.location.href = response?.url as string;
        }catch (error){
            setErrorMessage('Something went wrong');
        }finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='connect-bank-account-wrapper'>
            <div className='connect-bank-column cursor' onClick={handleConnectStripe}>
                <div className={`connect-bank-icon-wrapper element-center ${isLoading ? 'spin' : ''}`}>
                    <Icon icon='fluent:building-bank-link-48-filled' className='bank-icon'/>
                </div>
                <div className='column'>
                    <p>Connect bank account</p>
                    {errorMessage && <p className='error-message'>{errorMessage}</p>}
                </div>
            </div>
            <Modal isOpen={modal.isOpen} className='basic-modal modal-2 bank-account-created-modal' onRequestClose={modal.closeModal}>
                <div className='modal-heading element-center'>
                    <Icon icon='material-symbols:close' fontSize={30} onClick={modal.closeModal}/>
                </div>
                <div className='modal-content column bank-account-created-modal-content'>
                    <img src={bankAccountCreatedImage} className='bank-account-created-image' alt='bank account created'/>
                    <h2>Bank account is already connected</h2>
                    <div className='primary-button done-button' onClick={modal.closeModal}>Done</div>
                </div>
            </Modal>
        </div>
    )
}

export default ConnectBankAccount;
