import './OrderDeliveryType.css';
import {useDispatch, useSelector} from "react-redux";
import {changeOrderType, selectOrderType} from "../../../../../core/redux/slices/customerSlice";

const OrderDeliveryType = () => {

    const orderType = useSelector(selectOrderType);
    const dispatch = useDispatch();

    const handleDeliveryType = (orderType: string) => {
        dispatch(changeOrderType(orderType))
    }

    return (
        <div className='checkout-delivery-type-container'>
            <div className='checkout-delivery-buttons-wrapper'>
                <div className={`checkout-delivery-type-button ${orderType === 'pickup' ? 'selected' : ''}`} onClick={() => handleDeliveryType('pickup')}>Pickup</div>
                <div className={`checkout-delivery-type-button ${orderType === 'delivery' ? 'selected' : ''}`} onClick={() => handleDeliveryType('delivery')}>Delivery</div>
            </div>
        </div>
    )
}

export default OrderDeliveryType;
