import React from 'react';
import { Tooltip, tooltipClasses, styled } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../../core/utils/Validation';
import './PasswordForm.css';

interface PasswordTooltipProps {
    password: string;
    open: boolean;
    children: React.ReactNode
}

export const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            color: '#333',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            fontSize: '14px',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#fff',
        },
}));

const PasswordTooltip: React.FC<PasswordTooltipProps> = ({ password, open, children }) => {
    const { t } = useTranslation();
    const validation = validatePassword(password);

    return (
        <CustomTooltip
            open={open}
            title={
                <div className='password-requirements'>
                    <p style={{ color: validation.length ? 'green' : 'red' }}>
                        {validation.length ? <CheckCircleOutlineIcon /> : <CancelIcon />} {t('passwordRequirements.length')}
                    </p>
                    <p style={{ color: validation.uppercase ? 'green' : 'red' }}>
                        {validation.uppercase ? <CheckCircleOutlineIcon /> : <CancelIcon />} {t('passwordRequirements.uppercase')}
                    </p>
                    <p style={{ color: validation.lowercase ? 'green' : 'red' }}>
                        {validation.lowercase ? <CheckCircleOutlineIcon /> : <CancelIcon />} {t('passwordRequirements.lowercase')}
                    </p>
                    <p style={{ color: validation.number ? 'green' : 'red' }}>
                        {validation.number ? <CheckCircleOutlineIcon /> : <CancelIcon />} {t('passwordRequirements.number')}
                    </p>
                    <p style={{ color: validation.specialChar ? 'green' : 'red' }}>
                        {validation.specialChar ? <CheckCircleOutlineIcon /> : <CancelIcon />} {t('passwordRequirements.specialChar')}
                    </p>
                </div>
            }
            placement="bottom-start"
            arrow
        >
            {children}
        </CustomTooltip>
    );
};

export default PasswordTooltip;
