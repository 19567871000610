// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cuisine-filter-list {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: relative;
}

.cuisine-carousel {
    margin: 20px 90px 0 90px;
}

.cuisine-filter-carousel-button {
    color: var(--sub-black);
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
    background: white;
    opacity: 1;
    cursor: pointer;
}

.cuisine-filter-carousel-button:hover {
    background: var(--primary-light-color);
}

.filter-right-button {
    right: 50px;
}

.filter-left-button {
    left: 50px;
}

@media screen and (max-width: 600px) {
    .cuisine-carousel {
        margin: 20px;
    }
    .filter-left-button {
        left: 10px;
    }
    .filter-right-button {
        right: 10px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/cuisines-carousel/CusineCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".cuisine-filter-list {\n    display: flex;\n    gap: 10px;\n    justify-content: center;\n    position: relative;\n}\n\n.cuisine-carousel {\n    margin: 20px 90px 0 90px;\n}\n\n.cuisine-filter-carousel-button {\n    color: var(--sub-black);\n    position: absolute;\n    outline: 0;\n    transition: all .5s;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #E4E4E4;\n    background: white;\n    opacity: 1;\n    cursor: pointer;\n}\n\n.cuisine-filter-carousel-button:hover {\n    background: var(--primary-light-color);\n}\n\n.filter-right-button {\n    right: 50px;\n}\n\n.filter-left-button {\n    left: 50px;\n}\n\n@media screen and (max-width: 600px) {\n    .cuisine-carousel {\n        margin: 20px;\n    }\n    .filter-left-button {\n        left: 10px;\n    }\n    .filter-right-button {\n        right: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
