// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offers-page {
    flex: 1 1;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.back-to-offer-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 800;
    display: none;
    color: var(--light-grey);
}

.back-to-offer-button:hover {
    border-radius: 50%;
    color: var(--primary-color);
}

.offers-page-heading {
    padding: 20px 20px 0 20px;
    background: white;
}

.selected-content {
    border-bottom: 6px solid var(--primary-color);
}

.offer-heading {
    padding: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    background: white;
}

.offer-form-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    border-radius: 8px;
    background: white;
}

@media screen and (max-width: 600px) {
    .back-to-offer-button {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/OffersPage.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".offers-page {\n    flex: 1;\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n}\n\n.back-to-offer-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    z-index: 800;\n    display: none;\n    color: var(--light-grey);\n}\n\n.back-to-offer-button:hover {\n    border-radius: 50%;\n    color: var(--primary-color);\n}\n\n.offers-page-heading {\n    padding: 20px 20px 0 20px;\n    background: white;\n}\n\n.selected-content {\n    border-bottom: 6px solid var(--primary-color);\n}\n\n.offer-heading {\n    padding: 20px;\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    background: white;\n}\n\n.offer-form-content {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin: 20px;\n    border-radius: 8px;\n    background: white;\n}\n\n@media screen and (max-width: 600px) {\n    .back-to-offer-button {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
