// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-error-container {
    color: red;
    display: flex;
    gap: 20px;
    border: 1px solid red;
    border-radius: 4px;
    padding: 4px;
    align-items: center;
    background: #f5e5e5;
}

@media screen and (max-width: 600px) {
    .save-error-container {
        position: fixed;
        bottom: 10px;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        z-index: 10;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/save-error-message/SaveErrorMessage.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;QACf,YAAY;QACZ,SAAS;QACT,UAAU;QACV,2BAA2B;QAC3B,WAAW;IACf;AACJ","sourcesContent":[".save-error-container {\n    color: red;\n    display: flex;\n    gap: 20px;\n    border: 1px solid red;\n    border-radius: 4px;\n    padding: 4px;\n    align-items: center;\n    background: #f5e5e5;\n}\n\n@media screen and (max-width: 600px) {\n    .save-error-container {\n        position: fixed;\n        bottom: 10px;\n        left: 50%;\n        width: 90%;\n        transform: translateX(-50%);\n        z-index: 10;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
