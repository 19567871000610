// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apps-coming-soon-banner {
    width: 100vw;
    display: flex;
    padding: 20px;
    gap: 50px;
    background-color: #f4f4f4;
}

.apps-coming-soon-banner .description {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 100px;
    justify-content: center;
    overflow: auto;
}


.column-2 {
    flex: 1 1;
}

.column-2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.apps-coming-soon-heading,
.apps-coming-soon-description {
    margin: 0;
}

.apps-coming-soon-heading {
    font-family: "Noto Serif", serif;
    font-weight: 600;
    font-size: 50px;
    font-style: italic;
}

.apps-coming-soon-description {
    font-size: 16px;
    color: #888888;
}

@media screen and (max-width: 600px) {
    .apps-coming-soon-banner {
        gap: 20px;
        flex-direction: column;
    }
    .apps-coming-soon-banner .description {
        padding: 20px;
    }
    .apps-coming-soon-heading {
        font-size: 35px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/coming-soon-banners/apps-coming-soon/AppsComingSoon.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,cAAc;IACd,uBAAuB;IACvB,cAAc;AAClB;;;AAGA;IACI,SAAO;AACX;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;;IAEI,SAAS;AACb;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,SAAS;QACT,sBAAsB;IAC1B;IACA;QACI,aAAa;IACjB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".apps-coming-soon-banner {\n    width: 100vw;\n    display: flex;\n    padding: 20px;\n    gap: 50px;\n    background-color: #f4f4f4;\n}\n\n.apps-coming-soon-banner .description {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    padding: 100px;\n    justify-content: center;\n    overflow: auto;\n}\n\n\n.column-2 {\n    flex: 1;\n}\n\n.column-2 img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}\n\n.apps-coming-soon-heading,\n.apps-coming-soon-description {\n    margin: 0;\n}\n\n.apps-coming-soon-heading {\n    font-family: \"Noto Serif\", serif;\n    font-weight: 600;\n    font-size: 50px;\n    font-style: italic;\n}\n\n.apps-coming-soon-description {\n    font-size: 16px;\n    color: #888888;\n}\n\n@media screen and (max-width: 600px) {\n    .apps-coming-soon-banner {\n        gap: 20px;\n        flex-direction: column;\n    }\n    .apps-coming-soon-banner .description {\n        padding: 20px;\n    }\n    .apps-coming-soon-heading {\n        font-size: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
