import useImageLoader from "../../../../../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";
import React from "react";
import MenuItem from "../../../../../../../model/MenuItem";

type AddedCustomizableMealItemCardProps = {
    menuItem: MenuItem;
}

const AddedCustomizableMealItemCard: React.FC<AddedCustomizableMealItemCardProps> = ({menuItem}) => {

    const menuItemUrl = menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;

    const isImageLoaded = useImageLoader(menuItemUrl);

    return (
        <div>
            {isImageLoaded ?
                <div className='brief-summary-card-wrapper cursor'>
                    <img src={menuItemUrl} className='' alt='cart item'/>
                </div> :
                <Skeleton variant="rectangular" width={100} height={100}/>
            }
        </div>
    )
}


export default AddedCustomizableMealItemCard;
