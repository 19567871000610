
const Confirmation = () => {

    return (
        <div>
            Your order is placed
        </div>
    )
}

export default Confirmation;