import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Modal from "react-modal";
import {LoadScript} from "@react-google-maps/api";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n/i18n";

const libraries: ("drawing" | "geometry" | "places" | "visualization")[] = ['places'];
const apiKey: any = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const appElement : any = document.getElementById('root');
Modal.setAppElement(appElement);

root.render(
  <React.StrictMode>
      <LoadScript
          googleMapsApiKey={apiKey}
          libraries={libraries}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
      </LoadScript>
  </React.StrictMode>
);

reportWebVitals();
