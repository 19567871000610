import './AddressList.css';
import Chef, {Address} from "../../../../../../model/Chef";
import Customer from "../../../../../../model/Customer";
import React from "react";
import {Icon} from "@iconify/react";
import {useDispatch} from "react-redux";
import {setAddress} from "../../../../../../core/redux/slices/profileManagementSlice";

type AddressListProps = {
    user: Customer | Chef,
    closeEditAddressModal: () => void,
}

const AddressList: React.FC<AddressListProps> = ({user, closeEditAddressModal}) => {

    const dispatch = useDispatch();

    const handleSetAddress = (address: Address) => {
        dispatch(setAddress(address));
        closeEditAddressModal();
    }

    return (
        <div className='address-list'>
            {user.addresses.map((address) => (
                <div className='address-card' onClick={() => handleSetAddress(address)}>
                    <Icon icon='carbon:location-filled' fontSize={24} />
                    <div className='selected-address-column'>
                        <h4>{address.streetAddress}</h4>
                        <p>{`${address.city}, ${address.postalCode}`}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AddressList;
