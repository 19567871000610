import './DeleteOfferModal.css';
import Modal from "react-modal";
import React, {useState} from "react";

type DeleteOfferModalProps = {
    closeDeleteModal: () => void;
    isDeleteModalOpen: boolean,
    deleteMenuItem: () => void;
}

const DeleteOfferModal:React.FC<DeleteOfferModalProps>  = ({closeDeleteModal,isDeleteModalOpen, deleteMenuItem}) => {

    const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
    const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

    const handleDeleteOffer = () => {
        setIsDeleteButtonClicked(true);
        deleteMenuItem();
        closeDeleteModal();
    }

    const handleAnimationEnd = () => {
        setIsDeleteButtonClicked(false);
        setIsCancelButtonClicked(false);
    }

    const handleCancelDeleteOffer = () => {
        setIsCancelButtonClicked(true);
        closeDeleteModal();
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    return (
        <Modal isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} style={customStyles} className='modal delete-item-modal'>
            <div className='roboto-slab delete-menu-item-heading'>Delete Offer</div>
            <p className='delete-item-confirmation-text'>Are you sure to permanently delete the offer?</p>
            <div className='delete-menu-item-button'>
                <div className={`animated-primary-button cancel-button ${isCancelButtonClicked ? 'animate-secondary' : ''}`} onClick={handleCancelDeleteOffer} onAnimationEnd={handleAnimationEnd}>Cancel</div>
                <div className={`animated-primary-button delete-button ${isDeleteButtonClicked ? 'animate' : ''}`} onClick={handleDeleteOffer} onAnimationEnd={handleAnimationEnd}>Delete</div>
            </div>
        </Modal>
    )
}

export default DeleteOfferModal;
