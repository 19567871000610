import React from "react";
import BriefSummaryItemCard
    from "../../../../checkout-page/checkout-steps/order-items-summary/brief-summary-item-card/BriefSummaryItemCard";
import {FixedMealItem} from "../../../../../model/meal-plans/FixedMeal";

type AddedMealItemsProps = {
    mealItems: FixedMealItem[]
}

const AddedMealItems: React.FC<AddedMealItemsProps> = ({mealItems}) => {

    const handleExpandSummaryItems = () => {}

    return (
        <div className='added-meal-plan-items'>
            <div className='flex gap-20'>
                {mealItems.map((mealItem, index) =>
                    <BriefSummaryItemCard cartItem={{count: mealItem.quantity, menuItem: mealItem.menuItem}}
                                          handleExpandSummaryItems={handleExpandSummaryItems} key={index}/>
                )}
            </div>
        </div>
    )
}


export default AddedMealItems;
