// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-details h3 {
    font-weight: lighter;
}

.preview-modal-amount-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.amount-row {
    display: flex;
    justify-content: space-between;
}

.preview-modal-amount-container hr {
    border: none;
    border-top: 1px solid rgba(54, 53, 53, 0.09);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/order-preview-modal/amount-details/AmountDetails.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,4CAA4C;AAChD","sourcesContent":[".customer-details h3 {\n    font-weight: lighter;\n}\n\n.preview-modal-amount-container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 20px;\n}\n\n.amount-row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.preview-modal-amount-container hr {\n    border: none;\n    border-top: 1px solid rgba(54, 53, 53, 0.09);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
