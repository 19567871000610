// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-loader {
    display: flex;
    position: relative;
    gap: 6px;
    border-radius: 8px;
    align-items: center;
}

.button-loader div {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.button-loader div:nth-child(1) {
    background: black;
    animation: button-loader1 0.6s infinite;
}
.button-loader div:nth-child(2) {
    background: var(--primary-color);
    animation: button-loader2 0.6s infinite;
}
.button-loader div:nth-child(3) {
    background: black;
    animation: button-loader2 0.6s infinite;
}
.button-loader div:nth-child(4) {
    background: var(--primary-color);
    animation: button-loader3 0.6s infinite;
}
@keyframes button-loader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes button-loader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes button-loader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/loader-button/LoadingButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;IACjB,uCAAuC;AAC3C;AACA;IACI,gCAAgC;IAChC,uCAAuC;AAC3C;AACA;IACI,iBAAiB;IACjB,uCAAuC;AAC3C;AACA;IACI,gCAAgC;IAChC,uCAAuC;AAC3C;AACA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;AACJ;AACA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;AACJ;AACA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".button-loader {\n    display: flex;\n    position: relative;\n    gap: 6px;\n    border-radius: 8px;\n    align-items: center;\n}\n\n.button-loader div {\n    position: relative;\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\n}\n\n.button-loader div:nth-child(1) {\n    background: black;\n    animation: button-loader1 0.6s infinite;\n}\n.button-loader div:nth-child(2) {\n    background: var(--primary-color);\n    animation: button-loader2 0.6s infinite;\n}\n.button-loader div:nth-child(3) {\n    background: black;\n    animation: button-loader2 0.6s infinite;\n}\n.button-loader div:nth-child(4) {\n    background: var(--primary-color);\n    animation: button-loader3 0.6s infinite;\n}\n@keyframes button-loader1 {\n    0% {\n        transform: scale(0);\n    }\n    100% {\n        transform: scale(1);\n    }\n}\n@keyframes button-loader3 {\n    0% {\n        transform: scale(1);\n    }\n    100% {\n        transform: scale(0);\n    }\n}\n@keyframes button-loader2 {\n    0% {\n        transform: translate(0, 0);\n    }\n    100% {\n        transform: translate(24px, 0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
