import './FilterOrders.css';
import {Checkbox, ClickAwayListener, FormControlLabel, IconButton} from "@mui/material";
import CustomMUITooltip, {
    customCheckboxProps,
    customLabelProps
} from "../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import React, {useState} from "react";
import {Icon} from "@iconify/react";
import {useTranslation} from "react-i18next";

const FilterOrders = () => {

    const { t } = useTranslation('ChefDashboard');
    const [filter, setFilter] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [stagedFilter, setStagedFilter] = useState(filter);

    const handleTooltipToggle = () => {
        setTooltipOpen(!tooltipOpen);
        if (tooltipOpen) {
            setStagedFilter(filter);
        }
    };

    const handleCheckboxClick = (value: string, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if(stagedFilter === value){
            setStagedFilter('');
            return;
        }
        setStagedFilter(value);
    };

    const handleResetFilter = () => {
        setFilter('');
        setStagedFilter('');
        setTooltipOpen(false);
    }

    const handleApplyFilter = () => {
        setTooltipOpen(false);
        setFilter(stagedFilter);
    }

    const handleClickAway = () => {
        setTooltipOpen(false);
        if(filter !== stagedFilter){
            setStagedFilter(filter);
        }
    }

    return (
            <CustomMUITooltip
                title={
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className='filter-orders'>
                            <h3>Filter By</h3>
                            <div className='filter-checkbox-container'>
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'latest'} onClick={(e) => handleCheckboxClick('latest',e)}/>}
                                                  label={t('latest-filter')}  sx={customLabelProps} />
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'date'} onClick={(e) => handleCheckboxClick('date', e)}/>}
                                                  label={t('date-filter')}  sx={customLabelProps} />
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'status'} onClick={(e) => handleCheckboxClick('status', e)}/>}
                                                  label={t('status-filter')}  sx={customLabelProps} />
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'future'} onClick={(e) => handleCheckboxClick('future', e)}/>}
                                                  label={t('future-filter')}  sx={customLabelProps} />
                                <div className='filter-action-buttons'>
                                    <div className='reset-button' onClick={handleResetFilter}>Reset</div>
                                    <div className='primary-button apply-filter-button' onClick={handleApplyFilter}>Apply Filter</div>
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
                open={tooltipOpen}
                disableHoverListener
                onOpen={() => setTooltipOpen(true)}
                enterTouchDelay={0}
                placement="bottom-end"
                arrow>
                <IconButton onClick={handleTooltipToggle}>
                    <Icon icon='mage:filter' fontSize={20} />
                </IconButton>
            </CustomMUITooltip>
    )
}

export default FilterOrders;
