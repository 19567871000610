import './ContactUsDetail.css';
import {Icon} from "@iconify/react";
import generalInquiryImage from '../../../assets/images/contact-us/general-inquiry.png';
import orderInquiryImage from '../../../assets/images/contact-us/order-inquiry.png';
import chefAssistanceImage from '../../../assets/images/contact-us/chef-assistance.png';


const ContactUsDetail = () => {

    return (
        <div className='contact-us-detail-container'>
            <div className='box-card-shadow contact-us-column element-center column'>
                <img src={generalInquiryImage} className='column-image' alt='general inquiry'/>
                <div className='contact-column-detail'>
                    <h3>For general inquiry</h3>
                    <div className='row light-grey-text'>
                        <Icon icon='clarity:email-line' fontSize={24}/>
                        <p>info@homeeatz.com</p>
                    </div>
                </div>
            </div>
            <div className='box-card-shadow contact-us-column element-center column'>
                <img src={orderInquiryImage} className='column-image' alt='order inquiry'/>
                <div className='contact-column-detail'>
                    <h3>For orders related inquiry</h3>
                    <div className='row light-grey-text'>
                        <Icon icon='clarity:email-line' fontSize={24}/>
                        <p>support@homeeatz.com</p>
                    </div>
                </div>
            </div>
            <div className='box-card-shadow contact-us-column element-center column'>
                <img src={chefAssistanceImage} className='column-image' alt='order inquiry'/>
                <div className='contact-column-detail'>
                    <h3>For chef related inquiry</h3>
                    <div className='row light-grey-text'>
                        <Icon icon='clarity:email-line' fontSize={24}/>
                        <p>chefhelp@homeeatz.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsDetail;
