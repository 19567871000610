// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-summary-card-wrapper {
    display: flex;
    gap: 30px;
}


.detail-summary-column {
    display: flex;
    gap: 16px;
    align-items: center;
}

.detail-summary-column img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.detail-summary-column .count-number {
    background: var(--primary-color);
    height: 24px;
    width: 24px;
    border-radius: 4px;
}

.detail-summary-column-2 {
    display: flex;
    text-align: left;
    flex-direction: column;
}

.speciality-detail {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.detail-summary-column-2 .item-price {
    color: var(--light-grey);
}

@media screen and (max-width: 600px) {
    .speciality-detail {
        width: 200px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/order-items-summary/detail-summary-item-card/DetailSummaryItemCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".detail-summary-card-wrapper {\n    display: flex;\n    gap: 30px;\n}\n\n\n.detail-summary-column {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n}\n\n.detail-summary-column img {\n    width: 100px;\n    height: 100px;\n    border-radius: 10px;\n}\n\n.detail-summary-column .count-number {\n    background: var(--primary-color);\n    height: 24px;\n    width: 24px;\n    border-radius: 4px;\n}\n\n.detail-summary-column-2 {\n    display: flex;\n    text-align: left;\n    flex-direction: column;\n}\n\n.speciality-detail {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.detail-summary-column-2 .item-price {\n    color: var(--light-grey);\n}\n\n@media screen and (max-width: 600px) {\n    .speciality-detail {\n        width: 200px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
