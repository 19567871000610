// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-menu-container {
    flex: 1 1;
    height: 100vh;
    overflow-y: scroll;
}

.chef-menu-heading {
    display: flex;
    padding: 20px;
    background: white;
    align-items: center;
    justify-content: space-between;
}

.chef-menu-add-column {
    display: flex;
    gap: 24px;
    background: white;
    align-items: center;
}

.chef-menu-add-column h4 {
    font-size: 14px;
    color: var(--sub-black);
}

.add-item-button {
    display: flex;
    gap: 4px;
    padding: 8px;
    align-items: center;
    border-radius: 8px;
    background: var(--primary-color);
    justify-content: center;
}

.selected-heading {
    border-bottom: 8px solid var(--primary-color);
}

@media screen and (max-width: 600px) {
    .chef-menu-container h2 {
        font-size: 20px;
    }

    .chef-menu-add-column {
        gap: 8px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/ChefMenu.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;IAChC,uBAAuB;AAC3B;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,QAAQ;IACZ;AACJ","sourcesContent":[".chef-menu-container {\n    flex: 1;\n    height: 100vh;\n    overflow-y: scroll;\n}\n\n.chef-menu-heading {\n    display: flex;\n    padding: 20px;\n    background: white;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.chef-menu-add-column {\n    display: flex;\n    gap: 24px;\n    background: white;\n    align-items: center;\n}\n\n.chef-menu-add-column h4 {\n    font-size: 14px;\n    color: var(--sub-black);\n}\n\n.add-item-button {\n    display: flex;\n    gap: 4px;\n    padding: 8px;\n    align-items: center;\n    border-radius: 8px;\n    background: var(--primary-color);\n    justify-content: center;\n}\n\n.selected-heading {\n    border-bottom: 8px solid var(--primary-color);\n}\n\n@media screen and (max-width: 600px) {\n    .chef-menu-container h2 {\n        font-size: 20px;\n    }\n\n    .chef-menu-add-column {\n        gap: 8px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
