import React from 'react';
import errorPageImage from '../../assets/images/error-page.png';
import './ErrorPage.css';
import { NavLink } from 'react-router-dom';

const ErrorPage: React.FC = () => (

    <div className='error-page'>
        <img src={errorPageImage} alt='error page' className='error-page-image' />
        <h2 className='heading-font-large'>Oops!</h2>
        <p>We're sorry, it seems something went wrong</p>
        <NavLink to='/' className='link-black primary-button cursor'>Back to homepage</NavLink>
    </div>
);

export default ErrorPage;
