import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {customInputField, paperProps} from "../../../../../../../../custom-tooltips-dropdowns/CustomMUIInput";

interface MaxValuesSelectProps {
    value: number;
    onChange: (event: SelectChangeEvent<number>) => void;
}

const MaxValuesSelect: React.FC<MaxValuesSelectProps> = ({ value, onChange }) => {
    return (
        <FormControl fullWidth>
            <Select
                labelId="max-values-select-label"
                id="max-values-select"
                value={value}
                displayEmpty
                sx={customInputField}
                MenuProps={{PaperProps: paperProps}}
                onChange={onChange}>
                {Array.from({ length: 20 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MaxValuesSelect;
