import './PaymentDetailStep.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../../../core/redux/slices/loginSlice";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import AddNewCard from "./add-new-card/AddNewCard";
import {selectCustomer, selectPaymentMethods} from "../../../../core/redux/slices/customerSlice";
import {Icon} from "@iconify/react";
import store, {RootState} from "../../../../core/redux/store";
import Chef from "../../../../model/Chef";
import Customer from "../../../../model/Customer";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import useErrorMessage from "../../../../custom-hooks/useErrorMessage";
import {useTranslation} from "react-i18next";
import useFormInput from "../../../../custom-hooks/useFormInput";
import CustomerService from "../../../../core/services/CustomerService";

type PaymentDetailStepProps = {
    onCardSelectClick: (paymentMethodId: string) => void;
    paymentCardSelected: string;
}

export const customInputField = {
    '& label.Mui-focused': {
        color: '#fcb500',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#fcb500',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'gray',
        },
        '&:hover fieldset': {
            borderColor: '#fcb500',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fcb500',
        },
    }
}

const PaymentDetailStep: React.FC<PaymentDetailStepProps> = ({onCardSelectClick, paymentCardSelected}) => {

    const userType = useSelector(selectUser).userType;
    const paymentMethods = useSelector(selectPaymentMethods);
    const [isAddNewCard, setIsAddNewCard] = useState(!(paymentMethods?.length && paymentMethods.length > 0));
    const [isAddPromoCode, setIsAddPromoCode] = useState(false);
    const promoCode = useFormInput('',true);
    const apiDispatch = useDispatch<typeof store.dispatch>();

    const user: Chef | Customer = useSelector((state: RootState) =>
        userType === 'chef' ? selectChef(state) : selectCustomer(state)
    );

    const handleCardAdd = () => {

        if (!isAddNewCard) {
            return setIsAddNewCard(true);
        }
        if (paymentMethods && paymentMethods?.length > 0) {
            setIsAddNewCard(false);
        }
    }

    useEffect(  () => {
        const fetchData = async () => {
            if (user.id) {
                await apiDispatch(CustomerService.getPaymentMethods({id: user.id}));
            }
        };
        fetchData();
    },[])

    const handlePaymentChange = (paymentCardId: string) => {
        onCardSelectClick(paymentCardId);
    }

    const PaymentCardIcon: React.FC<{cardType: string}> = ({ cardType }) => {

        switch (cardType.toLowerCase()) {
            case 'visa':
                return <Icon icon='logos:visa' className='payment-card-icon'/>
            case 'mastercard':
                return <Icon icon='logos:mastercard' className='payment-card-icon'/>
            case 'amex':
                return <Icon icon='fa:cc-amex' className='payment-card-icon amex-card' />
            default:
                return <Icon icon='ion:card-sharp' className='payment-card-icon'/>
        }
    };

    return (
        <div className='checkout-step-wrapper'>
            <div className='checkout-step-heading-wrapper'>
                <p className='step-heading'>Payment Details</p>
            </div>
                <div className='payment-details-content'>
                    {paymentMethods && paymentMethods.length > 0 &&
                        <div className='payment-methods-container'>
                            {paymentMethods.map((paymentMethod, index) => (
                                <div className='payment-method-card' key={index} onClick={() => handlePaymentChange(paymentMethod.id)}>
                                    <div className='payment-card-details'>
                                        <PaymentCardIcon cardType={paymentMethod.brand} />
                                        <div className='column'>
                                            <p>****{paymentMethod.last4}</p>
                                            <sub className='light-grey-text'>{paymentMethod.expMonth}/{paymentMethod.expYear.toString().slice(2,4)}</sub>
                                        </div>
                                    </div>
                                    <RadioGroup name="row-radio-buttons-group" className='address-radio-button' onChange={() => handlePaymentChange(paymentMethod.id)}>
                                        <FormControlLabel value="new" control={<Radio checked={paymentCardSelected === paymentMethod.id}/>} label='' />
                                    </RadioGroup>
                                </div>
                            ))}
                        </div>
                    }
                    <div className='checkout-add-icon-container cursor' onClick={handleCardAdd}>
                        {!isAddNewCard ?
                            <div className='checkout-add-icon-wrapper'><Icon icon='material-symbols:add' className='checkout-add-icon'/></div>
                            : <div className='checkout-add-icon-wrapper'> <Icon icon='ic:baseline-minus' className='checkout-add-icon'/></div>
                        }
                        <label>Add new card</label>
                    </div>
                    {isAddNewCard && <AddNewCard onCardAddition={handleCardAdd}/>}
                    <div>
                        <div className='checkout-add-icon-container cursor' onClick={() => setIsAddPromoCode(!isAddPromoCode)}>
                            {!isAddPromoCode ?
                                <div className='checkout-add-icon-wrapper'><Icon icon='material-symbols:add' className='checkout-add-icon'/></div>
                                : <div className='checkout-add-icon-wrapper'> <Icon icon='ic:baseline-minus' className='checkout-add-icon'/></div>
                            }
                            <label>Add promo</label>
                        </div>
                        {isAddPromoCode &&
                            <div className='promo-wrapper'>
                                <TextField fullWidth={true} id="standard-basic" label="Promo code" variant="standard" onChange={promoCode.onChange} sx={customInputField} />
                                <button className='apply-promo-button cursor'>Apply</button>
                            </div>
                        }
                    </div>
                </div>
        </div>
    )
}

export default PaymentDetailStep;
