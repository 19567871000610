import './FeaturedDish.css';
import chefBusinessLogo from "../../../../assets/images/chef-business-logo.png";
import {Icon} from "@iconify/react";
import EditFeaturedDishPhotoModal from "./edit-featured-dish-photo-modal/EditFeaturedDishPhotoModal";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectChef} from "../../../../core/redux/slices/chefSlice";

const FeaturedDish = () => {

    const chef = useSelector(selectChef);
    const chefBusinessImageUrl = chef.featuredDishUrl ? chef.featuredDishUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN : chef.featuredDishUrl;
    const [isEditBusinessImageModalOpen, setIsEditBusinessImageModalOpen] = useState(false);


    const closeEditBusinessImageModal = () => {
        setIsEditBusinessImageModalOpen(false);
    }

    return (
        <div className='featured-dish-container box-card-shadow' id='featured-dish'>
            <h3 className='featured-dish-heading'>Featured Dish</h3>
            <div className='featured-dish-upload-column'>
                <div className='featured-dish-logo'>
                    <img src={chefBusinessImageUrl ? chefBusinessImageUrl : chefBusinessLogo} alt='featured dish logo' className='featured-dish-image'/>
                    <label className='chef-image-upload-icon cursor' onClick={() => setIsEditBusinessImageModalOpen(true)}>
                        <Icon icon='ion:camera-outline' fontSize={24}/>
                    </label>
                </div>
                <EditFeaturedDishPhotoModal isModalOpen={isEditBusinessImageModalOpen} closeModal={closeEditBusinessImageModal} chef={chef}/>
                <div className='info-container'>
                    <Icon icon='flat-color-icons:info' fontSize={24} className='info-icon'/>
                    <p>A photo of your signature dish can entice food lovers by highlighting your culinary expertise</p>
                </div>
            </div>
        </div>
    )
}

export default FeaturedDish;
