import './Footer.css';
import {NavLink, useLocation} from "react-router-dom";
import logoOrange from '../../../assets/images/logos/logo-orange-chef.png';
import {Icon} from "@iconify/react";
import LanguageSwitcherModal, {languages} from "../../../i18n/language-switcher-modal/LanguageSwitcherModal";
import useModal from "../../../custom-hooks/useModal";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const location = useLocation();
    const languageChangeModal = useModal();
    const { i18n } = useTranslation();

    const currentLanguage = () => {
        return languages.find((language) => language.abbreviation === i18n.language)?.label;
    }

    return (
        <>
            {
            !(location.pathname.startsWith('/chef/dashboard') || location.pathname.startsWith('/checkout')) &&
                <div className='footer'>
                    <div className='footer-content'>
                        <div className='apps-wrapper'>
                            <div className='logo-wrapper'>
                                <img src={logoOrange} alt='HomeEatz'/>
                            </div>
                            <NavLink className='mobile-application-wrapper link' to='/coming-soon/apps'>
                                <div className='mobile-application-button'>
                                    <Icon icon='logos:google-play-icon' fontSize={24}/>
                                    <div>
                                        <sub>Get it on</sub>
                                        <p>Google Play</p>
                                    </div>
                                </div>
                                <div className='mobile-application-button'>
                                    <Icon icon='ic:baseline-apple' fontSize={32}/>
                                    <div>
                                        <sub>Get it on</sub>
                                        <p>App Store</p>
                                    </div>
                                </div>
                            </NavLink>
                            <div className='social-media-handles'>
                                <Icon icon='circum:facebook' fontSize={32}/>
                                <Icon icon='lets-icons:insta' fontSize={32}/>
                                <Icon icon='prime:twitter' fontSize={24} />
                            </div>
                            <div className='newsletter-subscribe-wrapper'>
                                <p>Subscribe for newsletter</p>
                                <div className='subscribe-letter-input-wrapper'>
                                    <input placeholder='Enter your email'/>
                                    <div className='subscribe-email-button'>
                                        Subscribe
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footer-column-2'>
                            <div className='footer-links-wrapper'>
                                <div className='footer-links'>
                                    <NavLink to='/about-us' className='link'>About Us</NavLink>
                                    <NavLink to='/contact-us' className='link'>Contact Us</NavLink>
                                    <NavLink to='/becomeChef' className='link'>Become A Chef</NavLink>
                                </div>
                                <div className='footer-links'>
                                    <NavLink to='/privacy-policy' className='link'>Privacy Policy</NavLink>
                                    <NavLink to='/legal/terms-of-service' className='link'>Terms Of Service</NavLink>
                                    <p className='cursor' onClick={() => languageChangeModal.openModal()}>{currentLanguage()}</p>
                                </div>
                            </div>
                            <div className='footer-chef-image'></div>
                        </div>
                    </div>
                    <LanguageSwitcherModal isModalOpen={languageChangeModal.isOpen} closeModal={languageChangeModal.closeModal} />
                    <div className='copyright-content'>
                        Copyright © 2024. All Rights Reserved.
                    </div>
                </div>
            }
        </>

    )
}

export default Footer;
