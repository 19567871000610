import './UserProfileContainer.css';
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../core/redux/store";
import {selectChef} from "../../../../../core/redux/slices/chefSlice";
import {selectCustomer} from "../../../../../core/redux/slices/customerSlice";
import Customer from "../../../../../model/Customer";
import Chef from "../../../../../model/Chef";
import userProfileImage from '../../../../../assets/images/userProfileImage.png';
import ProfilePhotoPlaceholder
    from "../../../../../pages/chef-dashboard/profile/edit-profile-picture-modal/profile-photo-placeholder/ProfilePhotoPlaceholder";
import Logout from "../../../logout/Logout";

type UserProfileContainerProps = {
    userType: string,
}

const UserProfileContainer: React.FC<UserProfileContainerProps> = ({userType}) => {

    const user: Chef | Customer = useSelector((state: RootState) =>
        userType === 'chef' ? selectChef(state) : selectCustomer(state)
    );
    const userImage = user.profileImageUrl ? user.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN : userProfileImage;

    return (
        <div className='chef-profile-container'>
            <div className='chef-profile-row'>
                {user.profileImageUrl ? <img src={userImage} className='chef-profile-photo' alt='profile'/> : <ProfilePhotoPlaceholder containerSize={'small-size'} name={user.name}/>}
                <Logout />
            </div>
            <span>{user.emailAddress}</span>
        </div>
    )
}

export default UserProfileContainer;
