// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/registration.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.chef-photo-container{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 100vh;
    width: 800px;
}

.registration-container {
    position: relative;
}

`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/BecomeAChef.css"],"names":[],"mappings":";AACA;IACI,mDAAuD;IACvD,sBAAsB;IACtB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.chef-photo-container{\n    background: url(\"../../assets/images/registration.gif\");\n    background-size: cover;\n    height: 100vh;\n    width: 800px;\n}\n\n.registration-container {\n    position: relative;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
