import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import { CustomizableMealItem } from "../../../../../../model/meal-plans/CustomizableMeal";
import MenuItem from "../../../../../../model/MenuItem";
import CustomizableMealItemCard from "./customizable-meal-item-card/CustomizableMealItemCard";
import {SelectChangeEvent} from "@mui/material/Select";
import MaxValuesSelect from "./customizable-meal-item-card/max-values-select/MaxValuesSelect";


type CustomizableMealItemListProps = {
    onApplyMealItemsClicked: (mealItemType: string, mealItems: CustomizableMealItem) => void;
    menu?: MenuItem[],
    mealItemType: string,
    mealItems: CustomizableMealItem,
}

const CustomizableMealItemList: React.FC<CustomizableMealItemListProps> = ({onApplyMealItemsClicked, mealItems, menu, mealItemType}) => {

    const [mealPlanItems, setMealPlanItems] = useState<CustomizableMealItem>(mealItems);
    const [maxValue, setMaxValue] = useState<number>(mealItems.maxItems ? mealItems.maxItems : 1);

    const handleMaxValueChange = (event: SelectChangeEvent<number>) => {
        setMaxValue(event.target.value as number);
    };

    const onSelectMealItems = (menuItem: MenuItem) => {
        const itemInArrayIndex = mealPlanItems.menuItems.findIndex(mealItem => mealItem.id === menuItem.id);

        if (itemInArrayIndex > -1) {
            setMealPlanItems({...mealPlanItems, menuItems: mealPlanItems.menuItems.filter(mealItem => mealItem.id !== menuItem.id)});
        } else {
            setMealPlanItems({...mealPlanItems, menuItems: [...mealPlanItems.menuItems, menuItem]});
        }
    }

    const isChecked = (menuItem: MenuItem) => {
        const itemInArrayIndex = mealPlanItems.menuItems.findIndex(mealItem => mealItem.id === menuItem.id);

        return itemInArrayIndex > -1;
    }

    return (
        <div className='box-card-shadow padding-20 meal-plan-items-wrapper'>
            {
                menu?.length && menu?.length > 0 ?
                    <div className='flex column gap-20'>
                        <div className='flex column gap-10'>
                            <p className='font-weight-medium-bold'>Maximum number of items customer can select</p>
                            <MaxValuesSelect value={maxValue} onChange={handleMaxValueChange} />
                        </div>
                        <>
                            <p className='font-weight-medium-bold'>Available items for {mealItemType}</p>
                            <div className='meal-plans-items-list'>
                                {menu?.map((menuItem: MenuItem, index) => (
                                    <CustomizableMealItemCard mealItem={menuItem} onSelectMenuItem={onSelectMealItems} key={index} isChecked={isChecked(menuItem)}/>
                                ))}
                            </div>
                        </>
                    </div> :
                    <div className='empty-menu-items'>
                        <p>Unable to find the menu items</p>
                        <NavLink to='/chef/dashboard/menu'><button className='add-menu-button'>Add menu items</button></NavLink>
                    </div>
            }
            <div className='primary-button-right-end-wrapper'>
                <div className={`primary-button apply-meal-items-button`} onClick={() => onApplyMealItemsClicked(mealItemType, {...mealPlanItems, maxItems: maxValue})}>Apply</div>
            </div>
        </div>
    )
}

export default CustomizableMealItemList;
