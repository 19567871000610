import { MealPlanItemCarousel } from "../../../../../../components/custom-components/CustomizeCarousel";
import '../../../../../../styles/common.css';
import 'react-multi-carousel/lib/styles.css';
import Carousel from "react-multi-carousel";
import React, { useEffect, useRef, useState } from "react";
// import './MealItemsCarousel.css';
import { imageUrlConverter } from "../../../../../../core/utils/ImageUrlConverter";
import { Icon } from "@iconify/react";
import {CustomizableMealItem} from "../../../../../../model/meal-plans/CustomizableMeal";

type MealItemsCarouselProps = {
    mealItem: CustomizableMealItem;
}

const CustomizableMealItemsCarousel: React.FC<MealItemsCarouselProps> = ({ mealItem }) => {
    const [currentKey, setCurrentKey] = useState<number>(0);
    const carouselRef = useRef<any>(null);

    const handleNext = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handlePrev = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const CustomRightArrow = () => (
        <div onClick={handleNext} className="meal-item-carousel-button right-button element-center cursor">
            <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} />
        </div>
    );

    const CustomLeftArrow = () => (
        <div onClick={handlePrev} className="meal-item-carousel-button left-button element-center cursor">
            <Icon icon='material-symbols:keyboard-arrow-left' fontSize={24} />
        </div>
    );

    useEffect(() => {
        setCurrentKey(prevKey => prevKey + 1);
    }, [mealItem]);

    return (
        <div className='carousel-container'>
            <Carousel
                responsive={MealPlanItemCarousel}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={mealItem.menuItems.length > 1}
                infinite={false}
                key={currentKey}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                partialVisible={false}
                ref={carouselRef}>
                {mealItem.menuItems.map((mealItem, index) => (
                    <div key={index} className='meal-item'>
                        <img
                            src={imageUrlConverter(mealItem.imageUrl as string)}
                            className='meal-item-image'
                            alt='meal item'
                        />
                        {/*<p className='meal-item-quantity element-center'>{mealItem.quantity}</p>*/}
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default CustomizableMealItemsCarousel;
