// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bogof-column-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1;
}

.bogof-offer-row {
    display: flex;
    gap: 20px;
}

.bogof-column-wrapper > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.offer-heading-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.offer-heading-info {
    color: var(--info-color)
}

.tooltip-label {
    padding: 20px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-2);
}

@media screen and (max-width: 600px) {
    .bogof-offer-row {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/bogof-offer-form/BogofOfferForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".bogof-column-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    flex: 1;\n}\n\n.bogof-offer-row {\n    display: flex;\n    gap: 20px;\n}\n\n.bogof-column-wrapper > div {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.offer-heading-wrapper {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.offer-heading-info {\n    color: var(--info-color)\n}\n\n.tooltip-label {\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: var(--box-shadow-2);\n}\n\n@media screen and (max-width: 600px) {\n    .bogof-offer-row {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
