import React, { useEffect } from 'react';
import {gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import CustomerService from '../../../../core/services/CustomerService';
import { Icon } from '@iconify/react';
import store from "../../../../core/redux/store";
import {setCustomer} from "../../../../core/redux/slices/customerSlice";
import Customer from "../../../../model/Customer";

const GoogleLoginButton = () => {
    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
    const dispatch = useDispatch<typeof store.dispatch>();

    useEffect(() => {
        function initGapi() {
            gapi.load('auth2', () => {
                if (!gapi.auth2.getAuthInstance()) {
                    gapi.auth2.init({
                        client_id,
                        scope: 'profile email',
                    });
                }
            });
        }

        initGapi();
    }, [client_id]);

    const handleSignIn = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn({
            prompt: 'select_account',
        }).then(async (googleUser: gapi.auth2.GoogleUser) => {

            const tokenId = googleUser.getAuthResponse().id_token;

            const loginResponse =  await dispatch(CustomerService.validateGoogleLogin(tokenId));
            if (loginResponse.payload && 'userType' in loginResponse?.payload) {
                if(loginResponse.payload.userType === 'customer'){
                    dispatch(setCustomer(loginResponse.payload.user as Customer))
                    window.history.back();
                }
            }

        }).catch(error => {
            console.error('Login Failed:', error);
        });
    };

    return (
        <div className='sign-in-item cursor' onClick={handleSignIn}>
            <Icon icon='flat-color-icons:google' />
            <span>Google</span>
        </div>
    );
};

export default GoogleLoginButton;
