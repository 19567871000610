import React, {useEffect} from 'react';
import './FoodTypeSelector.css';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setFoodCategories} from "../../../../../core/redux/slices/chefSlice";
import store from "../../../../../core/redux/store";
import ChefService from "../../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import {customInputField, paperProps} from "../../../../../custom-tooltips-dropdowns/CustomMUIInput";

interface FoodTypeSelectorProps {
    foodType: string;
    setFoodType: (foodType: string) => void;
    handleAddCategory: () => void;
}

const FoodTypeSelector: React.FC<FoodTypeSelectorProps> = ({ foodType, setFoodType, handleAddCategory }) => {

    const foodTypes = useSelector(selectChef).foodCategories;
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const chefId = useSelector(selectChef).id;

    useEffect(() => {

        const fetchData = async () => {
            try {
                 await apiDispatch(ChefService.getFoodTypes({id: chefId as string}));
            }catch (error) {
                console.log(error);
            }
        }
        fetchData();

    }, [apiDispatch, chefId])

    const handleSelectFoodType = (event: SelectChangeEvent) => {
        if (event.target.value){
            setFoodType(event.target.value as string);
        }
    };



    return (
        <FormControl fullWidth>
            <Select
                className='food-type-selector'
                defaultValue='Main'
                value={foodType}
                onChange={handleSelectFoodType}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={customInputField}
                MenuProps={{PaperProps: paperProps}}>
                <MenuItem value="Main" key="Main">Main</MenuItem>
                <MenuItem value="Appetizer" key="Appetizer">Appetizer</MenuItem>
                <MenuItem value="Side" key="Side">Side</MenuItem>
                <MenuItem value="Dessert" key="Dessert">Dessert</MenuItem>
                <MenuItem value="Beverage" key="Beverage">Beverage</MenuItem>
                {foodTypes?.map((item, index) => (
                    <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                ))}
                <MenuItem value="" onClick={handleAddCategory} className='add-category-option-button'>Add a category</MenuItem>
            </Select>
        </FormControl>
    );
}

export default FoodTypeSelector;
