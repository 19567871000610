// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meet-chef-button {
    padding: 16px;
    text-align: center;
    border-radius: 8px;
}

.meet-chef-button.button-primary:hover {
    background: var(--primary-color-hover);
}

.button-primary {
    background: var(--primary-color);
}

.button-white {
    background: white;
    padding: 16px;
    border: 1px solid var(--sub-black);
}

.button-white:hover {
    background: #e8e8e8;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/find-and-hire-chef/meet-chefs/meet-chef-button/MeetChefButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".meet-chef-button {\n    padding: 16px;\n    text-align: center;\n    border-radius: 8px;\n}\n\n.meet-chef-button.button-primary:hover {\n    background: var(--primary-color-hover);\n}\n\n.button-primary {\n    background: var(--primary-color);\n}\n\n.button-white {\n    background: white;\n    padding: 16px;\n    border: 1px solid var(--sub-black);\n}\n\n.button-white:hover {\n    background: #e8e8e8;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
