import './SaveErrorMessage.css';
import {Icon} from "@iconify/react";
import React from "react";

type InputErrorHandlingProps = {
    message: string,
}
const ErrorMessage: React.FC<InputErrorHandlingProps>  = ({message}) => {

    return (
        <div className='save-error-container'>
            <Icon icon='material-symbols:info' className='error-message-icon'/>
            <p>{message}</p>
        </div>
    )
}

export default ErrorMessage;
