// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-photo-container.large {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile-photo-container.small {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.profile-photo-container img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/profile-photo/ProfilePhoto.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".profile-photo-container.large {\n    width: 150px;\n    height: 150px;\n    border-radius: 50%;\n}\n\n.profile-photo-container.small {\n    width: 80px;\n    height: 80px;\n    border-radius: 50%;\n}\n\n.profile-photo-container img {\n    height: 100%;\n    width: 100%;\n    border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
