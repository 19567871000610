import './AddressSelected.css';
import {Icon} from "@iconify/react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectChosenAddress, setAddress} from "../../../core/redux/slices/profileManagementSlice";
import {Address} from "../../../model/Chef";
import {setChefAddresses} from "../../../core/redux/slices/chefSlice";

type AddressSelectedProps = {
    isEditable?: boolean;
    selectedAddress?: Address;
    cancelChefAddress?: boolean;
}

const AddressSelected: React.FC<AddressSelectedProps> = ({isEditable, selectedAddress, cancelChefAddress}) => {

    const chosenAddressFromState = useSelector(selectChosenAddress);
    const chosenAddress = selectedAddress || chosenAddressFromState;
    const dispatch = useDispatch();

    const handleAddressCancel = () => {

        if (cancelChefAddress){
            dispatch(setChefAddresses([]));
        }else {
            dispatch(setAddress(undefined));
        }
    }

    return (
        <div className='search-input-wrapper'>
            <Icon icon='pajamas:location' fontSize={24} className='sub-black' />
            <div className='address-search-input'>{`${chosenAddress?.streetAddress}, ${chosenAddress?.city}`}
                {isEditable &&
                    <Icon className='cursor' icon='material-symbols:cancel' fontSize={24} color='gray' onClick={handleAddressCancel} />
                }
            </div>
        </div>
    )
}

export default AddressSelected;
