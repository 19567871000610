import './Roadmap.css';
import roadmapImage1 from '../../../assets/images/roadmap/roadmap-1.png';
import roadmapImage2 from '../../../assets/images/roadmap/roadmap-2.jpg';
import roadmapImage3 from '../../../assets/images/roadmap/roadmap-3.png';
import roadmapImage4 from '../../../assets/images/roadmap/roadmap-4.png';

import RoadmapCard from "./roadmap-card/RoadmapCard";
import {useTranslation} from "react-i18next";

const Roadmap = () => {

    const { t } = useTranslation('BecomeAChef');

    return (
        <div className='become-chef-roadmap-wrapper'>
            <div className='heading-font-large text-center roadmap-heading'>How it works</div>
            <div className='become-chef-roadmap'>
                <RoadmapCard image={roadmapImage1} heading={t('roadmap-1-heading')} icon={'mdi:number-1'} details={t('roadmap-1-detail')} />
                <RoadmapCard image={roadmapImage2} heading={t('roadmap-2-heading')} icon={'mdi:number-2'} details={t('roadmap-2-detail')} />
                <RoadmapCard image={roadmapImage3} heading={t('roadmap-3-heading')} icon={'mdi:number-3'} details={t('roadmap-3-detail')} />
                <RoadmapCard image={roadmapImage4} heading={t('roadmap-4-heading')} icon={'mdi:number-4'} details={t('roadmap-4-detail')} />
            </div>
        </div>
    )
}

export default Roadmap;
