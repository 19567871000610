import './EmptyOffers.css';
import emptyOffersImage from '../../../../assets/images/empty-offers.svg';
import {useTranslation} from "react-i18next";

interface EmptyOfferType {
    onExploreOffersClick: () => void,
}

const EmptyOffers: React.FC<EmptyOfferType> = ({onExploreOffersClick}) => {

    const { t } = useTranslation('ChefDashboard');

    return (
        <div className='empty-offers-container'>
            <img src={emptyOffersImage} className='empty-offer-image' alt='empty-menu'/>
            <div className='flex column gap-10 text-center'>
                <p>{t('empty-offers-heading-part-1')}</p>
                <p>{t('empty-offers-heading-part-2')}</p>
                <div className='primary-button' onClick={onExploreOffersClick}>{t('empty-offers-button')}</div>
            </div>
        </div>
    )
}

export default EmptyOffers;
