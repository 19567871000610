import './AmountDetails.css';
import Order from "../../../../../model/order/Order";
import React from "react";


type AmountDetailsProps = {
    order?: Order,
}

const AmountDetails: React.FC<AmountDetailsProps> = ({order}) => {

    return (
        <div className='preview-modal-amount-container'>
            <div className='amount-row'>
                <p>Subtotal</p>
            </div>
            <div className='amount-row'>
                <p>QST(9.975%)</p>
            </div>
            <div className='amount-row'>
                <p>GST(5%)</p>
            </div>

            <hr />
            <div className='amount-row'>
                <p>Total</p>
            </div>
        </div>
    )
}

export default AmountDetails;
