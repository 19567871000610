import {TextField} from "@mui/material";
import React, {FormEvent, useState} from "react";
import useFormInput from "../../../custom-hooks/useFormInput";
import {useTranslation} from "react-i18next";
import './ContactUsForm.css';
import ErrorMessage from "../../chef-dashboard/profile/save-error-message/ErrorMessage";
import {validateEmailSyntax} from "../../../core/utils/Validation";
import EmailService, {GeneralInquiryEmailType} from "../../../core/services/EmailService";
import useErrorMessage from "../../../custom-hooks/useErrorMessage";
import LoadingButton from "../../../components/shared/loader-button/LoadingButton";
import SuccessMessage from "../../../components/shared/success-message/SuccessMessage";

const ContactUsForm = () => {

    const firstName = useFormInput('', true);
    const lastName = useFormInput('', true);
    const email = useFormInput('', true);
    const [emailValidationError, setEmailValidationError] = useState('');
    const subject = useFormInput('', true);
    const messageBody = useFormInput('', true);
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useErrorMessage(3000);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const { t } = useTranslation('common');

    const customInputField = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: 'white',
            '& fieldset': {
                border: '1px solid black',
            },

            '&.Mui-focused fieldset': {
                borderColor: '#f4c858',
            },
        },
        '& label.Mui-focused': {
            color: 'black',
        },
    }

    const validateForm = () => {

        firstName.validate(firstName.value);
        lastName.validate(lastName.value);
        subject.validate(subject.value);
        messageBody.validate(messageBody.value);
        email.validate(email.value);

        return firstName.value && lastName.value && subject.value && email.value;
    }

    const resetFormValues = () => {
        firstName.reset();
        lastName.reset();
        subject.reset();
        messageBody.reset();
        email.reset();

        console.log(firstName.value);
    }

    const handleContactUsFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        if (validateForm()){

            if (!validateEmailSyntax(email.value)){
                setIsLoading(false);
                return setEmailValidationError('Invalid email address')
            }
            const emailToSend: GeneralInquiryEmailType = {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                subject: subject.value,
                body: messageBody.value,
            }

            const emailSentResponse = await EmailService.sendGeneralInquiryEmail(emailToSend);

            if (emailSentResponse.success){
                setIsEmailSent(true)
                setTimeout(() => setIsEmailSent(false), 2000);
                resetFormValues();
            }else {
                setApiError(t('email-send-error'));
            }
        }

        setIsLoading(false);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValidationError('');
        email.onChange(event);
    }


    return (
        <form className='contact-us-form' onSubmit={handleContactUsFormSubmit}>
            <div className='contact-us-name-row'>
                <div>
                    <TextField fullWidth={true} id="outlined-basic" label={t('contact-us.form.firstName')} variant="outlined"
                               sx={customInputField} onChange={firstName.onChange} value={firstName.value}/>
                    {firstName.isMissing && <p className='error-message'>{t('field-required')}</p>}
                </div>
                <div>
                    <TextField fullWidth={true} id="outlined-basic" label={t('contact-us.form.lastName')} variant="outlined"
                               sx={customInputField} onChange={lastName.onChange} value={lastName.value}/>
                    {lastName.isMissing && <p className='error-message'>{t('field-required')}</p>}
                </div>
            </div>
            <div>
                <TextField fullWidth={true} id="outlined-basic" label={t('contact-us.form.email')} variant="outlined"
                           onChange={handleEmailChange} sx={customInputField} value={email.value}/>
                {emailValidationError && <p className='error-message'>{t('invalid-email-address')}</p>}
                {email.isMissing && <p className='error-message'>{t('field-required')}</p>}
            </div>
            <div>
                <TextField fullWidth={true} id="outlined-basic" label={t('contact-us.form.subject')} variant="outlined"
                           onChange={subject.onChange} sx={customInputField} value={subject.value}/>
                {subject.isMissing && <p className='error-message'>{t('field-required')}</p>}
            </div>
            <div>
                <TextField fullWidth={true} id="outlined-basic" label={t('contact-us.form.body')} variant="outlined" multiline={true} rows={4}
                           onChange={messageBody.onChange} sx={customInputField} value={messageBody.value}/>
                {messageBody.isMissing && <p className='error-message'>{t('field-required')}</p>}
            </div>
            {isLoading ?
                <LoadingButton /> : isEmailSent ? <SuccessMessage message={t('form-submitted')} /> :
                <button className='send-message-button primary-button'>Submit Message</button>
            }
            {apiError && <ErrorMessage message={apiError} />}
        </form>
    )
}

export default ContactUsForm;
