import React, { useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { setFormattedAddress } from "../../core/redux/slices/profileManagementSlice";

const GeoLocation = () => {
    const {isLoaded, loadError} = useLoadScript({googleMapsApiKey: 'AIzaSyCahsaGxn-851VlftJGJEfvAFUnD9hZWoU', libraries:['places']});
    const dispatch = useDispatch();

        useEffect(() => {
            if (isLoaded && !loadError) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        async (position) => {
                            const { latitude, longitude } = position.coords;
                            const geocoder = new window.google.maps.Geocoder();
                            const location = new window.google.maps.LatLng(latitude, longitude);
                            await geocoder.geocode({location}, (results, status) => {
                                if (status === "OK" && results && results.length > 0) {
                                    dispatch(setFormattedAddress(results[0].formatted_address));
                                } else {
                                    console.error("Geocode was not successful for the following reason:", status);
                                }
                            });
                        },
                        (error) => {
                            console.error("Error retrieving geolocation:", error);
                        }
                    );
                } else {
                    console.error("Geolocation is not supported by this browser.");
                }
            }
        }, [isLoaded, loadError]);

        return <></>
};

export default GeoLocation;
