import './LoginButtonStyle.css'
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";

const LoginButton = () => {

    const navigateTo = useNavigate();

    return (
        <div onClick={() => navigateTo('/login')} className='login-button flex-space-around'>
            <Icon icon='ri:login-circle-line' fontSize={24} />
            <span>Log In</span>
        </div>
    )
}

export default LoginButton;
