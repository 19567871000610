import './PageChangeRouter.css';
import chefHatImage from '../../../../assets/images/loaders/chef-hat.png';
import plateImage from '../../../../assets/images/loaders/plate.png';


const PageChangeRouter = () => {

    return (
        <div className='loader-backdrop'>
            <div className="loader-container">
                <img src={chefHatImage} alt="Chef's Hat" className="chef-hat"/>
                <img src={plateImage} alt="Food Plate" className="food-plate"/>
            </div>

        </div>
    )
}

export default PageChangeRouter;
