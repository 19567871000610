// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-dish-container {
    border-radius: 10px;
    background: white;
}

.featured-dish-heading {
    padding: 15px 30px;
    border-bottom: 1px solid var(--border-light);;
}

.featured-dish-upload-column {
    display: flex;
    gap: 30px;
    padding: 20px 40px;
}

.featured-dish-logo {
    position: relative;
}

.featured-dish-image {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.info-container {
    display: flex;
    gap: 20px;
    border-radius: 4px;
    align-self: center;
    padding: 6px 12px;
    font-size: 14px;
    background: rgba(167, 206, 248, 0.34);
    align-items: center;
}

.info-container p {
    color: var(--sub-black);
}

@media screen and (max-width: 600px) {
    .featured-dish-upload-column {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 10px;
    }
    .info-icon {
        min-height: 20px;
        min-width: 20px;
    }
    .info-container p {
        font-size: 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/featured-dish/FeaturedDish.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,aAAa;QACb,SAAS;IACb;IACA;QACI,gBAAgB;QAChB,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".featured-dish-container {\n    border-radius: 10px;\n    background: white;\n}\n\n.featured-dish-heading {\n    padding: 15px 30px;\n    border-bottom: 1px solid var(--border-light);;\n}\n\n.featured-dish-upload-column {\n    display: flex;\n    gap: 30px;\n    padding: 20px 40px;\n}\n\n.featured-dish-logo {\n    position: relative;\n}\n\n.featured-dish-image {\n    height: 100px;\n    width: 100px;\n    border-radius: 50%;\n}\n\n.info-container {\n    display: flex;\n    gap: 20px;\n    border-radius: 4px;\n    align-self: center;\n    padding: 6px 12px;\n    font-size: 14px;\n    background: rgba(167, 206, 248, 0.34);\n    align-items: center;\n}\n\n.info-container p {\n    color: var(--sub-black);\n}\n\n@media screen and (max-width: 600px) {\n    .featured-dish-upload-column {\n        flex-direction: column;\n        align-items: center;\n        padding: 20px;\n        gap: 10px;\n    }\n    .info-icon {\n        min-height: 20px;\n        min-width: 20px;\n    }\n    .info-container p {\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
