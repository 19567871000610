// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-top: 20px;
    border-bottom: 1px solid #ccc;
}

.carousel-filter {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.menu-search-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    max-width: fit-content;
    border-radius: 16px;
    background: #F7F7F7;
    border: 1px solid transparent;
}

.menu-search-bar:focus-within {
    border: 1px solid black;
}

.menu-search-bar input {
    border: none;
    width: 100%;
    background: transparent;
}

.menu-search-bar input:focus {
    border: none;
    outline: none;
}

.carousel-filter button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-bottom: 10px;
}

.carousel-filter button.active {
    border-bottom: 2px solid black;
}

@media screen and (max-width: 600px) {
    .menu-search-bar {

    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-business-page/menu-sub-categories/MenuSubCategories.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;IACN,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI;;IAEA;AACJ","sourcesContent":[".sticky-header {\n    position: sticky;\n    top: 0;\n    background-color: white;\n    z-index: 10;\n    padding-top: 20px;\n    border-bottom: 1px solid #ccc;\n}\n\n.carousel-filter {\n    display: flex;\n    gap: 20px;\n    overflow-x: scroll;\n}\n\n.header-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 20px;\n}\n\n.menu-search-bar {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    padding: 8px 16px;\n    max-width: fit-content;\n    border-radius: 16px;\n    background: #F7F7F7;\n    border: 1px solid transparent;\n}\n\n.menu-search-bar:focus-within {\n    border: 1px solid black;\n}\n\n.menu-search-bar input {\n    border: none;\n    width: 100%;\n    background: transparent;\n}\n\n.menu-search-bar input:focus {\n    border: none;\n    outline: none;\n}\n\n.carousel-filter button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    padding-bottom: 10px;\n}\n\n.carousel-filter button.active {\n    border-bottom: 2px solid black;\n}\n\n@media screen and (max-width: 600px) {\n    .menu-search-bar {\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
