import './ProfilePhotoPlaceholder.css';
import React from "react";

type ProfilePhotoProps = {
    containerSize: string,
    name: string
}

const ProfilePhotoPlaceholder: React.FC<ProfilePhotoProps> = ({containerSize, name}) => {

    const initialsArray = name.trim().split(' ');
    const initials = initialsArray.length > 1 ? initialsArray[0].charAt(0) + initialsArray[initialsArray.length-1].charAt(0) : initialsArray[0].charAt(0);

    return (
        <div className={`image-container ${containerSize}`}>
            <p>{initials}</p>
        </div>
    )
}

export default ProfilePhotoPlaceholder;
