// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-us-details {
    display: flex;
    gap: 30px;
    margin: 0 100px 0 100px;
}

.why-us-heading {
    text-align: center;
    margin-bottom: 50px;
}

.why-us-container {
    margin-bottom: 100px;
}

@media screen and (max-width: 600px) {
    .why-us-details {
        gap: 30px;
        flex-direction: column;
        margin: 0 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/why-us/WhyUs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,SAAS;QACT,sBAAsB;QACtB,cAAc;IAClB;AACJ","sourcesContent":[".why-us-details {\n    display: flex;\n    gap: 30px;\n    margin: 0 100px 0 100px;\n}\n\n.why-us-heading {\n    text-align: center;\n    margin-bottom: 50px;\n}\n\n.why-us-container {\n    margin-bottom: 100px;\n}\n\n@media screen and (max-width: 600px) {\n    .why-us-details {\n        gap: 30px;\n        flex-direction: column;\n        margin: 0 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
