// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-filter-buttons {
    display: flex;
    gap: 10px;
    padding: 0 20px;
    background: white;
    overflow-x: scroll;
}

.category-filter-button {
    padding: 10px;
}

.current-filter {
    border-bottom: 3px solid var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/added-items/category-filter-buttons/CategoryFilterButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".category-filter-buttons {\n    display: flex;\n    gap: 10px;\n    padding: 0 20px;\n    background: white;\n    overflow-x: scroll;\n}\n\n.category-filter-button {\n    padding: 10px;\n}\n\n.current-filter {\n    border-bottom: 3px solid var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
