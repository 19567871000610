// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-location{
    display: flex;
    margin-bottom: 150px;
    justify-content: center;
    flex-direction: column;
}

.address-input{
    border: none;
    width: 250px;
    padding: 10px;
    border-bottom: 1px black solid;
}

.address-input:focus{
    outline: none;
}

.location-icon{
    margin:0 4px 0 2px;
    color: var(--primary-color);
}

.pac-container:after{
    content:none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/google-maps/GoogleMapsStyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;IACpB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".address-location{\n    display: flex;\n    margin-bottom: 150px;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.address-input{\n    border: none;\n    width: 250px;\n    padding: 10px;\n    border-bottom: 1px black solid;\n}\n\n.address-input:focus{\n    outline: none;\n}\n\n.location-icon{\n    margin:0 4px 0 2px;\n    color: var(--primary-color);\n}\n\n.pac-container:after{\n    content:none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
