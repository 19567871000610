import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import {customCheckboxProps, customLabelProps} from "../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import MenuItem from "../../../../model/MenuItem";
import './MenuItemCard.css';

type MenuItemCardProps = {
    menuItem: MenuItem,
    offerList: string[]
    handleSelectedMenuItem: (itemId: string) => void,
}

const MenuItemCard: React.FC<MenuItemCardProps> = ({menuItem, handleSelectedMenuItem, offerList}) => {

    const [isChecked, setIsChecked] = useState(offerList.includes(menuItem.id || '') || false);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const image = menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
        setImageUrl(image);
    },[menuItem.imageUrl])

    const handleCheckboxClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(menuItem.id){
            handleSelectedMenuItem(menuItem.id);
        }
        setIsChecked(event.target.checked)
    }

    return (
        <div className='menu-item-card'>
            {imageUrl &&
                <>
                    <img src={imageUrl} alt='menu-item' className='menu-item-image'/>
                    <p>{menuItem.name}</p>
                    <FormControlLabel control={<Checkbox sx={customCheckboxProps} onChange={handleCheckboxClicked} checked={isChecked}/>}
                                      label=''  sx={customLabelProps}/>
                </>
            }
        </div>
    )
}

export default MenuItemCard;
