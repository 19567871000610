import React, {useState} from 'react';
import './AvailabilityDateCard.css';
import { DayAvailability } from '../../../../model/Schedule';
import ChangeAvailability from '../change-availability/ChangeAvailability';
import ChefService from "../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import {selectChef, setSchedule} from "../../../../core/redux/slices/chefSlice";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../../core/redux/store";
import {selectToken} from "../../../../core/redux/slices/loginSlice";

type AvailabilityDateCardProps = {
    dayAvailability: DayAvailability;
};

const AvailabilityDateCard: React.FC<AvailabilityDateCardProps> = ({ dayAvailability }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [stagedAvailability, setStagedAvailability] = useState(dayAvailability.isAvailable);
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const id = useSelector(selectChef).id;
    const token = useSelector(selectToken).accessToken;

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    const toggle = (newAvailability: boolean) => {
        setStagedAvailability(newAvailability);
    };

    const updateSchedule = async () => {

        const availability: DayAvailability = {
            date: dayAvailability.date,
            day: dayAvailability.day,
            isAvailable: stagedAvailability,
        };

        try {
            const resultAction = await apiDispatch(ChefService.updateSchedule({
                id: id as string,
                token,
                dayAvailability: availability,
            }));

            const schedule: DayAvailability[] = unwrapResult(resultAction);
            apiDispatch(setSchedule(schedule));
            setIsFlipped(false);
        }catch (error) {
            console.log(error);
        }
    }


    return (
        <div className={`availability-card-wrapper ${isFlipped ? 'flipped' : ''}`}>
            <div className="availability-date-card-inner">
                <div className={`availability-date-card-front ${dayAvailability.isAvailable ? 'available' : ''}`} onClick={handleClick}>
                    <div>
                        <div>{dayAvailability.day}</div>
                    </div>
                </div>
                <div className="availability-date-card-back">
                    <div className="availability-tooltip-content-row">
                        <p>Available</p>
                        <div>
                            <ChangeAvailability onToggle={toggle} isAvailable={stagedAvailability}/>
                        </div>
                    </div>
                    {stagedAvailability !== dayAvailability.isAvailable ?
                        <div className="change-availability-button" onClick={updateSchedule}>Update</div> :
                        <div className="change-availability-button" onClick={handleClick}>Cancel</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default AvailabilityDateCard;
