// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-switch {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-switch.toggled {
    background-color: #99e599;
}

.toggle-knob {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s;
}

.toggle-switch.toggled .toggle-knob {
    left: 26px;
}

@media screen and (max-width: 600px) {
    .toggle-switch {
        width: 40px;
    }

    .toggle-switch.toggled .toggle-knob {
        left: 17px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/schedule/change-availability/ChangeAvailability.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".toggle-switch {\n    width: 50px;\n    height: 25px;\n    border-radius: 25px;\n    background-color: #ccc;\n    position: relative;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.toggle-switch.toggled {\n    background-color: #99e599;\n}\n\n.toggle-knob {\n    width: 23px;\n    height: 23px;\n    border-radius: 50%;\n    background-color: #fff;\n    position: absolute;\n    top: 1px;\n    left: 1px;\n    transition: left 0.3s;\n}\n\n.toggle-switch.toggled .toggle-knob {\n    left: 26px;\n}\n\n@media screen and (max-width: 600px) {\n    .toggle-switch {\n        width: 40px;\n    }\n\n    .toggle-switch.toggled .toggle-knob {\n        left: 17px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
