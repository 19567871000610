import './CreateMealPlan.css';
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import infoIcon from '../../../../assets/images/info.svg';
import MealPlanForm, {MealType} from "./meal-plan-form/MealPlanForm";
import useModal from "../../../../custom-hooks/useModal";
import CustomModal from "../../../../components/shared/custom-modal/CustomModal";
import {Icon} from "@iconify/react";
import FixedMeal from "../../../../model/meal-plans/FixedMeal";
import CustomizableMeal from "../../../../model/meal-plans/CustomizableMeal";

type CreateMealPlanProps = {
    onCloseAddMeal: () => void;
    mealPlanToEdit?: FixedMeal | CustomizableMeal;
    typeOfMeal?: MealType;
}

const CreateMealPlan: React.FC<CreateMealPlanProps> = ({onCloseAddMeal, mealPlanToEdit, typeOfMeal}) => {

    const { t } = useTranslation('ChefDashboard');
    const infoModal = useModal();
    const [mealType, setMealType] = useState<MealType>(typeOfMeal ? typeOfMeal : MealType.FIXED);

    const changeMealType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMealType(event.target.value as MealType);
    }

    const infoModalContent = () => {

        return (
            <div className='meal-plan-form-detail'>
                <div className='flex column gap-10'>
                    <h3>{t('meal-plans.create.fixed-plan-label')}</h3>
                    <p className='grey-light-text'>{t('meal-plans.create.fixed-plan-info')}</p>
                </div>
                <div className='flex column gap-10'>
                    <h3>{t('meal-plans.create.customizable-plan-label')}</h3>
                    <p className='grey-light-text'>{t('meal-plans.create.customizable-plan-info')}</p>
                </div>
                <div onClick={infoModal.closeModal} className='light-grey-button close-modal2-button'>Close</div>
            </div>
        )
    }

    return (
        <div className='create-meal-plan-page'>
            <div className='add-new-item-heading box-card-shadow'>
                <Icon icon='ion:chevron-back' fontSize={28} className='cursor back-to-added-items' onClick={onCloseAddMeal}/>
                <h3>{!mealPlanToEdit ? t('add-meal-heading') : t('edit-meal-heading')}</h3>
            </div>
            <div className='box-card-shadow dashboard-content-card pd-btm-20 create-meal-form'>
                <div className='meal-plan-form-detail light-border-bottom'>
                    <div className='flex gap-10 center-align'>
                        <h3>{t('meal-plans.create.select-meal-plan')}</h3>
                        <img src={infoIcon} className='info-icon' alt='info icon' onClick={infoModal.openModal}/>
                    </div>
                    <RadioGroup name="row-radio-buttons-group" className='address-radio-button flex column gap-20' onChange={changeMealType}>
                        <FormControlLabel value="FIXED" control={<Radio checked={mealType === 'FIXED'}/>} label={t('meal-plans.create.fixed-plan-label')} ></FormControlLabel>
                        <FormControlLabel value="CUSTOMIZABLE" control={<Radio checked={mealType === 'CUSTOMIZABLE'}/>} label={t('meal-plans.create.customizable-plan-label')} ></FormControlLabel>
                    </RadioGroup>
                </div>
                <MealPlanForm onCloseCreateMealPlan={onCloseAddMeal} mealPlanToEdit={mealPlanToEdit} mealType={mealType}/>
            </div>
            <CustomModal isOpen={infoModal.isOpen} closeModal={infoModal.closeModal} children={infoModalContent()}/>
        </div>
    )
}

export default CreateMealPlan;
