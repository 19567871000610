import './CheckoutSteps.css';
import DeliveryDetailsStep from "./delivery-details-step/DeliveryDetailsStep";
import PaymentDetailStep from "./payment-detail-step/PaymentDetailStep";
import OrderSummary from "./order-items-summary/OrderSummary";
import React from "react";

type CheckoutStepsProps = {
    onCardSelectClick: (paymentMethodId: string) => void;
    paymentCardSelected: string;
}

const CheckoutSteps: React.FC<CheckoutStepsProps> = ({onCardSelectClick, paymentCardSelected}) => {

    return (
        <div className='checkout-steps-wrapper'>
            <OrderSummary />
            <DeliveryDetailsStep />
            <PaymentDetailStep onCardSelectClick={onCardSelectClick} paymentCardSelected={paymentCardSelected}/>
        </div>
    )
}

export default CheckoutSteps;
