import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../../model/Customer";
import {RootState} from "../store";
import Credentials from "../../../model/Credentials";
import MenuItem from "../../../model/MenuItem";
import {Address} from "../../../model/Chef";
import PaymentMethod from "../../../model/PaymentMethod";

type selectedChefType = {
    id: string,
    name: string,
    profileImageUrl: string,
}

export type CartItemType = {
    count: number,
    menuItem: MenuItem,
    specialInstructions?: string,
}

type initialStateType = {
    customer: Customer,
    checkoutCart: {
        chef?: selectedChefType,
        cartItems: CartItemType[],
        orderType: string
    }
}

const initialState : initialStateType = {
    customer: {
        id: undefined,
        addresses: [],
        emailAddress : '',
        name : '',
        phoneNumber : '',
        favoriteChefs: [],
        credentials: undefined,
    },
    checkoutCart: {
        cartItems: [],
        orderType: 'pickup'
    }
}

const customerSlice = createSlice({
    name : 'customerSave',
    initialState,
    reducers : {
        setCustomer : (state, action: PayloadAction<Customer>) => {
            return {...state, customer: action.payload};
        },
        setEmail : (state, action: PayloadAction<string>) => {
            state.customer.emailAddress = action.payload;
        },
        setName : (state, action: PayloadAction<string>) => {
            state.customer.name = action.payload;
        },
        setPhoneNumber : (state, action: PayloadAction<string>) => {
            state.customer.phoneNumber = action.payload;
        },
        addFavoriteChef : (state, action: PayloadAction<string|undefined>) => {

            const emailAddress = action.payload ??  '';

            if(emailAddress !== '') {
                state.customer.favoriteChefs = [...state.customer.favoriteChefs, emailAddress];
            }
        },

        removeFavoriteChef : (state, action: PayloadAction<string|undefined>) => {

            state.customer.favoriteChefs = state.customer.favoriteChefs.filter(chef =>{
                return action.payload !== chef;
            });
        },
        addCustomerAddress : (state, action: PayloadAction<Address>) => {
            state.customer.addresses = [...state.customer.addresses, action.payload];
        },
        setCustomerAddresses : (state, action: PayloadAction<Address[]>) => {
            state.customer.addresses = action.payload;
        },
        setCustomerCredentials: (state, action: PayloadAction<Credentials>) => {
            state.customer.credentials = action.payload;
        },
        emptyCart: (state) => {
            state.checkoutCart = {cartItems: [], chef: undefined, orderType: 'pickup'};
        },
        setSelectedChef : (state, action: PayloadAction<selectedChefType|undefined>) => {
            state.checkoutCart.chef = action.payload;
        },
        addToCart : (state, action: PayloadAction<CartItemType>) => {

            const itemIndex = state.checkoutCart.cartItems?.findIndex(item => item.menuItem.id === action.payload.menuItem.id);

            if (itemIndex > -1) {
                state.checkoutCart.cartItems[itemIndex].count += 1;
            } else {
                state.checkoutCart.cartItems.push({ menuItem: action.payload.menuItem, count: 1, specialInstructions: action.payload.specialInstructions });
            }
        },
        addNewItemToCart : (state, action: PayloadAction<CartItemType>) => {
            state.checkoutCart.cartItems = [...state.checkoutCart.cartItems, action.payload];
        },
        decreaseItemCountFromCart : (state, action: PayloadAction<string>) => {

            const existingIndex = state.checkoutCart.cartItems.findIndex(item => item.menuItem.id === action.payload);
            if (existingIndex !== -1 && state.checkoutCart.cartItems[existingIndex].count > 1) {
                state.checkoutCart.cartItems[existingIndex].count -= 1;
            } else {
                state.checkoutCart.cartItems.splice(existingIndex, 1);
            }
        },
        removeItemFromCart : (state, action: PayloadAction<string>) => {
            state.checkoutCart.cartItems = state.checkoutCart.cartItems.filter(item => item.menuItem.id !== action.payload);
        },
        updateItemToCart : (state, action: PayloadAction<CartItemType>) => {
            state.checkoutCart.cartItems = state.checkoutCart.cartItems.map(item => {
                if (item.menuItem.id === action.payload.menuItem.id) {
                    return {...item, count: action.payload.count, specialInstructions: action.payload.specialInstructions};
                }
                return item;
            });
        },
        changeOrderType : (state, action: PayloadAction<string>) => {
            state.checkoutCart.orderType = action.payload;
        },
        setPaymentMethod: (state, action: PayloadAction<PaymentMethod[]>) => {
            state.customer.paymentMethods = action.payload;
        }
    }
});

export const {setEmail, setName, setPhoneNumber
    ,addFavoriteChef,removeFavoriteChef,addCustomerAddress, setCustomerAddresses
    , setCustomerCredentials, setCustomer, addToCart, decreaseItemCountFromCart, removeItemFromCart, addNewItemToCart, setSelectedChef ,emptyCart, changeOrderType, updateItemToCart, setPaymentMethod} = customerSlice.actions;

export const selectCustomer = (state: RootState) => state.customerReducer.customer;
export const selectCustomerAddress = (state: RootState) => state.customerReducer.customer.addresses[0];
export const selectCredentials = (state: RootState) => state.customerReducer.customer.credentials;

export const selectFavoriteChefs = (state:RootState) => state.customerReducer.customer.favoriteChefs;

export const selectOrderType = (state: RootState) => state.customerReducer.checkoutCart.orderType;

export const selectItemCount = (state: RootState, menuItemId: string) =>
    state.customerReducer.checkoutCart.cartItems.find(item => item.menuItem.id === menuItemId)?.count || 0;

export const selectCheckoutItem = (state: RootState, menuItemId: string)  => {
    return state.customerReducer.checkoutCart.cartItems.find(item =>  item.menuItem.id === menuItemId);
}
export const selectCartItemsCount = (state: RootState) => state.customerReducer?.checkoutCart?.cartItems.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.count;
}, 0);

export const selectSubTotal = (state: RootState) : number => {
    return state.customerReducer.checkoutCart.cartItems.reduce((total, item) => total + (item.count * +item.menuItem.price), 0);
}

export const selectPaymentMethods = (state: RootState) => {
    return state.customerReducer.customer.paymentMethods;
}

export const selectCheckoutCart = (state: RootState) => state.customerReducer.checkoutCart;

export default customerSlice.reducer;
