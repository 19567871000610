// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.available-chef-list {
    display: flex;
    gap: 30px;
    flex-direction: column;
    margin: 50px 100px;
}

@media screen and (max-width: 600px) {
    .available-chef-list {
        margin: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/available-chefs/AvailabeChefsList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".available-chef-list {\n    display: flex;\n    gap: 30px;\n    flex-direction: column;\n    margin: 50px 100px;\n}\n\n@media screen and (max-width: 600px) {\n    .available-chef-list {\n        margin: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
