// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frequently-asked-questions-card {
    padding: 20px;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
}

.frequently-asked-questions-card:hover {
    cursor: pointer;
}

.question {
    font-size: 20px;
    font-weight: 500;
}

.questions-row {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.frequently-asked-questions-card-icon {
    min-height: 24px;
    min-width: 24px;
}

.answer-row {
    color: #858585;
    will-change: height, opacity;
    overflow: hidden;
    padding-top: 20px;
}


@media screen and (max-width: 600px) {
    .question {
        font-size: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/frequently-asked-questions-list/frequently-asked-questions-card/FrequentlyAskedQuestionsCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,4BAA4B;IAC5B,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".frequently-asked-questions-card {\n    padding: 20px;\n    border: 1px solid #E4E4E4;\n    border-radius: 10px;\n}\n\n.frequently-asked-questions-card:hover {\n    cursor: pointer;\n}\n\n.question {\n    font-size: 20px;\n    font-weight: 500;\n}\n\n.questions-row {\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.frequently-asked-questions-card-icon {\n    min-height: 24px;\n    min-width: 24px;\n}\n\n.answer-row {\n    color: #858585;\n    will-change: height, opacity;\n    overflow: hidden;\n    padding-top: 20px;\n}\n\n\n@media screen and (max-width: 600px) {\n    .question {\n        font-size: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
