import './MenuItemQuickViewModal.css';
import Modal from "react-modal";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {customStyles} from "../../../pages/chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {Icon} from "@iconify/react";
import MenuItem from "../../../model/MenuItem";
import useImageLoader from "../../../custom-hooks/useImageLoader";
import {imageUrlConverter} from "../../../core/utils/ImageUrlConverter";
import {Skeleton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewItemToCart,
    CartItemType,
    removeItemFromCart,
    selectCheckoutCart,
    selectCheckoutItem,
    selectItemCount, setSelectedChef,
    updateItemToCart
} from "../../../core/redux/slices/customerSlice";
import {RootState} from "../../../core/redux/store";
import useModal from "../../../custom-hooks/useModal";
import Chef from "../../../model/Chef";
import CreateNewOrderModal from "../../../pages/chef-business-page/create-new-order-modal/CreateNewOrderModal";


type MenuItemQuickViewModalProps = {
    isMenuItemQuickViewModalOpen: boolean;
    closeMenuItemQuickViewModal: () => void;
    menuItem: MenuItem,
    chef?: Chef,
}

const MenuItemQuickViewModal: React.FC<MenuItemQuickViewModalProps> = ({isMenuItemQuickViewModalOpen, closeMenuItemQuickViewModal, menuItem, chef}) => {

    const closeModal = (event :  React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        setItemCountStage(1);
        event.stopPropagation();
        closeMenuItemQuickViewModal();
    }

    const imageUrl = imageUrlConverter(menuItem.imageUrl as string);
    const isImageLoaded = useImageLoader(imageUrl);
    const dispatch = useDispatch();
    const checkoutCart = useSelector(selectCheckoutCart);
    const createNewOrderModal = useModal();
    const itemInCart = useSelector((state: RootState) => selectCheckoutItem(state, menuItem.id as string));
    const [specialInstructions, setSpecialInstructions] = useState('');
    const itemCountFromStore = useSelector((state: RootState) => selectItemCount(state, menuItem.id as string));
    const [itemCountStage, setItemCountStage] = useState(1);

    useEffect(() => {
        setSpecialInstructions(itemInCart?.specialInstructions as string);
    }, [itemInCart])

    const decrementItemCountStaged = () => {
        if (itemCountStage !== 1){
            setItemCountStage(prevState => prevState - 1);
        }
    }

    const addItemCountStaged = () => {
        setItemCountStage(prevState => prevState + 1);
    }

    const removeItem: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        dispatch(removeItemFromCart(menuItem.id as string));
        closeMenuItemQuickViewModal();
    }

    const updateCart: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        const cartItem: CartItemType = {
            count: itemCountStage + itemCountFromStore,
            menuItem: menuItem,
            specialInstructions: specialInstructions,
        }
        dispatch(updateItemToCart(cartItem));
        setItemCountStage(1);
        closeMenuItemQuickViewModal();
    }

    const addItem: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();

        if(!!checkoutCart.chef && chef?.id !== checkoutCart.chef?.id){
            createNewOrderModal.openModal();
            return;
        }
        setChef();
        const cartItem: CartItemType = {
            count: itemCountStage,
            menuItem: menuItem,
            specialInstructions: specialInstructions,
        }
        dispatch(addNewItemToCart(cartItem));
        closeMenuItemQuickViewModal();
    }

    const handleSpecialInstructions = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSpecialInstructions(event.target.value);
    }

    const setChef = () => {
        dispatch(setSelectedChef({name: chef?.name as string, id: chef?.id as string, profileImageUrl: chef?.profileImageUrl as string}))
    }

    return (
        <>
            <Modal isOpen={isMenuItemQuickViewModalOpen} className='basic-modal modal-2 menu-quick-view-modal' style={customStyles} onRequestClose={closeModal}>
                <div className='modal-heading element-center'>
                    <Icon icon='material-symbols:close' fontSize={30} onClick={closeModal}/>
                </div>
                <div className='modal-content menu-item-quick-view-content'>
                    <div className='modal-quick-view-image-wrapper'>
                        {isImageLoaded ? <img src={imageUrl} className='menu-item-quick-view-image' alt='item'/> : <Skeleton variant="rectangular" width="100%" height='100%' style={{borderRadius: '10px'}}/>}
                    </div>
                    <div className='quick-view-detail-column'>
                        <div>
                            <p className='heading-font-medium'>{menuItem.name}</p>
                            <p className='light-grey-text font-weight-medium-bold'>${menuItem.price}</p>
                            <sub className='light-grey-text item-description'>{menuItem.description}</sub>
                        </div>
                        <div className='column-row-1'>
                            <h3>Special instructions</h3>
                            <div className='basic-input-container'>
                                <textarea className='basic-input special-instructions' rows={3} style={{resize: 'none'}} onChange={handleSpecialInstructions} value={specialInstructions}/>
                            </div>
                            <div className='add-to-cart'>
                                <p className={itemCountStage > 1 ? 'menu-item-card-remove-icon' : 'empty-padding'}>
                                    {itemCountStage !== 1 && <Icon icon='ic:baseline-minus'  fontSize={24} onClick={decrementItemCountStaged}/>}
                                </p>
                                <p>{itemCountStage}</p>
                                <p className='menu-item-card-add-icon' onClick={addItemCountStaged}>
                                    <Icon icon='ic:outline-add' fontSize={24}/>
                                </p>
                            </div>
                        </div>
                        <div className='menu-items-quick-view-modal-actions'>
                            {
                                itemCountFromStore !== 0 ?
                                <div>
                                    <div className='primary-button' onClick={updateCart}>Update {itemCountStage} items to cart</div>
                                    <div className='text-center remove-from-cart-button cursor' onClick={removeItem}>Remove from cart</div>
                                </div> :
                                <div className='primary-button' onClick={addItem}>Add to cart</div>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            {createNewOrderModal.isOpen && <CreateNewOrderModal isCreateNewOrderModalOpen={createNewOrderModal.isOpen} closeModal={createNewOrderModal.closeModal}
                                                                  setChef={setChef} cartItem={{count: itemCountStage, specialInstructions: specialInstructions, menuItem: menuItem}} chef={chef as Chef} />}
        </>
    )
}

export default MenuItemQuickViewModal;
