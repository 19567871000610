import React from 'react';
import './LanguageSwitcherModal.css';
import { useTranslation } from 'react-i18next';
import {customStyles} from "../../pages/chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {Icon} from "@iconify/react";
import Modal from "react-modal";

type LanguageSwitcherModalType = {
    isModalOpen: boolean,
    closeModal: () => void,
}

type Language = {
    label: string,
    abbreviation: string,
}

const english: Language = {label: 'English', abbreviation: 'en'};
const french: Language = {label: 'Français', abbreviation: 'fr'};

export const languages: Language[] = [english, french];

const LanguageSwitcherModal: React.FC<LanguageSwitcherModalType> = ({isModalOpen, closeModal}) => {

    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        closeModal();
    };

    const english: Language = {label: 'English', abbreviation: 'en'};
    const french: Language = {label: 'Français', abbreviation: 'fr'};

    const languages: Language[] = [english, french];

    return (
        <Modal isOpen={isModalOpen} className='basic-modal modal-2' style={customStyles} onRequestClose={closeModal}>
            <div className='modal-heading element-center'>
                <Icon icon='material-symbols:close' fontSize={30} onClick={closeModal}/>
            </div>
            <div className='modal-content column gap-20'>
                <p className='heading-font-medium'>Select Language</p>
                <div className='language-list'>
                    {languages.map((language, index) => (
                        <div className='flex-space-between cursor' key={index} onClick={() => changeLanguage(language.abbreviation)}>
                            <p>{language.label}</p>
                            {language.abbreviation === i18n.language && <Icon icon='mingcute:check-fill' fontSize={24}/>}
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default LanguageSwitcherModal;
