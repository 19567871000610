import './AddedItems.css';
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setMenu} from "../../../../core/redux/slices/chefSlice";
import React, {useCallback, useState} from "react";
import MenuItem from "../../../../model/MenuItem";
import ChefService from "../../../../core/services/ChefService";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import DeleteMenuItemModal from "../delete-menu-item-modal/DeleteMenuItemModal";
import {useTranslation} from "react-i18next";
import {unwrapResult} from "@reduxjs/toolkit";
import MenuItemCard from "./menu-item-card/MenuItemCard";
import ErrorMessage from "../../profile/save-error-message/ErrorMessage";

type AddedItemsProps = {
    handleEditItem : (menu: MenuItem)=> void;
    menu: MenuItem[],
}

const AddedItems: React.FC<AddedItemsProps> = ({handleEditItem, menu}) => {

    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const dispatch = useDispatch();
    const id = useSelector(selectChef).id;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<MenuItem>();
    const [itemDeleted, setItemDeleted] = useState(false);
    const {t} = useTranslation('common');
    const [apiError, setApiError] = useState('');

    const handleOpenDeleteModal = (menuItem: MenuItem, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setIsDeleteModalOpen(true);
        setItemToDelete(menuItem);
    }

    const handleCloseDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);

    const handleDeleteMenuItem = (async (menuItem?: MenuItem) => {
        try {
            const response = await ChefService.deleteMenuItem({id, menuItem});

            if (response) {
                setItemDeleted(true);
                setTimeout(() => {
                    setItemDeleted(false);
                    dispatch(setMenu(response));
                }, 2000);
            }
        } catch (error){
            setApiError(t('api-error-message'));
            setTimeout(() => setApiError(''), 4000);
        }
    });


    const handleEditMenuItem = (menuItem: MenuItem, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        handleEditItem(menuItem);
    }

    return (
        <div className='added-items-container'>
            <div className='added-item-list'>
                {menu?.map((menuItem, index) => (
                    <MenuItemCard
                        key={index}
                        menuItem={menuItem}
                        handleEditMenuItem={handleEditMenuItem}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        itemDeleted={itemDeleted}
                        itemToDelete={itemToDelete}
                        daysOfWeek={daysOfWeek}
                    />
                ))}
            </div>
            <DeleteMenuItemModal closeDeleteModal={handleCloseDeleteModal} itemToDelete={itemToDelete} isDeleteModalOpen={isDeleteModalOpen} deleteMenuItem={handleDeleteMenuItem}/>
            {apiError && <div className='api-response-error'><ErrorMessage message={apiError}/></div>}
        </div>
    )
}

export default AddedItems;
