// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-size {
    width: 150px;
    height: 150px;
}

.small-size {
    width: 60px;
    height: 60px;
    border: 1px solid var(--primary-color);
}

.extra-small-size {
    width: 40px;
    height: 40px;
}

.image-container {
    display: flex;
    border-radius: 50%;
    padding: 6px;
    justify-content: center;
    align-items: center;
    background: var(--primary-light-color);
}

.image-container p {
    color: var(--primary-color);
}

.large-size p {
    font-size: 80px
}

.small-size p {
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/edit-profile-picture-modal/profile-photo-placeholder/ProfilePhotoPlaceholder.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,sCAAsC;AAC1C;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".large-size {\n    width: 150px;\n    height: 150px;\n}\n\n.small-size {\n    width: 60px;\n    height: 60px;\n    border: 1px solid var(--primary-color);\n}\n\n.extra-small-size {\n    width: 40px;\n    height: 40px;\n}\n\n.image-container {\n    display: flex;\n    border-radius: 50%;\n    padding: 6px;\n    justify-content: center;\n    align-items: center;\n    background: var(--primary-light-color);\n}\n\n.image-container p {\n    color: var(--primary-color);\n}\n\n.large-size p {\n    font-size: 80px\n}\n\n.small-size p {\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
