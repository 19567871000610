// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/become-chef/become-chef-main.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.become-chef-welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    padding-left: 125px;
    overflow: hidden;
}

.become-chef-welcome-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 0;
}

.summary {
    max-width: 700px;
    margin: 20px 0 20px 0;
}

.become-chef-welcome-container > * {
    position: relative;
}

.become-chef-content {
    color: white;
}

.getting-started-button {
    width: 200px;
    padding: 12px;
    border: none;
    color: black;
    border-radius: 8px;
    background: var(--primary-color);
}

.getting-started-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .become-chef-welcome-container {
        padding-left: 0;
        background-position: center center;
    }
}

@media screen and (max-width: 600px){
    .become-chef-welcome-container {
        padding: 20px;
        background-size: cover;
    }

}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/become-chef-welcome-container/BecomeChefWelcomeContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,6DAAoF;IACpF,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,8BAA8B;IAC9B,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;QACf,kCAAkC;IACtC;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;IAC1B;;AAEJ","sourcesContent":[".become-chef-welcome-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    background: url('../../../assets/images/become-chef/become-chef-main.png') no-repeat;\n    background-size: cover;\n    height: 100vh;\n    width: 100%;\n    position: relative;\n    padding-left: 125px;\n    overflow: hidden;\n}\n\n.become-chef-welcome-container::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background: rgba(0, 0, 0, 0.4);\n    z-index: 0;\n}\n\n.summary {\n    max-width: 700px;\n    margin: 20px 0 20px 0;\n}\n\n.become-chef-welcome-container > * {\n    position: relative;\n}\n\n.become-chef-content {\n    color: white;\n}\n\n.getting-started-button {\n    width: 200px;\n    padding: 12px;\n    border: none;\n    color: black;\n    border-radius: 8px;\n    background: var(--primary-color);\n}\n\n.getting-started-button:hover {\n    cursor: pointer;\n}\n\n@media screen and (max-width: 768px) {\n    .become-chef-welcome-container {\n        padding-left: 0;\n        background-position: center center;\n    }\n}\n\n@media screen and (max-width: 600px){\n    .become-chef-welcome-container {\n        padding: 20px;\n        background-size: cover;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
