// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-delivery-type-container {
    display: flex;
    background: #EEEEEE;
    border-radius: 16px;
    padding: 6px;
    font-size: 14px;
    align-self: flex-end;
    /*max-height: 56px;*/
    justify-content: flex-end;
}

.checkout-delivery-buttons-wrapper {
    display: flex;
}

.buttons-wrapper > div:hover {
    cursor: pointer;
}

.delivery-type-container {
    display: flex;
}

.checkout-delivery-type-button {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.checkout-delivery-type-button.selected {
    background: white;
}

@media screen and (max-width: 600px) {
    .delivery-type-container {
        flex: 1 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/delivery-details-step/order-delivery-type/OrderDeliveryType.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,SAAO;IACX;AACJ","sourcesContent":[".checkout-delivery-type-container {\n    display: flex;\n    background: #EEEEEE;\n    border-radius: 16px;\n    padding: 6px;\n    font-size: 14px;\n    align-self: flex-end;\n    /*max-height: 56px;*/\n    justify-content: flex-end;\n}\n\n.checkout-delivery-buttons-wrapper {\n    display: flex;\n}\n\n.buttons-wrapper > div:hover {\n    cursor: pointer;\n}\n\n.delivery-type-container {\n    display: flex;\n}\n\n.checkout-delivery-type-button {\n    padding: 6px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 12px;\n}\n\n.checkout-delivery-type-button.selected {\n    background: white;\n}\n\n@media screen and (max-width: 600px) {\n    .delivery-type-container {\n        flex: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
