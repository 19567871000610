// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal-plans {
    flex: 1 1;
}

.meal-plan-heading {
    border-bottom: 8px solid var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/MealPlans.css"],"names":[],"mappings":"AAAA;IACI,SAAO;AACX;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".meal-plans {\n    flex: 1;\n}\n\n.meal-plan-heading {\n    border-bottom: 8px solid var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
