
export type GeneralInquiryEmailType = {
    firstName: string,
    lastName: string,
    email: string,
    subject: string,
    body: string,
}

class EmailService {

    static sendValidationCodeEmail = async (email: string) => {

        const sendValidationEmailResponse = await fetch(
            `${process.env.REACT_APP_HOME_EATZ_API_URL}/email/send?email=${email}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        if(sendValidationEmailResponse.ok){
            return await sendValidationEmailResponse.json();
        }else {
            throw new Error('Unable to send email');
        }
    }

    static verifyValidationCode = async (email: string, code: string) => {
        try {
            const validateCodeResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/email/validate_code?code=${code}&email=${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (validateCodeResponse.ok) {
                return { success: true, data: await validateCodeResponse.json() };
            } else if (validateCodeResponse.status === 404) {
                return { success: false, error: 'Validation code not found.' };
            } else {
                return { success: false, error: 'Unable to verify code' };
            }
        } catch (error) {
            console.error('Error in verifyValidationCode:', error);
            return { success: false, error: (error as Error).message || 'An unexpected error occurred' };
        }
    }

    static sendGeneralInquiryEmail = async (email: GeneralInquiryEmailType) => {
        try {
            const sendGeneralInquiryEmailResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/email/general-inquiry`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(email)
                }
            );

            if (sendGeneralInquiryEmailResponse.ok) {
                return { success: true, data: await sendGeneralInquiryEmailResponse.json() };
            } else {
                return { success: false, error: 'Unable to send email' };
            }
        } catch (error) {
            return { success: false, error: (error as Error).message || 'An unexpected error occurred' };
        }
    }

}

export default EmailService;
