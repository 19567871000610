import React, {useState} from "react";
import './SortMenu.css';
import {Icon} from "@iconify/react";
import CustomMUITooltip, {
    customCheckboxProps,
    customLabelProps
} from "../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import {Checkbox, ClickAwayListener, FormControlLabel, IconButton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setMenu} from "../../../../core/redux/slices/chefSlice";
import MenuItem from "../../../../model/MenuItem";

const SortMenu = () =>  {

    const menu= useSelector(selectChef).menu;
    const [filter, setFilter] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [stagedFilter, setStagedFilter] = useState(filter);
    const dispatch = useDispatch();

    const handleClickAway = () => {
        setTooltipOpen(false);
        if(filter !== stagedFilter){
            setStagedFilter(filter);
        }
    }

    const handleTooltipToggle = () => {
        setTooltipOpen(!tooltipOpen);
        if (tooltipOpen) {
            setStagedFilter(filter);
        }
    };

    const handleCheckboxClick = (value: string, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if(stagedFilter === value){
            setStagedFilter('');
            return;
        }
        setStagedFilter(value);
    };

    const handleResetFilter = () => {
        setFilter('');
        setStagedFilter('');
        dispatch(setMenu(getSortedMenu(menu as MenuItem[], 'Earliest')));
        setTooltipOpen(false);
    }

    const handleApplyFilter = () => {
        setTooltipOpen(false);
        setFilter(stagedFilter);
        dispatch(setMenu(getSortedMenu(menu as MenuItem[], stagedFilter)));

    }

    const getSortedMenu = (menu: MenuItem[], filter: string) =>{
        if (!menu) return [];

        return [...menu].sort((a, b) => {
            if (!a.dateOfCreation || !b.dateOfCreation) return 0;

            return filter === 'Latest'
                ? +b.dateOfCreation - +a.dateOfCreation
                : +a.dateOfCreation - +b.dateOfCreation;
        });
    }


    return (
        <div className='sorting-menu-wrapper'>
            <CustomMUITooltip
                title={
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div className='filter-orders'>
                            <h3>Filter By</h3>
                            <div className='filter-checkbox-container'>
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'Latest'} onClick={(e) => handleCheckboxClick('Latest',e)}/>}
                                                  label={'Latest'}  sx={customLabelProps} />
                                <FormControlLabel control={<Checkbox sx={customCheckboxProps} checked={stagedFilter === 'Earliest'} onClick={(e) => handleCheckboxClick('Earliest', e)}/>}
                                                  label={'Earliest'}  sx={customLabelProps} />
                                <div className='filter-action-buttons'>
                                    <div className='reset-button grey-light-button' onClick={handleResetFilter}>Reset</div>
                                    <div className='primary-button' onClick={handleApplyFilter}>Apply Filter</div>
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
                open={tooltipOpen}
                disableHoverListener
                onOpen={() => setTooltipOpen(true)}
                enterTouchDelay={0}
                placement="bottom-end"
                arrow>
                <IconButton onClick={handleTooltipToggle}>
                    <Icon icon='mage:filter' fontSize={20} />
                </IconButton>
            </CustomMUITooltip>
        </div>
    );
}

export default SortMenu;
