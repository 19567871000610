import React, {useEffect, useState} from 'react';
import './DishAvailability.css';
import MenuItem from "../../../../../model/MenuItem";


export const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

type DishAvailabilityProps = {
    onSelectedDaysChange: (selectedDays: string[]) => void;
    menuItem?: MenuItem,
};

const DishAvailability: React.FC<DishAvailabilityProps> = ({onSelectedDaysChange, menuItem}) => {

    const [selectedDays, setSelectedDays] = useState<string[]>((menuItem ? menuItem.availability : daysOfWeek));

    useEffect(() => {
        onSelectedDaysChange(selectedDays);
    }, [selectedDays, onSelectedDaysChange]);

    const toggleDay = (day: any) => {
        setSelectedDays((prevSelectedDays) => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(d => d !== day);
            } else {
                return [...prevSelectedDays, day];
            }
        });
    };

    return (
        <div className="availability-container">
            <div className='add-item-heading-container'>
                <div className='add-item-label dish-availability-heading'>Dish availability</div>
                <sub>This dish will be available all days. Tap to change</sub>
            </div>
            <div className="days-container flex-wrap">
                {daysOfWeek.map((day) => (
                    <span
                        key={day}
                        className={`day ${selectedDays.includes(day) ? 'selected' : ''}`}
                        onClick={() => toggleDay(day)}>
                        {day}
                    </span>
                ))}
            </div>
        </div>
    );
}

export default DishAvailability;
