import './RoadmapCard.css';
import React from "react";
import {Icon} from "@iconify/react";

interface roadmapCardProps {
    image: string,
    heading: string,
    icon: string,
    details: string
}

const RoadmapCard: React.FC<roadmapCardProps>= (props) => {

    return (
        <div className='roadmap-card'>
            <img src={props.image} alt='roadmap'/>
            <div className='roadmap-row'>
                <h2>{props.heading}</h2>
                <div className='roadmap-icon-number element-center'>
                    <Icon icon={props.icon} fontSize={34} />
                </div>
            </div>
            <div className='grey-light-text'>{props.details}</div>
        </div>
    )
}

export default RoadmapCard;
