// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-navbar {
    padding: 10px 10px;
    display: none;
    align-items: center;
    width: 100%;
    background: white;
    z-index: 800;
    -webkit-overflow-scrolling: touch;
}

.chef-navbar-logo {
    height: 34px;
    width: 150px;
}

.chef-navbar-heading {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 24px;
}

.chef-profile-image {
    height: 40px;
    width: 40px;
    border: 1px solid white;
    border-radius: 50%;
}

.navbar-profile-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

@media screen and (max-width: 600px) {
    .chef-navbar {
        display: flex;
        gap: 20px;
        /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.5);*/
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-navbar/ChefNavbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;QACb,SAAS;QACT,iDAAiD;IACrD;AACJ","sourcesContent":[".chef-navbar {\n    padding: 10px 10px;\n    display: none;\n    align-items: center;\n    width: 100%;\n    background: white;\n    z-index: 800;\n    -webkit-overflow-scrolling: touch;\n}\n\n.chef-navbar-logo {\n    height: 34px;\n    width: 150px;\n}\n\n.chef-navbar-heading {\n    color: var(--primary-color);\n    font-weight: 500;\n    font-size: 24px;\n}\n\n.chef-profile-image {\n    height: 40px;\n    width: 40px;\n    border: 1px solid white;\n    border-radius: 50%;\n}\n\n.navbar-profile-image {\n    height: 40px;\n    width: 40px;\n    border-radius: 50%;\n}\n\n@media screen and (max-width: 600px) {\n    .chef-navbar {\n        display: flex;\n        gap: 20px;\n        /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.5);*/\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
