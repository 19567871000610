// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
    color: red;
    display: flex;
    gap: 20px;
    border: 1px solid red;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    align-items: center;
    background: #f5e5e5;
}

.error-message-icon {
    min-height: 24px;
    min-width: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/error-handling/input-error/InputError.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".error-container {\n    color: red;\n    display: flex;\n    gap: 20px;\n    border: 1px solid red;\n    border-radius: 4px;\n    padding: 10px;\n    margin-top: 20px;\n    align-items: center;\n    background: #f5e5e5;\n}\n\n.error-message-icon {\n    min-height: 24px;\n    min-width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
