// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frequently-asked-questions-list {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin: 50px 120px 80px 120px;
}


@media screen and (max-width: 600px) {
    .frequently-asked-questions-list {
        margin: 20px 20px 100px 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/frequently-asked-questions-list/FrequentlyAskedQuestionsList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,6BAA6B;AACjC;;;AAGA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".frequently-asked-questions-list {\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n    margin: 50px 120px 80px 120px;\n}\n\n\n@media screen and (max-width: 600px) {\n    .frequently-asked-questions-list {\n        margin: 20px 20px 100px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
