export const paperProps = {
    sx: {
        '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'var(--primary-light-color)'
        },
        '& .MuiMenu-list': {
            '&& .MuiMenuItem-root:last-child': {
                backgroundColor: 'red',
            },
        },
    }
}

export const customInputField = {
    '&.MuiOutlinedInput-root': {
        padding: '12px',
        borderRadius: '8px',
        '& fieldset': {
            border: '1px solid #E4E4E4'
        },
        '&:hover fieldset': {
            border: '1px solid var(--primary-color)',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid var(--primary-color)',
        },
    },
}
