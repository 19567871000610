// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-manager-container {
    position: relative;
}

.photo-placeholder-wrapper {
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: 2px solid var(--primary-color);
}

.account-info-tootlip {
    position: absolute;
    background: white ;
    top: calc(100% + 10px);
    right: 0;
    width: 300px;
    border-radius: 8px;
    animation: fade-in 0.5s ease-in forwards;
    box-shadow: 2px 4px 8px rgb(7, 7, 7);
}

.signout-button {
    display: flex;
    justify-content: flex-end;
}

.signout-button p {
    padding: 10px;
    width: fit-content;
}

.signout-button p:hover {
    background: var(--grey2);
    border-radius: 8px;
}

.profile-options-modal:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/nav-bar/account-manager/AccountManager.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,QAAQ;IACR,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".account-manager-container {\n    position: relative;\n}\n\n.photo-placeholder-wrapper {\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    border: 2px solid var(--primary-color);\n}\n\n.account-info-tootlip {\n    position: absolute;\n    background: white ;\n    top: calc(100% + 10px);\n    right: 0;\n    width: 300px;\n    border-radius: 8px;\n    animation: fade-in 0.5s ease-in forwards;\n    box-shadow: 2px 4px 8px rgb(7, 7, 7);\n}\n\n.signout-button {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.signout-button p {\n    padding: 10px;\n    width: fit-content;\n}\n\n.signout-button p:hover {\n    background: var(--grey2);\n    border-radius: 8px;\n}\n\n.profile-options-modal:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
