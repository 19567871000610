// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-menu-items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.menu-items {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.chef-menu-items-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .chef-menu-items-container {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-business-page/chef-menu-items/ChefMenuItems.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".chef-menu-items-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 30px;\n}\n\n.menu-items {\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.chef-menu-items-section {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .chef-menu-items-container {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
