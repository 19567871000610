import {useTranslation} from "react-i18next";
import {QuestionsAndAnswers} from "../../../model/QuestionAndAnswers";
import FrequentlyAskedQuestionsCard
    from "../../../components/shared/frequently-asked-questions-list/frequently-asked-questions-card/FrequentlyAskedQuestionsCard";

const BecomeChefFrequentlyAskedQuestions = () => {

    const { t } = useTranslation('FrequentlyAskedQuestionsList');

    const faqs: QuestionsAndAnswers[] = t('become-chef-faqs', { returnObjects: true });

    const style = {marginTop: '30px'}

    return (
        <div className='become-chef-faq' style={style}>
            <div className='heading-font-large text-center questions-heading'>{t('heading')}</div>
            <div className='frequently-asked-questions-list'>
                {faqs.map((faq, index) => (
                    <FrequentlyAskedQuestionsCard key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    )
}

export default BecomeChefFrequentlyAskedQuestions;
