// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-container {
    flex: 1 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 50px 20px;
}

.onboarding-details {
    display: flex;
    gap: 30px;
}

.onboarding-image {
    width: 200px;
    height: 200px;
}

.completion-heading {
    color: var(--sub-black);
    text-align: center;
}

.checklist-description {
    width: 300px;
    height: 400px;
}
@media screen and (max-width: 600px) {
    .onboarding-details {
        flex-direction: column;
    }
    .checklist-description {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/onboarding/Onboarding.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".onboarding-container {\n    flex: 1;\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 40px 20px 50px 20px;\n}\n\n.onboarding-details {\n    display: flex;\n    gap: 30px;\n}\n\n.onboarding-image {\n    width: 200px;\n    height: 200px;\n}\n\n.completion-heading {\n    color: var(--sub-black);\n    text-align: center;\n}\n\n.checklist-description {\n    width: 300px;\n    height: 400px;\n}\n@media screen and (max-width: 600px) {\n    .onboarding-details {\n        flex-direction: column;\n    }\n    .checklist-description {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
