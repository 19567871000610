// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-notification {
    position: fixed;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    background-color: red;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease-in-out;
    z-index: 1000;
}

.error-notification.slide-in {
    bottom: 20px;
}

.error-notification.slide-out {
    bottom: -100px;
}

@media screen and (max-width: 600px) {
    .error-notification {
        width: 90%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/error-handling/common-error-notification/ErrorNotification.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,mCAAmC;IACnC,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".error-notification {\n    position: fixed;\n    bottom: -100px;\n    left: 50%;\n    transform: translateX(-50%);\n    padding: 16px;\n    background-color: red;\n    color: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    transition: bottom 0.3s ease-in-out;\n    z-index: 1000;\n}\n\n.error-notification.slide-in {\n    bottom: 20px;\n}\n\n.error-notification.slide-out {\n    bottom: -100px;\n}\n\n@media screen and (max-width: 600px) {\n    .error-notification {\n        width: 90%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
