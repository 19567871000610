// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-meal-card {
    display: flex;
    overflow: hidden;
}

.fixed-meal-card > div {
    flex: 1 1;
}

.fixed-meal-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 30px 20px 10px;
}

.fixed-meal-card-actions {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.fixed-meal-card-actions svg:hover {
    border-radius: 50%;
    background: rgb(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/manage-meals/meal-card/fixed-meal-card/FixedMealCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;AACjC","sourcesContent":[".fixed-meal-card {\n    display: flex;\n    overflow: hidden;\n}\n\n.fixed-meal-card > div {\n    flex: 1;\n}\n\n.fixed-meal-card-content {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 20px 30px 20px 10px;\n}\n\n.fixed-meal-card-actions {\n    position: absolute;\n    top: 50%;\n    right: 0;\n    transform: translateY(-50%);\n}\n\n.fixed-meal-card-actions svg:hover {\n    border-radius: 50%;\n    background: rgb(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
