// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discount-offers-heading {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.discount-offers-heading p {
    font-size: 22px;
    font-weight: 500;
}

.discount-offer-button {
    color: var(--sub-black);
    outline: 0;
    transition: all .5s;
    border-radius: 50%;
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
    background: white;
    opacity: 1;
    cursor: pointer;
}

.discount-offer-buttons {
    display: flex;
    gap: 10px;
}

.discount-offer-button:hover {
    background: var(--primary-light-color);
}

.discount-offer-carousel {
    display: flex;
    gap: 30px;
    padding: 10px 0;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .discount-offer-carousel {
        flex-wrap: wrap;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/available-chefs/discount-offers-carousel/DiscountOffersCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".discount-offers-heading {\n    display: flex;\n    padding: 10px;\n    justify-content: space-between;\n}\n\n.discount-offers-heading p {\n    font-size: 22px;\n    font-weight: 500;\n}\n\n.discount-offer-button {\n    color: var(--sub-black);\n    outline: 0;\n    transition: all .5s;\n    border-radius: 50%;\n    display: flex;\n    padding: 6px;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #E4E4E4;\n    background: white;\n    opacity: 1;\n    cursor: pointer;\n}\n\n.discount-offer-buttons {\n    display: flex;\n    gap: 10px;\n}\n\n.discount-offer-button:hover {\n    background: var(--primary-light-color);\n}\n\n.discount-offer-carousel {\n    display: flex;\n    gap: 30px;\n    padding: 10px 0;\n    justify-content: space-between;\n}\n\n@media screen and (max-width: 600px) {\n    .discount-offer-carousel {\n        flex-wrap: wrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
