import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAddressSuggestions, setSuggestions} from "../../../core/redux/slices/addressSuggestionSlice";
import UserAddress from "../../../model/UserAddress";
import {
    closeAddressChangeModal,
    closeAddressSelectModal, selectAddressChooseModalState,
    selectChangeAddressModalState
} from "../../../core/redux/slices/addressChangeModalSlice";
import {setAddress} from "../../../core/redux/slices/profileManagementSlice";
import {Address} from "../../../model/Chef";

type AddressSuggestionsProps = {
    isPositionRelative?: boolean,
    closeChangeAddressModal? : () => void,
    onSelectPlace?: (place: Address) => void
}

const AddressSuggestions: React.FC<AddressSuggestionsProps> = ({isPositionRelative, closeChangeAddressModal, onSelectPlace}) => {

    const suggestions = useSelector(selectAddressSuggestions);
    const isChangeAddressModalOpen = useSelector(selectChangeAddressModalState);
    const isAddressSelectModalOpen = useSelector(selectAddressChooseModalState);
    const [isVisible, setIsVisible] = useState(true);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const style: CSSProperties = isPositionRelative ? {
        position: 'relative',top: '15px',
        ...(suggestions && suggestions.length !== 0 ? {
            border: '1px solid #e4e4e4',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px'
        } : {})
    } : (
        suggestions && suggestions.length !== 0 ? {
            border: '1px solid #e4e4e4',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px'
        } : {}
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsVisible(true);
    },[suggestions])

    const handleSelectPlace =  (place: UserAddress) => {
        if (place.place_id) {
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails(
                {
                    placeId: place.place_id,
                    fields: ['address_components', 'geometry']
                },
                async (placeDetails: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && placeDetails) {
                        const postalCodeComponent = placeDetails.address_components?.find((component) =>
                            component.types.includes('postal_code')
                        );
                        const postalCode = postalCodeComponent ? postalCodeComponent.long_name : undefined;
                        const geocoder = new google.maps.Geocoder();
                        const geoCodePromise = new Promise<{ latitude: number; longitude: number }>((resolve, reject) => {
                            geocoder.geocode({ 'address': postalCode }, (results, status) => {
                                if (status === "OK" && results && results.length > 0) {
                                    const location = results[0].geometry.location;
                                    resolve({ latitude: location.lat(), longitude: location.lng() });
                                } else {
                                    console.error("Geocode was not successful for the following reason:", status);
                                    reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                                }
                            });
                        });

                        geoCodePromise
                            .then(({ latitude, longitude }) => {
                                const updatedPlace: Address = {
                                    postalCode: postalCode as string,
                                    streetAddress: place.structured_formatting.main_text,
                                    city: place.description.split(',')[1].trim(),
                                    province: place.description.split(',')[2].trim(),
                                    longitude: placeDetails.geometry?.location?.lng(),
                                    latitude: placeDetails.geometry?.location?.lat()
                                };

                                closeChangeAddressModal && closeChangeAddressModal();
                                if (onSelectPlace) {
                                    onSelectPlace(updatedPlace);
                                }else {
                                    dispatch(setAddress(updatedPlace));
                                }
                            })
                            .catch(error => {
                                console.error(error.message);
                            });

                        dispatch(setSuggestions([]));
                        if(isChangeAddressModalOpen){
                            dispatch(closeAddressChangeModal());
                        }
                        if(isAddressSelectModalOpen){
                            setTimeout(()=>{
                                dispatch(closeAddressSelectModal());
                            },1000);
                        }
                    }
                }
            );
        }
    };


    return (
        <>
            {isVisible && suggestions?.length >= 1 &&
                <div className='suggestion-address-wrapper' ref={elementRef} style={style}>
                    {suggestions?.map((suggestion: UserAddress, index: React.Key | null | undefined) => {
                        const {structured_formatting} = suggestion;
                        const {main_text, secondary_text} = structured_formatting;
                        const [city, province] = secondary_text ? secondary_text.split(',') : [];
                        const formattedCityProvince = [city, ',', province];

                        return (
                            <div className='suggestion-address' key={index} onClick={() => handleSelectPlace(suggestion)}>
                                <h3>{main_text}</h3>
                                <div className='secondary-text'>{formattedCityProvince}</div>
                            </div>
                        );
                    })}
                </div>
            }
        </>
    )
}

export default AddressSuggestions;
