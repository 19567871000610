import Carousel from "react-multi-carousel";
import ChefCard from "../../../../components/shared/chef-card/ChefCard";
import Chef from "../../../../model/Chef";
import 'react-multi-carousel/lib/styles.css';
import {useNavigate} from "react-router-dom";
import {CustomizeCarouselResponsiveness1} from "../../../../components/custom-components/CustomizeCarousel";
import '../../../../styles/common.css';

const BestChefList = () => {

    const navigateTo = useNavigate();

    const chefList: Chef[] = [
        {
            name: 'Ronan Kruithof',
            reviews: [{rating: 4.5, review: '1009'}, {rating: 90}],
            featuredDishUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/food/victoria-shes-UC0HZdUitWY-unsplash.jpg',
            profileImageUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/chefs/thomas-marban-EHK-EH1SRzQ-unsplash.jpg',
            featuredDishName: 'Grilled Chicken',
            location: {
                type: 'Point',
                coordinates: [-73.6275584, 45.4416856]
            },
            addresses: [],
            specialityCuisines: [],
            isOnboardingCompleted: true,
        },
        {
            name: 'Katie Rosario',
            reviews: [{rating: 4.9, review: '229'}, {rating: 98}],
            featuredDishUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/food/katie-rosario-QNyRp21hb5I-unsplash.jpg',
            profileImageUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/chefs/vitor-monthay-673jcnrm8bM-unsplash.jpg',
            featuredDishName: 'Cake',
            location: {
                type: 'Point',
                coordinates: [-73.6275584, 45.4416856]
            },
            addresses: [],
            specialityCuisines: [],
            isOnboardingCompleted: true,
        },
        {
            name: 'Drew Taylor',
            reviews: [{rating: 5.0, review: '150'}, {rating: 100}],
            featuredDishUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/food/orijit-chatterjee-wEBg_pYtynw-unsplash (1).jpg',
            profileImageUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/chefs/vince-fleming-7qileFwJEWo-unsplash.jpg',
            featuredDishName: 'Noodles',
            location: {
                type: 'Point',
                coordinates: [-73.6275584, 45.4416856]
            },
            addresses: [],
            specialityCuisines: [],
            isOnboardingCompleted: true,
        },
        {
            name: 'Rohan',
            reviews: [{rating: 4.8, review: '773'}, {rating: 95}],
            featuredDishUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/food/raman-sqcH2q7lkvo-unsplash (1).jpg',
            profileImageUrl: 'https://homeeatzimages.blob.core.windows.net/websiteimages/test/chefs/aaron-thomas-KrpRWdDYQck-unsplash (1).jpg',
            featuredDishName: 'Butter Chicken',
            location: {
                type: 'Point',
                coordinates: [-73.6275584, 45.4416856]
            },
            addresses: [],
            specialityCuisines: [],
            isOnboardingCompleted: true,
        },
    ]

    const redirectToBusinessPage = (name: string) => {
        navigateTo(name);
    }

    return (
        <div className="carousel">
            <Carousel
                responsive={CustomizeCarouselResponsiveness1}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={false}
                infinite={true}
                partialVisible={true}
                dotListClass="custom-dot-list-style">
                {chefList.map((item, index) => {
                    return (
                        <ChefCard chef={item} key={index}/>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default BestChefList;
