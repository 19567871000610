import './NotificationsButton.css';
import {Icon} from "@iconify/react";
import {ClickAwayListener, IconButton} from "@mui/material";
import CustomMUITooltip from "../../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import React, {useState} from "react";
import notificationImage from "../../../../../assets/images/no-notifications.png";
import {useLocation} from "react-router-dom";

const NotificationsButton = () => {

    const messages = 0;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const dashboardPath = useLocation().pathname.startsWith('/chef/dashboard');
    const customizeTooltipProps = {
        modifiers: [{
                name: 'offset',
                options: {
                    offset: [0, -16],
                },
            },],}

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    }

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (

        <CustomMUITooltip
            title={
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div className='notification-list-container'>
                        <div className='notification-list-heading'>
                            <h3>Notifications</h3>
                        </div>
                        <div className='no-notification-container'>
                            <img src={notificationImage} className='no-notification-image' alt='no-notification'/>
                            <p>Currently you don't have any messages!</p>
                        </div>
                    </div>
                </ClickAwayListener>
            }
            PopperProps={customizeTooltipProps}
            open={tooltipOpen}
            disableHoverListener={false}
            enterTouchDelay={0}
            placement="bottom-end"
            arrow>
            <IconButton onClick={toggleTooltip}>
                <div className='notification-button-container cursor' >
                    <Icon icon='jam:messages-f' fontSize={28} className='messages-icon'/>
                    <p className={`element-center ${dashboardPath ? 'chef-dashboard-messages':'messages-wrapper'}`}>{messages}</p>
                </div>
            </IconButton>
        </CustomMUITooltip>

    )
}

export default NotificationsButton;
