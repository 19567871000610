import './Availability.css';
import {daysOfWeek} from "../../chef-menu/add-new-item/availability/DishAvailability";
import React, {useEffect, useState} from "react";
import {MealPlanDuration} from "../../../../model/meal-plans/MealPlanDuration";

type AvailabilityProps = {
    onSelectedDaysChange: (selectedDays: string[]) => void;
    duration: MealPlanDuration;
}

const Availability: React.FC<AvailabilityProps> = ({ onSelectedDaysChange, duration }) => {
    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    useEffect(() => {
        setSelectedDays([]);
    }, [duration])

    useEffect(() => {
        onSelectedDaysChange(selectedDays);
    }, [selectedDays, onSelectedDaysChange]);

    const toggleDay = (day: string) => {
        if (duration === MealPlanDuration.ONCE) {
            setSelectedDays([day]);
        } else {
            setSelectedDays((prevSelectedDays) => {
                if (prevSelectedDays.includes(day)) {
                    return prevSelectedDays.filter(d => d !== day);
                } else {
                    return [...prevSelectedDays, day];
                }
            });
        }
    };

    return (
        <div className="days-container flex-wrap">
            {daysOfWeek.map((day) => (
                <span
                    key={day}
                    className={`day ${selectedDays.includes(day) ? 'selected' : ''}`}
                    onClick={() => toggleDay(day)}>
                    {day}
                </span>
            ))}
        </div>
    );
}

export default Availability;
