import './NewPasswordContainer.css';
import React, {useState} from "react";
import HashingService from "../../../../core/services/HashingService";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveUser, setEmailVerified} from "../../../../core/redux/slices/profileManagementSlice";
import ChefService from "../../../../core/services/ChefService";
import Credentials from "../../../../model/Credentials";
import CustomerService from "../../../../core/services/CustomerService";
import LoadingButton from "../../loader-button/LoadingButton";
import InputError from "../../../../error-handling/input-error/InputError";
import {useNavigate} from "react-router-dom";

type NewPasswordContainerProps = {
    username: string
}

const NewPasswordContainer: React.FC<NewPasswordContainerProps> = ({username}) => {

    const [newPasswordInput1, setNewPasswordInput1] = useState('');
    const [newPasswordInput2, setNewPasswordInput2] = useState('');
    const [newPassword1Missing, setNewPassword1Missing] = useState(false);
    const [newPassword2Missing, setNewPassword2Missing] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false);
    const [isBothNewPasswordsMatched, setIsBothNewPasswordsMatched] = useState(false);
    const currentUser = useSelector(selectActiveUser);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const [loadingError, setLoadingError] = useState('');

    const handleSavePassword = async () => {

        if(!newPasswordInput1){
            setNewPassword1Missing(true);
        }

        if(!newPasswordInput2){
            return setNewPassword2Missing(true);
        }

        if(isBothNewPasswordsMatched){
            setIsLoading(true);
            if(newPasswordInput1 === newPasswordInput2){
                setIsBothNewPasswordsMatched(true);
                try {
                    const hashedPassword = await HashingService.hashPassword(newPasswordInput1);
                    const credentials: Credentials = {username: username, password: hashedPassword};
                    const response = currentUser === 'chef' ? await ChefService.changePassword(credentials) : await CustomerService.changePassword(credentials);
                    if(response){
                        setPasswordChangedSuccessfully(true);
                        setTimeout(()=>{
                            navigateTo('/login');
                            dispatch(setEmailVerified(false));
                            setPasswordChangedSuccessfully(false);
                        }, 1000)
                    }
                } catch (error){
                    setLoadingError('Oops! It looks like our service is temporarily unavailable. We\'re working to get things back to normal quickly.');
                    setTimeout(()=> {setLoadingError('')}, 2000);
                }finally {
                    setIsLoading(false);
                }
            }
        }

    }

    const handleNewPasswordInput1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword1Missing(false);
        setNewPasswordInput1(event.target.value);
        setIsBothNewPasswordsMatched(event.target.value === newPasswordInput2);
    }

    const handleNewPasswordInput2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword2Missing(false);
        setNewPasswordInput2(event.target.value);
        setIsBothNewPasswordsMatched(newPasswordInput1 === event.target.value);
    }

    return (
        <div className='new-password-container'>
            <div className='new-password-row'>
                <p className='label'>New password</p>
                <div>
                    <div className='password-input-wrapper'>
                        <input placeholder='Enter your new password' onChange={handleNewPasswordInput1Change} type={`${showPassword1 ? 'text' : 'password'}`}/>
                        <Icon icon={showPassword1 ? 'mdi:eye-off':'mdi:eye'} fontSize={20} className='toggle-password-icon' onClick={() => setShowPassword1(!showPassword1)}/>
                    </div>
                    {newPassword1Missing && <p className='error-message'>This field is required</p>}
                </div>
            </div>
            <div className='new-password-row'>
                <p className='label'>Confirm new password</p>
                <div className='password-input-wrapper'>
                    <input placeholder='Confirm your new password' onChange={handleNewPasswordInput2Change} type={`${showPassword2 ? 'text' : 'password'}`}/>
                    <Icon icon={showPassword2 ? 'mdi:eye-off':'mdi:eye'} fontSize={20} className='toggle-password-icon' onClick={() => setShowPassword2(!showPassword2)}/>
                </div>
                {newPassword2Missing && <p className='error-message'>This field is required</p>}
            </div>
            {!isBothNewPasswordsMatched && newPasswordInput2 && <sub className='error-message'>Password doesn't match, please try again</sub>}
            {isBothNewPasswordsMatched && <sub className='passwords-match-success'><Icon icon='mdi:success' fontSize={20}/> Passwords matched</sub>}
            {loadingError && <InputError message={loadingError} />}
            {passwordChangedSuccessfully ? <div className='password-changed-success'> <Icon icon='ep:success-filled' fontSize={20}/> Password changed successfully!</div> :
                !isLoading ?
                <div onClick={handleSavePassword} className='primary-button action-button'>Change Password</div> :
                    <div className='loading-button'><LoadingButton /></div>
            }
        </div>
    )
}

export default NewPasswordContainer;
