import './Onboarding.css';
import onboardingImage from "../../../assets/images/chef-onboarding.png";
import ChefCheckList from "./checklist/ChefCheckList";

const Onboarding = () => {

    return (
        <div className='onboarding-container'>
            <img src={onboardingImage} className='onboarding-image' alt='onboarding' />
            <p className='completion-heading'>Complete your application and start earning</p>
            <div className='onboarding-details'>
                <ChefCheckList />
            </div>
        </div>
    )
}

export default Onboarding;
