// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    align-items: center;
}

.sort-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    color: black;
    background-color: #ddd;
    cursor: pointer;
    white-space: nowrap;
    transition: background-color 0.3ms ease;
}

.sort-button.active, .sort-button.active:hover {
    background-color: #FFC107;
}

.sort-button:hover {
    background-color: #ddd;
}

@media screen and (max-width: 600px) {
    .button-container {
        overflow-x: auto;
    }

}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/sorting-buttons/SortingButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,gBAAgB;IACpB;;AAEJ","sourcesContent":[".button-container {\n    display: flex;\n    align-items: center;\n}\n\n.sort-button {\n    padding: 10px 20px;\n    margin: 5px;\n    border: none;\n    color: black;\n    background-color: #ddd;\n    cursor: pointer;\n    white-space: nowrap;\n    transition: background-color 0.3ms ease;\n}\n\n.sort-button.active, .sort-button.active:hover {\n    background-color: #FFC107;\n}\n\n.sort-button:hover {\n    background-color: #ddd;\n}\n\n@media screen and (max-width: 600px) {\n    .button-container {\n        overflow-x: auto;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
