// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-container {
    flex: 1 1;
    display: flex;
    gap: 20px;
    padding: 20px;
    flex-direction: column;
}

.orders-heading-container {
    background: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
}

.orders-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sort-container {
    display: flex;
    gap: 12px;
    align-items: center;
}

.sort-input-container {
    display: flex;
    gap: 8px;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid var(--primary-color);
}

.sort-input-container input {
    border: none;
}

.sort-input-container input:focus {
    outline: none;
}

.filter-orders-input {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/Orders.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,SAAS;IACT,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,sCAAsC;AAC1C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".orders-container {\n    flex: 1;\n    display: flex;\n    gap: 20px;\n    padding: 20px;\n    flex-direction: column;\n}\n\n.orders-heading-container {\n    background: white;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.orders-heading {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.sort-container {\n    display: flex;\n    gap: 12px;\n    align-items: center;\n}\n\n.sort-input-container {\n    display: flex;\n    gap: 8px;\n    padding: 10px;\n    border-radius: 12px;\n    border: 1px solid var(--primary-color);\n}\n\n.sort-input-container input {\n    border: none;\n}\n\n.sort-input-container input:focus {\n    outline: none;\n}\n\n.filter-orders-input {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
