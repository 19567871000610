import React, {useRef} from 'react';
import { useLoadScript } from "@react-google-maps/api";
import './AutoCompleteAddress.css';
import { Icon } from '@iconify/react';
import { useDispatch } from "react-redux";
import { setSuggestions } from "../../core/redux/slices/addressSuggestionSlice";

type AutoCompleteAddressProps = {
    changeAddress?: boolean;
    placeholder?: string;
}

const AutoCompleteAddress: React.FC<AutoCompleteAddressProps> = ({placeholder}) => {

    const apiKey: any = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE;
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries: ['places']
    });

    return (
        <>
            {isLoaded ? <AutoCompleteAddressInput changeAddress={true} placeholder={placeholder}/> : <div>Unable to load maps</div>}
        </>)
}

const AutoCompleteAddressInput: React.FC<AutoCompleteAddressProps>  = ({ placeholder}) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const handlePlaceChanged = () => {
        if (inputRef.current) {
            const autoCompleteService = new window.google.maps.places.AutocompleteService();
            const input = inputRef.current.value;

            autoCompleteService.getPlacePredictions(
                {
                    input,
                    componentRestrictions: {
                        country: 'CA'
                    }
                },
                (predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                        const suggestedPlaces = predictions.map((prediction) => {
                            const structuredFormatting = prediction.structured_formatting;
                            const terms = prediction.terms;

                            return {
                                description: prediction.description,
                                place_id: prediction.place_id,
                                structured_formatting: structuredFormatting,
                                terms,
                                geometry: undefined
                            };
                        });
                        dispatch(setSuggestions(suggestedPlaces));
                    }
                }
            );
        }

        if(inputRef.current?.value.length === 0) {
            dispatch(setSuggestions([]));
        }
    };

    return (
        <div className='search-input-wrapper'>
            <Icon icon='pajamas:location' fontSize={24} className='sub-black address-location-icon' />
                <input className='address-search-input' ref={inputRef} type="text" placeholder={placeholder ? placeholder : "Search address"}
                       onChange={handlePlaceChanged}/>
        </div>
    );
}


export default AutoCompleteAddress;
