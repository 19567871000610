// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal-plan-row {
    display: flex;
    gap: 20px;
}

.meal-plan-column {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.meal-plan-form-input {
    outline: none;
    width: 100%;
    color: black;
    padding: 12px;
    border: 1px solid var(--border-light);
    border-radius: 8px;
}

.meal-plan-form-input:focus {
    border: 1px solid var(--primary-color);
}

.duration-radio-button {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
}

.full-width-span {
    width: 100%;
}


.light-border-bottom {
    border-bottom: 1px solid var(--border-light);
}

.meal-plan-form-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
}

.serving-icon-wrapper {
    display: inline-block;
    padding: 30px 30px 0 30px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.serving-icon {
    width: 200px;
    height: 100px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.serving-icon-wrapper:hover .serving-icon {
    transform: scale(1.1);
    opacity: 1;
}

@media screen and (max-width: 600px) {

    .serving-icon-wrapper {
        width: 100%;
        text-align: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/create-meal-plan/meal-plan-form/MealPlanForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,aAAa;IACb,qCAAqC;IACrC,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,gEAAgE;AACpE;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gEAAgE;AACpE;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;;IAEI;QACI,WAAW;QACX,kBAAkB;IACtB;AACJ","sourcesContent":[".meal-plan-row {\n    display: flex;\n    gap: 20px;\n}\n\n.meal-plan-column {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n.meal-plan-form-input {\n    outline: none;\n    width: 100%;\n    color: black;\n    padding: 12px;\n    border: 1px solid var(--border-light);\n    border-radius: 8px;\n}\n\n.meal-plan-form-input:focus {\n    border: 1px solid var(--primary-color);\n}\n\n.duration-radio-button {\n    display: flex;\n    flex-direction: row !important;\n    gap: 10px;\n}\n\n.full-width-span {\n    width: 100%;\n}\n\n\n.light-border-bottom {\n    border-bottom: 1px solid var(--border-light);\n}\n\n.meal-plan-form-detail {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 20px;\n}\n\n.serving-icon-wrapper {\n    display: inline-block;\n    padding: 30px 30px 0 30px;\n    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;\n}\n\n.serving-icon {\n    width: 200px;\n    height: 100px;\n    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;\n}\n\n.serving-icon-wrapper:hover .serving-icon {\n    transform: scale(1.1);\n    opacity: 1;\n}\n\n@media screen and (max-width: 600px) {\n\n    .serving-icon-wrapper {\n        width: 100%;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
