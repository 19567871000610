// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-chef-offer-modal {
    width: 700px;
    padding: 20px;
    display: flex;
}

.new-chef-offer-detail {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.new-chef-offer-detail .main-heading-wrapper {
    font-weight: 600;
}


.new-chef-offer-image {
    flex: 1 1;
    object-fit: contain;
    max-width: 50%;
}

.new-offer-main-heading {
    color: var(--primary-color);
}

.close-new-offer-modal {
    position: absolute;
    top: -20px;
    right: 10px;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid black;
    background: var(--grey2);
}

.close-new-offer-modal:hover {
    background: var(--grey-color-hover);
}

.register-now-button {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .new-chef-offer-modal {
        max-width: 90%;
        flex-direction: column;
    }

    .new-chef-offer-detail {
        padding: 20px;
    }

    .new-chef-offer-detail .main-heading-wrapper {
        font-size: 35px;
    }

    .new-chef-offer-image {
        max-width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/new-chef-offer/NewChefOfferModal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,SAAO;IACP,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,cAAc;QACd,sBAAsB;IAC1B;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".new-chef-offer-modal {\n    width: 700px;\n    padding: 20px;\n    display: flex;\n}\n\n.new-chef-offer-detail {\n    flex: 2;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    justify-content: center;\n    align-items: center;\n    padding: 30px;\n}\n\n.new-chef-offer-detail .main-heading-wrapper {\n    font-weight: 600;\n}\n\n\n.new-chef-offer-image {\n    flex: 1;\n    object-fit: contain;\n    max-width: 50%;\n}\n\n.new-offer-main-heading {\n    color: var(--primary-color);\n}\n\n.close-new-offer-modal {\n    position: absolute;\n    top: -20px;\n    right: 10px;\n    border-radius: 50%;\n    padding: 10px;\n    border: 1px solid black;\n    background: var(--grey2);\n}\n\n.close-new-offer-modal:hover {\n    background: var(--grey-color-hover);\n}\n\n.register-now-button {\n    width: 100%;\n}\n\n@media screen and (max-width: 600px) {\n    .new-chef-offer-modal {\n        max-width: 90%;\n        flex-direction: column;\n    }\n\n    .new-chef-offer-detail {\n        padding: 20px;\n    }\n\n    .new-chef-offer-detail .main-heading-wrapper {\n        font-size: 35px;\n    }\n\n    .new-chef-offer-image {\n        max-width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
