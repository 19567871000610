// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/landing-page-new.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
  overflow-x: hidden;
}

.home-landing {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), no-repeat;
  background-size: cover;
  height: 100vh;
}

.address{
  text-align: center;
  display: flex;
  padding: 24px;
  width: 60%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 5px 30px 0 black;
  flex-direction: column;
}

.address-row {
  display: flex;
  flex: 1 1;
  gap: 20px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #FFE2C7;
  padding: 20px 16px 20px 16px;
  flex-direction: row;
}

.address-column-1 {
  display: flex;
  flex: 1 1;
  position: relative;
  gap: 12px;
  flex-direction: column;
}

.find-chef-wrapper{
  text-align: center;
}

.address-input-wrapper {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 600px) {

  .home-landing {
    height: auto;
    align-items: flex-start;
  }

  .signup-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1100;
  }

  .address {
    width: 90%;
    height: auto;
    margin: 50px 20px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .address-input-wrapper {
    flex-direction: column;
  }

}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/HomeStyle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,8DAAsE;EACtE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,SAAS;EACT,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,kBAAkB;EAClB,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;;EAEE;IACE,YAAY;IACZ,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;EACf;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;AAEF","sourcesContent":[".home-page {\n  overflow-x: hidden;\n}\n\n.home-landing {\n  background: url(\"../../assets/images/landing-page-new.png\"), no-repeat;\n  background-size: cover;\n  height: 100vh;\n}\n\n.address{\n  text-align: center;\n  display: flex;\n  padding: 24px;\n  width: 60%;\n  border-radius: 10px;\n  background: #FFF;\n  box-shadow: 0 5px 30px 0 black;\n  flex-direction: column;\n}\n\n.address-row {\n  display: flex;\n  flex: 1;\n  gap: 20px;\n  justify-content: space-between;\n  border-radius: 10px;\n  border: 1px solid #FFE2C7;\n  padding: 20px 16px 20px 16px;\n  flex-direction: row;\n}\n\n.address-column-1 {\n  display: flex;\n  flex: 1;\n  position: relative;\n  gap: 12px;\n  flex-direction: column;\n}\n\n.find-chef-wrapper{\n  text-align: center;\n}\n\n.address-input-wrapper {\n  display: flex;\n  gap: 12px;\n}\n\n@media screen and (max-width: 600px) {\n\n  .home-landing {\n    height: auto;\n    align-items: flex-start;\n  }\n\n  .signup-modal {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 1100;\n  }\n\n  .address {\n    width: 90%;\n    height: auto;\n    margin: 50px 20px;\n    padding-left: 18px;\n    padding-right: 18px;\n  }\n\n  .address-input-wrapper {\n    flex-direction: column;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
