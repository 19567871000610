import LoginButton from "./login-button/LoginButton";
import SignupButton from "./signup-button/SignupButton";
import {NavLink, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './NavBarStyle.css';
import { Icon } from "@iconify/react";
import { openMenuModal } from "../../../core/redux/slices/menuModalSlice";
import BecomeChefButton from "./become-chef-button/BecomeChefButton";
import {selectUser} from "../../../core/redux/slices/loginSlice";
import NotificationsButton from "./notifications/notifications-button/NotificationsButton";
import CheckoutButton from "../checkout-cart/CheckoutButton";
import React, {useEffect, useState} from "react";
import orangeLogo from '../../../assets/images/logos/logo-orange-chef.png';
import blackLogo from '../../../assets/images/logos/logo-black-chef.png';
import AccountManager from "./account-manager/AccountManager";

const NavBar = () => {

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const location = useLocation();
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
    const isDashboardPath = location.pathname.startsWith('/chef/dashboard');
    const checkoutPath = location.pathname === '/checkout';

    const openMenu = () => {
        dispatch(openMenuModal());
    }

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 600);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            {(!isDashboardPath || isDesktop) &&
                <div className={`nav-bar ${isDashboardPath ? 'dashboard-path': ''}`}>
                    <div className='nav-bar-column-1'>
                        {!isDashboardPath && <Icon icon='line-md:close-to-menu-alt-transition' fontSize={36} onClick={openMenu} className='cursor'/>}
                        <NavLink to='/' className='link-black logo'>
                            {isDashboardPath ? <img src={blackLogo} alt='logo' className='navbar-logo'/> :
                                <img src={orangeLogo} alt='logo' className='navbar-logo'/>
                            }
                        </NavLink>
                    </div>
                    <div className='navbar-link-wrapper'>
                        {user.loading !== 'succeeded' &&
                            <>
                                <LoginButton/>
                                <SignupButton/>
                                <BecomeChefButton backgroundColor='primary' />
                            </>
                        }
                        { !checkoutPath &&
                            <>
                                {isDesktop && <CheckoutButton />}
                                {user.userType === 'customer' && isDesktop && <BecomeChefButton backgroundColor='primary' />}
                                {user.userType === 'chef' &&
                                    <div className='chef-options-wrapper'>
                                        <NotificationsButton />
                                        {(!isDashboardPath && isDesktop) && <NavLink to='/chef/dashboard' className='primary-button dashboard-link link-black'>Dashboard</NavLink>}
                                    </div>
                                }
                                {user.loading === 'succeeded' && <AccountManager user={user.currentUser} />}
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default NavBar;
