// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meal-items-wrapper {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-light);
    border-radius: 10px;
}

.meal-items-wrapper.openTooltip {
    border: 1px solid var(--primary-color);
}

.meal-plans-items-list {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.meal-plan-items-wrapper {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media screen and (max-width: 600px) {
    .meal-plans-items-list > div {
        flex: 1 1;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/meal-plan-items/fixed-meal-plan-items/FixedMealPlanItems.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;;AAGA;IACI;QACI,SAAO;IACX;AACJ","sourcesContent":[".meal-items-wrapper {\n    display: flex;\n    padding: 15px;\n    justify-content: space-between;\n    align-items: center;\n    border: 1px solid var(--border-light);\n    border-radius: 10px;\n}\n\n.meal-items-wrapper.openTooltip {\n    border: 1px solid var(--primary-color);\n}\n\n.meal-plans-items-list {\n    display: flex;\n    gap: 30px;\n    flex-wrap: wrap;\n}\n\n.meal-plan-items-wrapper {\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n\n@media screen and (max-width: 600px) {\n    .meal-plans-items-list > div {\n        flex: 1;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
