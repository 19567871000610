import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from "../store";
import {Address} from "../../../model/Chef";

interface profileManagementType {
    activeUser: string,
    isAddressSelected : boolean;
    address?: Address;
    formattedAddress: string,
    emailVerified: boolean,
}

const initialState: profileManagementType = {
    activeUser: '',
    isAddressSelected: false,
    address: undefined,
    formattedAddress: '',
    emailVerified: false,
}

const profileManagementSlice = createSlice({
    name: 'profileManagement',
    initialState,
    reducers: {
        setActiveUser : (state, action: PayloadAction<string>) => {
            state.activeUser = action.payload;
        },
        setAddress : (state, action: PayloadAction<Address | undefined>) => {
            state.address = action.payload;
        },
        setFormattedAddress : (state, action: PayloadAction<string>) => {
            state.formattedAddress = action.payload;
        },
        setEmailVerified : (state, action: PayloadAction<boolean>) => {
            state.emailVerified = action.payload;
        }
    }
});

export const { setAddress, setFormattedAddress, setEmailVerified, setActiveUser } = profileManagementSlice.actions;

export const selectChosenAddress = (state: RootState) => state.profileManagement.address;

export const selectEmailVerified = (state: RootState) => state.profileManagement.emailVerified;
export const selectActiveUser = (state: RootState) => state.profileManagement.activeUser;

export const selectFormattedAddress = (state: RootState) => state.profileManagement.formattedAddress;
export default profileManagementSlice.reducer;
