import './AvailableChefs.css';
import React, {useEffect, useState} from "react";
import DeliveryOptions from "./delivery-options/DeliveryOptions";
import CuisineCarousel from "./cuisines-carousel/CuisineCarousel";
import AvailableChefsList from "./available-chefs/AvailableChefsList";
import Chef from "../../model/Chef";
import {useSelector} from "react-redux";
import {selectAvailableChefs} from "../../core/redux/slices/chefListSlice";
import EmptyChefWrapper from "./empty-chef-wrapper/EmptyChefWrapper";
import PageChangeRouter from "../../components/shared/loaders/PageChangeLoader/PageChangeRouter";

const AvailableChefs = () => {

    const chefs: Chef[] = useSelector(selectAvailableChefs);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <PageChangeRouter />;
    }

    return (
        <div className='available-chefs-wrapper'>
            <DeliveryOptions />
            <CuisineCarousel />
            {
               (chefs && chefs.length > 0) ? <AvailableChefsList /> : <EmptyChefWrapper />
            }

        </div>
    )
}

export default AvailableChefs;
