// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer-type-card {
    padding: 30px 20px 20px 20px;
    background: white;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    gap: 20px;
    text-align: center;
    flex-direction: column;
}

.offer-type-card.selected {
    border: 1px solid var(--primary-color);
}

.customize-offer-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon-wrapper {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.offer-type-icon {
    height: 36px;
    width: 36px;
}

.icon-wrapper.discount {
    background: rgba(250, 61, 61, 0.42);
}

.icon-wrapper.bogof {
    background: rgba(0, 206, 58, 0.47);
}

.icon-wrapper.spend-and-get-free-offer {
    background: rgba(238, 177, 19, 0.53);
}

.offer-description {
    color: #858585;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/OfferTypesOverview/OfferTypeCard/OfferTypeCard.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,SAAO;IACP,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".offer-type-card {\n    padding: 30px 20px 20px 20px;\n    background: white;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    position: relative;\n    gap: 20px;\n    text-align: center;\n    flex-direction: column;\n}\n\n.offer-type-card.selected {\n    border: 1px solid var(--primary-color);\n}\n\n.customize-offer-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n\n.icon-wrapper {\n    height: 80px;\n    width: 80px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n}\n\n.offer-type-icon {\n    height: 36px;\n    width: 36px;\n}\n\n.icon-wrapper.discount {\n    background: rgba(250, 61, 61, 0.42);\n}\n\n.icon-wrapper.bogof {\n    background: rgba(0, 206, 58, 0.47);\n}\n\n.icon-wrapper.spend-and-get-free-offer {\n    background: rgba(238, 177, 19, 0.53);\n}\n\n.offer-description {\n    color: #858585;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
