import './SpecialityCuisineCard.css';
import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import {customCheckboxProps, customLabelProps} from "../../../../../../custom-tooltips-dropdowns/CustomMUITooltip";

type SpecialityCuisineCardProps = {
    isChecked: boolean,
    cuisine: string,
    onChecked: (cuisine: string) => void,
}

const SpecialityCuisineCard: React.FC<SpecialityCuisineCardProps> = ({isChecked, cuisine, onChecked}) => {

    return (
        <div className='speciality-cuisine-card'>
            <p>{cuisine}</p>
            <FormControlLabel control={<Checkbox sx={customCheckboxProps} onChange={() => onChecked(cuisine)} checked={isChecked}/>}
                              label=''  sx={customLabelProps}/>
        </div>
    )
}

export default SpecialityCuisineCard;
