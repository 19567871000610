import {Icon} from "@iconify/react";
import React from "react";
import MenuItem from "../../../../../model/MenuItem";
import {addToCart, CartItemType, decreaseItemCountFromCart, selectCartItemsCount, setSelectedChef} from "../../../../../core/redux/slices/customerSlice";
import {useDispatch, useSelector} from "react-redux";
import useImageLoader from "../../../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";

type CheckoutCartItemCardType = {
    item: CartItemType,
}

const CheckoutCartItemCard: React.FC<CheckoutCartItemCardType> = ({item}) => {

    const cartItemsCount = useSelector(selectCartItemsCount);
    const dispatch = useDispatch();
    const imageUrl = item.menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const isImageLoaded = useImageLoader(imageUrl);

    const addItemToCart = (menuItem: MenuItem) => {
        dispatch(addToCart({menuItem: menuItem, count: 1, specialInstructions: ''}));
    }

    const decreaseItemFromCart = (itemCount: number, menuItem: MenuItem) => {
        if (cartItemsCount === 1){
            dispatch(setSelectedChef(undefined));
        }
        dispatch(decreaseItemCountFromCart(menuItem.id as string));
    }

    return (
        <div className='checkout-item-card'>
            <div className='item-details'>
                {isImageLoaded ? <img src={`${item.menuItem.imageUrl}?${process.env.REACT_APP_BLOB_SAS_TOKEN}`}
                      className='checkout-item-card-image' alt='checkout item'/> : <Skeleton variant='circular' width={60} height={60}/>}
                <div>
                    <p className='item-name'>{item.menuItem.name}</p>
                    <p className='item-price'>${+item.menuItem.price * item.count}</p>
                </div>
            </div>
            <div className='add-cart-item-action-buttons'>
                <p className='menu-item-card-remove-icon'>
                    <Icon icon={item.count === 1 ? 'ic:round-delete' : 'ic:baseline-minus'}  fontSize={24} onClick={() => decreaseItemFromCart(item.count, item.menuItem)}/>
                </p>
                <p>{item.count}</p>
                <p className='menu-item-card-add-icon' onClick={() => addItemToCart(item.menuItem)}>
                    <Icon icon='ic:outline-add' fontSize={24}/>
                </p>
            </div>
        </div>
    )
}

export default CheckoutCartItemCard;
