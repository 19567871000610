import './SpecialityCuisinesList.css';
import Chef from "../../../../../model/Chef";
import React, {useState} from "react";
import SpecialityCuisineCard from "./speciality-cuisine-card/SpecialityCuisineCard";
import useFormInput from "../../../../../custom-hooks/useFormInput";

type SpecialityCuisinesListProps = {
    chef: Chef,
    onCuisinesChange: (cuisines: string[]) => void,
}

const SpecialityCuisinesList: React.FC<SpecialityCuisinesListProps> = ({chef, onCuisinesChange}) => {
    const defaultCuisines =['Mexican','Indian','Thai','Chinese','Canadian', 'Japanese', 'Korean', 'Italian', 'Baked'];
    const [cuisines, setCuisines] =useState([...defaultCuisines, ...chef.specialityCuisines.filter((cuisine) => !defaultCuisines.includes(cuisine))]);
    const [selectedCuisines, setSelectedCuisines] = useState(chef.specialityCuisines);
    const [isAddNewCuisine, setIsAddNewCuisine] = useState(false);
    const newCuisine = useFormInput('',true);

    const handleSelectedCuisine = (cuisine: string) => {

        let newCuisines;
        if(selectedCuisines.includes(cuisine)){
            newCuisines = selectedCuisines.filter(selectedCuisine => selectedCuisine !== cuisine);
            setSelectedCuisines(newCuisines);
            onCuisinesChange(newCuisines);
        }else {
            newCuisines = [...selectedCuisines, cuisine];
            setSelectedCuisines(newCuisines);
        }
        onCuisinesChange(newCuisines);
    }

    const handleSaveNewCuisine = () => {
        setCuisines([...cuisines, newCuisine.value]);
        onCuisinesChange([...selectedCuisines, newCuisine.value]);
        setSelectedCuisines([...selectedCuisines, newCuisine.value]);
        setIsAddNewCuisine(false);
    }

    return (
        <div className='speciality-cuisine-list'>
            {cuisines.map((cuisine, index) => (
                <SpecialityCuisineCard isChecked={selectedCuisines.includes(cuisine)} cuisine={cuisine} onChecked={handleSelectedCuisine} key={index}/>
            ))}
            {!isAddNewCuisine ?
                <div className='primary-button' onClick={() => setIsAddNewCuisine(true)}>Add new cuisine</div> :
                <label className='add-new-cuisine-input-wrapper'>
                    <input value={newCuisine.value} onChange={newCuisine.onChange} />
                    <p onClick={handleSaveNewCuisine} className='save-cuisine-button cursor'>Save</p>
                </label>
            }
        </div>
    )
}

export default SpecialityCuisinesList;
