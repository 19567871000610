// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-received-orders-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.empty-received-orders-card {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 16px;
    height: 400px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.empty-received-orders-card img {
    width: 100%;
    border-radius: 16px;
    height: 80%;
}

.empty-orders-body {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .empty-received-orders-card {
        width: 90%;
        margin-top: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/empty-received-orders/EmptyReceivedOrders.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,0CAA0C;AAC9C;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;QACV,gBAAgB;IACpB;AACJ","sourcesContent":[".empty-received-orders-container {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n}\n\n.empty-received-orders-card {\n    display: flex;\n    flex-direction: column;\n    width: 400px;\n    border-radius: 16px;\n    height: 400px;\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);\n}\n\n.empty-received-orders-card img {\n    width: 100%;\n    border-radius: 16px;\n    height: 80%;\n}\n\n.empty-orders-body {\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    align-items: center;\n}\n\n@media screen and (max-width: 600px) {\n    .empty-received-orders-card {\n        width: 90%;\n        margin-top: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
