import ScheduleForm from "../ScheduleForm";

const SchedulePage = () => {


    return (
        <div className='schedule-page'>
            <ScheduleForm />
        </div>
    )
}

export default SchedulePage;
