import {createAsyncThunk} from "@reduxjs/toolkit";
import {Address} from "../../model/Chef";
import PaymentMethod from "../../model/PaymentMethod";
import ErrorResponse from "../../model/error/ErrorResponse";

class CommonService {

    static addAddress = createAsyncThunk(
        'addAddress',
        async (userData: { userType: string, id: string, address: Address, token?: string }) => {

            const addAddressResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/${userData.userType === 'chef' ? 'chef' : 'customer'}/address/add`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userData.token}`
                    },
                    body: JSON.stringify({id: userData.id, address: userData.address})
                }
            );

            if (addAddressResponse.ok){
                return await addAddressResponse.json();
            }
        }
    );

    static getAddressList = createAsyncThunk(
        'getAddressList',
        async (userData: { userType: string, id: string, token?: string }) => {

            const addressListResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/${userData.userType === 'chef' ? 'chef' : 'customer'}/address/list/${userData.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userData.token}`
                    },
                }
            );

            if (addressListResponse.ok){
                return await addressListResponse.json();
            }
        }
    );

    static getPaymentMethods = createAsyncThunk(
        'getPaymentMethods',
        async (userData: { userType: string, id: string, token?: string }): Promise<PaymentMethod[] | ErrorResponse> => {

            const addressListResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/${userData.userType === 'chef' ? 'chef' : 'customer'}/payment-methods/list/${userData.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userData.token}`
                    },
                }
            );

            if (addressListResponse.ok){
                return await addressListResponse.json();
            }

            return {message: 'Something went wrong, please try again'}
        }
    );
}


export default CommonService;
