import React, {useState} from "react";

const useCheckbox = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
    };
    const reset = () => {
        setValue(initialValue);
    }

    const uncheck = () => {
        setValue(false);
    }

    return {
        value, setValue,
        onChange: handleChange,
        reset, uncheck
    };
};

export default useCheckbox;
