// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brief-summary-card-wrapper {
    width: 100px;
    height: 100px;
    position: relative;
}

.brief-summary-card-wrapper .count-number {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: var(--primary-color);
}

.brief-summary-card-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/order-items-summary/brief-summary-item-card/BriefSummaryItemCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".brief-summary-card-wrapper {\n    width: 100px;\n    height: 100px;\n    position: relative;\n}\n\n.brief-summary-card-wrapper .count-number {\n    position: absolute;\n    right: 5px;\n    top: 5px;\n    height: 24px;\n    width: 24px;\n    border-radius: 50%;\n    background: var(--primary-color);\n}\n\n.brief-summary-card-wrapper img {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
