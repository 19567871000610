import './CuisineCard.css';
import React from "react";
import {cuisineType} from "../CuisineCarousel";
import useImageLoader from "../../../../custom-hooks/useImageLoader";
import CuisineCardSkeleton from "./cuisine-card-skeleton/CuisineCardSkeleton";
import {useSelector} from "react-redux";
import {selectCuisineFilter} from "../../../../core/redux/slices/chefListSlice";

type CuisineCardProps = {
    cuisine: cuisineType
}

const CuisineCard: React.FC<CuisineCardProps> = ({cuisine}) => {

    const isImageLoaded = useImageLoader(cuisine.imageSource);
    const selectedCuisine = useSelector(selectCuisineFilter);

    return (
        <div className={`cuisine-card cursor ${selectedCuisine === cuisine.cuisineName ? 'selected' : ''}`}>
            {
                isImageLoaded ?
                <>
                    <img src={cuisine.imageSource} alt='cuisien-type' />
                    <p>{cuisine.cuisineName}</p>
                </> :
                    <CuisineCardSkeleton />
            }
        </div>
    )
}

export default CuisineCard;
