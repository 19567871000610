import {MealPlanDuration} from "./MealPlanDuration";
import MenuItem from "../MenuItem";

export enum MealServing {
    ONE = 'ONE',
    TWO = 'TWO',
    FOUR = 'FOUR',
}

export type FixedMealItem = {
    menuItem: MenuItem,
    quantity: number,
}

export type FixedMealItems = {
    main?: FixedMealItem[],
    sides?: FixedMealItem[],
    beverages?: FixedMealItem[],
    dessert?: FixedMealItem[],
}

interface FixedMeal {
    id?: string,
    title: string,
    duration: MealPlanDuration,
    availability?: string[],
    price: string,
    serving: MealServing
    mealItems: FixedMealItems,
}

export default FixedMeal;
