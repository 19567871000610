// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cuisine-card {
    padding: 20px;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background: #FDFAF1;
}

.cuisine-card img {
    height: 64px;
    width: 100px;
    transition: all 1s linear;
}

.cuisine-card:hover img {
    transform: scale(1.3);
}

.cuisine-card:hover {
    border-inline: 1px solid var(--primary-color);
}

.cuisine-card.selected {
    border: 1px solid var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/cuisines-carousel/cuisine-card/CuisineCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".cuisine-card {\n    padding: 20px;\n    display: flex;\n    gap: 10px;\n    border-radius: 10px;\n    align-items: center;\n    flex-direction: column;\n    background: #FDFAF1;\n}\n\n.cuisine-card img {\n    height: 64px;\n    width: 100px;\n    transition: all 1s linear;\n}\n\n.cuisine-card:hover img {\n    transform: scale(1.3);\n}\n\n.cuisine-card:hover {\n    border-inline: 1px solid var(--primary-color);\n}\n\n.cuisine-card.selected {\n    border: 1px solid var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
