// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.new-password-container {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
}

.new-password-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.password-changed-success {
    color: green;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 128, 0, 0.55);
}

.label {
    color: var(--sub-black);
}

.new-password-row input {
    width: 100%;
    font-size: 16px;
    outline: none;
    border: none;
}

.password-input-wrapper:focus-within {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid var(--primary-color);
}

.password-input-wrapper {
    padding: 8px;
    position: relative;
    border: 1px solid var(--border-light);
    border-radius: 4px;
}

.passwords-match-success {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #03a103;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/password-change/new-password-container/NewPasswordContainer.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;IAC/B,6CAA6C;AACjD;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,cAAc;AAClB","sourcesContent":["\n.new-password-container {\n    display: flex;\n    margin-top: 20px;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.new-password-row {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.password-changed-success {\n    color: green;\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    justify-content: center;\n    padding: 10px;\n    border-radius: 4px;\n    border: 1px solid rgba(0, 128, 0, 0.55);\n}\n\n.label {\n    color: var(--sub-black);\n}\n\n.new-password-row input {\n    width: 100%;\n    font-size: 16px;\n    outline: none;\n    border: none;\n}\n\n.password-input-wrapper:focus-within {\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n    border-bottom: 1px solid var(--primary-color);\n}\n\n.password-input-wrapper {\n    padding: 8px;\n    position: relative;\n    border: 1px solid var(--border-light);\n    border-radius: 4px;\n}\n\n.passwords-match-success {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: #03a103;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
