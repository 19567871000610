// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-item-modal {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
}

.delete-item-confirmation-text {
    color: var(--sub-black);
}

.delete-menu-item-heading {
    font-weight: 600;
    font-size: 50px;
}

.delete-menu-item-button {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.cancel-button {
    padding: 10px 20px;
    border-radius: 8px;
    background: #F6F6F6;
    border: none;
    color: black;
}

.delete-button {
    padding: 10px 20px;
    border-radius: 8px;
    background: var(--primary-color);
}

@media screen and (max-width: 600px) {
    .delete-item-modal {
        width: 90%;
    }

    .delete-menu-item-heading {
        font-size: 35px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/delete-menu-item-modal/DeleteMenuItemModal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".delete-item-modal {\n    max-width: 500px;\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    text-align: center;\n}\n\n.delete-item-confirmation-text {\n    color: var(--sub-black);\n}\n\n.delete-menu-item-heading {\n    font-weight: 600;\n    font-size: 50px;\n}\n\n.delete-menu-item-button {\n    display: flex;\n    gap: 20px;\n    justify-content: center;\n}\n\n.cancel-button {\n    padding: 10px 20px;\n    border-radius: 8px;\n    background: #F6F6F6;\n    border: none;\n    color: black;\n}\n\n.delete-button {\n    padding: 10px 20px;\n    border-radius: 8px;\n    background: var(--primary-color);\n}\n\n@media screen and (max-width: 600px) {\n    .delete-item-modal {\n        width: 90%;\n    }\n\n    .delete-menu-item-heading {\n        font-size: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
