import './FixedMealPlanItems.css';
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import ChefService from "../../../../../core/services/ChefService";
import { useDispatch, useSelector } from "react-redux";
import { selectChef } from "../../../../../core/redux/slices/chefSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import store from "../../../../../core/redux/store";
import MenuItem from "../../../../../model/MenuItem";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import MealPlanItemList from "../meal-plan-item-list/MealPlanItemList";
import AddedMealItems from "../added-meal-items/AddedMealItems";
import SimpleErrorMessage from "../../../../../error-handling/error-message/SimpleErrorMessage";
import { FixedMealItems, FixedMealItem } from "../../../../../model/meal-plans/FixedMeal";

export const itemsInArray = (mealPlanItems: FixedMealItem[]) => {
    return mealPlanItems.reduce(((acc, mealItem) => mealItem.quantity + acc), 0);
}

interface FixedMealPlanItemsProps {
    fixedMealItemsToEdit?: FixedMealItems;
}

const FixedMealPlanItems = forwardRef<{ validateMeals: () => FixedMealItems; resetItemsForm: () => void }, FixedMealPlanItemsProps>(({fixedMealItemsToEdit}, ref) => {

    const id = useSelector(selectChef).id;
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [menu, setMenu] = useState<MenuItem []>();
    const { t } = useTranslation('ChefDashboard');
    const {t: tCommon} = useTranslation('common');
    const [sideItemsTooltipOpen, setSideItemsTooltipOpen] = useState(false);
    const [mainCourseTooltipOpen, setMainCourseTooltipOpen] = useState(false);
    const [beveragesTooltipOpen, setBeveragesTooltipOpen] = useState(false);
    const [dessertTooltipOpen, setDessertTooltipOpen] = useState(false);
    const [sideItems, setSideItems] = useState<FixedMealItem[]>([]);
    const [mainCourse, setMainCourse] = useState<FixedMealItem[]>([]);
    const [beverages, setBeverages] = useState<FixedMealItem[]>([]);
    const [desserts, setDesserts] = useState<FixedMealItem[]>([]);
    const [mealsMissing, setMealsMissing] = useState(false);

    useEffect(() => {

        const fetchMenu = async () => {
            try {
                const resultAction = await apiDispatch(ChefService.getMenu({
                    id: id as string
                }));
                const menu: MenuItem[] = unwrapResult(resultAction);
                setMenu(menu);
            }catch (error) {
                console.log(error);
            }
        }

        fetchMenu();

        if (fixedMealItemsToEdit) {
            if (fixedMealItemsToEdit.main) {
                setMainCourse(fixedMealItemsToEdit.main);
            }
            if (fixedMealItemsToEdit.sides) {
                setSideItems(fixedMealItemsToEdit.sides);
            }
            if (fixedMealItemsToEdit.dessert) {
                setDesserts(fixedMealItemsToEdit.dessert);
            }
            if (fixedMealItemsToEdit.beverages) {
                setBeverages(fixedMealItemsToEdit.beverages);
            }
        }
    }, []);

    const resetItemsForm = () => {
        setSideItems([]);
        setMainCourse([]);
        setBeverages([]);
        setDesserts([]);
    }

    useImperativeHandle(ref, () => ({
        validateMeals,
        resetItemsForm
    }));

    const validateMeals = () => {
        const mealItems: FixedMealItems = {};

        if (mainCourse.length > 0) {
            mealItems.main = mainCourse;
        }
        if (sideItems.length > 0) {
            mealItems.sides = sideItems;
        }
        if (beverages.length > 0) {
            mealItems.beverages = beverages;
        }
        if (desserts.length > 0) {
            mealItems.dessert = desserts;
        }

        const hasItems = Object.keys(mealItems).length > 0;
        setMealsMissing(!hasItems);

        return mealItems;
    };


    const onApplyMealItemsClicked = (mealItemType: string, mealItems: FixedMealItem[]) => {

        switch (mealItemType) {
            case 'main' :
                setMainCourse(mealItems);
                setMainCourseTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'sides':
                setSideItems(mealItems);
                setSideItemsTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'beverages':
                setBeverages(mealItems);
                setBeveragesTooltipOpen(false);
                setMealsMissing(false);
                break;
            case 'dessert':
                setDesserts(mealItems);
                setDessertTooltipOpen(false);
                setMealsMissing(false);
                break;
        }
    }

    return (
        <div className='flex column gap-20 meal-plan-form-detail light-border-bottom'>
            <div className='flex-space-between'>
                <h3>Meal Plan Items</h3>
                {mealsMissing && <SimpleErrorMessage message={tCommon('required-field-error')} />}
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.sides-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${sideItemsTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setSideItemsTooltipOpen(!sideItemsTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.sides-heading')}</p>
                        {sideItemsTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {sideItemsTooltipOpen && <MealPlanItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                               menu={menu} mealItems={sideItems} mealItemType='sides'/>}
                    {(itemsInArray(sideItems) > 0 && !sideItemsTooltipOpen) && <AddedMealItems mealItems={sideItems} />}
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.main-course-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${mainCourseTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setMainCourseTooltipOpen(!mainCourseTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.main-course-heading')}</p>
                        {mainCourseTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {mainCourseTooltipOpen && <MealPlanItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                                menu={menu} mealItems={mainCourse} mealItemType='main'/>}
                    {(itemsInArray(mainCourse) > 0 && !mainCourseTooltipOpen) && <AddedMealItems mealItems={mainCourse} />}
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.beverages-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${beveragesTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setBeveragesTooltipOpen(!beveragesTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.beverages-heading')}</p>
                        {beveragesTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {beveragesTooltipOpen && <MealPlanItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                               menu={menu} mealItems={beverages} mealItemType='beverages'/>}
                    {(itemsInArray(beverages) > 0 && !beveragesTooltipOpen) && <AddedMealItems mealItems={beverages} />}
                </div>
            </div>
            <div className='meal-plan-column'>
                <label>{t('meal-plans.create.meal-form.dessert-label')}</label>
                <div className='flex column gap-10'>
                    <div className={`meal-items-wrapper cursor ${dessertTooltipOpen ? 'openTooltip' : ''}`} onClick={() => setDessertTooltipOpen(!dessertTooltipOpen)}>
                        <p>{t('meal-plans.create.meal-form.dessert-heading')}</p>
                        {dessertTooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                    </div>
                    {dessertTooltipOpen && <MealPlanItemList onApplyMealItemsClicked={onApplyMealItemsClicked}
                                                             menu={menu} mealItems={desserts} mealItemType='dessert'/>}
                    {(itemsInArray(desserts) > 0 && !dessertTooltipOpen) && <AddedMealItems mealItems={desserts} />}
                </div>
            </div>
        </div>
    )
})

export default FixedMealPlanItems;
