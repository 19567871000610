import './FoodSafetyDetails.css';
import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useState} from "react";
import useCheckbox from "../../../../custom-hooks/useCheckbox";
import {useTranslation} from "react-i18next";
import {orange} from "@mui/material/colors";
import {ButtonState} from "../PersonalDetails/PersonalDetails";
import SavedProfileMessage from "../saved-profile-message/SavedProfileMessage";
import ErrorMessage from "../save-error-message/ErrorMessage";
import Chef from "../../../../model/Chef";
import ChefService from "../../../../core/services/ChefService";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setChef} from "../../../../core/redux/slices/chefSlice";
import store from "../../../../core/redux/store";
import {unwrapResult} from "@reduxjs/toolkit";

const FoodSafetyDetails = () => {

    const chef = useSelector(selectChef);
    const isJewelleryChecked = useCheckbox(true);
    const isWearUniformChecked = useCheckbox(false);
    const isKeepHairRestrainedChecked = useCheckbox(true);
    const isWearGlovesChecked = useCheckbox(true);
    const isCleanAndSanitizingChecked = useCheckbox(true);
    const isFoodContainerChecked = useCheckbox(true);
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const { t } = useTranslation('ChefDashboard');
    const [buttonState, setButtonState] = useState<ButtonState>('idle');
    const dispatch = useDispatch<typeof store.dispatch>();

    const customCheckboxProps = {
        '&.Mui-checked': {
            color: orange[300],
        },
    }

    const customLabelProps = {
        '& .MuiFormControlLabel-label': {
            '&.Mui-disabled': {
                color: 'black',
            },
        },
    }

    const handleEditFoodSafety = () => {
        setIsEditModeOn(true);
        setButtonState('save');
    }

    const resetButtonState = () => {
        setTimeout(()=>{
            setIsEditModeOn(false);
            setButtonState('idle');
        },2000)
    }

    const resetValues = () => {
        isJewelleryChecked.reset();
        isWearUniformChecked.reset();
        isKeepHairRestrainedChecked.reset();
        isWearGlovesChecked.reset();
        isCleanAndSanitizingChecked.reset();
        isFoodContainerChecked.reset();
    }

    const handleSavePersonalDetails = async () => {
        const chefToSave: Chef = {
            ...chef,
            foodSafetyPrecautions: {
                isRemoveJewellery: isJewelleryChecked.value,
                isCleaningAndSanitizing: isCleanAndSanitizingChecked.value,
                isKeepHairRestrained: isKeepHairRestrainedChecked.value,
                isWearGloves: isWearGlovesChecked.value,
                isWearUniform: isWearUniformChecked.value,
                hasFoodContainers: isFoodContainerChecked.value,
            }
        }

        try {
            const response = await dispatch(ChefService.saveFoodSafety(chefToSave));
            const savedFoodSafetyDetailsResponse = unwrapResult(response);
            dispatch(setChef(savedFoodSafetyDetailsResponse));
            setButtonState('saved');
        }catch (error){
            resetValues();
            setButtonState('error')
        }
        finally {
            resetButtonState();
        }
    }

    return (
        <div className='food-safety-container box-card-shadow'>
            <div className='food-safety-heading-container'>
                <h3>Food Safety</h3>
                {buttonState === 'idle' && <div className='edit-personal-details-button cursor' onClick={handleEditFoodSafety}>Edit food safety info</div>}
                {buttonState === 'save' && <div className='edit-personal-details-button cursor' onClick={handleSavePersonalDetails}>Save personal info</div>}
                {buttonState === 'saved' && <SavedProfileMessage />}
                {buttonState === 'error' && <ErrorMessage message='Oops! Something went wrong'/>}
            </div>
            <div className='checkbox-wrapper'>
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isJewelleryChecked.value} onChange={isJewelleryChecked.onChange}/>}
                                  label={t('remove-jewellery')}  sx={customLabelProps} />
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isWearUniformChecked.value} onChange={isWearUniformChecked.onChange}/>}
                                  label={t('wear-uniform')}  sx={customLabelProps}/>
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isKeepHairRestrainedChecked.value} onChange={isKeepHairRestrainedChecked.onChange}/>}
                                  label={t('keep-hair-restrained')}  sx={customLabelProps}/>
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isWearGlovesChecked.value} onChange={isWearGlovesChecked.onChange}/>}
                                  label={t('wear-gloves')}  sx={customLabelProps}/>
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isCleanAndSanitizingChecked.value} onChange={isCleanAndSanitizingChecked.onChange}/>}
                                  label={t('cleaning')}  sx={customLabelProps}/>
                <FormControlLabel control={<Checkbox disabled={!isEditModeOn} sx={customCheckboxProps} checked={isFoodContainerChecked.value} onChange={isFoodContainerChecked.onChange}/>}
                                  label={t('food-container')}  sx={customLabelProps}/>
            </div>
        </div>
    )
}

export default FoodSafetyDetails;
