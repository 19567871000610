import './SortingButtons.css';

import React, { useState } from 'react';
import './SortingButtons.css';

const SortingButtons = () => {
    const [active, setActive] = useState('TODAY');

    const buttons = ['TODAY', 'FUTURE ORDERS', 'YESTERDAY', 'ALL'];

    const handleButtonClick = (button: string) => {
        setActive(button);
    };

    return (
        <div className="button-container">
            {buttons.map((button) => (
                <button
                    key={button}
                    className={`sort-button ${active === button ? 'active' : ''}`}
                    onClick={() => handleButtonClick(button)}>
                    {button}
                </button>
            ))}
        </div>
    );
};

export default SortingButtons;
