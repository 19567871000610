import './FindAndHireChef.css';
import MeetChefs from "./meet-chefs/MeetChefs";
import HireChef from "./hire-chef/HireChef";

const FindAndHireChef = () => {

    return (
        <div className='find-and-hire-chef-wrapper'>
            <MeetChefs />
            <HireChef />
        </div>
    )
}

export default FindAndHireChef;
