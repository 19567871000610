import React from 'react';
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const CustomMUITooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: '450px',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.12)',
        fontSize: '0.875rem',
        padding: 0,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
    },
});

export const customCheckboxProps = {
    '&.Mui-checked': {
        color: '#f4c858',
    },
}

export const customLabelProps = {
    '& .MuiFormControlLabel-label': {
        '&.Mui-disabled': {
            color: 'black',
        },
    }
}

export const customChefManualAddressEnter = {
    '& .MuiFormControlLabel-label': {
        color: 'var(--sub-black)',
        fontSize: '14px'
    }
};

export default CustomMUITooltip;
