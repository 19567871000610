import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectChosenAddress} from "../../../core/redux/slices/profileManagementSlice";
import Chef from "../../../model/Chef";

type AvailableChefLocationProps = {
    chef?: Chef,
}

const AvailableChefLocation: React.FC<AvailableChefLocationProps> = ({chef}) => {

    const  customerLongitude = useSelector(selectChosenAddress)?.longitude;
    const  customerLatitude = useSelector(selectChosenAddress)?.latitude;
    const origin = `${customerLatitude},${customerLongitude}`;
    const destination = `${chef?.addresses[0].latitude},${chef?.addresses[0].longitude}`;
    const [travelTime, setTravelTime] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        const calculateTravelTime = () => {
            const service = new google.maps.DistanceMatrixService();
            service.getDistanceMatrix(
                {
                    origins: [{ lat: parseFloat(origin.split(',')[0]), lng: parseFloat(origin.split(',')[1]) }],
                    destinations: [{ lat: parseFloat(destination.split(',')[0]), lng: parseFloat(destination.split(',')[1]) }],
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                    if (status === google.maps.DistanceMatrixStatus.OK) {
                        const result = response?.rows[0].elements[0];
                        if (result?.status === 'OK') {
                            console.log(result);
                            setTravelTime(result?.duration.text.split(' ')[0]);
                            setError(null);
                        } else {
                            setError(`Error fetching travel time: ${result?.status}`);
                            setTravelTime(undefined);
                        }
                    } else {
                        setError(`Error fetching data from Distance Matrix API: ${status}`);
                        setTravelTime(undefined);
                    }
                }
            );
        };

        if (chef) {
            calculateTravelTime();
        }
    }, [])


    return (
        travelTime ?
            <div>
                <div className='flex column'>
                    <sub>
                        {travelTime}-{+travelTime+5} min
                    </sub>
                    <sub className='grey-light-text'>Pickup time</sub>
                </div>

            </div> : null
    )

};

export default AvailableChefLocation;
