// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hire-chef-button {
    padding: 16px;
    border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/find-and-hire-chef/hire-chef/HireChef.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".hire-chef-button {\n    padding: 16px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
