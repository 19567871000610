// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/login-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-wrapper {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
    background-size: cover;
    display: flex;
    padding-top: 30px;
    padding-bottom: 100px;
    justify-content: center;
}

.signup-container{
    padding: 30px ;
    border-radius: 16px;
    margin-top: 56px;
    width: 500px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
}

.signup-container .primary-button {
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 15px;
}

.signup-heading {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    font-family: "Roboto Slab", sans-serif;
}

.signup-loader {
    display: flex;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 600px){
    .signup-container {
        width: 90%;
        padding: 20px;
        margin-top: 0;
    }
    .signup-heading {
        font-size: 30px;
    }
}


`, "",{"version":3,"sources":["webpack://./src/pages/signup-page/SignupPage.css"],"names":[],"mappings":"AAAA;IACI,oEAA4E;IAC5E,sBAAsB;IACtB,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,wEAAwE;AAC5E;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,aAAa;QACb,aAAa;IACjB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".signup-wrapper {\n    background: url('../../assets/images/login-background.png') no-repeat center;\n    background-size: cover;\n    display: flex;\n    padding-top: 30px;\n    padding-bottom: 100px;\n    justify-content: center;\n}\n\n.signup-container{\n    padding: 30px ;\n    border-radius: 16px;\n    margin-top: 56px;\n    width: 500px;\n    background: white;\n    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;\n}\n\n.signup-container .primary-button {\n    margin-top: 20px;\n    margin-bottom: 15px;\n    padding: 15px;\n}\n\n.signup-heading {\n    font-size: 40px;\n    text-align: center;\n    font-weight: bold;\n    font-family: \"Roboto Slab\", sans-serif;\n}\n\n.signup-loader {\n    display: flex;\n    justify-content: center;\n    text-align: center;\n}\n\n@media screen and (max-width: 600px){\n    .signup-container {\n        width: 90%;\n        padding: 20px;\n        margin-top: 0;\n    }\n    .signup-heading {\n        font-size: 30px;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
