import './EmptyChefWrapper.css';
import emptyChefList from '../../../assets/images/empty-chef-list.png';

const EmptyChefWrapper = () => {

    return (
        <div className='empty-chef-wrapper'>
            <div className='empty-chef-details'>
                <p className='empty-chef-heading'>Coming soon to your area</p>
                <p className='empty-chef-description'>We're currently expanding our horizons! Although we're not delivering in your region just yet, we're working hard to bring our delicious offerings to your doorstep. Stay tuned, and thank you for your patience. We promise it'll be worth the wait!</p>
            </div>
            <div className='empty-chef-image-container'>
                <img src={emptyChefList} className='empty-chef-image' alt='empty-chefs'/>
            </div>

        </div>
    )
}

export default EmptyChefWrapper;
