import {useEffect, useState} from "react";
import {ButtonState} from "../pages/chef-dashboard/profile/PersonalDetails/PersonalDetails";

const useButton = (initialValue: ButtonState) => {

    const [state, setState] = useState<ButtonState>(initialValue || 'idle');

    useEffect(() => {
        if (state === 'saved') {
            setTimeout(() => {
                setState('idle');
            }, 2000)
        }
    }, [state])

    const resetButtonState = () => {
        setState('idle');
    }

    return {
        state,
        setState,
        resetButtonState,
    }
}


export default useButton;
