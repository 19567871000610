// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
    display: flex;
    padding: 50px 100px;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.error-page-image {
    height: 400px;
    width: 700px;
}

@media screen and (max-width: 600px){
    .error-page {
        padding: 30px 20px 50px 20px;
        text-align: center;
    }
    .error-page-image {
        width: 90%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/error-handling/error-page/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;QACI,4BAA4B;QAC5B,kBAAkB;IACtB;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".error-page {\n    display: flex;\n    padding: 50px 100px;\n    flex-direction: column;\n    gap: 10px;\n    justify-content: center;\n    align-items: center;\n}\n\n.error-page-image {\n    height: 400px;\n    width: 700px;\n}\n\n@media screen and (max-width: 600px){\n    .error-page {\n        padding: 30px 20px 50px 20px;\n        text-align: center;\n    }\n    .error-page-image {\n        width: 90%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
