import './Orders.css';
import {Icon} from "@iconify/react";
import SortingButtons from "./sorting-buttons/SortingButtons";
import EmptyReceivedOrders from "./empty-received-orders/EmptyReceivedOrders";
import OrderTable from "./order-table/OrderTable";
import FilterOrders from "./filter-orders/FilterOrders";

const Orders = () => {

    const orders = [1];

    const handleSortOrders = () => {

    }

    return (
        <div className='orders-container'>
            {
                orders.length !== 0 ?
                    <>
                        <div className='orders-heading-container'>
                            <div className='orders-heading'>
                                <h2>Orders</h2>
                                <div className='sort-container'>
                                    <FilterOrders />
                                    <div className='sort-input-container'>
                                        <Icon icon='iconoir:search' fontSize={20} className='normal-field'/>
                                        <input placeholder='Search by name' className='filter-orders-input'/>
                                    </div>
                                </div>
                            </div>
                            <SortingButtons/>
                        </div>
                        <OrderTable />
                    </> :
                    <EmptyReceivedOrders />
            }
        </div>
    )
}

export default Orders;
