import './CheckoutButton.css';

import {Icon} from "@iconify/react";
import React, {useState} from "react";
import CustomMUITooltip from "../../../custom-tooltips-dropdowns/CustomMUITooltip";
import {ClickAwayListener, IconButton} from "@mui/material";
import  emptyCartImage from '../../../assets/images/empty-checkout-cart.png';
import CheckoutItems from "./checkout-items/CheckoutItems";
import {useSelector} from "react-redux";
import {selectCartItemsCount} from "../../../core/redux/slices/customerSlice";
import {useLocation} from "react-router-dom";

const CheckoutButton = () => {


    const [tooltipOpen, setTooltipOpen] = useState(false);
    const cartItemsCount = useSelector(selectCartItemsCount);
    const isDashboardPath = useLocation().pathname.startsWith('/chef/dashboard');

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    }

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <CustomMUITooltip
            title={
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div className='checkout-container'>
                        {cartItemsCount ? <CheckoutItems closeTooltip={handleTooltipClose}/> :
                            <div className='no-checkout-container'>
                                <img src={emptyCartImage} className='no-checkout-image' alt='no-checkout-item'/>
                                <p>Add the items to the cart!</p>
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            }
            open={tooltipOpen}
            disableHoverListener={false}
            enterTouchDelay={0}
            placement="bottom-end"
            arrow>
            <IconButton onClick={toggleTooltip}>
                <div className='checkout-button'>
                    <Icon icon='mdi:cart' fontSize={24}/>
                    <p className={`cart-items-count ${isDashboardPath ? 'black-background' : ''}`}>
                        <sub>{cartItemsCount}</sub>
                    </p>
                </div>
            </IconButton>
        </CustomMUITooltip>
    )
}

export default CheckoutButton;

