import './OfferTypeCard.css';
import React from "react";
import {Icon} from "@iconify/react";
import {useTranslation} from "react-i18next";

type OfferTypeCardProps = {
    customizeOfferType: (offerType: string) => void,
    icon:string,
    offer: string,
    offerType: string,
    offerCustomizeSelected: boolean
}

const OfferTypeCard: React.FC<OfferTypeCardProps> = ({customizeOfferType, offer, icon, offerType, offerCustomizeSelected}) => {

    const { t } = useTranslation('ChefDashboard');

    const heading = t(`${offer}.heading`);
    const description = t(`${offer}.description`);

    return (
        <div className={`offer-type-card box-card-shadow ${offerCustomizeSelected ? 'selected' : ''}`}>
            <button className='customize-offer-button primary-button' onClick={() => customizeOfferType(offer)}>Customize</button>
            <div className={`icon-wrapper ${offerType}`}>
                <Icon icon={icon} className='offer-type-icon'/>
            </div>
            <h4>{heading}</h4>
            <p className='offer-description'>{description}</p>
        </div>
    )
}

export default OfferTypeCard;
