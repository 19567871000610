import './ProfilePhoto.css';
import React from "react";

type ProfilePhotoProps = {
    imageUrl: string,
    containerSize: string,
}

const ProfilePhoto: React.FC<ProfilePhotoProps>= ({imageUrl, containerSize}) => {

    return (
        <div className={`profile-photo-container ${containerSize === 'large' ? 'large' : 'small'}`}>
            <img src={imageUrl} alt='profile'/>
        </div>
    )
}

export default ProfilePhoto;
