import './EmptyReceivedOrders.css';
import EmptyOrders from '../../../../assets/images/no-chef-order-received.png';

const EmptyReceivedOrders = () => {
    return (
        <div className='empty-received-orders-container'>
            <div className='empty-received-orders-card'>
                <img src={EmptyOrders} alt='empty-order'/>
                <div className='empty-orders-body'>
                    <p>NO ORDER FOUND</p>
                    <p className='grey-light-text'>Looks like you haven't received any order yet</p>
                </div>
            </div>
        </div>


    )
}

export default EmptyReceivedOrders;
