// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-profile-photo {
    height: 50px;
    width: 50px;
    padding: 2px;
    background: gainsboro;
    border-radius: 50%;
    background-size: cover;
}

.chef-profile-row {
    display: flex;
    gap: 12px;
    justify-content: space-between;
}

.chef-profile-container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
    background: var(--primary-color);
}


.user-menu-options {
    text-align: center;
}

.chef-options > *:hover, .common-options > *:hover, .customer-options > *:hover {
    background: var(--primary-light-color);
    border-bottom: 1px solid var(--primary-color);
    cursor: pointer;
}

.chef-options > * , .common-options > *, .customer-options > *{
    padding: 20px;
    border-bottom: 1px solid gainsboro;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/menu-modal/user-menu-container/user-profile-container/UserProfileContainer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gCAAgC;AACpC;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,6CAA6C;IAC7C,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC","sourcesContent":[".chef-profile-photo {\n    height: 50px;\n    width: 50px;\n    padding: 2px;\n    background: gainsboro;\n    border-radius: 50%;\n    background-size: cover;\n}\n\n.chef-profile-row {\n    display: flex;\n    gap: 12px;\n    justify-content: space-between;\n}\n\n.chef-profile-container {\n    display: flex;\n    padding: 20px;\n    flex-direction: column;\n    gap: 20px;\n    background: var(--primary-color);\n}\n\n\n.user-menu-options {\n    text-align: center;\n}\n\n.chef-options > *:hover, .common-options > *:hover, .customer-options > *:hover {\n    background: var(--primary-light-color);\n    border-bottom: 1px solid var(--primary-color);\n    cursor: pointer;\n}\n\n.chef-options > * , .common-options > *, .customer-options > *{\n    padding: 20px;\n    border-bottom: 1px solid gainsboro;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
