import './WhyUsCard.css';
import React from "react";
import {Icon} from "@iconify/react";

interface cardProps {
    icon: string,
    heading: string,
    information: string
}
const WhyUsCard: React.FC<cardProps>= (props) => {
    return (
        <div className='card-container'>
            <div className='card-image-wrapper element-center'>
                <Icon icon={props.icon} fontSize={48} />
            </div>
            <h3>{props.heading}</h3>
            <div className='card-information grey-light-text'>{props.information}</div>
        </div>
    )
}

export default WhyUsCard;
