import './HomeStyle.css';
import ActivateLocation from "./activate-location/ActivateLocation";
import FindHomeChefs from "./find-chefs/FindHomeChefs";
import { useSelector } from "react-redux";
import AutoCompleteAddress from "../../components/autocomplete-address-search/AutoCompleteAddress";
import AddressSuggestions from "./address-suggestions/AddressSuggestions";
import GoogleMaps from "../../components/google-maps/GoogleMaps";
import { selectAddressSuggestions } from "../../core/redux/slices/addressSuggestionSlice";
import UserRoadmap from "./user-roadmap/UserRoadmap";
import BestChefs from "./best-chefs/BestChefs";
import MoreOptions from "./more-options/MoreOptions";
import FrequentlyAskedQuestionsList from "../../components/shared/frequently-asked-questions-list/FrequentlyAskedQuestionsList";
import {useTranslation} from "react-i18next";
import AddressSelected from "../../components/shared/address-selected/AddressSelected";
import FindAndHireChef from "./find-and-hire-chef/FindAndHireChef";
import {selectChosenAddress} from "../../core/redux/slices/profileManagementSlice";

const HomePage = () => {

    const isAddressSelected = useSelector(selectChosenAddress);
    const suggestions = useSelector(selectAddressSuggestions);
    const { t } = useTranslation('common');

    return (
        <div className='home-page'>
            <div className='home-landing flex-space-around'>
                <div className='address' id='user-address'>
                    <p className='heading-font-large' style={{marginBottom: '50px'}}>{t('landing-page-address-heading.part1')}<br/>{t('landing-page-address-heading.part2')}</p>
                    <div className='address-input-wrapper'>
                        <div className='address-column-1'>
                            <div className='address-row'>
                                {isAddressSelected ? <AddressSelected isEditable={true}/> : <AutoCompleteAddress />}
                                {!isAddressSelected && <ActivateLocation />}
                            </div>
                            <AddressSuggestions />
                        </div>
                        <FindHomeChefs />
                    </div>
                    {isAddressSelected && suggestions.length < 1 && <GoogleMaps />}
                </div>
            </div>
            <UserRoadmap />
            <FindAndHireChef />
            <BestChefs />
            <MoreOptions />
            <FrequentlyAskedQuestionsList />
        </div>
    )
}

export default HomePage;
