import './OfferTypeOverview.css';
import React, {useState} from "react";
import OfferTypeCard from "./OfferTypeCard/OfferTypeCard";

type OfferTypeOverviewProps = {
    customizeOfferType: (offerType: string) => void,
}

type offerType = {
    icon: string,
    offer: string,
    offerType: string
}

const OfferTypeOverview: React.FC<OfferTypeOverviewProps> = ({customizeOfferType}) => {

    const [offerCustomizeSelected, setOfferCustomizeSelected] = useState('');

    const offers: offerType[] = [
        {icon: "ic:outline-percentage", offer: 'percentage-based-offer', offerType: 'discount'},
        {icon: "ri:price-tag-3-line", offer: "bogof-offer", offerType: 'bogof'},
        {icon: "ph:gift-light", offer: "spend-and-get-free-offer", offerType: 'spend-and-get-free-offer'}
    ]

    const handleCustomizeOfferType = (offerType: string) => {
        setOfferCustomizeSelected(offerType);
        customizeOfferType(offerType);
    }

    return (
        <div className='offers-overview'>
            <div className='default-offers-list'>
                {offers.map((item, index) => (
                    <OfferTypeCard customizeOfferType={() => handleCustomizeOfferType(item.offerType)} icon={item.icon} key={index}
                                   offer={item.offer} offerType={item.offerType} offerCustomizeSelected={offerCustomizeSelected === item.offerType}/>
                ))}
            </div>
        </div>
    )
}

export default OfferTypeOverview;
