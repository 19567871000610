import './DeliveryDate.css';
import {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {Icon} from "@iconify/react";

const DeliveryDate = () => {

    const [maxDate, setMaxDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        const today = new Date();
        const twoWeeksFromNow = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
        setMaxDate(twoWeeksFromNow);
    }, []);

    return (
        <div className='delivery-date-container'>
            <h4>Delivery Date</h4>
            <div className='date-picker-container'>
                <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} minDate={startDate} maxDate={maxDate}></DatePicker>
                <Icon icon='carbon:calendar' fontSize={24} className='datepicker-icon'/>
            </div>
        </div>
    );
}

export default DeliveryDate;
