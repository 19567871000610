// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-cart-mobile {
    position: fixed;
    bottom: 20px;
    z-index: 100;
    width: 90vw;
    margin: 0 20px;
    border-radius: 24px;
    padding: 0 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: var(--primary-color);
}

.checkout-cart-mobile .checkout-cart-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.cart-items {
    background: #e3a403;
    width: 40px;
    height: 40px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.checkout-cart-modal {
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 2px 4px 8px rgb(7, 7, 7);
    overflow-y: auto;
    border-radius: 12px 12px 0 0;
    background: white;
}

.checkout-cart-modal:focus {
    outline: none;
}

.close-checkout-cart-icon {
    position: absolute;
    right: 20px;
    top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/checkout-cart/checkout-cart-mobile/CheckoutCartMobile.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,SAAS;IACT,oCAAoC;IACpC,gBAAgB;IAChB,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb","sourcesContent":[".checkout-cart-mobile {\n    position: fixed;\n    bottom: 20px;\n    z-index: 100;\n    width: 90vw;\n    margin: 0 20px;\n    border-radius: 24px;\n    padding: 0 10px;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    background: var(--primary-color);\n}\n\n.checkout-cart-mobile .checkout-cart-profile-image {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n}\n\n.cart-items {\n    background: #e3a403;\n    width: 40px;\n    height: 40px;\n    color: black;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n}\n\n.checkout-cart-modal {\n    width: 100%;\n    position: fixed;\n    bottom: 0;\n    box-shadow: 2px 4px 8px rgb(7, 7, 7);\n    overflow-y: auto;\n    border-radius: 12px 12px 0 0;\n    background: white;\n}\n\n.checkout-cart-modal:focus {\n    outline: none;\n}\n\n.close-checkout-cart-icon {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
