import './tip-buttons.css';
import {
    ButtonOption
} from "../../../available-chefs/delivery-options/filter-button/filter-chefs-modal/FilterChefsModal";
import React from "react";

type TipButtonsType = {
    onTipClick: (percentage: number) => void,
    tipOptionSelected: number,
}

const TipButtons: React.FC<TipButtonsType>= ({onTipClick, tipOptionSelected}) => {

    const tipButtons: ButtonOption[] = [{label: '10'}, {label: '15'}, {label: '20'}, {label: '25'}];

    return (
        <div className='tip-buttons-wrapper'>
            {tipButtons.map((button, index) => (
                <div key={index} className={`tip-button element-center cursor ${tipOptionSelected === +button.label ? 'tip-button-selected' : ''}`} role='button' onClick={() => onTipClick(+button.label)}>{button.label}%</div>
            ))}
        </div>
    )
}

export default TipButtons;
