import './UserRoadmapCard.css';

type props = {
    stepNumber: number,
    imageSource: string,
    heading: string,
    stepDescription: string,
}

const UserRoadmapCard = (props: props) => {
    return (
        <div className='user-roadmap-card'>
            <h2 className='user-roadmap-card-step-number'>{props.stepNumber}</h2>
            <div>
                <img src={props.imageSource} className='user-roadmap-card-image' alt='card'/>
            </div>
            <p className='card-heading'>{props.heading}</p>
            <span className='step-description'>{props.stepDescription}</span>
        </div>
    )
}

export default UserRoadmapCard;
