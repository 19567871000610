import './SocialMedia.css';
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setSocialMediaAccount} from "../../../../core/redux/slices/chefSlice";
import useFormInputWithoutValidation from "../../../../custom-hooks/useFormInputWithoutValidation";
import React, {useState} from "react";
import {ButtonState} from "../PersonalDetails/PersonalDetails";
import SavedProfileMessage from "../saved-profile-message/SavedProfileMessage";
import ErrorMessage from "../save-error-message/ErrorMessage";
import ChefService from "../../../../core/services/ChefService";

const SocialMedia = () => {

    const chef = useSelector(selectChef);
    const twitterAccount = useFormInputWithoutValidation(chef.socialNetwork?.twitter);
    const linkedinAccount = useFormInputWithoutValidation(chef.socialNetwork?.linkedin);
    const instagramAccount = useFormInputWithoutValidation(chef.socialNetwork?.instagram);
    const youtubeAccount =useFormInputWithoutValidation(chef.socialNetwork?.youtube);
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [buttonState, setButtonState] = useState<ButtonState>('idle');
    const dispatch = useDispatch();

    const handleEditSocialMedia = () => {
        setIsEditModeOn(true);
        setButtonState('save');
    }

    const resetButtonState = () => {
        setTimeout(()=>{
            setIsEditModeOn(false);
            setButtonState('idle');
        },2000)
    }

    const resetValues = () => {
        twitterAccount.reset();
        linkedinAccount.reset();
        youtubeAccount.reset();
        instagramAccount.reset();
    }

    const handleSaveSocialMedia = async () => {

        const socialMediaAccounts = {
            instagram: instagramAccount.value,
            twitter: twitterAccount.value,
            youtube: youtubeAccount.value,
            linkedin: linkedinAccount.value,
        }

        try {
            const response = await ChefService.saveSocialMediaAccounts(chef.id as string, socialMediaAccounts);

            if (response){
                setButtonState('saved');
                setIsEditModeOn(false);
                dispatch(setSocialMediaAccount(response));
            }

        } catch(error){
            resetValues();
            setButtonState('error');
        }finally {
            resetButtonState();
        }
    }

    return (
        <div className='social-media-wrapper box-card-shadow'>
            <div className='social-media-heading'>
                <h3>Social Accounts</h3>
                {buttonState === 'idle' && <div className='edit-personal-details-button cursor' onClick={handleEditSocialMedia}>Edit social info</div>}
                {buttonState === 'save' && <div className='edit-personal-details-button cursor' onClick={handleSaveSocialMedia}>Save social info</div>}
                {buttonState === 'saved' && <SavedProfileMessage />}
                {buttonState === 'error' && <ErrorMessage message='Oops! Something went wrong'/>}
            </div>
            <div className='social-media-form'>
                <div className='details-row'>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Twitter</div>
                        <input type='text' disabled={!isEditModeOn} value={twitterAccount.value} onChange={twitterAccount.onChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                    </div>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Linkedin</div>
                        <input type='text' disabled={!isEditModeOn} value={linkedinAccount.value} onChange={linkedinAccount.onChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                    </div>
                </div>
                <div className='details-row'>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Instagram</div>
                        <input type='text' disabled={!isEditModeOn} value={instagramAccount.value} onChange={instagramAccount.onChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                    </div>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Youtube</div>
                        <input type='text' disabled={!isEditModeOn} value={youtubeAccount.value} onChange={youtubeAccount.onChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialMedia;
