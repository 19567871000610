import './ChefCard.css';
import {Icon} from "@iconify/react";
import Chef from '../../../model/Chef';
import React from "react";
import useImageLoader from "../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";

interface ChefCardProps {
    chef: Chef;
}

const ChefCard: React.FC<ChefCardProps> = ({chef}) => {

    const { featuredDishName, name} = chef;
    const profileImageUrl = chef.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const featuredDishUrl = chef.featuredDishUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;

    const isProfileImageLoaded = useImageLoader(profileImageUrl);
    const isFoodImageLoaded = useImageLoader(featuredDishUrl);

    return (
        <div className='chef-card-wrapper'>
            {isFoodImageLoaded ? <img src={featuredDishUrl} alt='food' className='chef-card-food-image'/> :
                <Skeleton variant="rectangular" width="100%" height={200}/>}
            {chef.reviews &&
                <div className='like-icon-wrapper'>
                    <Icon icon='mdi:like' fontSize={24} style={{display: 'flex',alignItems: 'center'}}/>
                    <span>{chef.reviews && chef.reviews[1].rating}%</span>
                </div>
            }
            <h3 className='featured-dish-name'>{featuredDishName}</h3>
            <div className='chef-profile-wrapper'>
                {isProfileImageLoaded ? <img src={profileImageUrl} alt='profile' className='chef-card-profile-photo'/> :
                    <Skeleton variant="circular" width={80} height={80} />
                }
                <div className='chef-profile-information'>
                    <span>{name}</span>
                    <div className='chef-rating-section'>
                        <Icon icon='emojione:star' fontSize={20} />
                        <h4>{chef.reviews && chef.reviews[0].rating}</h4>
                        <span className='rating-light'>{chef.reviews && chef.reviews[0].review} reviews</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChefCard;
