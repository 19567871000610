import './AddNewCard.css';
import React, { useState } from "react";
import visaCardImage from '../../../../../assets/images/visa-card.png';
import {
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { StripeCardNumberElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardCvcElementChangeEvent } from "@stripe/stripe-js";
import useErrorMessage from "../../../../../custom-hooks/useErrorMessage";
import StripeService from "../../../../../core/services/StripeService";
import {useDispatch, useSelector} from "react-redux";
import {selectCustomer, setPaymentMethod} from "../../../../../core/redux/slices/customerSlice";
import {selectToken, selectUser} from "../../../../../core/redux/slices/loginSlice";
import {Checkbox, FormControlLabel} from "@mui/material";
import {customCheckboxProps, customLabelProps} from "../../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import useCheckbox from "../../../../../custom-hooks/useCheckbox";
import LoadingButton from "../../../../../components/shared/loader-button/LoadingButton";
import ErrorMessage from "../../../../chef-dashboard/profile/save-error-message/ErrorMessage";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../core/redux/store";
import {selectChef} from "../../../../../core/redux/slices/chefSlice";

type AddNewCardProps = {
    onCardAddition: () => void;
}

const AddNewCard: React.FC<AddNewCardProps> = ({onCardAddition}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardSaveError, setCardSaveError] = useErrorMessage(2000);
    const [nameOnCardError, setNameOnCardError] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvcError, setCvcError] = useState('');
    const [isCardSaveLoading, setIsCardSaveLoading] = useState(false);
    const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
    const [isExpiryComplete, setIsExpiryComplete] = useState(false);
    const [isCvcComplete, setIsCvcComplete] = useState(false);
    const isDefaultCardChecked = useCheckbox(false);
    const userType = useSelector(selectUser).userType;
    const dispatch = useDispatch();

    const customerId: string = useSelector((state: RootState) =>
        userType === 'chef' ? selectChef(state).id as string: selectCustomer(state).id as string
    );

    const token = useSelector(selectToken).accessToken;
    const {t} = useTranslation('common');

    const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
        setIsCardNumberComplete(event.complete);
        if (event.empty) {
            setCardNumberError('Enter the card number');
        } else if (event.error) {
            setCardNumberError('Enter the valid card number');
        }
        else {
            setCardNumberError('');
        }
    };

    const handleCardExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
        setIsExpiryComplete(event.complete);
        if (event.empty) {
            setExpiryError('Enter the expiry date');
        } else if (event.error) {
            setExpiryError('Incorrect expiry date');
        } else {
            setExpiryError('');
        }
    };

    const handleCardCvcChange = (event: StripeCardCvcElementChangeEvent) => {
        setIsCvcComplete(event.complete);
        if (event.empty) {
            setCvcError('Enter the CVC');
        } else if (event.error) {
            setCvcError('Incorrect CVC');
        } else {
            setCvcError('');
        }
    };

    const handleNameOnCardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameOnCard(event.target.value);
        setNameOnCardError('');
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsCardSaveLoading(true);

        if (!stripe || !elements) {

            setCardSaveError('Something went wrong, please try again');
            setIsCardSaveLoading(false);
            return;
        }

        let formValidated = true;

        const cardNumberElement = elements?.getElement(CardNumberElement);
        const cardExpiryElement = elements?.getElement(CardExpiryElement);
        const cardCvcElement = elements?.getElement(CardCvcElement);

        if (!isCardNumberComplete) {
            setCardNumberError('Card number is required');
            formValidated = false;
        }
        if (!isExpiryComplete) {
            setExpiryError('Expiry date is required');
            formValidated = false;
        }
        if (!isCvcComplete) {
            setCvcError('CVC is required');
            formValidated = false;
        }

        if (!nameOnCard) {
            setNameOnCardError('Enter the name on card');
            formValidated = false;
        }

        if (formValidated && cardNumberElement && cardExpiryElement && cardCvcElement) {

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    name: nameOnCard
                }
            });

            if (result.error) {
                setCardSaveError(t('api-error-message'));
                setIsCardSaveLoading(false);
                return;
            } else {
                const attachPaymentCardResponse = await StripeService.attachPaymentCard({
                    id: customerId as string,
                    token: token,
                    payLoad: {...result.paymentMethod, "isDefault": isDefaultCardChecked.value}
                })

                if ('message' in attachPaymentCardResponse) {
                    setIsCardSaveLoading(false);
                    setCardSaveError(t('api-error-message'));
                    return;
                }

                dispatch(setPaymentMethod(attachPaymentCardResponse));
                setIsCardSaveLoading(false);
                onCardAddition();
            }
        } else if (!formValidated) {
            setIsCardSaveLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='add-new-payment-card-form'>
            <div className='add-new-payment-card'>
                <label className='checkout-form-input-label'>
                    Name on card
                    <div className='checkout-form-input-wrapper'>
                        <input onChange={handleNameOnCardChange} value={nameOnCard} placeholder='Enter name on card'/>
                    </div>
                    {nameOnCardError && <div className='error-message payment-card-error'>Enter the name on card</div>}
                </label>
                <label className='checkout-form-input-label'>
                    Card number
                    <div className='visa-card-wrapper'>
                        <CardNumberElement id="cardNumber" onChange={handleCardNumberChange} />
                        <img src={visaCardImage} className='visa-card-image' alt='payment-card'/>
                    </div>
                    {cardNumberError && <div className='error-message payment-card-error'>{cardNumberError}</div>}
                </label>
                <div className='row'>
                    <label className='checkout-form-input-label'>
                        Expiry date
                        <div className='checkout-form-input-wrapper'>
                            <CardExpiryElement id="cardExpiry" onChange={handleCardExpiryChange} className='payment-card-input'/>
                        </div>
                        {expiryError && <div className='error-message payment-card-error'>{expiryError}</div>}
                    </label>
                    <label className='checkout-form-input-label'>
                        CVC
                        <div className='checkout-form-input-wrapper'>
                            <CardCvcElement id="cardCvc" onChange={handleCardCvcChange} className='payment-card-input'/>
                        </div>
                        {cvcError && <div className='error-message payment-card-error'>{cvcError}</div>}
                    </label>
                </div>
                <FormControlLabel control={<Checkbox checked={isDefaultCardChecked.value} sx={customCheckboxProps} onChange={isDefaultCardChecked.onChange}/>}
                                  label={'Use as default card'}  sx={customLabelProps} />
                <div>
                    {isCardSaveLoading ? <LoadingButton /> : <button className='primary-button add-payment-card-button' type='submit'>Add card</button>}
                </div>
            </div>
            {cardSaveError && <ErrorMessage message={cardSaveError} />}
        </form>
    );
};

export default AddNewCard;
