import React from 'react';
import { useTranslation } from 'react-i18next';
import '../LegalDocument.css';

type PrivacyPolicy = {
    title: string,
    text: string
}

const PrivacyPolicies: React.FC = () => {
    const { t } = useTranslation('PrivacyPolicy');
    const sections: PrivacyPolicy[] = t('sections', { returnObjects: true });

    return (
        <div className="legal-documents-container">
            <div className='element-center column legal-documents-heading'>
                <h1 className='heading-font-large'>{t('privacyPolicy')}</h1>
                <p className="last-updated">{t('lastUpdated')}</p>
            </div>

            {sections.map((section: any, index: number) => (
                <div className="section" key={index}>
                    <div className="content">
                        <h2>{index+1}. {section.title}</h2>
                        {Array.isArray(section.text) ? (
                            section.text.map((line: string, lineIndex: number) => (
                                <p key={lineIndex}>{line}</p>
                            ))
                        ) : (
                            <p>{section.text}</p>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PrivacyPolicies;
