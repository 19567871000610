import './FoodHandlineCertificateDetails.css';
import {Icon} from "@iconify/react";
import React, {useState} from "react";
import {selectChef, setFoodHandlingCertificateUrl} from "../../../../core/redux/slices/chefSlice";
import {useDispatch, useSelector} from "react-redux";
import ChefService from "../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../../core/redux/store";

const FoodHandlingCertificateDetails = () => {

    const chef = useSelector(selectChef);
    const [isCertificateMissing,setIsCertificateMissing] = useState(chef.foodHandlingCertificateProgress?.isMissing);
    const dispatch = useDispatch();
    const apiDispatch = useDispatch<typeof store.dispatch>();

    const handleCertificateUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("image", file)

            try {
                const savePhotoResponse = unwrapResult(await apiDispatch(ChefService.savePhoto({
                    image: formData,
                    imageType: 'foodHandlingCertificate',
                    id: chef.id
                })));

                dispatch(setFoodHandlingCertificateUrl(savePhotoResponse.foodHandlingCertificateProgress?.foodHandlingCertificateUrl as string));
                setIsCertificateMissing(false);
            }
            catch (error) {

            }
        }
    }

    return (
        <div className='food-handling-certificate-container box-card-shadow'>
            <div className='certificate-heading'>
                <h3>Food Handling Certificate</h3>
                <div className='upload-details'>
                    <div className='upload-due-date'>20 days left</div>
                    <label className='certificate-upload-button cursor'>
                        <Icon icon='material-symbols:add' fontSize={20} />
                        Upload
                        <input type='file' onChange={handleCertificateUpload} style={{display:"none"}} accept='.png,.jpg,.pdf'/>
                    </label>
                </div>
            </div>
            <div className='certificate-status-row'>
                <div className={`certificate-status-element ${isCertificateMissing ? 'not-validated' : 'validated-document'}`}>
                    <div className='success-icon-wrapper'><Icon icon='mdi:success' fontSize={24}/> </div>
                    {isCertificateMissing ? `Missing Certificate` :  'Certificate Uploaded'}
                </div>
                <div className={`certificate-status-element ${chef.foodHandlingCertificateProgress?.isVerifying ? 'validated-document' : 'not-validated'}`}>
                    <div className='success-icon-wrapper'><Icon icon='mdi:success' fontSize={24}/> </div>
                    Verifying
                </div>
                <div className={`certificate-status-element ${chef.foodHandlingCertificateProgress?.isVerified ? 'validated-document' : 'not-validated'}`}>
                    <div className='success-icon-wrapper'><Icon icon='mdi:success' fontSize={24}/> </div>
                    Verified
                </div>
            </div>
        </div>
    )
}

export default FoodHandlingCertificateDetails;
