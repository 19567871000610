import './ChefMenuItems.css';
import Chef from "../../../model/Chef";
import React from "react";
import ChefMenuItemCard from "./chef-menu-item-card/ChefMenuItemCard";
import MenuItem from "../../../model/MenuItem";

type ChefMenuItemsProps = {
    chef?: Chef,
    menuItemsRecord: Record<string, MenuItem[]>
    activeFoodType: string,
    refsMap: React.MutableRefObject<Record<string, HTMLDivElement | null>>;
}

const ChefMenuItems: React.FC<ChefMenuItemsProps> = ({chef, activeFoodType, menuItemsRecord, refsMap}) => {

    return (
        <div className='menu-items'>
            {Object.entries(menuItemsRecord)?.map(([foodType, menuItem], index) => (
                <div className='chef-menu-items-section' ref={el => (refsMap.current[foodType] = el)} key={index}>
                    <h3>{foodType}</h3>
                    <div className='chef-menu-items-container'>
                        {menuItem.map((menuItem, index) => (
                            <ChefMenuItemCard menuItem={menuItem} key={index} chef={chef as Chef}/>))
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ChefMenuItems;
