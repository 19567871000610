import './MoreOptions.css';
import {useTranslation} from "react-i18next";
import MeetChefButton from "../find-and-hire-chef/meet-chefs/meet-chef-button/MeetChefButton";
import React from "react";
import BecomeChefButton from "../../../components/shared/nav-bar/become-chef-button/BecomeChefButton";

const MoreOptions = () => {

    const { t } = useTranslation('MoreOptions');

    return (
        <div className='more-options-wrapper'>
            <div className='more-information'>
                <div className='heading-font-large text-center'>{t('heading')}</div>
                <span>{t('description')}</span>
            </div>
            <div className='more-information-links'>
                <MeetChefButton backgroundColor='white' />
                <BecomeChefButton backgroundColor='white' />
            </div>
        </div>
    )
}

export default MoreOptions;
