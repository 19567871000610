import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface MenuModalState {
    isModalOpen: boolean;
}

const initialState: MenuModalState = {
    isModalOpen : false
};

const menuModalSlice = createSlice({
    name: 'menuModal',
    initialState,
    reducers: {
        openMenuModal: (state) => {
            state.isModalOpen = true;
        },
        closeMenuModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openMenuModal, closeMenuModal } = menuModalSlice.actions;
export const selectIsMenuModalOpen = (state : RootState) => state.menuModal.isModalOpen;
export default menuModalSlice.reducer;
