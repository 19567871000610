import './BogofOfferForm.css';
import React, {useEffect, useState} from "react";
import MenuItem from "../../../../model/MenuItem";
import {Icon} from "@iconify/react";
import DatePicker from "react-datepicker";
import ChefService from "../../../../core/services/ChefService";
import {useDispatch, useSelector} from "react-redux";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import MenuItemCard from "../menu-item-checklist/MenuItemCard";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import {NavLink} from "react-router-dom";
import {ButtonState} from "../../profile/PersonalDetails/PersonalDetails";
import Bogof from "../../../../model/offers/Bogof";
import CustomMUITooltip from "../../../../custom-tooltips-dropdowns/CustomMUITooltip";
import {IconButton} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../../core/redux/store";

type BogofOfferFormProps = {
    isEditModeOn: boolean,
    offer?: Bogof,
    cancelOfferSelection: () => void,
}

const BogofOfferForm: React.FC<BogofOfferFormProps> = ({isEditModeOn, offer, cancelOfferSelection}) => {

    const [datesError, setDatesError] = useState(false);
    const todayDate = new Date();
    const [startDate, setStartDate] = useState(offer?.startDate || todayDate);
    const [expiryDate, setExpiryDate] = useState(offer?.endDate || todayDate);
    const id = useSelector(selectChef)?.id;
    const [menu, setMenu] = useState<MenuItem[]>();
    const [purchaseItemTooltip, setPurchaseItemTooltip] = useState(false);
    const [freeItemTooltip, setFreeItemTooltip] = useState(false);
    const [purchaseMenuItems, setPurchaseMenuItems] = useState(offer?.purchaseMenuItems || []);
    const [freeMenuItems, setFreeMenuItems] = useState(offer?.freeMenuItems || []);
    const [purchaseQuantity, setPurchaseQuantity] = useState(offer?.purchaseItemsQuantity || '');
    const [freeQuantity, setFreeQuantity] = useState(offer?.freeItemsQuantity || '');
    const [buttonState, setButtonState] = useState<ButtonState>('idle');
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const token = useSelector(selectToken).accessToken;

    const togglePurchaseTooltip = () => {
        setPurchaseItemTooltip(!purchaseItemTooltip);
    }

    const toggleFreeTooltip = () => {
        setFreeItemTooltip(!freeItemTooltip);
    }


    useEffect(  () => {

        const fetchMenu = async () => {
            try {
                const resultAction = await apiDispatch(ChefService.getMenu({
                     id: id as string
                }));
                const menu: MenuItem[] = unwrapResult(resultAction);
                setMenu(menu);
            } catch (error) {
                console.log(error);
            }
        }
        fetchMenu();
    },[])

    const handlePurchaseMenuItems = (purchaseMenuItem: string) => {

        setPurchaseMenuItems(prevItems =>
            prevItems.includes(purchaseMenuItem)
                ? prevItems.filter(item => item !== purchaseMenuItem)
                : [...prevItems, purchaseMenuItem]
        );
    }

    const handleFreeMenuItems = (freeMenuItem: string) => {

        if(freeMenuItems.includes(freeMenuItem)){
            setFreeMenuItems(freeMenuItems.filter(item => item !== freeMenuItem));
        }else {
            setFreeMenuItems([...freeMenuItems, freeMenuItem])
        }
    }

    const handlePurchaseQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPurchaseQuantity(event.target.value);
    }

    const handleFreeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFreeQuantity(event.target.value);
    }

    const handleSaveDiscountOffer = async () => {

        setButtonState('saving');

        const bogofOffer: Bogof = {
            purchaseItemsQuantity: purchaseQuantity,
            freeItemsQuantity: freeQuantity,
            freeMenuItems: freeMenuItems,
            purchaseMenuItems: purchaseMenuItems,
            startDate: startDate,
            endDate: expiryDate,
            id: isEditModeOn ? offer?.id as string : '',
        }

        try {
            isEditModeOn ? await ChefService.editOffer(id as string, bogofOffer, 'bogof')
                : await ChefService.createOffer(id as string, bogofOffer, 'bogof');

            setTimeout(()=>{
                setButtonState('idle');
                cancelOfferSelection();
            },1000)

        }catch(error){
            console.log('unable to save');
        }
    }

    return (
        <div className='discount-offer'>
            <div className='offer-heading'>
                <Icon icon='ion:chevron-back' fontSize={28} className='cursor back-to-added-items' onClick={cancelOfferSelection} />
                <h3>{isEditModeOn ? 'Edit offer' : 'Add offer'}</h3>
            </div>
            <div className='offer-form-content'>
                <div className='bogof-offer-row'>
                    <div className='bogof-column-wrapper'>
                        <div>
                            <div className='offer-heading-wrapper'>
                                <div className='discount-offer-label'>Purchase items quantity</div>
                                <CustomMUITooltip
                                    title={<p className='tooltip-label'>Number of items customer needs to buy to get the offer</p>}
                                    disableInteractive={false}
                                    enterTouchDelay={0}
                                    placement="bottom"
                                    sx={{
                                        '.MuiTooltip-tooltip': {
                                            borderRadius: '10px',
                                        },
                                    }}
                                    arrow>
                                    <IconButton>
                                        <Icon icon='ic:baseline-info' fontSize={24} className='offer-heading-info'/>
                                    </IconButton>
                                </CustomMUITooltip>
                            </div>
                            <div className='discount-change-input-wrapper'>
                                <input type='number' value={purchaseQuantity} onChange={handlePurchaseQuantity} className='discount-change-input'/>
                            </div>
                        </div>
                        <div>
                            <div className={`select-offer-items-wrapper cursor ${purchaseItemTooltip ? 'openTooltip' : ''}`} onClick={togglePurchaseTooltip}>
                                <p>Select the required items to purchase</p>
                                {purchaseItemTooltip ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                            </div>
                            {purchaseItemTooltip &&
                                <div className='discount-menu-items box-card-shadow'>
                                    {menu?.length && menu?.length > 0 ?
                                        <div className='menu-items-list'>
                                            {menu?.map((menuItem: MenuItem, index) => (
                                                <MenuItemCard menuItem={menuItem} key={index} handleSelectedMenuItem={handlePurchaseMenuItems} offerList={purchaseMenuItems}/>
                                            ))}
                                        </div> :
                                        <div className='empty-menu-items'>
                                            <p>Unable to find the menu items</p>
                                            <NavLink to='/chef/dashboard/menu'><button className='add-menu-button'>Add menu items</button></NavLink>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className='bogof-column-wrapper'>
                        <div>
                            <div className='offer-heading-wrapper'>
                                <div className='discount-offer-label'>Free items quantity</div>
                                <CustomMUITooltip
                                    title={<p className='tooltip-label'>Number of free items customer will get</p>}
                                    disableInteractive={false}
                                    enterTouchDelay={0}
                                    placement="bottom"
                                    sx={{
                                        '.MuiTooltip-tooltip': {
                                            borderRadius: '10px',
                                        },
                                    }}
                                    arrow>
                                    <IconButton>
                                        <Icon icon='ic:baseline-info' fontSize={24} className='offer-heading-info'/>
                                    </IconButton>
                                </CustomMUITooltip>

                            </div>
                            <div className='discount-change-input-wrapper'>
                                <input type='number' value={freeQuantity} onChange={handleFreeQuantity} className='discount-change-input'/>
                            </div>
                        </div>
                        <div>
                            <div className={`select-offer-items-wrapper cursor ${freeItemTooltip ? 'openTooltip' : ''}`} onClick={toggleFreeTooltip}>
                                <p>Select the free items</p>
                                {freeItemTooltip ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                            </div>
                            {freeItemTooltip &&
                                <div className='discount-menu-items box-card-shadow'>
                                    {menu?.length && menu?.length > 0 ?
                                        <div className='menu-items-list'>
                                            {menu?.map((menuItem: MenuItem, index) => (
                                                <MenuItemCard menuItem={menuItem} key={index} handleSelectedMenuItem={handleFreeMenuItems} offerList={freeMenuItems}/>
                                            ))}
                                        </div> :
                                        <div className='empty-menu-items'>
                                            <p>Unable to find the menu items</p>
                                            <NavLink to='/chef/dashboard/menu'><button className='add-menu-button'>Add menu items</button></NavLink>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='discount-offer-wrapper-row'>
                    <div>
                        <h4>Start Date</h4>
                        <div className='date-picker-container'>
                            <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} minDate={todayDate}></DatePicker>
                            <Icon icon='carbon:calendar' fontSize={24} className='datepicker-icon'/>
                        </div>
                    </div>
                    <div>
                        <h4>End Date</h4>
                        <div className='date-picker-container'>
                            <DatePicker selected={expiryDate} onChange={(date: Date) => setExpiryDate(date)} minDate={todayDate}></DatePicker>
                            <Icon icon='carbon:calendar' fontSize={24} className='datepicker-icon'/>
                        </div>
                        {datesError && <div className='error-message'>Dates are not correct</div>}
                    </div>
                </div>
                <div className='discount-offer-actions'>
                    {(!isEditModeOn) && <button className='primary-button apply-offer-button' disabled={buttonState !== 'idle'} onClick={handleSaveDiscountOffer}>Apply Offer</button>}
                    {isEditModeOn && <button className='primary-button' onClick={handleSaveDiscountOffer}>Save Changes</button>}
                    <button className='cancel-button cursor' onClick={cancelOfferSelection}>Cancel</button>
                </div>
            </div>

        </div>
    )
}

export default BogofOfferForm;
