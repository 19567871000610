import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './ErrorNotification.css';
import {selectResponseError} from "../../core/redux/slices/errorSlice";
import {useTranslation} from "react-i18next";

const ErrorNotification: React.FC = () => {
    const error = useSelector(selectResponseError);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {

        if (error.message) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [error]);

    return (
        error.message ?
            <div className={`error-notification ${isVisible ? 'slide-in' : 'slide-out'}`}>
                {t('common-error-message')}
            </div> : null
    );
};

export default ErrorNotification;
