import './BusinessDetails.css';
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setBusinessDetails} from "../../../../core/redux/slices/chefSlice";
import SavedProfileMessage from "../saved-profile-message/SavedProfileMessage";
import ErrorMessage from "../save-error-message/ErrorMessage";
import {ButtonState} from "../PersonalDetails/PersonalDetails";
import ChefService from "../../../../core/services/ChefService";
import useFormInput from "../../../../custom-hooks/useFormInput";
import {formatPhoneNumber, removePhoneNumberBrackets} from "../../../../core/utils/InputManipulation";
import {validateEmailSyntax, validatePhoneNumberSyntax} from "../../../../core/utils/Validation";
import {unwrapResult} from "@reduxjs/toolkit";

const BusinessDetails = () => {

    const chef = useSelector(selectChef);
    const businessName = useFormInput(chef.businessDetails?.businessName, true);
    const businessPhone = useFormInput(chef.businessDetails?.businessPhone, true);
    const businessEmail = useFormInput(chef.businessDetails?.businessEmail, true);
    const [businessPhoneError, setBusinessPhoneError] = useState('');
    const [businessEmailError, setBusinessEmailError] = useState('');
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const [buttonState, setButtonState] = useState<ButtonState>('idle');
    const dispatch = useDispatch();

    const handleEditBusinessDetails = () => {
        setIsEditModeOn(true);
        setButtonState('save');
    }

    const validateForm = () => {

        businessPhone.validate(businessPhone.value);
        businessName.validate(businessName.value);
        businessEmail.validate(businessEmail.value);

        return businessEmail.value && businessName.value && businessPhone.value;
    };

    const resetButtonState = () => {
        setTimeout(()=>{
            setIsEditModeOn(false);
            setButtonState('idle');
        },2000)
    }

    const resetValues = () => {
        businessPhone.reset();
        businessEmail.reset();
        businessName.reset();
    }

    const handleSaveBusinessDetails = async () => {

        if(!validateForm()){
            return;
        }

        if (!validatePhoneNumberSyntax(businessPhone.value) || !validateEmailSyntax(businessEmail.value)){
            if(!validatePhoneNumberSyntax(businessPhone.value)){
                setBusinessPhoneError('Please enter valid phone number');
            }

            if (!validateEmailSyntax(businessEmail.value)){
                setBusinessEmailError('Please enter valid business email');
            }
            return;
        }

        const businessDetails = {
                businessName: businessName.value,
                businessPhone: removePhoneNumberBrackets(businessPhone.value),
                businessEmail: businessEmail.value,
        }

        try {
            const response = await ChefService.saveBusinessInfo(chef.id as string, businessDetails);
            if (response){
                setButtonState('saved');
                setIsEditModeOn(false);
                dispatch(setBusinessDetails(response));
            }

        } catch(error){
            resetValues();
            setButtonState('error');
        }finally {
            resetButtonState();
        }
    }

    const handleBusinessPhoneChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        businessPhone.setValue(formatPhoneNumber(event.target.value));
        businessPhone.setIsMissing(false);
        setBusinessPhoneError('');
    }

    const handleBusinessEmailChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        businessEmail.onChange(event);
        setBusinessEmailError('');
    }

    return (
        <div className='business-details box-card-shadow' id='business-details'>
            <div className='business-heading-container'>
                <h3>Business info</h3>
                {buttonState === 'idle' && <div className='edit-personal-details-button cursor' onClick={handleEditBusinessDetails}>Edit business info</div>}
                {buttonState === 'save' && <div className='edit-personal-details-button cursor' onClick={handleSaveBusinessDetails}>Save business info</div>}
                {buttonState === 'saved' && <SavedProfileMessage />}
                {buttonState === 'error' && <ErrorMessage message='Oops! Something went wrong'/>}
            </div>
            <div className='business-details-wrapper'>
                <div className='business-details-form'>
                    <div className='details-row'>
                        <div>
                            <div className={`${isEditModeOn && 'detail-label'}`}>Business Name</div>
                            <input type='text' disabled={!isEditModeOn} value={businessName.value} onChange={businessName.onChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                            {businessName.isMissing && <div className='error-message'>This field is required</div>}
                        </div>
                    </div>
                    <div className='details-row'>
                        <div>
                            <div className={`${isEditModeOn && 'detail-label'}`}>Business Phone</div>
                            <input type='tel' disabled={!isEditModeOn} value={businessPhone.value} onChange={handleBusinessPhoneChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`} maxLength={14}/>
                            {businessPhone.isMissing && <div className='error-message'>This field is required</div>}
                            {businessPhoneError && <div className='error-message'>{businessPhoneError}</div>}
                        </div>
                        <div>
                            <div className={`${isEditModeOn && 'detail-label'}`}>Business Email</div>
                            <input type='email' disabled={!isEditModeOn} value={businessEmail.value} onChange={handleBusinessEmailChange} className={`details-input ${isEditModeOn ? 'input-editing': 'input-hidden'}`}/>
                            {businessEmail.isMissing && <div className='error-message'>This field is required</div>}
                            {businessEmailError && <div className='error-message'>{businessEmailError}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessDetails;
