// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button-right-end-wrapper {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.cancel-meal-button {
    padding: 0 30px;
}

.apply-meal-items-button {
    margin-top: 10px;
    width: 200px;
}

@media screen and (max-width: 600px) {
    .primary-button-right-end-wrapper {
        justify-content: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/meal-plan-items/meal-plan-item-list/MealPlanItemList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".primary-button-right-end-wrapper {\n    display: flex;\n    gap: 20px;\n    justify-content: flex-end;\n}\n\n.cancel-meal-button {\n    padding: 0 30px;\n}\n\n.apply-meal-items-button {\n    margin-top: 10px;\n    width: 200px;\n}\n\n@media screen and (max-width: 600px) {\n    .primary-button-right-end-wrapper {\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
