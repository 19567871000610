import './ManageMeals.css';
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setMeals} from "../../../../core/redux/slices/chefSlice";
import React, {useState} from "react";
import FixedMealCard from "./meal-card/fixed-meal-card/FixedMealCard";
import FixedMeal from "../../../../model/meal-plans/FixedMeal";
import useModal from "../../../../custom-hooks/useModal";
import Modal from "react-modal";
import {customStyles} from "../../../chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import ChefService from "../../../../core/services/ChefService";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../../core/redux/store";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import useButton from "../../../../custom-hooks/useButton";
import PrimaryButtonLoader from "../../../../components/shared/loaders/primary-button-loader/PrimaryButtonLoader";
import SuccessButton from "../../../../components/shared/buttons/SuccessButton";
import {Meals} from "../../../../model/Chef";
import CustomizableMealCard from "./meal-card/customizable-meal-card/CustomizableMealCard";
import CustomizableMeal from "../../../../model/meal-plans/CustomizableMeal";
import {MealType} from "../create-meal-plan/meal-plan-form/MealPlanForm";

type ManageMealsProps = {
    editMealItem: (mealItem: FixedMeal | CustomizableMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => void;
}

const ManageMeals: React.FC<ManageMealsProps> = ({editMealItem}) => {

    const meals = useSelector(selectChef).meals;
    const id = useSelector(selectChef).id;
    const token = useSelector(selectToken).accessToken;
    const deleteModal = useModal();
    const dispatch = useDispatch<typeof store.dispatch>();
    const [itemToDelete, setItemToDelete] = useState<FixedMeal | CustomizableMeal | undefined>(undefined);
    const [mealType, setMealType] = useState<MealType>(MealType.FIXED);
    const loadingResponse = useButton('idle');
    const [activeFilter, setActiveFilter] = useState('All');


    const allFilters = () => {
        let filters: string[] = [];

        filters = [...filters, 'All'];

        if (meals?.fixed && meals?.fixed.length > 0){
            filters = [...filters, 'Fixed'];
        }

        if (meals?.customizable && meals?.customizable.length > 0){
            filters = [...filters, 'Customizable'];
        }
        return filters;
    }

    const handleDeleteMealItemConfirmation = (mealItem: FixedMeal | CustomizableMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => {
        event.stopPropagation();
        setItemToDelete(mealItem);
        setMealType(mealType);
        deleteModal.openModal();
    }

    const changeFilter = (filter: string) => {
        setActiveFilter(filter);
    }

    const handleDeleteMeal = async () => {
        try {
            const resultAction = await dispatch(ChefService.deleteMeal({id: id as string, token, meal: itemToDelete as FixedMeal | CustomizableMeal, mealType: mealType}));
            const meals: Meals = unwrapResult(resultAction);
            dispatch(setMeals(meals));
            loadingResponse.setState('saved');
            deleteModal.closeModal();
        } catch (error: any) {
            console.log(error.message);
            loadingResponse.setState('idle');
        }
    }

    const deleteModalContent = () => {

        return (
            <div className='flex column gap-10 text-center delete-meal-item-modal'>
                <div className='roboto-slab delete-menu-item-heading'>Delete Meal</div>
                <p className='delete-item-confirmation-text'>Are you sure to delete your {itemToDelete?.title} meal. If you delete it this will not be restored</p>
                <div className='delete-menu-item-button'>
                    {loadingResponse.state === 'idle' &&
                        <>
                            <div className='light-grey-button' onClick={deleteModal.closeModal}>Cancel</div>
                            <div className='primary-button' onClick={handleDeleteMeal}>Delete</div>
                        </>
                    }
                    {loadingResponse.state === 'loading' && <PrimaryButtonLoader className='primary-button' />}
                    {loadingResponse.state === 'saved' && <SuccessButton message='Deleted' className='primary-button'/>}
                </div>
            </div>
        )
    }

    const filteredMeals = () => {
        if (activeFilter === 'All') {
            return (
                <>
                    {meals?.fixed?.map((fixedMeal, index) => (
                        <FixedMealCard key={index} fixedMeal={fixedMeal} editMealItem={editMealItem} deleteMealItem={handleDeleteMealItemConfirmation} />
                    ))}
                    {meals?.customizable?.map((customizableMeal, index) => (
                        <CustomizableMealCard key={index} customizableMeal={customizableMeal} editMealItem={editMealItem} deleteMealItem={handleDeleteMealItemConfirmation} />
                    ))}
                </>
            );
        } else if (activeFilter === 'Fixed') {
            return (
                <>
                    {meals?.fixed?.map((fixedMeal, index) => (
                        <FixedMealCard key={index} fixedMeal={fixedMeal} editMealItem={editMealItem} deleteMealItem={handleDeleteMealItemConfirmation} />
                    ))}
                </>
            );
        } else if (activeFilter === 'Customizable') {
            return (
                <>
                    {meals?.customizable?.map((customizableMeal, index) => (
                        <CustomizableMealCard key={index} customizableMeal={customizableMeal} editMealItem={editMealItem} deleteMealItem={handleDeleteMealItemConfirmation} />
                    ))}
                </>
            );
        }
    };

    return (
        <div>
            <div className='category-filter-buttons box-card-shadow'>
                {allFilters().map((filter, index) => (
                    <div className={`category-filter-button cursor ${activeFilter === filter ? 'current-filter' : ''}`}
                         key={index} onClick={() => changeFilter(filter)}>
                        {filter}
                    </div>
                ))}
            </div>
            <div className='added-items-container'>
                <div className='added-item-list'>
                    {filteredMeals()}
                </div>
                <Modal className='modal delete-item-modal' isOpen={deleteModal.isOpen} style={customStyles} children={deleteModalContent()}/>
            </div>
        </div>
    )
}

export default ManageMeals;
