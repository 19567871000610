// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/contact-us-page-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us-page {
    width: 100vw;
}

.contact-us-bottom-container {
    height: 100vh;
}

.contact-form-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 100px 400px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.contact-form-details > div, .contact-form-details > form {
    flex: 1 1;
}

.contact-details > div {
    background: white;
    border: 1px solid black;
}

.contact-details {
    display: flex;
    gap: 30px;
    padding: 50px;
    background: rgba(245, 245, 245, 0.29);
    justify-content: center;
}

.contact-details > div {
    padding: 20px;
    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    .contact-form-details {
        padding: 50px 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/contact-us-page/ContactUsPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;IACpB,mDAAqE;AACzE;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,aAAa;IACb,qCAAqC;IACrC,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".contact-us-page {\n    width: 100vw;\n}\n\n.contact-us-bottom-container {\n    height: 100vh;\n}\n\n.contact-form-details {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding: 100px 400px;\n    background: url(\"../../assets/images/contact-us-page-background.png\");\n}\n\n.contact-form-details > div, .contact-form-details > form {\n    flex: 1;\n}\n\n.contact-details > div {\n    background: white;\n    border: 1px solid black;\n}\n\n.contact-details {\n    display: flex;\n    gap: 30px;\n    padding: 50px;\n    background: rgba(245, 245, 245, 0.29);\n    justify-content: center;\n}\n\n.contact-details > div {\n    padding: 20px;\n    border-radius: 8px;\n}\n\n@media screen and (max-width: 600px) {\n    .contact-form-details {\n        padding: 50px 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
