import './BogofOffersCarousel.css';
import Chef from "../../../../model/Chef";
import {useSelector} from "react-redux";
import {selectAvailableChefs} from "../../../../core/redux/slices/chefListSlice";
import React, {useRef} from "react";
import Carousel from "react-multi-carousel";
import {CustomArrowProps} from "../../cuisines-carousel/CuisineCarousel";
import {Icon} from "@iconify/react";
import {AvailableChefsResponsiveness} from "../../../../components/custom-components/CustomizeCarousel";
import AvailableChefCard from "../../available-chef-card/AvailableChefCard";

const BogofOffersCarousel = () => {

    const chefs: Chef[] = useSelector(selectAvailableChefs);

    const carouselRef = useRef<Carousel>(null);

    const handleNext = () => {
        carouselRef?.current?.next(1);
    };

    const handlePrev = () => {
        carouselRef?.current?.previous(1);
    };

    const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
        <button onClick={handleNext} className="discount-offer-button offer-right-button">
            <Icon icon='material-symbols:keyboard-arrow-right' fontSize={24} />
        </button>
    );

    const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
        <button onClick={handlePrev} className="discount-offer-button">
            <Icon icon='material-symbols:keyboard-arrow-left' fontSize={24} />
        </button>
    );

    return (
        <div>
            <div className='discount-offers-heading'>
                <p>Today Deals</p>
                <div className='discount-offer-buttons'>
                    <CustomLeftArrow />
                    <CustomRightArrow />
                </div>
            </div>
            <Carousel
                className='discount-offer-carousel'
                ref={carouselRef}
                responsive={AvailableChefsResponsiveness}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={false}
                infinite={true}
                partialVisible={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}>
                {chefs.map((chef, index) => {
                    return (
                        <div key={index} className='cuisine-filter-list'>
                            <AvailableChefCard {...chef} key={index}/>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
}

export default BogofOffersCarousel;
