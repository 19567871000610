
export const validateEmailSyntax = (email: string): boolean => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}

export const validatePhoneNumberSyntax = (phoneNumber: string): boolean => {
    const regex = /^(\(?(?:[2-9][0-9]{2})\)?[- ]?[2-9][0-9]{2}[- ]?[0-9]{4})$/;
    return regex.test(phoneNumber);
};

export const validatePostalCodeSyntax = (postalCode: string): boolean => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    return regex.test(postalCode.toUpperCase());
};

export const validateProvince = (province: string): boolean => {

    const provinces = ["AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "QC", "SK", "NT", "NU", "YT"];

    return provinces.includes(province);
}

export function validatePassword(password: string) {
    const length = password.length >= 8;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return {
        length,
        uppercase,
        lowercase,
        number,
        specialChar,
        isValid: length && uppercase && lowercase && number && specialChar,
    };
}

