// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-chef-wrapper {
   display: flex;
    height: auto;
    align-items: center;
    margin: 0 100px 100px 100px;
    gap: 30px;
    flex-direction: row;
}

.empty-chef-wrapper > div{
    flex: 1 1;
}

.empty-chef-image-container {
    position: relative;
}

.empty-chef-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.empty-chef-heading {
    font-family: "Noto Serif", serif;
    font-weight: 600;
    font-size: 50px;
    font-style: italic;
}

.empty-chef-details {
    display: flex;
    gap: 20px;
    padding: 20px;
    flex-direction: column;
}

.empty-chef-description {
    color: #888888;
    font-family: "Roboto Slab", sans-serif;
}

@media screen and (max-width: 600px) {
    .empty-chef-heading {
        font-size: 35px;
    }
    .empty-chef-wrapper {
        margin: 20px;
        gap: 0;
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/available-chefs/empty-chef-wrapper/EmptyChefWrapper.css"],"names":[],"mappings":"AAAA;GACG,aAAa;IACZ,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,sCAAsC;AAC1C;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;QACZ,MAAM;QACN,sBAAsB;IAC1B;AACJ","sourcesContent":[".empty-chef-wrapper {\n   display: flex;\n    height: auto;\n    align-items: center;\n    margin: 0 100px 100px 100px;\n    gap: 30px;\n    flex-direction: row;\n}\n\n.empty-chef-wrapper > div{\n    flex: 1;\n}\n\n.empty-chef-image-container {\n    position: relative;\n}\n\n.empty-chef-image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.empty-chef-heading {\n    font-family: \"Noto Serif\", serif;\n    font-weight: 600;\n    font-size: 50px;\n    font-style: italic;\n}\n\n.empty-chef-details {\n    display: flex;\n    gap: 20px;\n    padding: 20px;\n    flex-direction: column;\n}\n\n.empty-chef-description {\n    color: #888888;\n    font-family: \"Roboto Slab\", sans-serif;\n}\n\n@media screen and (max-width: 600px) {\n    .empty-chef-heading {\n        font-size: 35px;\n    }\n    .empty-chef-wrapper {\n        margin: 20px;\n        gap: 0;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
