// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-details h3 {
    font-weight: lighter;
}

.customer-details {
    max-width: 300px;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 40px;
    row-gap: 20px;
}

.customer-details-row {
    display: contents;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/order-preview-modal/customer-details/CustomerDetails.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,+BAA+B;IAC/B,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".customer-details h3 {\n    font-weight: lighter;\n}\n\n.customer-details {\n    max-width: 300px;\n    display: grid;\n    grid-template-columns: auto 1fr;\n    column-gap: 40px;\n    row-gap: 20px;\n}\n\n.customer-details-row {\n    display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
