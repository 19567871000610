import './ChefMenuItemCard.css';
import MenuItem from "../../../../model/MenuItem";
import React from "react";
import {Card, CardContent, Skeleton} from "@mui/material";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, decreaseItemCountFromCart, selectCheckoutCart, selectItemCount, setSelectedChef} from "../../../../core/redux/slices/customerSlice";
import {RootState} from "../../../../core/redux/store";
import Chef from "../../../../model/Chef";
import useImageLoader from "../../../../custom-hooks/useImageLoader";
import MenuItemQuickViewModal from "../../../../components/shared/menu-item-quick-view/MenuItemQuickViewModal";
import useModal from "../../../../custom-hooks/useModal";
import CreateNewOrderModal from "../../create-new-order-modal/CreateNewOrderModal";

type ChefMenuItemCardProps = {
    menuItem: MenuItem,
    chef: Chef,
}

export const customStyles = {
    overlay: {
        backgroundColor: 'rgba(38, 38, 38, 0.8)',
    },
};

const ChefMenuItemCard: React.FC<ChefMenuItemCardProps> = ({menuItem, chef}) => {

    const menuItemImageUrl = menuItem.imageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const dispatch = useDispatch();
    const checkoutCart = useSelector(selectCheckoutCart);
    const createNewOrderModal = useModal();
    const itemCount = useSelector((state: RootState) => selectItemCount(state,menuItem.id as string));
    const imageUrl = menuItem.imageUrl + '?' + process.env.REACT_APP_BLOB_SAS_TOKEN;
    const isImageLoaded = useImageLoader(imageUrl);
    const menuItemQuickViewModal = useModal();

    const addItemToCart = (event: React.MouseEvent<HTMLParagraphElement>) => {

        event.stopPropagation();
        if(!!checkoutCart.chef && chef.id !== checkoutCart.chef?.id){
            createNewOrderModal.openModal();
            return;
        }
        else if (itemCount === 0){
            setChef();
        }
        dispatch(addToCart({menuItem: menuItem, count: 1, specialInstructions: ''}));
    }

    const setChef = () => {
        dispatch(setSelectedChef({name: chef.name, id: chef.id as string, profileImageUrl: chef.profileImageUrl as string}))
    }

    const decreaseItemCount = (event: React.MouseEvent<HTMLParagraphElement>) => {
        event.stopPropagation();

        if (itemCount === 1){
            dispatch(setSelectedChef(undefined));
        }
        dispatch(decreaseItemCountFromCart(menuItem.id as string));
    }



    return (
        <>
            <div className='chef-menu-item-card cursor' onClick={menuItemQuickViewModal.openModal}>
                {isImageLoaded ?
                    <img src={menuItemImageUrl} alt='menu item' /> :
                    <Card>
                        <Skeleton variant="rectangular" width="100%" height={200}/>
                        <CardContent>
                            <Skeleton width="60%"/>
                            <Skeleton width="80%"/>
                            <Skeleton width="40%"/>
                        </CardContent>
                    </Card>
                }
                <div className='menu-item-card-details'>
                    <p className='menu-item-heading'>{menuItem.name}</p>
                    <div className='description'>{menuItem.description}</div>
                    <div className='price-row'>
                        <p>$ {menuItem.price}</p>
                        <div>
                            {itemCount === 0 ?
                                <div className='add-cart-item-button' onClick={(event) => addItemToCart(event)}>
                                    <p>Add</p>
                                    <Icon icon='ic:outline-add' className='menu-item-card-icon'/>
                                </div> :
                                <div className='add-cart-item-action-buttons'>
                                    <p className='menu-item-card-remove-icon' onClick={(event) => decreaseItemCount(event)}>
                                        <Icon icon={itemCount === 1 ? 'ic:round-delete' : 'ic:baseline-minus'}  fontSize={24} />
                                    </p>
                                    <p>{itemCount}</p>
                                    <p className='menu-item-card-add-icon' onClick={(event) => addItemToCart(event)}>
                                        <Icon icon='ic:outline-add' fontSize={24}/>
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <CreateNewOrderModal isCreateNewOrderModalOpen={createNewOrderModal.isOpen} closeModal={createNewOrderModal.closeModal}
                                 setChef={setChef} cartItem={{menuItem: menuItem, count: 1, specialInstructions: ''}} chef={chef} />
            <MenuItemQuickViewModal isMenuItemQuickViewModalOpen={menuItemQuickViewModal.isOpen}
                                    closeMenuItemQuickViewModal={menuItemQuickViewModal.closeModal} menuItem={menuItem} chef={chef}/>
        </>
    )
}

export default ChefMenuItemCard;
