import './SignupButtonStyle.css'
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";

const SignupButton = () => {

    const navigateTo = useNavigate();

    return (
        <div className='signup-button' onClick={() => navigateTo('/signup')}>
            <Icon icon='ri:user-add-line' fontSize={20} />
            <span>Sign Up</span>
        </div>
    )
}

export default SignupButton;
