import React, {useState, useEffect, useRef} from 'react';
import './GoogleMapsStyle.css'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import { useSelector } from "react-redux";
import {selectChosenAddress} from "../../core/redux/slices/profileManagementSlice";

function GoogleMaps() {
    const apiKey: any = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE;
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: apiKey, libraries: ['places'],
    });

    return (
        <div>
            {isLoaded ? <ShowMaps /> : <div>Unable to google maps api</div>}
        </div>)
}

const ShowMaps = () => {

    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>({lat:44,lng:-80});
    const userAddress = useSelector(selectChosenAddress);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const mapOptions = {
        mapTypeControl: false, zoomControl: false,
        fullscreenControl: false, streetViewControl: false,
        mapTypeControlOptions: {
            mapTypeIds: [],
        },
    };

    useEffect(()=> {
        const lat = userAddress?.latitude || 44;
        const lng = userAddress?.longitude || -80;
        setMarkerPosition({lat,lng});
    }, [userAddress]);

    return (
        <div style={{display: 'flex', gap: '56px', position: 'relative'}} ref={elementRef} className='google-map'>
            <GoogleMap center={markerPosition} zoom={10} mapContainerStyle={{height: '300px', width: '900px',
                marginTop: '16px', borderRadius: '12px'}} options={mapOptions}>
                {userAddress && <MarkerF position={markerPosition}/>}
            </GoogleMap>
        </div>
    )

}

export default GoogleMaps;
