// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offers-overview {
    padding: 20px;
    border-radius: 10px;
}

.default-offers-list {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

@media screen and (max-width: 600px) {
    .default-offers-list {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/offers-page/OfferTypesOverview/OfferTypeOverview.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".offers-overview {\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.default-offers-list {\n    display: flex;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .default-offers-list {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
