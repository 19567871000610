// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-button{
    padding: 8px;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .signup-button {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/nav-bar/signup-button/SignupButtonStyle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".signup-button{\n    padding: 8px;\n    border-radius: 24px;\n    cursor: pointer;\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n@media only screen and (max-width: 600px) {\n    .signup-button {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
