// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-list {
    display: flex;
    padding-top: 20px;
    max-height: 400px;
    overflow: auto;
    flex-direction: column;
}

.address-card {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 10px;
}

.address-card:hover {
    cursor: pointer;
    background: var(--grey2);
}

.address-card:not(:last-child) {
    border-bottom: 1px solid var(--border-light);
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/delivery-details-step/edit-address-modal/address-list/AddressList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,4CAA4C;AAChD","sourcesContent":[".address-list {\n    display: flex;\n    padding-top: 20px;\n    max-height: 400px;\n    overflow: auto;\n    flex-direction: column;\n}\n\n.address-card {\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    padding: 20px 10px;\n}\n\n.address-card:hover {\n    cursor: pointer;\n    background: var(--grey2);\n}\n\n.address-card:not(:last-child) {\n    border-bottom: 1px solid var(--border-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
