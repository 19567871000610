import Credentials from "../../model/Credentials";
import Chef, {Meals} from "../../model/Chef";
import { createAsyncThunk } from "@reduxjs/toolkit";
import menuItem from "../../model/MenuItem";
import {setChef, setFoodCategories, setMenu} from "../redux/slices/chefSlice";
import DiscountOffer from "../../model/offers/DiscountOffer";
import Offers from "../../model/offers/Offers";
import Bogof from "../../model/offers/Bogof";
import SpendAndGetFreeOffer from "../../model/offers/SpendAndGetFreeOffer";
import {ResponseError} from "./CustomerService";
import ConnectStripeLinkResponse from "../../model/ConnectStripeLinkResponse";
import {DayAvailability} from "../../model/Schedule";
import protectedApiClient from "./ProtectedApiClient";
import {setError} from "../redux/slices/errorSlice";
import MenuItem from "../../model/MenuItem";
import FixedMeal from "../../model/meal-plans/FixedMeal";
import CustomizableMeal from "../../model/meal-plans/CustomizableMeal";
import {MealType} from "../../pages/chef-dashboard/meal-plans/create-meal-plan/meal-plan-form/MealPlanForm";

export interface ResponseDTO<T> {
    data: T;
    status: string;
}


class ChefService {


    static save = createAsyncThunk<Chef, Chef, { rejectValue: ResponseError }>(
        'chefSave',
        async (chef, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Chef> = await protectedApiClient('/chef/dashboard/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(chef),
                });

                if(response.status === 'OK') {
                    dispatch(setChef(response.data));

                    return response.data;
                } else {
                    return rejectWithValue({message: 'Unable to save'} as ResponseError);
                }

            } catch (error) {
                return rejectWithValue({message: 'Unable to save'} as ResponseError);
            }
        }
    );

    static savePersonalDetail = createAsyncThunk<Chef, Chef, { rejectValue: ResponseError }>(
        'chefPersonalInformation',
        async (chef, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Chef> = await protectedApiClient(
                    `/chef/dashboard/personal-detail`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(chef),
                    }
                );
                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static saveFoodSafety = createAsyncThunk<Chef, Chef, { rejectValue: ResponseError }>(
        'chefFoodSafety',
        async (chef, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Chef> = await protectedApiClient(
                    `/chef/dashboard/food-safety`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(chef),
                    }
                );
                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static saveBusinessInfo = async (userId: string, businessDetails: {
        businessName: string,
        businessEmail: string,
        businessPhone: string
    }) => {

        const saveBusinessInfoResponse = await protectedApiClient(`/chef/dashboard/saveBusinessInfo?userId=${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    businessName: businessDetails.businessName,
                    businessEmail: businessDetails.businessEmail,
                    businessPhone: businessDetails.businessPhone
                })
            }
        );

        if (saveBusinessInfoResponse.status === 'OK') {
            return await saveBusinessInfoResponse.data;
        } else {
            throw new Error('Unable to save business info');
        }
    }

    static saveSocialMediaAccounts = async (userId: string, socialNetwork: {
        twitter: string,
        linkedin: string,
        instagram: string,
        youtube: string
    }) => {

        const socialMediaResponse = await protectedApiClient(`/chef/dashboard/saveSocialMediaInfo?userId=${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    twitter: socialNetwork.twitter,
                    youtube: socialNetwork.youtube,
                    linkedin: socialNetwork.linkedin,
                    instagram: socialNetwork.instagram
                })
            }
        );

        if (socialMediaResponse.status === 'OK') {
            return socialMediaResponse.data;
        } else {
            throw new Error('Unable to save social media info');
        }
    }

    static getChefDetails = createAsyncThunk(
        'getChefDetail',
        async (chefData: { username?: string }) => {

            const chefDetailsResponse = await protectedApiClient(`/chef/dashboard/getDetails?username=${chefData.username}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (chefDetailsResponse.status === 'OK') {
                return await chefDetailsResponse.data;
            }
        }
    )

    static addMenuItem = createAsyncThunk<MenuItem[], { chefId?: string, formData: FormData }, { rejectValue: string }>(
        'addMenuItem',
        async (data, { rejectWithValue, dispatch }) => {
            try {
                const addMenuItemResponse: ResponseDTO<MenuItem[]> = await protectedApiClient(
                    `/chef/dashboard/menu-item?id=${data.chefId}`,
                    {
                        method: 'POST',
                        body: data.formData
                    }
                );

                if (addMenuItemResponse.status === 'OK') {
                    dispatch(setMenu(addMenuItemResponse.data));
                    return addMenuItemResponse.data;
                } else {
                    return rejectWithValue('Failed to add menu item');
                }
            } catch (error: any) {
                return rejectWithValue('Unable to add menu item');
            }
        }
    );

    static getMenu = createAsyncThunk<MenuItem[], { id: string}, { rejectValue: ResponseError }>(
        'getMenu',
        async (chefData: { id: string;}, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<MenuItem[]> = await protectedApiClient(
                    `/chef/dashboard/getMenu?id=${chefData.id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static createOffer = async (id: string, offer: DiscountOffer | Bogof | SpendAndGetFreeOffer, offerType: string) => {

        const createOfferResponse = await protectedApiClient(`/chef/dashboard/createOffer?id=${id}&offerType=${offerType}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(offer)
            }
        );

        if (createOfferResponse.status === 'OK') {
            return createOfferResponse.data;
        } else {
            throw new Error('Unable to create discount offer');
        }
    }

    static editOffer = async (id: string, discountOffer: DiscountOffer | Bogof | SpendAndGetFreeOffer, offerType: string) => {

        const editOfferResponse = await protectedApiClient(`/chef/dashboard/editOffer?id=${id}&offerType=${offerType}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(discountOffer)
            }
        );

        if (editOfferResponse.status === 'OK') {
            return editOfferResponse.data;
        } else {
            throw new Error('Unable to create discount offer');
        }
    }

    static deleteOffer = createAsyncThunk<Offers, {
        userId: string;
        offerId: string;
        offerType: string;
    }, {}>(
        'offers/deleteOffer',
        async (arg, {rejectWithValue}) => {
            try {
                const deleteOfferResponse = await protectedApiClient<Offers>(
                    `/chef/dashboard/deleteOffer?userId=${arg.userId}&offerType=${arg.offerType}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: arg.offerId
                    }
                );

                if (deleteOfferResponse.status === 'OK') {
                    return deleteOfferResponse.data;
                } else {
                    return rejectWithValue('Failed to delete offer');
                }
            } catch (error: any) {
                throw new Error('Unable to delete offer');
            }
        }
    );


    static getOffers = createAsyncThunk<Offers, { username?: string }, {rejectValue: string}>(
        'getOffers',
        async (chefData, {rejectWithValue}) => {
            try {
                const getOffersResponse: ResponseDTO<Offers> = await protectedApiClient(`/chef/dashboard/offers?username=${chefData.username}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (getOffersResponse.status === 'OK') {
                    return getOffersResponse.data;
                } else {
                    return rejectWithValue('Unable to fetch offers');
                }
            }catch (error) {
                return rejectWithValue('Unable to fetch offers');
            }
        }
    )


    static editMenuItem = createAsyncThunk<MenuItem[], { chefId?: string, formData: FormData}, { rejectValue: string }>(
        'editMenuItem',
        async ( data, {rejectWithValue, dispatch}) => {
            try {
                const editMenuItemResponse: ResponseDTO<MenuItem[]> = await protectedApiClient(`/chef/dashboard/menu-item?id=${data.chefId}`, {
                        method: 'PUT',
                        body: data.formData
                    }
                );

                if (editMenuItemResponse.status === 'OK') {
                    dispatch(setMenu(editMenuItemResponse.data));
                    return editMenuItemResponse.data;
                } else {
                    return rejectWithValue('Unable to edit menu item');
                }
            } catch (error) {
                return rejectWithValue('Unable to edit menu item');
            }
        }
    )

    static deleteMenuItem = async (chefData: { id?: string, menuItem?: menuItem }) => {

        const deleteMenuItemResponse: ResponseDTO<MenuItem[]> = await protectedApiClient(`/chef/dashboard/menu-item?id=${chefData.id}`, {
                method: 'Delete',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(chefData.menuItem)
            }
        );

        if (deleteMenuItemResponse.status === 'OK') {
            return deleteMenuItemResponse.data;
        } else {
            throw new Error('unable to delete');
        }
    }


    static verifyPassword = async (credentials: Credentials) => {
        const passwordVerifiedResponse: ResponseDTO<boolean> = await protectedApiClient(`/chef/dashboard/verifyPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({credentials: credentials}),
        });
        return passwordVerifiedResponse.data;
    }

    static changePassword = async (credentials: Credentials) => {
        const passwordUpdateResponse = await protectedApiClient(`/chef/dashboard/updatePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials),
        });
        return passwordUpdateResponse.data;
    }

    static savePhoto = createAsyncThunk<Chef, { image: FormData, imageType: string, id?: string }, {
        rejectValue: ResponseError}>(
        'savePhoto',
        async (chefData: { image: FormData, imageType: string, id?: string }, {rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Chef> = await protectedApiClient<Chef>(
                    `/chef/dashboard/image?id=${chefData.id}&imageType=${chefData.imageType}`,
                    {
                        method: 'POST',
                        body: chefData.image,
                    }
                );
                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        })

    static deletePhoto = async (imageType: string, username?: string) => {
        const savePhotoResponse = await protectedApiClient(`/chef/dashboard/image?username=${username}&imageType=${imageType}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return savePhotoResponse.data;
    }

    static addFoodCategory = createAsyncThunk(
        'addFoodCategory',
        async  ( chefData:{id: string, category: string }, { rejectWithValue, dispatch }) => {

            try {
                const addFoodCategoryResponse: ResponseDTO<{id: string, name: string}[]> = await protectedApiClient(
                    `/chef/dashboard/add-category?id=${chefData.id}&category=${chefData.category}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if(addFoodCategoryResponse.status === 'OK'){
                    dispatch(setFoodCategories(addFoodCategoryResponse.data));
                }
            } catch (error) {
                return rejectWithValue('Unable to add food category');
            }
        }
    )


    static getFoodTypes = createAsyncThunk(
        'getFoodCategory',
        async  ( chefData:{id: string }, {rejectWithValue, dispatch}) => {
            try {
                const getFoodTypesResponse: ResponseDTO<{id: string, name: string}[]> = await protectedApiClient(
                    `/chef/dashboard/getFoodCategories?id=${chefData.id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (getFoodTypesResponse.status === 'OK') {
                    dispatch(setFoodCategories(getFoodTypesResponse.data));
                }
            }catch (error) {
                return rejectWithValue('Unable to get food category')
            }
        }
    )

    static getStripeLink = createAsyncThunk(
        'getStripeLink',
        async  ( chefData:{id: string, token?: string }): Promise<ConnectStripeLinkResponse | undefined> => {

            const getStripeUrlResponse = await fetch(
                `${process.env.REACT_APP_HOME_EATZ_API_URL}/stripe/connect-stripe/${chefData.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${chefData.token}`
                    },
                }
            );

            if(getStripeUrlResponse.ok){

                return await getStripeUrlResponse.json();
            }
        }
    )


    static completeOnboarding = createAsyncThunk<Chef, { id: string }, { rejectValue: ResponseError }>(
        'completeOnboarding',
        async (chefData: { id: string; token?: string }, { rejectWithValue }) => {
            try {
                const response: ResponseDTO<Chef> = await protectedApiClient(
                    `/chef/dashboard/onboarding-complete/${chefData.id}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );

                if (response.status === 'OK') {
                    return response.data;
                } else {
                    return rejectWithValue({message: 'Unable to complete onboarding'});
                }
            } catch (err: any) {
                return rejectWithValue({
                    message: err.message
                });
            }
        }
    );

    static getChefSchedule = createAsyncThunk<DayAvailability[], { id: string; token?: string }, { rejectValue: ResponseError }>(
        'getChefSchedule',
        async (chefData: { id: string; token?: string }, { rejectWithValue, dispatch}) => {
            try {
                const response = await protectedApiClient<DayAvailability[]>(
                    `/chef/dashboard/schedule/${chefData.id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${chefData.token}`
                        }
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static updateSchedule = createAsyncThunk<DayAvailability[], { id: string; token?: string, dayAvailability: DayAvailability }, { rejectValue: ResponseError }>(
        'updateSchedule',
        async (chefData: { id: string; token?: string; dayAvailability: DayAvailability }, { rejectWithValue, dispatch }) => {
            try {
                const response: ResponseDTO<DayAvailability[]> = await protectedApiClient(
                    `/chef/dashboard/schedule/update/${chefData.id}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${chefData.token}`
                        },
                        body: JSON.stringify(chefData.dayAvailability)
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static addMealPlan = createAsyncThunk<Meals, { id: string; meal: FixedMeal | CustomizableMeal, mealType: MealType }, { rejectValue: ResponseError }>(
        'addMealPlan',
        async (chefData: { id: string; meal: FixedMeal | CustomizableMeal, mealType: MealType }, { rejectWithValue, dispatch }) => {
            try {
                const response: ResponseDTO<Meals> = await protectedApiClient(
                    `/chef/dashboard/meal/add/${chefData.id}?mealType=${chefData.mealType}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(chefData.meal)
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static editMealPlan = createAsyncThunk<Meals, { id: string; meal: FixedMeal | CustomizableMeal, mealType: string }, { rejectValue: ResponseError }>(
        'editMealPlan',
        async (chefData: { id: string; meal: FixedMeal | CustomizableMeal, mealType: string }, { rejectWithValue, dispatch }) => {
            try {
                const response: ResponseDTO<Meals> = await protectedApiClient(
                    `/chef/dashboard/meals/edit/${chefData.id}?mealType=${chefData.mealType}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(chefData.meal)
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static getMeals = createAsyncThunk<Meals, { id: string; token?: string }, { rejectValue: ResponseError }>(
        'getMeals',
        async (chefData: { id: string; token?: string }, { rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Meals> = await protectedApiClient<Meals>(
                    `/chef/dashboard/meals/${chefData.id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${chefData.token}`
                        }
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

    static deleteMeal = createAsyncThunk<Meals, { id: string; token?: string, meal: FixedMeal | CustomizableMeal, mealType: MealType}, { rejectValue: ResponseError }>(
        'deleteMeal',
        async (chefData: { id: string; token?: string, meal: FixedMeal | CustomizableMeal, mealType: string }, { rejectWithValue, dispatch}) => {
            try {
                const response: ResponseDTO<Meals> = await protectedApiClient<Meals>(
                    `/chef/dashboard/meals/delete/${chefData.id}?mealType=${chefData.mealType}`,
                    {
                        method: 'Delete',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${chefData.token}`
                        },
                        body: JSON.stringify(chefData.meal)
                    }
                );

                return response.data;
            } catch (error) {
                dispatch(setError(error as ResponseError));
                return rejectWithValue(error as ResponseError);
            }
        }
    );

}

export default ChefService;
