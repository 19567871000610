import './FilterChefsModal.css';
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {selectChefList, setCuisineTypeFilter, setDishTypeFilter, setFoodTypeFilter, setRemoveAllFilters}
    from "../../../../../core/redux/slices/chefListSlice";
import {toggleBodyScroll} from "../FilterButton";

type FilterChefsModalProps = {
    isFilterChefsModalOpen: boolean,
    closeFilterChefsModal: () => void,
}

type filterType = {
    filterCategory: string,
    filterOptions: ButtonOption[]
}

export interface ButtonOption {
    label: string;
}

const foodTypeOptions: ButtonOption[] = [
    { label: 'Vegetarian'},
    { label: 'Non Vegetarian'},
    { label: 'Gluten Free'},
    { label: 'Halal'},
    { label: 'Organic'},
    { label: 'Sugar Free'},
    { label: 'Vegan'},
];

const dishTypeOptions: ButtonOption[] = [
    { label: 'Main'},
    { label: 'Sides'},
    { label: 'Desserts'},
];

const cuisineTypeOptions: ButtonOption[] = [
    { label: 'All Cuisines'},
    { label: 'Indian'},
    { label: 'American'},
    { label: 'Caribbean'},
    { label: 'Chinese'},
    { label: 'Southern'},
    { label: 'Mexican'},
    { label: 'African'},
    { label: 'Korean'},
    { label: 'Pakistani'},
    { label: 'Italian'},
    { label: 'Baked Goods'},
];


const FilterChefsModal: React.FC<FilterChefsModalProps> = ({isFilterChefsModalOpen, closeFilterChefsModal}) => {

    const filterByType: filterType = {
        filterCategory: 'Food Type',
        filterOptions: foodTypeOptions
    }

    const filterByDishType: filterType = {
        filterCategory: 'Dish Type',
        filterOptions: dishTypeOptions,
    }

    const filterByCuisine: filterType = {
        filterCategory: 'All Cuisines',
        filterOptions: cuisineTypeOptions,
    }

    const selectFoodTypeFilter = useSelector(selectChefList).foodTypeFilter;
    const selectDishTypeFilter = useSelector(selectChefList).dishTypeFilter;
    const selectCuisineTypeFilter = useSelector(selectChefList).cuisineTypeFilter;

    const [foodFilter, setFoodFilter] = useState<string|undefined>(selectFoodTypeFilter);
    const [dishFilter, setDishFilter] = useState<string|undefined>(selectDishTypeFilter)
    const [cuisineFilter, setCuisineFilter] = useState<string|undefined>(selectCuisineTypeFilter);
    const [isModalOpen, setIsModalOpen] = useState(isFilterChefsModalOpen);
    const dispatch = useDispatch();
    const [modalAnimation, setModalAnimation] = useState('');

    useEffect(() => {
        setIsModalOpen(isFilterChefsModalOpen);
        setFoodFilter(selectFoodTypeFilter);
        setDishFilter(selectDishTypeFilter);
        setCuisineFilter(selectCuisineTypeFilter);
        if(isFilterChefsModalOpen) {
            setModalAnimation('right-enter-slide-in');
        }
    }, [isFilterChefsModalOpen, selectCuisineTypeFilter, selectDishTypeFilter, selectFoodTypeFilter]);

    const handleFoodTypeFilter = (option: string) => {
        if(option === foodFilter){
            setFoodFilter('');
            return;
        }
        setFoodFilter(option);
    }

    const handleDishTypeFilter = (option: string) => {
        if(option === dishFilter){
            setDishFilter('');
            return;
        }
        setDishFilter(option);
    }

    const handleCuisineTypeFilter = (option: string) => {
        if(option === cuisineFilter){
            setCuisineFilter(option === 'All Cuisines' ? 'All Cuisines' : '');
            return;
        }
        setCuisineFilter(option);
    }

    const handleRemoveAllFilters = () => {
        dispatch(setRemoveAllFilters());
    }

    const handleApplyFilters = () => {
        if(selectFoodTypeFilter !== foodFilter){
            dispatch(setFoodTypeFilter(foodFilter || ''));
        }
        if(selectDishTypeFilter !== dishFilter){
            dispatch(setDishTypeFilter(dishFilter || ''));
        }
        if(selectCuisineTypeFilter !== cuisineFilter){
            dispatch(setCuisineTypeFilter(cuisineFilter || ''));
        }
        closeFilterChefsModal();
    }

    const closeModal = () => {
        setModalAnimation('right-exit-fade-out');
        setTimeout(() => {
            toggleBodyScroll(true);
            setIsModalOpen(false);
        }, 500);
        setIsModalOpen(false);
        closeFilterChefsModal();
    }

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    return (
        <Modal className={`filter-chefs-modal ${modalAnimation}`} isOpen={isModalOpen} onRequestClose={closeModal} style={customStyles} preventScroll={isFilterChefsModalOpen}>
            <div className='filter-chefs-modal-heading'>
                <h2>Filter By</h2>
                <Icon icon='material-symbols-light:close' fontSize={24} className='close-filter-modal-icon' onClick={closeModal}/>
            </div>
            <div className='filter-modal-row'>
                <h4>{filterByType.filterCategory}</h4>
                <div className='filter-buttons'>
                    {filterByType.filterOptions.map((option) => (
                        <button
                            key={option.label}
                            className={`filter-option-button ${foodFilter === option.label ? 'selected' : ''}`}
                            onClick={() => handleFoodTypeFilter(option.label)}>
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className='filter-modal-row'>
                <h4>{filterByDishType.filterCategory}</h4>
                <div className='filter-buttons'>
                    {filterByDishType.filterOptions.map((option) => (
                        <button
                            key={option.label}
                            className={`filter-option-button ${dishFilter === option.label ? 'selected' : ''}`}
                            onClick={() => handleDishTypeFilter(option.label)}>
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className='filter-modal-row'>
                <h4>{filterByCuisine.filterCategory}</h4>
                <div className='filter-buttons'>
                    {filterByCuisine.filterOptions.map((option) => (
                        <button
                            key={option.label}
                            className={`filter-option-button ${cuisineFilter === option.label ? 'selected' : ''}`}
                            onClick={() => handleCuisineTypeFilter(option.label)}>
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>
            <div className='filter-chefs-action-buttons'>
                <button className='secondary-grey-button filter-clear-button' onClick={handleRemoveAllFilters}>Clear All</button>
                <button className='primary-button' onClick={handleApplyFilters}>Apply Filters</button>
            </div>
        </Modal>
    )
}

export default FilterChefsModal;
