import React, {useEffect, useState} from 'react';
import './CategoryFilterButtons.css';
import {useSelector} from "react-redux";
import {selectChef} from "../../../../../core/redux/slices/chefSlice";
import MenuItem from "../../../../../model/MenuItem";

type CategoryFilterButtonsProps = {
    onFilterMenu: (menu: MenuItem[]) => void;
}

const CategoryFilterButtons: React.FC<CategoryFilterButtonsProps> = ({onFilterMenu}) => {

    const menu = useSelector(selectChef).menu;
    const [activeFilter, setActiveFilter] = useState('All');
    const [allFilters, setAllFilters] = useState<string[]>([]);

    useEffect(() => {
        const foodTypes = menu?.map(item => item.foodType);
        setAllFilters(['All',...Array.from(new Set(foodTypes))]);
    }, []);

    const changeFilter = (filter: string) => {
        setActiveFilter(filter);

        if (filter === 'All') {
            onFilterMenu(menu as MenuItem[]);
        } else {
            onFilterMenu(menu?.filter((menuItem) => (menuItem.foodType === filter)) as MenuItem[])
        }
    }

    return (
        <div className='category-filter-buttons box-card-shadow'>
            {
                allFilters.map((filter, index) => (
                    <div className={`category-filter-button cursor ${activeFilter === filter ? 'current-filter' : ''}`}
                         key={index} onClick={() => changeFilter(filter)}>
                        {filter}
                    </div>
                ))
            }
        </div>
    );
};

export default CategoryFilterButtons;
