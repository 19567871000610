import './DeleteConfirmation.css';
import {Icon} from "@iconify/react";


const DeleteConfirmation = () => {

    return (
        <div className='saved-profile-container'>
            <Icon icon='ep:success-filled' fontSize={16} />
            <p>Deleted</p>
        </div>
    )
}

export default DeleteConfirmation;
