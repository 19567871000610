// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-details-step-wrapper {
    border-radius: 10px;
}

.add-new-payment-card {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    background: rgba(238, 238, 238, 0.65);
    flex-direction: column;
    gap: 20px;
}

.payment-details-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-new-payment-card .row {
    display: flex;
    gap: 20px;
}

.payment-card-details {
    display: flex;
    gap: 30px;
}

.payment-card-icon {
    height: 40px;
    width: 50px;
}

.amex-card {
    color: #0264b9;
}

.payment-method-card {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.payment-method-card:not(:last-child) {
    border-bottom: 1px solid var(--border-light);
}

.payment-method-card:hover {
    cursor: pointer;
    border-bottom-color: var(--primary-color);
}

.payment-card-details .row {
    display: flex;
    gap: 10px;
}

.default-payment-card {
    color: #01dc01;
}

.payment-methods-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/payment-detail-step/PaymentDetailStep.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,eAAe;IACf,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".payment-details-step-wrapper {\n    border-radius: 10px;\n}\n\n.add-new-payment-card {\n    display: flex;\n    padding: 20px;\n    border-radius: 10px;\n    background: rgba(238, 238, 238, 0.65);\n    flex-direction: column;\n    gap: 20px;\n}\n\n.payment-details-content {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.add-new-payment-card .row {\n    display: flex;\n    gap: 20px;\n}\n\n.payment-card-details {\n    display: flex;\n    gap: 30px;\n}\n\n.payment-card-icon {\n    height: 40px;\n    width: 50px;\n}\n\n.amex-card {\n    color: #0264b9;\n}\n\n.payment-method-card {\n    display: flex;\n    gap: 20px;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 0;\n}\n\n.payment-method-card:not(:last-child) {\n    border-bottom: 1px solid var(--border-light);\n}\n\n.payment-method-card:hover {\n    cursor: pointer;\n    border-bottom-color: var(--primary-color);\n}\n\n.payment-card-details .row {\n    display: flex;\n    gap: 10px;\n}\n\n.default-payment-card {\n    color: #01dc01;\n}\n\n.payment-methods-container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
