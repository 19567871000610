import './SuccessMessage.css';
import {Icon} from "@iconify/react";
import React from "react";

type SuccessMessageType = {
    message: string,
}
const SuccessMessage: React.FC<SuccessMessageType>  = ({message}) => {

    return (
        <div className='success-message-container'>
            <Icon icon='ooui:success' className='success-message-icon'/>
            <p>{message}</p>
        </div>
    )
}

export default SuccessMessage;
