import React, { ErrorInfo, ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface ErrorBoundaryState {
    hasError: boolean;
}

interface ErrorBoundaryProps {
    children: ReactNode;
    navigate: NavigateFunction;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
        if (this.state.hasError && !prevState.hasError) {
            this.props.navigate('/error-page');
        }
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}

const withNavigate = (Component: React.ComponentType<ErrorBoundaryProps>) => {

    return (props: { children: ReactNode }) => {
        const navigate = useNavigate();
        return (
            <Component {...props} navigate={navigate}>
                {props.children}
            </Component>
        );
    };
};

const ErrorBoundaryWithNavigate = withNavigate(ErrorBoundary);

export default ErrorBoundaryWithNavigate;
