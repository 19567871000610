
export const formatCardNumber = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
        .slice(0, 19);
};

export const formatExpiryDate = (value: string) => {
    const onlyNums = value.replace(/\D/g, '');
    if (onlyNums.length <= 2) {
        return onlyNums;
    }
    return `${onlyNums.slice(0, 2)} / ${onlyNums.slice(2, 4)}`;
};

export const formatPhoneNumber = (value: string): string => {

    const digits = value.replace(/\D/g, '');

    const match = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        const part1 = match[1] ? `(${match[1]}` : '';
        const part2 = match[2] ? `) ${match[2]}` : '';
        const part3 = match[3] ? `-${match[3]}` : '';
        return `${part1}${part2}${part3}`;
    }
    return value;
};

export const removePhoneNumberBrackets = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g,'');
}

export const validatePositiveNumber = (number: string) => {
    if (+number > 0){
        return true;
    }
}

export const capitalizeFirstAlphabet = (word: string) => {
    return word.charAt(0).toUpperCase()+ word.slice(1,word.length);
}
