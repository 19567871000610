// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-roadmap-card {
    display: flex;
    align-items: center;
    flex: 1 1;
    gap: 10px;
    text-align: center;
    flex-direction: column;
    animation: 3s card-slide-in;
}

.step-description {
    padding: 0 40px;
    color: var(--light-grey);
}

.user-roadmap-card .card-heading {
    font-size: 24px;
    font-weight: 500;
}

.user-roadmap-card-image {
    width: 300px;
    height: 300px;
    margin-top: 20px;
    object-fit: cover;
    border-radius: 50%;
}

.user-roadmap-card-step-number {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--primary-color);
}

@keyframes card-slide-in {

    from {
        transform: translateY(20%);
    }
    to {
        transform: translateX(0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/user-roadmap/user-roadmap-card/UserRoadmapCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAO;IACP,SAAS;IACT,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;;IAEI;QACI,0BAA0B;IAC9B;IACA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".user-roadmap-card {\n    display: flex;\n    align-items: center;\n    flex: 1;\n    gap: 10px;\n    text-align: center;\n    flex-direction: column;\n    animation: 3s card-slide-in;\n}\n\n.step-description {\n    padding: 0 40px;\n    color: var(--light-grey);\n}\n\n.user-roadmap-card .card-heading {\n    font-size: 24px;\n    font-weight: 500;\n}\n\n.user-roadmap-card-image {\n    width: 300px;\n    height: 300px;\n    margin-top: 20px;\n    object-fit: cover;\n    border-radius: 50%;\n}\n\n.user-roadmap-card-step-number {\n    width: 58px;\n    height: 58px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    background: var(--primary-color);\n}\n\n@keyframes card-slide-in {\n\n    from {\n        transform: translateY(20%);\n    }\n    to {\n        transform: translateX(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
