import {NavLink} from "react-router-dom";
import './DefaultMenuContainer.css';
import React from "react";

type DefaultMenuContainerProps = {
    closeMenuModal : () => void;
}

const DefaultMenuContainer: React.FC<DefaultMenuContainerProps> = ({closeMenuModal}) => {

    return (
        <div className='default-menu-wrapper'>
            <div className='menu-buttons'>
                <NavLink to='/login' onClick={closeMenuModal} className='remove-decoration link-black menu-button button-login cursor'>Log in</NavLink>
                <NavLink to='/signup' onClick={closeMenuModal}   className='link-black menu-button button-signup cursor remove-decoration'>Sign Up</NavLink>
            </div>
            <div className='menu-detail-wrapper'>
                <NavLink to='/becomeChef' onClick={closeMenuModal} className='cursor menu-detail-item remove-decoration link-black'>Become a chef</NavLink>
                <NavLink to='/#user-address' onClick={closeMenuModal} className='cursor menu-detail-item remove-decoration link-black'>Explore Nearby Chefs</NavLink>
            </div>
        </div>
    )
}

export default DefaultMenuContainer;
