// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-items {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    padding: 4px;
    border-radius: 8px;
    background: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/manage-meals/meal-card/customizable-meal-card/CustomizableMealCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;AACpC","sourcesContent":[".max-items {\n    position: absolute;\n    left: 10px;\n    top: 10px;\n    z-index: 1;\n    padding: 4px;\n    border-radius: 8px;\n    background: var(--primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
