// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speciality-cuisine-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    position: relative;
}

.add-new-cuisine-input-wrapper {
    display: flex;
    padding-left: 10px;
    gap: 20px;
    border-radius: 4px;
    border: 1px solid var(--border-light);
    justify-content: space-between;
}

.add-new-cuisine-input-wrapper input {
    border: none;
    height: 100%;
    width: 100%;
    background: transparent;
}

.add-new-cuisine-input-wrapper input:focus {
    outline: none;
}

.add-new-cuisine-input-wrapper:focus-within {
    border: 1px solid var(--primary-color);
}

.save-cuisine-button {
    padding: 10px;
    background: var(--primary-color);
}

.save-cuisine-button:hover {
    background: var(--primary-color-hover);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/profile/PersonalDetails/speciality-cuisines-list/SpecialityCuisinesList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,qCAAqC;IACrC,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[".speciality-cuisine-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n    overflow: hidden;\n    position: relative;\n}\n\n.add-new-cuisine-input-wrapper {\n    display: flex;\n    padding-left: 10px;\n    gap: 20px;\n    border-radius: 4px;\n    border: 1px solid var(--border-light);\n    justify-content: space-between;\n}\n\n.add-new-cuisine-input-wrapper input {\n    border: none;\n    height: 100%;\n    width: 100%;\n    background: transparent;\n}\n\n.add-new-cuisine-input-wrapper input:focus {\n    outline: none;\n}\n\n.add-new-cuisine-input-wrapper:focus-within {\n    border: 1px solid var(--primary-color);\n}\n\n.save-cuisine-button {\n    padding: 10px;\n    background: var(--primary-color);\n}\n\n.save-cuisine-button:hover {\n    background: var(--primary-color-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
