// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-change-input {
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid var(--border-light);
}

.cancel-edit-address-button {
    width: calc(100% - 40px);
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.edit-address-error {
    padding: 20px;
}

@media screen and (max-width: 600px) {

    .cancel-edit-address-button {
        position: fixed;
        bottom: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/checkout-steps/delivery-details-step/edit-address-modal/EditAddressModal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,qCAAqC;AACzC;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI;QACI,eAAe;QACf,YAAY;IAChB;AACJ","sourcesContent":[".address-change-input {\n    border-radius: 10px;\n    padding: 10px;\n    margin-top: 20px;\n    border: 1px solid var(--border-light);\n}\n\n.cancel-edit-address-button {\n    width: calc(100% - 40px);\n    margin-top: 20px;\n    margin-left: 20px;\n    margin-right: 20px;\n}\n\n.edit-address-error {\n    padding: 20px;\n}\n\n@media screen and (max-width: 600px) {\n\n    .cancel-edit-address-button {\n        position: fixed;\n        bottom: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
