// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-button {
    padding: 20px;
    border-radius: 8px;
    border: none;
    background-color: var(--primary-color) !important;
}

.chef-button:disabled:hover {
    cursor: not-allowed;
}

.chef-button:hover {
    background: var(--primary-light-color);
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/pages/home-page/find-chefs/FindHomeChefs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,iDAAiD;AACrD;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;IACtC,eAAe;AACnB","sourcesContent":[".chef-button {\n    padding: 20px;\n    border-radius: 8px;\n    border: none;\n    background-color: var(--primary-color) !important;\n}\n\n.chef-button:disabled:hover {\n    cursor: not-allowed;\n}\n\n.chef-button:hover {\n    background: var(--primary-light-color);\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
