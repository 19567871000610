import './BecomeChefButton.css';
import {NavLink} from "react-router-dom";

interface props {
    backgroundColor: string
}

const BecomeChefButton: React.FC<props> = ({backgroundColor}) => {
    return (
            <NavLink to='/becomeChef' className={backgroundColor !== 'white' ? 'link-black become-chef-link primary-button'
                : 'link-black become-chef-link button-white meet-chef-button'}>Become a chef</NavLink>
    )
}

export default BecomeChefButton;
