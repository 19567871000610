import './AboutUs.css';
import { useTranslation } from "react-i18next";
import AboutUsHeaderImage from '../../assets/images/about-us/AboutUsHeader.png';
import quotationMarkOpen from '../../assets/images/opening-quotation-mark.png';
import quotationMarkClose from '../../assets/images/closing-quotation-mark.png';
import section1Image from '../../assets/images/about-us/section-1.png';
import missionImage from '../../assets/images/about-us/mission.png';
import visionImage from '../../assets/images/about-us/vision.png';

const AboutUs = () => {

    const { t } = useTranslation('common');

    return (
        <div className='about-us-page'>
            <p className='heading-font-large text-center'>{t('about_us.header')}</p>
            <p className='sub-black text-center'>{t('about_us.welcome_message')}</p>
            <img src={AboutUsHeaderImage} className='about-us-header-image' alt='header'/>
            <div className='quotation-wrapper'>
                <img src={quotationMarkOpen} alt='quotation-open' className='quotation-mark'/>
                <p className='quotation-description'>{t('about_us.welcome_message_subheading')}</p>
                <img src={quotationMarkClose} alt='quotation-close' className='quotation-mark'/>
            </div>
            <section className='about-us-section'>
                <div>
                    <img src={section1Image} alt='section'/>
                </div>
                <div className='section-description-wrapper'>
                    <p className='heading-font-large'>{t('about_us.section_1.title')}</p>
                    <p className='sub-black'>{t('about_us.section_1.description')}</p>
                </div>
            </section>
            <section className='about-us-section-2'>
                <div className='section-description-wrapper-2'>
                    <img src={missionImage} alt='section' />
                    <p className='heading-font-large'>{t('about_us.mission.title')}</p>
                </div>
                <div>
                    <p className='sub-black'>{t('about_us.mission.description')}</p>
                </div>
            </section>
            <section className='vision-section'>
                <div className='vision-description-wrapper'>
                    <p className='heading-font-large'>{t('about_us.vision.title')}</p>
                    <img src={visionImage} alt='section' />
                </div>
                <div>
                    <p className='sub-black'>{t('about_us.vision.description')}</p>
                </div>
            </section>
        </div>
    )
}

export default AboutUs;
