import './MealPlans.css';
import React, {useEffect, useState} from "react";
import {Icon} from "@iconify/react";
import CreateMealPlan from "./create-meal-plan/CreateMealPlan";
import ChefService from "../../../core/services/ChefService";
import { unwrapResult } from "@reduxjs/toolkit";
import {selectChef, selectIsMealsPresent, setMeals} from "../../../core/redux/slices/chefSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../core/redux/slices/loginSlice";
import store from "../../../core/redux/store";
import EmptyMealPlans from "./empty-meals-list/EmptyMealPlans";
import ManageMeals from "./manage-meals/ManageMeals";
import FixedMeal from "../../../model/meal-plans/FixedMeal";
import {Meals} from "../../../model/Chef";
import CustomizableMeal from "../../../model/meal-plans/CustomizableMeal";
import {MealType} from "./create-meal-plan/meal-plan-form/MealPlanForm";

const MealPlans = () => {

    const [selectedAction, setSelectedAction] = useState('List');
    const [mealItemsToEdit, setMealItemsToEdit] = useState<FixedMeal | CustomizableMeal | undefined>(undefined);
    const isMealsPresent = useSelector(selectIsMealsPresent);
    const [mealType, setMealType] = useState(MealType.FIXED);
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const id = useSelector(selectChef).id;
    const token = useSelector(selectToken).accessToken;


    useEffect(() => {
        const fetchData = async () => {

            try {
                const resultAction = await apiDispatch(ChefService.getMeals({id: id as string, token}));
                const mealsResponse: Meals = unwrapResult(resultAction);
                apiDispatch(setMeals(mealsResponse));
            } catch (error: any) {
                console.log(error.message);
            }
        }

        fetchData();

    }, [])


    const addMeals = () => {
        setSelectedAction('Add');
    }

    const handleEditMealItem = (mealItem: FixedMeal | CustomizableMeal, event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, mealType: MealType) => {
        event.stopPropagation();
        setSelectedAction('Edit');
        setMealType(mealType);
        setMealItemsToEdit(mealItem);
    }

    return (
        <div className='meal-plans'>
            {selectedAction === 'List' &&
                <>
                    <div className='dashboard-page-heading'>
                        <div className='meal-plan-heading'>
                            <h2>List</h2>
                        </div>
                        <div className='add-item-button cursor' onClick={addMeals}>
                            <Icon icon='material-symbols:add' fontSize={24}/>
                            Add New
                        </div>
                    </div>
                    {!isMealsPresent && <EmptyMealPlans onClickAdd={addMeals}/>}
                    {isMealsPresent && <ManageMeals editMealItem={handleEditMealItem}/>}
                </>
            }
            {selectedAction === 'Add' && <CreateMealPlan onCloseAddMeal={() => setSelectedAction('List')} />}
            {selectedAction === 'Edit' && <CreateMealPlan onCloseAddMeal={() => setSelectedAction('List')} mealPlanToEdit={mealItemsToEdit} typeOfMeal={mealType}/>}
        </div>
    )
}

export default MealPlans;
