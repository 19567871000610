import './CusineCarousel.css';
import '../../../styles/common.css';
import Carousel from "react-multi-carousel";
import {CustomizeCuisineCarouselResponsiveness} from "../../../components/custom-components/CustomizeCarousel";
import 'react-multi-carousel/lib/styles.css';
import allCuisineImage from '../../../assets/images/cuisines/all-cuisines.png';
import indianImage from '../../../assets/images/cuisines/indian.png';
import chineseImage from '../../../assets/images/cuisines/chinese.png';
import italianImage from '../../../assets/images/cuisines/italian.png';
import japaneseImage from '../../../assets/images/cuisines/japanese.png';
import koreanImage from '../../../assets/images/cuisines/korean.png';
import mexicanImage from '../../../assets/images/cuisines/mexican.png';
import bakedImage from '../../../assets/images/cuisines/baked.png';
import CuisineCard from "./cuisine-card/CuisineCard";
import React from "react";
import {Icon} from "@iconify/react";
import {useDispatch} from "react-redux";
import {setCuisineTypeFilter} from "../../../core/redux/slices/chefListSlice";

export type cuisineType = {
    imageSource: string,
    cuisineName: string,
}

export interface CustomArrowProps {
    onClick?: () => void;
}

const CuisineCarousel = () => {

    const dispatch = useDispatch();

    const cuisines: cuisineType[] = [
        {imageSource: allCuisineImage, cuisineName: 'All Cuisines'},
        {imageSource: indianImage, cuisineName: 'Indian'},
        {imageSource: chineseImage, cuisineName: 'Chinese'},
        {imageSource: italianImage, cuisineName: 'Italian'},
        {imageSource: japaneseImage, cuisineName: 'Japanese'},
        {imageSource: koreanImage, cuisineName: 'Korean'},
        {imageSource: mexicanImage, cuisineName: 'Mexican'},
        {imageSource: bakedImage, cuisineName: 'Baked'}
    ];

    const CustomRightArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
        <button onClick={onClick} className="cuisine-filter-carousel-button filter-right-button">
            <Icon icon='material-symbols:keyboard-arrow-right' fontSize={36} />
        </button>
    );

    const CustomLeftArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
        <button onClick={onClick} className="cuisine-filter-carousel-button filter-left-button">
            <Icon icon='material-symbols:keyboard-arrow-left' fontSize={36} />
        </button>
    );

    const selectCuisine = (filter: string) => {
        dispatch(setCuisineTypeFilter(filter));
    }

    return (
        <div className="cuisine-carousel">
            <Carousel
                responsive={CustomizeCuisineCarouselResponsiveness}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={true}
                infinite={true}
                partialVisible={true}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}>
                {cuisines.map((cuisine, index) => {
                    return (
                        <div key={index} className='cuisine-filter-list' onClick={() => selectCuisine(cuisine.cuisineName)}>
                            <CuisineCard cuisine={cuisine} />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
}

export default CuisineCarousel;
