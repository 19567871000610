import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserAddress from "../../../model/UserAddress";
import {RootState} from "../store";


interface suggestionState {
    suggestions: UserAddress[],
}

const initialState: suggestionState = {
    suggestions: [],
}

const suggestionSlice = createSlice({
    name : 'addressSuggestions',
    initialState,
    reducers : {
        setSuggestions: (state, action: PayloadAction<UserAddress[]>) => {
            state.suggestions = action.payload;
        }
    }
});

export const { setSuggestions } = suggestionSlice.actions;

export const selectAddressSuggestions = (state: RootState) => state.addressSuggestion.suggestions;
export default suggestionSlice.reducer;
