import './EmailVerificationModal.css';
import Modal from "react-modal";
import React, {FormEvent, useState} from "react";
import './EmailVerificationModal.css';
import {Icon} from "@iconify/react";
import emailVerificationImage from '../../../assets/images/email-verification.png';
import emailSentSuccessImage from '../../../assets/images/email-sent-success.png';
import validationSuccessImage from '../../../assets/images/validation-code-success.png';
import EmailService from "../../../core/services/EmailService";

import RingLoader from "../ring-loader/RingLoader";
import {useDispatch, useSelector} from "react-redux";
import {selectEmailVerified, setEmailVerified} from "../../../core/redux/slices/profileManagementSlice";

type EmailVerificationModalProps = {
    isModalOpen : boolean;
    closeModal: () => void;
    email: string
}

const EmailVerificationModal: React.FC<EmailVerificationModalProps> =({isModalOpen, closeModal, email}) => {

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    const [code, setCode] = useState<string[]>(new Array(6).fill(''));
    const [isCodeMissing, setIsCodeMissing] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const [emailSendStatus, setEmailSendStatus] = useState('');
    const isEmailVerified = useSelector(selectEmailVerified);

    const handleChange = (element: HTMLInputElement, index: number): void => {
        setIsCodeMissing(false);
        setError('');
        const newCode = [...code];
        newCode[index] = element.value;
        setCode(newCode);

        if (element.nextSibling && element.value) {
            (element.nextSibling as HTMLInputElement).focus();
        }
        else if (!element.value && index > 0) {
            (element.previousSibling as HTMLInputElement).focus();
        }
        setIsCodeMissing(false);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if(!code.every((value) => value.trim() !== '')){
            return setIsCodeMissing(true);
        }

        const response  = await EmailService.verifyValidationCode(email, code.join(''));
        if (response.success){
            if(response.data === true) {
                dispatch(setEmailVerified(true));
                setCode(code.fill(''));
                closeModal();
            }else if (response.data === false){
                setError('Invalid code, please try again');
            }
        }else {
            setError('Verification code expired. Please request a new one')
        }
    };

    const handleResend = async () => {
        try {
            setError('');
            setEmailSendStatus('sending');
            dispatch(setEmailVerified(false));
            await EmailService.sendValidationCodeEmail(email);
            setEmailSendStatus('sent');
            setCode(code.fill(''));
            setTimeout(()=>{setEmailSendStatus('')}, 4000);
        }catch(error){
            console.log(error);
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        event.preventDefault();
        setIsCodeMissing(false);
        setError('');
        const paste = event.clipboardData.getData('text');
        const pasteArray = paste.split('');
        const maxChars = code.length - index;
        const part = pasteArray.slice(0, maxChars);

        const newCode = [...code];
        for (let i = 0; i < part.length; i++) {
            newCode[index + i] = part[i];
        }
        setCode(newCode);

        const nextIndex = index + part.length;
        const nextSibling = document.querySelector(`input[name="code${nextIndex}"]`);
        if (nextSibling) {
            (nextSibling as HTMLInputElement).focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
        if (event.key === 'Backspace' && event.currentTarget.value === '' && index > 0) {

            const previousSibling = event.currentTarget.previousElementSibling as HTMLInputElement;
            if (previousSibling) {
                previousSibling.focus();
            }
            event.preventDefault();
        }
    };


    return (
        <Modal isOpen={isModalOpen} className='email-verification-modal' style={customStyles}>
            <Icon icon='ion:chevron-back' fontSize={28} onClick={closeModal} className='cursor modal-back-icon'/>
            <form onSubmit={handleSubmit} className='email-verification-form'>
                <img src={isEmailVerified ? validationSuccessImage : emailSendStatus === 'sent' ? emailSentSuccessImage : emailVerificationImage} className='email-verification-image' alt='email-verification'/>
                <p className={isCodeMissing ? 'error-message' : `email-verification-description`}>Please enter the 6-digit verification code that was sent to your email</p>
                <div className='validation-code'>
                    {code.map((num, index) => (
                        <input
                            name={'code'+index}
                            key={index}
                            className="digit-input"
                            type="tel"
                            maxLength={1}
                            value={num}
                            onPaste={(e) => handlePaste(e, index)}
                            onChange={(e) => handleChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                    ))}
                </div>
                {error && <div className='error-message'>{error}</div>}
                <button type="submit" className='email-verification-form-submit' disabled={!!error} onClick={() => handleSubmit}>Continue</button>
                {emailSendStatus === 'sending' ?
                    <RingLoader /> :
                    <div className='resend-email' onClick={handleResend}>Didn't receive an email? <span
                    className='resend-icon'>Resend<Icon className='icon' icon='fluent:arrow-counterclockwise-16-filled'/></span></div>
                }
            </form>
        </Modal>
    )
}


export default EmailVerificationModal;
