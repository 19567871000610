import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {closeNewChefGuidelinesModal, isNewChefGuidelinesModalOpen} from "../../../core/redux/slices/modalSlice";
import { Icon } from "@iconify/react";
import {useTranslation} from "react-i18next";
import './NewChefGuidelines.css';
import { Checkbox, FormControlLabel } from "@mui/material";
import { orange } from '@mui/material/colors';
import {selectChef} from "../../../core/redux/slices/chefSlice";
import store from "../../../core/redux/store";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CuisineList from "./cuisine-list/CuisineList";
import LoadingButton from "../../../components/shared/loader-button/LoadingButton";
import ChefPublicService from "../../../core/services/ChefPublicService";

const NewChefGuidelines = () => {

    const isNewChefGuidelinesModal = useSelector(isNewChefGuidelinesModalOpen);
    const { t } = useTranslation('BecomeAChef');
    const [cuisineSelectedError, setCuisineSelectedError] = useState(true);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const chef = useSelector(selectChef);
    const [isLoading, setIsLoading] = useState(false);
    const apiDispatch =  useDispatch<typeof store.dispatch>();
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38, 38, 38, 0.8)',
        },
    };

    useEffect(() => {
        if (chef.specialityCuisines){
            setCuisineSelectedError(false);
        }
    }, [chef.specialityCuisines])

    const customCheckboxProps = {
        '&.Mui-checked': {
            color: orange[300],

        },
    }

    const customLabelProps = {
        '& .MuiFormControlLabel-label': {
            '&.Mui-disabled': {
                color: 'black',
            },
        },
    }

    const handleCloseModal = () => {
        dispatch(closeNewChefGuidelinesModal());
    }

    const handleRedirectToDashboard = async () => {

        setIsLoading(true);

        if(chef.specialityCuisines.length < 1){
            setIsLoading(false);
            return setCuisineSelectedError(true);
        }
        const chefLoginPromise = await apiDispatch(ChefPublicService.register(chef));

        if(chefLoginPromise.payload.userType === 'chef'){
            handleCloseModal();
            navigateTo('/chef/dashboard/onboarding');
        }
        setIsLoading(false);
    }

    return (
        <Modal isOpen={isNewChefGuidelinesModal} className='new-chef-guidelines-modal' style={customStyles}>
            <div className='heading-font-large'>{t('new-chef-guidelines')}</div>
            <CuisineList cuisineMissingError={cuisineSelectedError}/>
            <div className='food-handling-certificate-wrapper'>
                <div className='food-handling-certificate-heading'>
                    <Icon icon='mdi:certificate' fontSize={36} className='food-handling-certificate-icon'/>
                    <p className='speciality-cuisine-heading'>{t('food-handling-certificate')}</p>
                </div>
                <p className='grey-light-text'>{t('certificate-upload-time')}</p>
            </div>
            <div>
                <p className='speciality-cuisine-heading'>Food Safety</p>
                <div className='regulations-container'>
                    <FormControlLabel control={<Checkbox defaultChecked disabled sx={customCheckboxProps}/>}
                                      label={t('remove-jewellery')}  sx={customLabelProps}/>
                    <FormControlLabel control={<Checkbox disabled sx={customCheckboxProps}/>}
                                      label={t('wear-uniform')}  sx={customLabelProps}/>
                    <FormControlLabel control={<Checkbox defaultChecked disabled sx={customCheckboxProps}/>}
                                      label={t('keep-hair-restrained')}  sx={customLabelProps}/>
                    <FormControlLabel control={<Checkbox defaultChecked disabled sx={customCheckboxProps}/>}
                                      label={t('wear-gloves')}  sx={customLabelProps}/>
                    <FormControlLabel control={<Checkbox defaultChecked disabled sx={customCheckboxProps}/>}
                                      label={t('cleaning')}  sx={customLabelProps}/>
                    <FormControlLabel control={<Checkbox defaultChecked disabled sx={customCheckboxProps}/>}
                                      label={t('food-container')}  sx={customLabelProps}/>
                </div>
            </div>
            <div className='text-center'>
                {isLoading ?
                    <div className='element-center'><LoadingButton /></div> :
                    <button onClick={handleRedirectToDashboard} className='cursor dashboard-button primary-button'>Go to Dashboard</button>
                }
            </div>
        </Modal>
    )
}


export default NewChefGuidelines;
