import React from "react";
import SuccessMessage from "../success-message/SuccessMessage";

type SuccessButtonProps = {
    className?: string,
    message: string,
}

const SuccessButton: React.FC<SuccessButtonProps> = ({className, message}) => {

    return (
        <div className={`element-center ${className ? className : 'primary-button'}`}>
            <SuccessMessage message={message} />
        </div>
    )
}

export default SuccessButton;
