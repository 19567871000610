import './RegistrationForm.css';
import {useTranslation} from "react-i18next";
import {Checkbox, FormControlLabel, InputAdornment, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {openNewChefGuidelinesModal} from "../../../core/redux/slices/modalSlice";
import React, {useEffect, useState} from "react";
import {Visibility} from "@mui/icons-material";
import {VisibilityOff} from '@mui/icons-material';
import {
    addChefAddress,
    setChefCredentials,
    setChefName,
    setChefPhoneNumber,
    setLocation, selectChefAddress, setChefAddresses
} from "../../../core/redux/slices/chefSlice";
import HashingService from "../../../core/services/HashingService";
import EmailVerificationModal from "../../../components/shared/email-verification-modal/EmailVerificationModal";
import EmailService from "../../../core/services/EmailService";
import {
    validateEmailSyntax, validatePassword,
    validatePhoneNumberSyntax,
    validatePostalCodeSyntax,
    validateProvince
} from "../../../core/utils/Validation";
import convertLocationToLongitudeAndLatitude from "../../../core/utils/LocationToLongitudeAndLatitudeConverter";
import {useNavigate} from "react-router-dom";
import LoadingButton from "../../../components/shared/loader-button/LoadingButton";
import {selectEmailVerified} from "../../../core/redux/slices/profileManagementSlice";
import {formatPhoneNumber, removePhoneNumberBrackets} from "../../../core/utils/InputManipulation";
import AutoCompleteAddress from "../../../components/autocomplete-address-search/AutoCompleteAddress";
import AddressSuggestions from "../../home-page/address-suggestions/AddressSuggestions";
import {selectAddressSuggestions} from "../../../core/redux/slices/addressSuggestionSlice";
import {Address} from "../../../model/Chef";
import AddressSelected from "../../../components/shared/address-selected/AddressSelected";
import useCheckbox from "../../../custom-hooks/useCheckbox";
import {
    customCheckboxProps,
    customChefManualAddressEnter,
} from "../../../custom-tooltips-dropdowns/CustomMUITooltip";
import PasswordForm from '../../../components/shared/password-validation/PasswordForm';
import ChefPublicService from "../../../core/services/ChefPublicService";

const RegistrationForm = () => {

    const { t } = useTranslation('ChefRegistrationForm');
    const { t: tCommon } = useTranslation('common');
    const [email, setCustomerEmail] = useState('');
    const dispatch = useDispatch();
    const chefAddress = useSelector(selectChefAddress);
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [postalCodeError, setPostalCodeError] = useState('');
    const [name, setCustomerName] = useState('');
    const [isNameMissing, setIsNameMissing] = useState(false);
    const [isCityMissing, setIsCityMissing] = useState(false);
    const [isStreetAddressMissing, setIsStreetAddressMissing] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [province, setProvince] = useState('');
    const [provinceError, setProvinceError] = useState('');
    const [addressMissingError, setAddressMissingError] = useState(false);
    const enterManualAddress = useCheckbox(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigateTo = useNavigate();
    const isEmailVerified = useSelector(selectEmailVerified);
    const [isOpenEmailVerificationModal, setIsOpenEmailVerificationModalOpen] = useState(false);
    const addressSuggestion =  useSelector(selectAddressSuggestions);


    useEffect(() => {
        setCity(chefAddress?.city || '');
        setPostalCode(chefAddress?.postalCode || '');
        setStreetAddress(chefAddress?.streetAddress || '');
        setProvince(chefAddress?.province || '');
        setIsStreetAddressMissing(false);
        setIsCityMissing(false);
        setProvinceError('');
        setPostalCodeError('');
        setAddressMissingError(false);
        if (chefAddress){
            enterManualAddress.setValue(true)
        } else {
            enterManualAddress.setValue(false);
        }

        console.log(chefAddress);
    }, [chefAddress])

    useEffect(() => {
        if (isEmailVerified){
            setIsLoading(false);
            dispatch(openNewChefGuidelinesModal());
        }
    },[isEmailVerified])

    const handleEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
        setCustomerEmail(event.target.value);
        setEmailError('');
    }

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setPasswordError('');
    };

    const handleShow = () => {
        setShowPassword(prevState => !prevState);
    }

    const handleLogin = () => {
        navigateTo('/login');
    }

    const handleName = (event : React.ChangeEvent<HTMLInputElement>) => {
        setCustomerName(event.target.value);
        setIsNameMissing(false);
    }

    const handlePhoneNumber = (event : React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(formatPhoneNumber(event.target.value));
        setPhoneNumberError('');
    }

    const handleStreetAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreetAddress(event.target.value);
        setIsStreetAddressMissing(false);
    }
    const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        setIsCityMissing(false);
    }

    const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
        setPostalCodeError('');
    }

    const handleProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProvince(event.target.value);
        setProvinceError('');
    }

    const customInputField = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#FFF7E1',
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
        },
        '& label.Mui-focused': {
            color: 'black',
        },
    }

    const customInputProps ={
        style: {
            height: '48px',
                padding: '10px 10px 10px 0',
        },
    }

    const customPhoneNumberProps = {
        style: {
            height: '48px',
            padding: '10px 10px 10px 0',
        },
        inputProps: {maxLength: 14}
    }

    const customInputPropsPassword = {
        style: {
            height: '48px',
            padding: '10px 10px 10px 0',
        },
        endAdornment: (<InputAdornment position='end' onClick={handleShow} className='password cursor'>
            {showPassword ? <VisibilityOff /> : <Visibility />}
        </InputAdornment>)
    }

    const closeEmailVerificationModal = () => {
        setIsOpenEmailVerificationModalOpen(false);
    }

    const convertLocationToCoordinates = async (): Promise<{ longitude: number; latitude: number } | undefined> => {
        try {
            const { latitude, longitude } = await convertLocationToLongitudeAndLatitude(postalCode, streetAddress.concat(city));
            dispatch(setLocation({ longitude, latitude }));
            setPostalCodeError('');
            return { longitude, latitude };
        } catch (error) {
            setPostalCodeError('Please enter a valid postal code');
            return;
        }
    }

    const verifyEmailAvailable = async () => {
        try {
            const isEmailAvailable = await ChefPublicService.verifyEmailAvailable(email);
            if(!isEmailAvailable){
                setEmailError('Email already exists, try choosing a different email');
                setIsLoading(false);
                return;
            }
        }catch (error){
            setIsLoading(false);
            return setEmailError('Email already exists, try choosing a different email');
        }
    }

    const sendVerificationEmail = async () => {
        try {
            await EmailService.sendValidationCodeEmail(email);
            setIsOpenEmailVerificationModalOpen(true);
        } catch(error){
            console.log('unable to send email');
        }
    }

    const handleRegistration = async () => {

        let isFormValid = true;

        if (phoneNumber.length === 0) {
            setPhoneNumberError('Phone number required');
            isFormValid = false;
        }
        if (name.length === 0) {
            setIsNameMissing(true);
            isFormValid = false;
        }
        if (email.length === 0) {
            setEmailError('Email address required');
            isFormValid = false;
        }
        if (streetAddress.length === 0) {
            setIsStreetAddressMissing(true);
            isFormValid = false;
        }
        if (city.length === 0) {
            setIsCityMissing(true);
            isFormValid = false;
        }
        if (postalCode.length === 0) {
            setPostalCodeError('Postal code required');
            isFormValid = false;
        }
        if (password.length === 0) {
            setPasswordError('Password required');
            isFormValid = false;
        }
        if (province.length === 0) {
            setProvinceError('Province required');
            isFormValid = false;
        }

        if(email && !validateEmailSyntax(email)){
            isFormValid = false;
            setEmailError('Please enter a valid email address');
        }

        if(phoneNumber && !validatePhoneNumberSyntax(phoneNumber)){
            isFormValid = false;
            setPhoneNumberError('Please enter a valid phone number');
        }

        if (province && !validateProvince(province)){
            console.log(!validateProvince(province));
            isFormValid = false;
            setProvinceError("Please enter a valid province");
        }

        if(postalCode.length !== 0){
            const isSyntaxValid = validatePostalCodeSyntax(postalCode);
            if (!isSyntaxValid){
                setPostalCodeError('Please enter a valid postal code');
                return;
            }
        }

        if (!chefAddress){
            isFormValid = false;
            setAddressMissingError(true);
        }

        if (!(validatePassword(password).isValid)){
            isFormValid = false;
            setPasswordError('Password does not meet requirements');
        }

        if(isFormValid) {
            setIsLoading(true);

            try {
                const location =  await convertLocationToCoordinates();
                await verifyEmailAvailable();
                dispatch(setChefCredentials({username: email, password: await HashingService.hashPassword(password)}));
                dispatch(setChefPhoneNumber(removePhoneNumberBrackets(phoneNumber)));
                dispatch(setChefName(name));
                dispatch(addChefAddress({streetAddress: streetAddress, city: city, postalCode: postalCode, longitude: location?.longitude as number, latitude: location?.latitude as number, province: province}));
                await sendVerificationEmail();
            }catch (error){
                console.log('error while registration');
            } finally {
                console.log('sent');
                setIsLoading(false);
            }
        }
    }

    const handleEnterManualAddress = () => {

        if (enterManualAddress.value){
            enterManualAddress.uncheck();
        }else {
            enterManualAddress.setValue(true);
        }
    }

    const handleAddressSelect = (address: Address) => {
        dispatch(setChefAddresses([address]));
    }

    return (
            <div className='chef-registration-form' id='chef-registration-form'>
                <div className='registration-form-description'>
                    <div className='heading-font-large'>{t('heading')}</div>
                    <div>{t('already-member-heading')} <span className='primary-color cursor already-member' onClick={handleLogin}>{t('already-member-sub-heading')}</span></div>
                </div>
                <div className='registration-form-container'>
                    <div className='registration-form-row'>
                        <TextField fullWidth={true} id="outlined-basic" label={t('name')} variant="outlined"
                                   InputProps={customInputProps} sx={customInputField} helperText={isNameMissing ? 'Name required' : null} onChange={handleName} error={isNameMissing}/>
                        <TextField fullWidth={true} id="outlined-basic" label={t('phone')} variant="outlined"
                                   InputProps={customPhoneNumberProps} sx={customInputField} helperText={phoneNumberError} onChange={handlePhoneNumber} error={!!phoneNumberError} value={phoneNumber}/>
                    </div>
                    <div className='registration-form-row'>
                        <TextField fullWidth={true} id="outlined-basic" label={t('email')} variant="outlined"
                                   InputProps={customInputProps} sx={customInputField} type='email' helperText={emailError} onChange={handleEmail} error={!!emailError}/>
                        <PasswordForm>
                            <TextField fullWidth={true} id="outlined-basic" label={t('password')} variant="outlined"
                                   InputProps={customInputPropsPassword} sx={customInputField} type={ showPassword ? 'text': 'password'} onChange={handlePassword} helperText={passwordError} error={!!passwordError}/>
                        </PasswordForm>
                    </div>
                    {(enterManualAddress.value || chefAddress) &&
                        <>
                            <div className='registration-form-row'>
                                <TextField fullWidth={true} id="outlined-basic" label={t('street-address')} variant="outlined"
                                           InputProps={customInputProps} sx={customInputField} helperText={isStreetAddressMissing ? 'Street Address required' : null}
                                           onChange={handleStreetAddress} error={isStreetAddressMissing} value={streetAddress} />
                                <TextField fullWidth={true} id="outlined-basic" label={t('city')} variant="outlined"  InputProps={customInputProps}
                                           sx={customInputField} helperText={isCityMissing ? 'City required' : null}
                                           onChange={handleCity} error={isCityMissing} value={city}/>
                            </div>
                            <div className='registration-form-row'>
                                <TextField fullWidth={true} id="outlined-basic" label={t('postal-code')} variant="outlined"
                                           InputProps={customInputProps} sx={customInputField} helperText={postalCodeError}
                                           onChange={handlePostalCode} error={!!postalCodeError} value={postalCode} />
                                <TextField fullWidth={true} id="outlined-basic" label={t('province')} variant="outlined"
                                           InputProps={customInputProps} sx={customInputField} helperText={provinceError}
                                           onChange={handleProvince} error={!!provinceError} value={province} />
                            </div>
                        </>
                    }
                    <div className='registration-form-row'>
                        <div className='address-wrapper'>
                            <div className='chef-address-input-wrapper'>
                                <div className='chef-address-input'>
                                    {chefAddress ? <AddressSelected isEditable={true} selectedAddress={chefAddress} cancelChefAddress={true}/> : <AutoCompleteAddress placeholder='Address' />}
                                </div>
                                <div className='address-suggestions-list'>
                                    {addressSuggestion.length > 0 &&
                                        <AddressSuggestions isPositionRelative={false} onSelectPlace={handleAddressSelect}/>
                                    }
                                </div>
                            </div>
                            {addressMissingError && <p className='error-message' style={{fontSize: '12px'}}>{tCommon('error-messages.address')}</p>}
                            <FormControlLabel control={<Checkbox sx={customCheckboxProps} value={enterManualAddress} onChange={handleEnterManualAddress}/>}
                                              label={t('manual-address')} sx={customChefManualAddressEnter}/>
                        </div>

                        <div className='register-button-wrapper'>
                            {
                                !isLoading ? <button className='primary-button register-button' onClick={handleRegistration}>Register Now</button> :
                                    <div className='registration-button-loader'><LoadingButton /></div>
                            }
                        </div>
                    </div>
                </div>
                <EmailVerificationModal isModalOpen={isOpenEmailVerificationModal} closeModal={closeEmailVerificationModal} email={email} />
            </div>
    )
}

export default RegistrationForm;
