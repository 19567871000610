import { customStyles } from "../../../pages/chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import {Icon} from "@iconify/react";

type CustomModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    children: React.ReactNode;
};

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, closeModal, children }) => {
    const [animationClass, setAnimationClass] = useState('');

    useEffect(() => {
        if (isOpen) {
            setAnimationClass('fade-in');
        } else {
            setAnimationClass('fade-out');
        }
    }, [isOpen]);

    const handleAnimationEnd = () => {
        if (!isOpen) {
            closeModal();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className={`basic-modal modal-2 ${animationClass}`}
            style={customStyles}
            onRequestClose={closeModal}
            closeTimeoutMS={500}>
                <div className='modal-heading element-center'>
                    <Icon icon='material-symbols:close' fontSize={30} onClick={closeModal}/>
                </div>
                {children}
        </Modal>
    );
};

export default CustomModal;
