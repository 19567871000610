import './InputError.css';
import {Icon} from "@iconify/react";
import React from "react";

type InputErrorHandlingProps = {
    message: string,
}
const InputErrorHandling: React.FC<InputErrorHandlingProps>  = ({message}) => {

    return (
        <div className='error-container'>
            <Icon icon='material-symbols:info' fontSize={24} className='error-message-icon'/>
            <p>{message}</p>
        </div>
    )
}

export default InputErrorHandling;
