import './CheckoutItems.css';
import {useSelector} from "react-redux";
import {selectCartItemsCount, selectCheckoutCart, selectSubTotal} from "../../../../core/redux/slices/customerSlice";
import {Icon} from "@iconify/react";
import React from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import useImageLoader from "../../../../custom-hooks/useImageLoader";
import {Skeleton} from "@mui/material";
import CheckoutCartItemCard from "./checkout-cart-item-card/CheckoutCartItemCard";

type CheckoutItemsProps = {
    closeTooltip?: () => void;
    closeModal?: (event: React.MouseEvent) => void;
}

const CheckoutItems: React.FC<CheckoutItemsProps> = ({closeTooltip, closeModal}) => {

    const checkoutCart = useSelector(selectCheckoutCart);
    const cartItemsCount = useSelector(selectCartItemsCount);
    const chefBusinessPagePath = useLocation().pathname === `/business-page/${checkoutCart.chef?.id}`;
    const navigateTo = useNavigate();
    const subTotal = useSelector(selectSubTotal);
    const chefProfileImageUrl = checkoutCart.chef?.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN;
    const chefProfileImage = useImageLoader(chefProfileImageUrl);

    const handleAddMoreItems = (event: React.MouseEvent) => {
        if (closeModal) {
            closeModal(event);
        }
        if(closeTooltip){
            closeTooltip();
        }
        navigateTo(`/business-page/${checkoutCart.chef?.id}`);
    }

    const handleGoToCheckout = (event: React.MouseEvent) => {
        if (closeModal) {
            closeModal(event);
        }
        if(closeTooltip){
            closeTooltip();
        }
    }

    return (
        <div className='checkout-items-container'>
            <div className='checkout-items-heading'>
                <p className='heading'>Cart</p>
                <div className='chef-profile-details'>
                    {chefProfileImage ? <img src={chefProfileImageUrl}
                          className='checkout-cart-profile-image' alt='profile logo'/> : <Skeleton variant='circular' width={50} height={50}/>}
                    <p className='chef-name'>{checkoutCart.chef?.name}</p>
                </div>
                {!chefBusinessPagePath && <p onClick={(event) => handleAddMoreItems(event)} className='add-more-items-button cursor'><Icon icon='ic:outline-add' fontSize={24}/>Add Items</p>}
            </div>
            <div className='checkout-items-list'>
                {checkoutCart.cartItems.map((item, index) => (
                    <CheckoutCartItemCard item={item} key={index}/>
                ))}
            </div>
            <div className='navigate-to-checkout-wrapper'>
                <div>
                    <sub>Subtotal</sub>
                    <p>${subTotal.toFixed(2)}<span className='total-items'>/{cartItemsCount} items</span></p>
                </div>
                <NavLink to='/checkout' className='primary-button link-black' onClick={(event) => handleGoToCheckout(event)}>Proceed to checkout</NavLink>
            </div>
        </div>
    )
}

export default CheckoutItems;
