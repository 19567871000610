import React, {useState} from "react";

const useFormInputWithoutValidation = (initialValue: string | undefined) => {
    const [value, setValue] = useState(initialValue || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setValue(event.target.value);
    };

    const reset = () => {
        setValue(initialValue || '');
    }

    return {
        value,
        onChange: handleChange,
        reset,
    };
};

export default useFormInputWithoutValidation;
