import './ForgotPassword.css';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {validateEmailSyntax} from "../../../core/utils/Validation";
import ChefService from "../../../core/services/ChefService";
import CustomerService from "../../../core/services/CustomerService";
import EmailService from "../../../core/services/EmailService";
import EmailVerificationModal from "../email-verification-modal/EmailVerificationModal";
import LoadingButton from "../loader-button/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import {selectEmailVerified, setActiveUser} from "../../../core/redux/slices/profileManagementSlice";
import NewPasswordContainer from "./new-password-container/NewPasswordContainer";
import InputError from "../../../error-handling/input-error/InputError";
import ChefPublicService from "../../../core/services/ChefPublicService";


const ForgotPassword = () => {

    const { t } = useTranslation('common');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValidateCodeModalOpen, setIsValidateModalOpen] = useState(false);
    const [loadingError, setLoadingError] = useState('');
    const isEmailVerified = useSelector(selectEmailVerified);
    const dispatch = useDispatch();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailError('');
        setEmail(event.target.value);
    }


    const handleContinue = async () => {
        if (!validateEmailSyntax(email)) {
            return setEmailError('Please enter valid email address')
        }
        try{
            setLoading(true);
            const chefEmailAvailable = await ChefPublicService.verifyEmailAvailable(email);
            const customerEmailExist = await CustomerService.verifyEmailExists(email);

            if(!chefEmailAvailable || customerEmailExist){
                setLoading(true);
                dispatch(setActiveUser(!chefEmailAvailable ? 'chef' : 'customer'));
                const isEmailSent = await EmailService.sendValidationCodeEmail(email.toLowerCase());
                if(isEmailSent){
                    setIsValidateModalOpen(true);
                }
            }else {
                setEmailError('Unable to find your account, please try again');
            }

        }catch (error) {
            setLoadingError('Oops! It looks like our service is temporarily unavailable. We\'re working to get things back to normal quickly.');
            setTimeout(()=> setLoadingError(''), 4000)
        } finally {
            setLoading(false);
        }

    }

    const closeEmailVerificationModal = () => {
        setIsValidateModalOpen(false);
    }

    return (
        <div className='password-change-container'>
            <div className='password-change-image'></div>
            <div className='password-change-form'>
                <p className='password-change-form-heading text-center'>{t('forgot-password.heading')}</p>
                <div>
                    <p className='password-change-form-description text-center'>{t('forgot-password.description-1')}</p>
                    <p className='password-change-form-description text-center'>{t('forgot-password.description-2')}</p>
                </div>
                {!isEmailVerified ?
                    <>
                        <div className='password-change-input'>
                            <input name='email' type='email' placeholder='Enter your email' value={email} onChange={handleEmailChange}/>
                        </div>
                        {!!emailError  && <p className='error-message password-change-error'>{emailError}</p>}
                        {loadingError && <InputError message={loadingError} />}
                        {!loading ?
                            <button className='primary-button continue-button' disabled={!email} onClick={handleContinue}>Continue</button> :
                            <div className='loading-button'><LoadingButton /></div>
                        }
                    </> : <NewPasswordContainer username={email.toLowerCase()}/>

            }
            </div>
            <EmailVerificationModal isModalOpen={isValidateCodeModalOpen} closeModal={closeEmailVerificationModal} email={email.toLowerCase()}/>
        </div>
    )
}


export default ForgotPassword;
