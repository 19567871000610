import {useDispatch, useSelector} from "react-redux";
import {clearError, selectResponseError} from "../../core/redux/slices/errorSlice";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


export const ServerNotReachableError = () => {

    const apiError = useSelector(selectResponseError).status;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (apiError && apiError >= 500) {
            navigate('/error-page');
            dispatch(clearError());
        }
    }, [apiError]);

    return null;

}
