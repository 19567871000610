import {Icon} from "@iconify/react";
import React from "react";
import './ChefNavbar.css';
import {useLocation} from "react-router-dom";

type ChefNavbarProps = {
    handleChefDashboardMenu: () => void;
}

const ChefNavbar: React.FC<ChefNavbarProps> = ({handleChefDashboardMenu}) => {

    const location = useLocation();

    const getTitle = () => {
        switch(location.pathname.split('/')[3]) {
            case 'menu':
                return 'My Menu';
            case 'orders':
                return 'My Orders';
            case 'onboarding':
                return 'Onboarding';
            case 'offers':
                return 'Offers';
            case 'support':
                return 'Support';
            case 'payments':
                return 'Payments';
            case 'schedule':
                return 'Schedule';
            case 'meals':
                return 'Meals'
            default:
                return 'My Profile';
        }
    }

    return (
        <div className='chef-navbar'>
            <Icon icon='line-md:close-to-menu-alt-transition' fontSize={32} onClick={handleChefDashboardMenu}/>
            <p className='chef-navbar-heading'>{getTitle()}</p>
        </div>
    )
}

export default ChefNavbar;
