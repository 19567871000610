import React from "react";
import Order from "../../../../../model/order/Order";

type OrderSummaryProps = {
    order?: Order,
}

const OrderSummary: React.FC<OrderSummaryProps> = ({order}) => {

    return (
        <div className='preview-modal-row order-item-list'>
            {/*{*/}
            {/*    order?.orderItems.map((orderItem) => (*/}
            {/*        <div className='order-preview-item'>*/}
            {/*            <div className='order-item-column'>*/}
            {/*                <img className='order-item-image' src={orderItem.itemImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN} alt='food item'/>*/}
            {/*                <p>{orderItem.itemName}</p>*/}
            {/*            </div>*/}
            {/*            <div className='order-item-column-2'>*/}
            {/*                <p>${orderItem.itemPricePerUnit*orderItem.itemQuantity}</p>*/}
            {/*                <p className='grey-light-text order-quantity'>Qty: {orderItem.itemQuantity}</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ))*/}
            {/*}*/}
        </div>
    )
}

export default OrderSummary;
