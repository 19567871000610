// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.description-container {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 50px 80px 50px;
    justify-content: space-evenly;
}

@media screen and (max-width: 600px) {
    .description-container {
        gap: 30px;
        padding-bottom: 50px;
        flex-direction: column;
    }

}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/user-roadmap/UserRoadmap.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,eAAe;IACf,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI;QACI,SAAS;QACT,oBAAoB;QACpB,sBAAsB;IAC1B;;AAEJ","sourcesContent":["\n\n.description-container {\n    display: flex;\n    flex-wrap: wrap;\n    padding: 50px 50px 80px 50px;\n    justify-content: space-evenly;\n}\n\n@media screen and (max-width: 600px) {\n    .description-container {\n        gap: 30px;\n        padding-bottom: 50px;\n        flex-direction: column;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
