import bcrypt from 'bcryptjs';

class HashingService {

    static hashPassword = async (password: string): Promise<string> => {

        try {
           return await bcrypt.hash(password, 10);
        }catch (error){
            return password;
        }
    }
}

export default HashingService;
