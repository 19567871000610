import {AnyAction, combineReducers} from "@reduxjs/toolkit";
import modalReducer from "../slices/modalSlice";
import profileManagementReducer from "../slices/profileManagementSlice";
import customerReducer from "../slices/customerSlice";
import addressModalsReducer from "../slices/addressChangeModalSlice";
import chefReducer from '../slices/chefSlice';
import menuModalReducer from '../slices/menuModalSlice';
import addressSuggestionReducer from '../slices/addressSuggestionSlice';
import chefRetrievalListReducer from '../slices/chefListSlice';
import loginReducer from '../slices/loginSlice';
import errorReducer from '../slices/errorSlice';

const appReducer = combineReducers({
    modal: modalReducer,
    login: loginReducer,
    profileManagement: profileManagementReducer,
    customerReducer: customerReducer,
    chef: chefReducer,
    addressModals: addressModalsReducer,
    menuModal: menuModalReducer,
    addressSuggestion: addressSuggestionReducer,
    availableChefList: chefRetrievalListReducer,
    error: errorReducer,
});

export const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === 'logout') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default appReducer;
