import './OrderPreviewModal.css';
import React, {useState} from "react";
import Modal from "react-modal";
import {Icon} from "@iconify/react";
import Order from "../../../../model/order/Order";
import OrderSummary from "./order-summary/OrderSummary";
import CustomerDetails from "./customer-details/CustomerDetails";
import AmountDetails from "./amount-details/AmountDetails";


type OrderPreviewModalProps = {
    isPreviewModalOpen: boolean,
    handleCloseModal: () => void,
    order?: Order,
    animationClass: string
}

const OrderPreviewModal: React.FC<OrderPreviewModalProps> = ({isPreviewModalOpen, handleCloseModal, order}) => {

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(38,38,38,0.23)',
        },
    };

    const [activeButton, setActiveButton] = useState('Summary');

    const buttons = ['Summary', 'Customer Info', 'Amount'];

    return (
        <Modal className='order-preview-modal basic-modal' isOpen={isPreviewModalOpen} onRequestClose={handleCloseModal} style={customStyles}>
            <div className='preview-modal-row'>
                <div className='preview-modal-heading-row'>
                    <div className='heading-column'>
                        <h2>Order: {order?.id}</h2>
                        {/*<p className='grey-light-text order-date'>Order date: <span className='order-date-detail'>{order?.dateOfOrder.toDateString()}</span></p>*/}
                    </div>
                    <div className='heading-right-column heading-column'>
                        <Icon icon='carbon:close-outline' className='preview-modal-close-button cursor' fontSize={24} onClick={handleCloseModal}/>
                        <p className='delivery-option'>{order?.orderDeliveryOptions}</p>
                    </div>
                </div>
            </div>
            <div className='preview-modal-buttons-container'>
                <div>
                    {buttons.map((button) => (
                        <div className={`preview-modal-button ${activeButton === button ? 'active' : ''} `} onClick={()=> setActiveButton(button)}>{button}</div>
                    ))}
                </div>
                <hr />
            </div>
            {activeButton === 'Summary' && <OrderSummary order={order}/>}
            {activeButton === 'Customer Info' && <CustomerDetails order={order}/>}
            {activeButton === 'Amount' && <AmountDetails order={order}/>}
        </Modal>
    )
}

export default OrderPreviewModal;
