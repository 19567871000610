// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1baulvz {
    display: none;
}

.password-requirements {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 16px;
}

.password-requirements p {
    display: flex;
    gap: 10px;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/password-validation/PasswordForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".css-1baulvz {\n    display: none;\n}\n\n.password-requirements {\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    border-radius: 16px;\n}\n\n.password-requirements p {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
