import './AddCategoryModal.css';
import Modal from "react-modal";
import React, {useState} from "react";
import {customStyles} from "../../../../chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {Icon} from "@iconify/react";
import useFormInput from "../../../../../custom-hooks/useFormInput";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../../../core/redux/store";
import ChefService from "../../../../../core/services/ChefService";
import {selectChef, setFoodCategories} from "../../../../../core/redux/slices/chefSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useTranslation} from "react-i18next";
import LoadingButton from "../../../../../components/shared/loader-button/LoadingButton";
import {capitalizeFirstAlphabet} from "../../../../../core/utils/InputManipulation";

type AddCategoryModalProps = {
    isAddCategoryModalOpen: boolean,
    closeModal: () => void,
    setFoodType: (foodType: string) => void,
}

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({isAddCategoryModalOpen, closeModal, setFoodType}) => {

    const category = useFormInput('', true);
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('common');
    const chef = useSelector(selectChef);

    const handleNewCategory = async () => {
        category.validate(category.value);
        if (category.value){
            try {
                setIsLoading(true);
                await apiDispatch(ChefService.addFoodCategory({
                    id: chef.id as string,
                    category: capitalizeFirstAlphabet(category.value)
                }));
                setFoodType(capitalizeFirstAlphabet(category.value));
            } catch (error) {
                setError(t('api-error-message'));
                setTimeout(()=> setError(''),2000);
            }finally {
                setIsLoading(false);
                if (!error){closeModal()}
            }
        }
    }

    return (
        <Modal isOpen={isAddCategoryModalOpen} style={customStyles} className='basic-modal add-new-category-modal' onRequestClose={closeModal}>
            <div>
                <div className='add-category-modal-heading'>
                    <p>Add new category</p>
                    <Icon icon='material-symbols-light:close' fontSize={24} onClick={closeModal} className='close-profile-image-change-modal'/>
                </div>
                <div className='add-category-content'>
                    <div>
                        <input type='text' value={category.value} onChange={category.onChange} className='add-item-input' placeholder='Enter category name'/>
                        {category.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    {!isLoading ?
                        <button className='primary-button add-category-button' onClick={handleNewCategory}>Add</button>
                        : <div className='loading-button'><LoadingButton /></div>
                    }
                    {error && <div className='error-message'>{error}</div>}
                </div>
            </div>
        </Modal>
    )
}

export default AddCategoryModal;
