import './AppsComingSoon.css';
import appsImage from "../../../../assets/images/coming-soon-banners/app-coming-soon.png"

const AppsComingSoon = () => {

    return (
        <div className='apps-coming-soon-banner'>
            <div className='description'>
                <p className='apps-coming-soon-heading'>Coming soon</p>
                <p className='apps-coming-soon-description'>We're thrilled to announce that our new mobile application is on the way! Get ready to experience all the features you love with the added convenience of accessing them directly from your smartphone</p>
            </div>
            <div className='column-2'>
                <img className='empty-apps-image' src={appsImage} alt='apps'/>
            </div>
        </div>
    )
}

export default AppsComingSoon;
