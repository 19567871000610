// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--grey2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-container {
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.chef-hat {
    position: absolute;
    bottom: 90px;
    animation: moveUpDown 2s ease-in-out infinite;
    width: 100px;
}

.food-plate {
    position: relative;
    width: 100px;
}

@keyframes moveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-50px);
    }
}


`, "",{"version":3,"sources":["webpack://./src/components/shared/loaders/PageChangeLoader/PageChangeRouter.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,wBAAwB;IACxB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".loader-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background: var(--grey2);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999;\n}\n\n.loader-container {\n    position: relative;\n    width: 200px;\n    height: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n}\n\n.chef-hat {\n    position: absolute;\n    bottom: 90px;\n    animation: moveUpDown 2s ease-in-out infinite;\n    width: 100px;\n}\n\n.food-plate {\n    position: relative;\n    width: 100px;\n}\n\n@keyframes moveUpDown {\n    0%, 100% {\n        transform: translateY(0);\n    }\n    50% {\n        transform: translateY(-50px);\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
