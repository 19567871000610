import './LoginPage.css';
import LoginCard from "./login-card/LoginCard";
import React from "react";

const LoginPage = () => {

    return (
      <div className='login-container'>
              <LoginCard />
      </div>
    )
}

export default LoginPage;
