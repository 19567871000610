import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface AddressChangeModalState {
    isAddressChangeModalOpen: boolean;
    isAddressSelectModalOpen: boolean;
}

const initialState: AddressChangeModalState = {
    isAddressChangeModalOpen : false,
    isAddressSelectModalOpen: false,
};

const addressModalSlice = createSlice({
    name: 'addressModals',
    initialState,
    reducers: {
        openAddressSelectModal : (state) => {
            state.isAddressSelectModalOpen = true;
        },
        closeAddressSelectModal : (state) => {
            state.isAddressSelectModalOpen = false;
        },
        openAddressChangeModal: (state) => {
            state.isAddressChangeModalOpen = true;
        },
        closeAddressChangeModal: (state) => {
            state.isAddressChangeModalOpen = false;
        },
    },
});

export const { openAddressChangeModal, closeAddressChangeModal,
                openAddressSelectModal, closeAddressSelectModal} = addressModalSlice.actions;

export const selectChangeAddressModalState = (state: RootState) => state.addressModals.isAddressChangeModalOpen;

export const selectAddressChooseModalState = (state: RootState) => state.addressModals.isAddressSelectModalOpen;

export default addressModalSlice.reducer;
