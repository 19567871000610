import './LoadingButton.css';

const LoadingButton = () => {
    return (
        <div className="button-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoadingButton;
