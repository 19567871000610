// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-menu-container {
    flex: 1 1;
    gap: 16px;
    background: white;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-menu-image {
    width: 200px;
    height: 200px;
}

.empty-menu-heading {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .empty-menu-container {
        height: 80vh;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/meal-plans/empty-meals-list/EmptyMealPlans.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,SAAS;IACT,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".empty-menu-container {\n    flex: 1;\n    gap: 16px;\n    background: white;\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.empty-menu-image {\n    width: 200px;\n    height: 200px;\n}\n\n.empty-menu-heading {\n    text-align: center;\n}\n\n@media screen and (max-width: 600px) {\n    .empty-menu-container {\n        height: 80vh;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
