// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-container {
    width: 450px;
    background: transparent;
    border-radius: 8px;
    box-shadow: var(--box-shadow-2);
}

.checkout-button {
    color: black;
    display: flex;
    position: relative;
    align-items: center;
}

.cart-items-count {
    top: -10px;
    left: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--primary-color);
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    position: absolute;
}

.cart-items-count.black-background {
    background: rgb(0, 0, 0, 0.65);
    color: var(--primary-color);
}

.no-checkout-image {
    width: 200px;
    height: 150px;
}

.no-checkout-container {
    padding: 20px;
    text-align: center;
}

.checkout-heading {
    padding: 20px;
    border-bottom: 1px solid gainsboro;
}

.no-checkout-container p {
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .checkout-container {
        width: 200px;
    }
    .no-checkout-image {
        width: 70px;
        height: 70px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/checkout-cart/CheckoutButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;IACA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".checkout-container {\n    width: 450px;\n    background: transparent;\n    border-radius: 8px;\n    box-shadow: var(--box-shadow-2);\n}\n\n.checkout-button {\n    color: black;\n    display: flex;\n    position: relative;\n    align-items: center;\n}\n\n.cart-items-count {\n    top: -10px;\n    left: 8px;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    background: var(--primary-color);\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: 600;\n    position: absolute;\n}\n\n.cart-items-count.black-background {\n    background: rgb(0, 0, 0, 0.65);\n    color: var(--primary-color);\n}\n\n.no-checkout-image {\n    width: 200px;\n    height: 150px;\n}\n\n.no-checkout-container {\n    padding: 20px;\n    text-align: center;\n}\n\n.checkout-heading {\n    padding: 20px;\n    border-bottom: 1px solid gainsboro;\n}\n\n.no-checkout-container p {\n    margin-top: 10px;\n}\n\n@media screen and (max-width: 600px) {\n    .checkout-container {\n        width: 200px;\n    }\n    .no-checkout-image {\n        width: 70px;\n        height: 70px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
