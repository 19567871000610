import './PrimaryButtonLoader.css';
import React from "react";

type PrimaryButtonLoaderProps = {
    className?: string,
}

const PrimaryButtonLoader: React.FC<PrimaryButtonLoaderProps> = ({className}) => {

    return (
        <div className={`${className ? className : 'primary-button'}`}>
            <span className="primary-button-loader"></span>
        </div>
    )
}

export default PrimaryButtonLoader;
