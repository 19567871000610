// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-modal {
    position: relative;
    z-index: 1010 !important;
    width: 300px;
    transform: translateX(-100%);
}

.menu-modal-box {
    background: white;
    height: 100vh;
}

.menu-modal-box:focus {
    outline: none;
}
.menu-modal:focus {
    outline: none;
}

.button-signup:hover {
    background: #fff4e8;
}


.modal-open {
    transform: translateX(0%);
    transition: transform 500ms ease-in;
}

.modal-closed {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/menu-modal/MenuModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;IACI,4BAA4B;IAC5B,uCAAuC;AAC3C","sourcesContent":[".menu-modal {\n    position: relative;\n    z-index: 1010 !important;\n    width: 300px;\n    transform: translateX(-100%);\n}\n\n.menu-modal-box {\n    background: white;\n    height: 100vh;\n}\n\n.menu-modal-box:focus {\n    outline: none;\n}\n.menu-modal:focus {\n    outline: none;\n}\n\n.button-signup:hover {\n    background: #fff4e8;\n}\n\n\n.modal-open {\n    transform: translateX(0%);\n    transition: transform 500ms ease-in;\n}\n\n.modal-closed {\n    transform: translateX(-100%);\n    transition: transform 500ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
