// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-total {
    flex: 1 1;
    border-radius: 16px;
    height: fit-content;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.13);
}

.payment-summary {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light);
}

.tip-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-light);
}

.place-order-button {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .order-total {
        border-top: 2px solid var(--border-light);
        box-shadow: none;
        border-radius: 0;
    }

    .place-order-button {
        width: 90%;
    }

    .place-order-button-wrapper {
        background: white;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        bottom: 10px;
    }

    .order-total .content {
        padding-bottom: 70px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/checkout-page/order-total/OrderTotal.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,mBAAmB;IACnB,mBAAmB;IACnB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;IACpB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;IACpB,4CAA4C;AAChD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,yCAAyC;QACzC,gBAAgB;QAChB,gBAAgB;IACpB;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,iBAAiB;QACjB,WAAW;QACX,SAAS;QACT,2BAA2B;QAC3B,eAAe;QACf,YAAY;IAChB;;IAEA;QACI,oBAAoB;IACxB;AACJ","sourcesContent":[".order-total {\n    flex: 1;\n    border-radius: 16px;\n    height: fit-content;\n    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.13);\n}\n\n.payment-summary {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding-bottom: 20px;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.tip-detail {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding-bottom: 20px;\n    border-bottom: 1px solid var(--border-light);\n}\n\n.place-order-button {\n    width: 100%;\n}\n\n@media screen and (max-width: 600px) {\n    .order-total {\n        border-top: 2px solid var(--border-light);\n        box-shadow: none;\n        border-radius: 0;\n    }\n\n    .place-order-button {\n        width: 90%;\n    }\n\n    .place-order-button-wrapper {\n        background: white;\n        width: 100%;\n        left: 50%;\n        transform: translateX(-50%);\n        position: fixed;\n        bottom: 10px;\n    }\n\n    .order-total .content {\n        padding-bottom: 70px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
