import {Icon} from "@iconify/react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectChef, setChef} from "../../../../core/redux/slices/chefSlice";
import {useTranslation} from "react-i18next";
import './PersonalDetails.css';
import useFormInput from "../../../../custom-hooks/useFormInput";
import Chef from "../../../../model/Chef";
import ChefService from "../../../../core/services/ChefService";
import store from "../../../../core/redux/store";
import ErrorMessage from "../save-error-message/ErrorMessage";
import SavedProfileMessage from "../saved-profile-message/SavedProfileMessage";
import SpecialityCuisinesList from "./speciality-cuisines-list/SpecialityCuisinesList";
import {formatPhoneNumber, removePhoneNumberBrackets} from "../../../../core/utils/InputManipulation";
import {validatePhoneNumberSyntax} from "../../../../core/utils/Validation";
import {unwrapResult} from "@reduxjs/toolkit";
import convertLocationToLongitudeAndLatitude from "../../../../core/utils/LocationToLongitudeAndLatitudeConverter";


export type ButtonState = 'idle' | 'save' | 'saving' | 'error' | 'saved' | 'loading';

const PersonalDetails = () => {

    const chef = useSelector(selectChef);

    const name = useFormInput(chef.name, true);
    const phone = useFormInput(chef.phoneNumber, true);
    const email = useFormInput(chef.credentials?.username, true);
    const streetAddress = useFormInput(chef.addresses.at(-1)?.streetAddress, true);
    const city = useFormInput(chef.addresses.at(-1)?.city, true);
    const postalCode = useFormInput(chef.addresses.at(-1)?.postalCode, true);
    const [postalCodeError, setPostalCodeError] = useState('');
    const [cuisines, setCuisines] = useState(chef.specialityCuisines);
    const [cuisinesMissingError, setCuisinesMissingError] = useState(false);
    const bio = useFormInput(chef.bio, false);
    const [phoneError, setPhoneError] = useState('');
    const [isEditModeOn, setIsEditModeOn] = useState(false);
    const { t } = useTranslation('ChefDashboard');
    const dispatch = useDispatch<typeof store.dispatch>();
    const [buttonState, setButtonState] = useState<ButtonState>('idle');


    const handleDeleteCuisine = (cuisine: string) => {
        setCuisines(prev => prev.filter((cuisineName)=> cuisineName !== cuisine));
    }

    const handleEditPersonalDetails = () => {
        setIsEditModeOn(true);
        setButtonState('save');
    }

    const validateForm = () => {

        name.validate(name.value);
        phone.validate(phone.value);
        email.validate(email.value);
        streetAddress.validate(streetAddress.value);
        city.validate(city.value);
        postalCode.validate(postalCode.value);

        if (cuisines.length < 1 )
        {
            setCuisinesMissingError(true);
            return false;
        }
        return name.value && phone.value && email.value && streetAddress.value && city.value && postalCode.value ;
    };

    const resetButtonState = () => {
        setTimeout(()=>{
            setIsEditModeOn(false);
            setButtonState('idle');
        },2000)
    }

    const resetValues = () => {
        name.reset();
        phone.reset();
        streetAddress.reset();
        city.reset();
        postalCode.reset();
    }

    const handleSavePersonalDetails = async () => {
        if(!validateForm()){
            return;
        }

        if (!validatePhoneNumberSyntax(phone.value)){
            setPhoneError('Please enter valid phone number');
            return;
        }

        let location;

        try {
            location = await convertLocationToLongitudeAndLatitude(postalCode.value, streetAddress.value.concat(city.value))
        }catch (error) {
            setPostalCodeError('Enter valid postal code');
            setTimeout(() => setPostalCodeError(''), 3000);
            return;
        }

        const address = {
            streetAddress: streetAddress.value,
            city: city.value,
            postalCode: postalCode.value,
            longitude: location && location.longitude,
            latitude: location && location.longitude,

        }

        const chefToSave: Chef = {
            ...chef,
            name: name.value,
            phoneNumber: removePhoneNumberBrackets(phone.value),
            emailAddress: email.value,
            addresses: [...chef.addresses,address],
            bio: bio.value,
            specialityCuisines: cuisines,
        }

        try{
            const response = await dispatch(ChefService.savePersonalDetail(chefToSave));
            const personalInformationSaveResponse: Chef = unwrapResult(response);
            dispatch(setChef(personalInformationSaveResponse));
            setButtonState('saved');
        }catch (error){
            resetValues();
            setButtonState('error');
        }finally {
            resetButtonState();
        }
    }

    const handlePhoneNumber = (event : React.ChangeEvent<HTMLInputElement>) => {
        phone.setValue(formatPhoneNumber(event.target.value));
        phone.setIsMissing(false);
        setPhoneError('');
    }

    const handleAddCuisines = (cuisines : string[]) => {
        setCuisinesMissingError(false);
        setCuisines(cuisines);
    }

    return (
        <div className='personal-details box-card-shadow'>
            <div className='personal-detail-heading-container'>
                <h3>Personal info</h3>
                {buttonState === 'idle' && <div className='edit-personal-details-button cursor' onClick={handleEditPersonalDetails}>Edit personal info</div>}
                {buttonState === 'save' && <div className='edit-personal-details-button cursor' onClick={handleSavePersonalDetails}>Save personal info</div>}
                {buttonState === 'saved' && <SavedProfileMessage />}
                {buttonState === 'error' && <ErrorMessage message='Oops! Something went wrong '/>}
            </div>

            <div className={`personal-details-container ${isEditModeOn ? 'editable': 'notEditable'}`}>
                <div className='details-row'>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Your Name</div>
                        <input type='text' disabled={!isEditModeOn} value={name.value} onChange={name.onChange} className={`details-input`}/>
                        {name.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Your Phone</div>
                        <input type='text' disabled={!isEditModeOn} value={phone.value} onChange={handlePhoneNumber} className={`details-input`}/>
                        {phone.isMissing && <div className='error-message'>This field is required</div>}
                        {phoneError && <div className='error-message'>{phoneError}</div>}
                    </div>
                </div>
                <div className='details-row'>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Your Email</div>
                        <input type='text' disabled={true} value={email.value}  className={`details-input email-disabled`}/>
                    </div>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Street Address</div>
                        <input type='text' disabled={!isEditModeOn} value={streetAddress.value} onChange={streetAddress.onChange} className={`details-input`}/>
                        {streetAddress.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                </div>
                <div className='details-row'>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>City</div>
                        <input type='text' disabled={!isEditModeOn} value={city.value} onChange={city.onChange} className={`details-input`}/>
                        {city.isMissing && <div className='error-message'>This field is required</div>}
                    </div>
                    <div>
                        <div className={`${isEditModeOn && 'detail-label'}`}>Postal Code</div>
                        <input type='text' disabled={!isEditModeOn} value={postalCode.value} onChange={postalCode.onChange} className={`details-input`}/>
                        {postalCode.isMissing && <div className='error-message'>This field is required</div>}
                        {postalCodeError && <div className='error-message'>Invalid postal code</div>}
                    </div>
                </div>
                <div className='details-row'>
                    <div>
                        <div className={`${cuisinesMissingError ? 'error-message' : ''} ${isEditModeOn && 'detail-label'}`}>Speciality Cuisine</div>
                        <div className='cuisines detail-label'>
                            {isEditModeOn ?
                                <SpecialityCuisinesList chef={chef} onCuisinesChange={handleAddCuisines}/> :
                                <div className='cuisine-list-show'>
                                    {cuisines.map((cuisine, index) => (
                                        <div className={`cuisine-item ${isEditModeOn ? 'edit-cuisine-item':''}`} key={index}>
                                            <p>{cuisine}</p>
                                            {isEditModeOn && <Icon icon='material-symbols-light:cancel' fontSize={20} onClick={() => handleDeleteCuisine(cuisine)} className='delete-cuisine-item cursor'/>}
                                            {(!isEditModeOn && index !== cuisines.length-1) && <Icon icon='radix-icons:dot-filled' fontSize={24} className='dot-icon'/>}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='bio-input-wrapper'>
                    <div className={`${isEditModeOn && 'detail-label'}`}>Your Bio</div>
                    <textarea rows={4} disabled={!isEditModeOn} value={bio.value} onChange={bio.onChange} className={`details-input bio-input`} placeholder={t('bio-placeholder')} style={{resize: 'none'}}/>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails;
