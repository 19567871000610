import { useNavigate } from "react-router-dom";
import './FindHomeChefs.css';
import {useDispatch, useSelector} from "react-redux";
import store from "../../../core/redux/store";
import {selectChosenAddress} from "../../../core/redux/slices/profileManagementSlice";
import {useTranslation} from "react-i18next";
import ChefPublicService from "../../../core/services/ChefPublicService";

const FindHomeChefs = () => {

    const navigateTo = useNavigate();
    const dispatch = useDispatch<typeof store.dispatch>();
    const userLocation = useSelector(selectChosenAddress);
    const { t }= useTranslation('common');

    const retrieveChefs = () => {
        dispatch(ChefPublicService.retrieveChefs({latitude: userLocation?.latitude, longitude: userLocation?.longitude,radius: 50}));
        navigateTo('/availableChefs');
    }

    return (
        <button className='link-black chef-button' onClick={retrieveChefs} disabled={!userLocation}>{t('find-home-chefs')}</button>
    )
}

export default FindHomeChefs;
