import './CreateNewOrderModal.css';
import {Icon} from "@iconify/react";
import Modal from "react-modal";
import React, {useState} from "react";
import {customStyles} from "../chef-menu-items/chef-menu-item-card/ChefMenuItemCard";
import {addToCart, CartItemType, emptyCart, selectCheckoutCart} from "../../../core/redux/slices/customerSlice";
import {useDispatch, useSelector} from "react-redux";
import Chef from "../../../model/Chef";


type CreateNewOrderModalType = {
    isCreateNewOrderModalOpen: boolean,
    closeModal: () => void;
    setChef: () => void;
    cartItem: CartItemType;
    chef: Chef;
}

const CreateNewOrderModal: React.FC<CreateNewOrderModalType> = ({isCreateNewOrderModalOpen, closeModal, setChef, cartItem, chef}) => {

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const dispatch = useDispatch();
    const checkoutCart = useSelector(selectCheckoutCart);

    const handleAnimationEnd = () => {
        setIsButtonClicked(false);
    }

    const handleCreateNewOrder = () => {
        setIsButtonClicked(true);
        dispatch(emptyCart());
        setChef();
        dispatch(addToCart({menuItem: cartItem.menuItem, count: cartItem.count, specialInstructions: cartItem.specialInstructions}));
        closeModal();
    }

    return (
        <Modal isOpen={isCreateNewOrderModalOpen} className='basic-modal create-new-order-modal' style={customStyles} onRequestClose={closeModal}>
            <p style={{textAlign: 'right'}}>
                <Icon className='cursor' icon='mingcute:close-fill' fontSize={24} onClick={closeModal} />
            </p>
            <p className='start-new-order-heading'>Start a new order?</p>
            <p className='description'>Your order has items from {checkoutCart.chef?.name}. To add items from {chef.name} please place a new order.</p>
            <div onClick={handleCreateNewOrder} className={`animated-primary-button primary-button ${isButtonClicked ? 'animate' : ''}`} onAnimationEnd={handleAnimationEnd}>New Order</div>
        </Modal>
    )
}

export default CreateNewOrderModal;
