import { ResponseDTO } from "./ChefService";
import { handleApiError, ResponseError } from "../../error-handling/ErrorHandler";
import { logout, updateTokens } from "../redux/slices/loginSlice";

const protectedApiClient = async <T>(url: string, options: RequestInit = {}): Promise<ResponseDTO<T>> => {
    try {
        let response = await apiRequest(url, options);

        if (response.ok) {
            return response.json();
        }

        try{
            const errorData: ResponseError = await response.json();

            if (errorData.message === 'ACCESS_TOKEN_EXPIRED' || errorData.status == 401) {
                const refreshedResponse = await refreshAccessToken(url, options);
                if (refreshedResponse) {
                    return refreshedResponse;
                }
            }
        } catch (error) {
            throw await handleApiError(response);
        }
        return Promise.reject("Unhandled error scenario");
    } catch (error) {
        if (error instanceof TypeError && !navigator.onLine) {
            throw { message: "No internet connection. Please check your network.", status: 500 } as ResponseError;
        }
        throw await handleApiError(null);
    }
};

const apiRequest = async (url: string, options: RequestInit = {}): Promise<Response> => {
    const { default: store } = await import('../redux/store');
    const { accessToken } = store.getState().login.tokens;

    const headers = new Headers(options.headers);

    if (accessToken) {
        headers.set('Authorization', accessToken);
    } else {
        return await logoutAndRedirectToLogin();
    }

    if (!(options.body instanceof FormData)) {
        headers.set('Content-Type', 'application/json');
    }

    return fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}${url}`, {
        ...options,
        headers,
    });
};

const refreshAccessToken = async (url: string, options: RequestInit = {}): Promise<ResponseDTO<any> | false> => {
    const { default: store } = await import('../redux/store');
    const { refreshToken } = store.getState().login.tokens;

    if (!refreshToken) {
        return await logoutAndRedirectToLogin();
    }

    const refreshResponse = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/auth/refresh`, {
        method: 'POST',
        headers: { 'Refresh-Token': refreshToken }
    });

    if (refreshResponse.ok) {
        console.log(refreshResponse);
        const newAccessToken = refreshResponse.headers.get('Authorization');
        const newRefreshToken = refreshResponse.headers.get('Refresh-Token');

        if (newAccessToken && newRefreshToken) {
            store.dispatch(updateTokens({ accessToken: newAccessToken, refreshToken: newRefreshToken }));
            return await retryRequest(url, options);
        }
    }

    return await logoutAndRedirectToLogin();
};

const retryRequest = async (url: string, options: RequestInit): Promise<ResponseDTO<any>> => {
    const response = await apiRequest(url, options);
    if (response.ok) {
        return response.json();
    }
    throw await handleApiError(response);
};

const logoutAndRedirectToLogin = async (): Promise<never> => {
    const { default: store } = await import('../redux/store');
    store.dispatch({type: 'logout'});
    window.location.href = '/login';

    return Promise.reject("Failed to refresh token. Redirecting to login.");
};

export default protectedApiClient;
