import React, {useEffect, useState} from "react";
import './SignupPage.css';
import useFormInput from "../../custom-hooks/useFormInput";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import store from "../../core/redux/store";
import {Icon} from "@iconify/react";
import SignInOthers from "../../components/shared/sign-in-others/SignInOthers";
import CustomerService from "../../core/services/CustomerService";
import InputError from "../../error-handling/input-error/InputError";
import Customer from "../../model/Customer";
import HashingService from "../../core/services/HashingService";
import {selectUser} from "../../core/redux/slices/loginSlice";
import EmailVerificationModal from "../../components/shared/email-verification-modal/EmailVerificationModal";
import EmailService from "../../core/services/EmailService";
import {validateEmailSyntax, validatePassword, validatePhoneNumberSyntax} from "../../core/utils/Validation";
import {selectEmailVerified, setEmailVerified} from "../../core/redux/slices/profileManagementSlice";
import LoadingButton from "../../components/shared/loader-button/LoadingButton";
import {formatPhoneNumber} from "../../core/utils/InputManipulation";
import PasswordForm from "../../components/shared/password-validation/PasswordForm";
import ChefPublicService from "../../core/services/ChefPublicService";

const SignupPage = () => {

    const [email, setEmail] = useState('');
    const password = useFormInput('',true, true);
    const [passwordError, setPasswordError] = useState('');
    const name = useFormInput('',true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [isSignupClicked, setIsSignupClicked] = useState(false);
    const [isContinueClicked, setIsContinueClicked] = useState(false);
    const [isEmailMissing, setIsEmailMissing] = useState(false);
    const isEmailVerified = useSelector(selectEmailVerified);
    const [emailExistError, setEmailExistError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isOpenEmailVerificationModal, setIsOpenEmailVerificationModalOpen] = useState(false);
    const navigateTo = useNavigate();
    const apiDispatch =  useDispatch<typeof store.dispatch>();
    const currentUser = useSelector(selectUser);
    const [signupError, setSignupError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setEmailVerified(false));
    }, [dispatch]);

    useEffect(() => {
        if (currentUser.loading === 'succeeded' && currentUser.userType === 'customer') {
            navigateTo('/');
        }
    }, [currentUser, navigateTo]);

    const validateForm = () => {
        password.validate(password.value);
        name.validate(name.value);

        if (password.value.length < 1) {
            setPasswordError('This field is required');
        }
        return validatePhoneNumber() && password.value && name.value;
    }

    const validatePhoneNumber = (): boolean => {
        if (!phoneNumber){
            setPhoneNumberError('This field is required');
            return false;
        }else if (!validatePhoneNumberSyntax(phoneNumber)){
            setPhoneNumberError('Please enter a valid phone number');
            return false;
        }
        return true;
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumberError('');
        setPhoneNumber(formatPhoneNumber(event.target.value));
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailMissing(false);
        setEmailExistError(false);
    }

    const handleEmailExistenceCheck = async () => {
        setSignupError('');
        setIsContinueClicked(true);
        if(!email){
            setIsEmailMissing(true);
            return;
        }else {
            if(!validateEmailSyntax(email)){
                setSignupError('Please enter a valid email address');
                return;
            }
        }

        try {
            setIsLoading(true);
            const chefEmailAvailable = await  ChefPublicService.verifyEmailAvailable(email);
            const isEmailExists = await CustomerService.verifyEmailExists(email);
            setSignupError('');

            if(!isEmailExists && chefEmailAvailable){
                try {
                    setEmailExistError(false);
                    await EmailService.sendValidationCodeEmail(email);
                    setIsOpenEmailVerificationModalOpen(true);
                }catch(error){
                    setSignupError('We\'re sorry, but there was a problem sending your validation email. We\'re working to resolve this quickly')
                }
            }
            if (isEmailExists || !chefEmailAvailable) {
                setEmailExistError(true);
            }
        }catch (error){
            setSignupError('Oops! It looks like our service is temporarily unavailable. We\'re working to get things back to normal quickly.');
        }finally {
            setIsLoading(false);
        }
    }

    const handleAnimationEnd = () => {
        setIsSignupClicked(false);
        setIsContinueClicked(false);
    }

    const handleSignup = async () => {
        setSignupError('');
        setIsSignupClicked(true);

        if (!(validatePassword(password.value).isValid)){
            setPasswordError('Password does not meet requirements');
            return;
        }

        const customer: Customer = {
            name: name.value,
            credentials: {username: email, password: await HashingService.hashPassword(password.value)},
            phoneNumber: phoneNumber.replace(/\D/g, ''),
            favoriteChefs: [],
            addresses: []
        }

        if(validateForm()){
            await apiDispatch(CustomerService.signUp(customer));
        }
    }

    const closeEmailVerificationModal = () => {
        setIsOpenEmailVerificationModalOpen(false);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordError('');
        password.onChange(event);
    }


    return (
        <div className='signup-wrapper'>
            <div className='signup-container'>
                <div className='signup-heading'>Sign Up</div>
                <div className='form-input-container'>
                    <div className='form-input-row'>
                        <h4>Your Email</h4>
                        <input value={email} onChange={handleEmailChange} placeholder='Enter your email address' disabled={isEmailVerified}/>
                        {emailExistError && <InputError message='Email already exists, try choosing a different email' />}
                        {isEmailMissing && <p className='error-message'>This field is required</p>}
                        {!!signupError  && <InputError message={signupError}/>}
                    </div>
                    {
                        isEmailVerified &&
                        <>
                            <div className='form-input-row'>
                                <h4>Your Name</h4>
                                <input value={name.value} onChange={name.onChange} placeholder='Enter your name'/>
                                {name.isMissing && <p className='error-message'>This field is required</p>}
                            </div>
                            <div className='form-input-row'>
                                <h4>Your Phone</h4>
                                <input value={phoneNumber} onChange={handlePhoneChange} placeholder='Enter your phone' maxLength={14}/>
                                {phoneNumberError && <p className='error-message'>{phoneNumberError}</p>}
                            </div>
                                <div className='form-input-row'>
                                    <h4>Password</h4>
                                    <label>
                                        <PasswordForm>
                                            <input value={password.value} onChange={handlePasswordChange} placeholder='Enter your password' className='password-input' type={showPassword ? 'text':'password'}/>
                                        </PasswordForm>
                                        <Icon icon={showPassword ? 'mdi:eye-off':'mdi:eye'} fontSize={20} className='toggle-password-icon' onClick={() => setShowPassword(!showPassword)}/>
                                    </label>
                                    {passwordError && <p className='error-message'>{passwordError}</p>}
                                </div>
                        </>
                    }
                </div>
                {isLoading ? <div className='signup-loader primary-button'><LoadingButton /></div>: isEmailVerified ?
                    <div className={`primary-button animated-primary-button ${isSignupClicked ? 'animate' : ''}`} onClick={handleSignup} onAnimationEnd={handleAnimationEnd} role='button'>Signup</div> :
                    <div className={`primary-button animated-primary-button ${isContinueClicked ? 'animate' : ''}`} onClick={handleEmailExistenceCheck} onAnimationEnd={handleAnimationEnd}>Continue</div>
                }
                {!isLoading && <SignInOthers text='Or'/>}
                <p className='signup-link-wrapper'>Already have an account? <NavLink className='signup-link link-black' to='/login'>Login</NavLink></p>
            </div>
            <EmailVerificationModal isModalOpen={isOpenEmailVerificationModal} closeModal={closeEmailVerificationModal} email={email} />
        </div>
    );
}

export default SignupPage;
