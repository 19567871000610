import React, { useEffect } from 'react';
import './MenuSubCategories.css';
import {Icon} from "@iconify/react";

interface StickyHeaderProps {
    foodTypes: string[];
    activeFoodType: string;
    setActiveFoodType: (foodType: string) => void;
    refsMap: React.MutableRefObject<Record<string, HTMLDivElement | null>>;
}

const MenuSubCategories: React.FC<StickyHeaderProps> = ({ foodTypes, activeFoodType, setActiveFoodType, refsMap}) => {

    useEffect(() => {
        const sections = document.querySelectorAll('.chef-menu-items-section');
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionHeading = entry.target.querySelector('h3');
                    if (sectionHeading) {
                        setActiveFoodType(sectionHeading.innerText);
                    }
                }
            });
        }, options);

        sections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, [setActiveFoodType]);

    const handleClick = (foodType: string) => {
        setActiveFoodType(foodType);
        const element = refsMap.current[foodType];
        if (element) {
            const yOffset = -120;
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <div className="sticky-header flex column gap-10">
            <div className="header-content">
                <h3>Menu</h3>
                <div className='menu-search-bar'>
                    <Icon icon='iconamoon:search-light' className='search-icon'/>
                    <input placeholder='Search in menu'/>
                </div>
            </div>
            <div className="carousel-filter">
                {foodTypes.map(foodType => (
                    <button
                        key={foodType}
                        className={activeFoodType === foodType ? 'active' : ''}
                        onClick={() => handleClick(foodType)}>
                        {foodType.charAt(0).toUpperCase() + foodType.slice(1)}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default MenuSubCategories;
