import './AvailabeChefsList.css';
import React from "react";
import DiscountOffersCarousel from "./discount-offers-carousel/DiscountOffersCarousel";
import BogofOffersCarousel from "./bogof-offers-carousel/BogofOffersCarousel";

const AvailableChefsList = () => {

    return (
        <div className='available-chef-list'>
            <DiscountOffersCarousel />
            <BogofOffersCarousel />
        </div>
    )
}

export default AvailableChefsList;
