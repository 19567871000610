import './BecomeAChef.css';
import BecomeChefWelcomeContainer from "./become-chef-welcome-container/BecomeChefWelcomeContainer";
import RegistrationForm from "./registration-form/RegistrationForm";
import WhyUs from "./why-us/WhyUs";
import Roadmap from "./roadmap/Roadmap";
import NewChefGuidelines from "./new-chef-guidelines/NewChefGuidelines";
import NewChefOfferModal from "./new-chef-offer/NewChefOfferModal";
import BecomeChefFrequentlyAskedQuestions from "./frequently-asked-questions/BecomeChefFrequentlyAskedQuestions";

const BecomeAChef = () => {

    return (
        <div className='registration-container'>
            <BecomeChefWelcomeContainer />
            <RegistrationForm />
            <WhyUs />
            <Roadmap />
            <NewChefGuidelines />
            <NewChefOfferModal />
            <BecomeChefFrequentlyAskedQuestions />
        </div>
    )
}

export default BecomeAChef;
