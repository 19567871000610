// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/homepage-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find-and-hire-chef-wrapper {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
    background-size: cover;
    display: flex;
    gap: 80px;
    flex-wrap: wrap;
    padding: 80px 126px 80px 126px;
}

.meet-chefs-content, .hire-chefs-content {
    display: flex;
    gap: 40px;
}

.meet-chefs-content img, .hire-chefs-content img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.meet-chefs-content > div, .hire-chefs-content > div {
    flex: 1 1;
}

.content-description-wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    flex-direction: column;
}

.content-description-wrapper .description {
    color: #47453E;
}

@media screen and (max-width: 600px) {
    .find-and-hire-chef-wrapper {
        padding: 50px 25px;
        gap: 40px;
        flex-direction: column;
    }
    .meet-chefs-content, .hire-chefs-content {
        flex-direction: column;
        gap: 30px;
    }

    .content-image {
        height: 100%;
        width: 100%;
    }

    .content-description-wrapper .description {
        padding-left: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/find-and-hire-chef/FindAndHireChef.css"],"names":[],"mappings":"AAAA;IACI,oEAAkF;IAClF,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,aAAa;IACb,SAAS;IACT,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,sBAAsB;IAC1B;IACA;QACI,sBAAsB;QACtB,SAAS;IACb;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".find-and-hire-chef-wrapper {\n    background: url(\"../../../assets/images/homepage-background.png\") no-repeat center;\n    background-size: cover;\n    display: flex;\n    gap: 80px;\n    flex-wrap: wrap;\n    padding: 80px 126px 80px 126px;\n}\n\n.meet-chefs-content, .hire-chefs-content {\n    display: flex;\n    gap: 40px;\n}\n\n.meet-chefs-content img, .hire-chefs-content img {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n}\n\n.meet-chefs-content > div, .hire-chefs-content > div {\n    flex: 1;\n}\n\n.content-description-wrapper {\n    display: flex;\n    gap: 20px;\n    justify-content: space-evenly;\n    flex-direction: column;\n}\n\n.content-description-wrapper .description {\n    color: #47453E;\n}\n\n@media screen and (max-width: 600px) {\n    .find-and-hire-chef-wrapper {\n        padding: 50px 25px;\n        gap: 40px;\n        flex-direction: column;\n    }\n    .meet-chefs-content, .hire-chefs-content {\n        flex-direction: column;\n        gap: 30px;\n    }\n\n    .content-image {\n        height: 100%;\n        width: 100%;\n    }\n\n    .content-description-wrapper .description {\n        padding-left: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
