// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-checkbox-container {
    display: flex;
    flex-direction: column;
}

.filter-orders {
    padding: 20px;
    display: flex;
    gap: 10px;
    width: 210px;
    flex-direction: column;
    horiz-align: center;
}

.filter-action-buttons {
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    gap: 10px;
    justify-content: space-between;
}

.reset-button {
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    background: var(--grey2);
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/orders/filter-orders/FilterOrders.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;IACT,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,wBAAwB;AAC5B","sourcesContent":[".filter-checkbox-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.filter-orders {\n    padding: 20px;\n    display: flex;\n    gap: 10px;\n    width: 210px;\n    flex-direction: column;\n    horiz-align: center;\n}\n\n.filter-action-buttons {\n    display: flex;\n    margin-top: 10px;\n    font-weight: 500;\n    gap: 10px;\n    justify-content: space-between;\n}\n\n.reset-button {\n    text-align: center;\n    padding: 10px;\n    border-radius: 4px;\n    background: var(--grey2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
