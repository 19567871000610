import useFormInput from "../../../../../custom-hooks/useFormInput";
import './MealPlanForm.css';
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import {useTranslation} from "react-i18next";
import Availability from "../../meal-plan-availability/Availability";
import FixedMealPlanItems from "../../meal-plan-items/fixed-meal-plan-items/FixedMealPlanItems";
import {MealPlanDuration} from "../../../../../model/meal-plans/MealPlanDuration";
import SimpleErrorMessage from "../../../../../error-handling/error-message/SimpleErrorMessage";
import FixedMeal, {FixedMealItems, MealServing} from "../../../../../model/meal-plans/FixedMeal";
import oneServingIcon from '../../../../../assets/images/serving/one-serving.svg';
import twoServingIcon from '../../../../../assets/images/serving/two-serving.svg';
import fourServingIcon from '../../../../../assets/images/serving/four-serving.svg';
import {useDispatch, useSelector} from "react-redux";
import store from "../../../../../core/redux/store";
import ChefService from "../../../../../core/services/ChefService";
import {selectChef, setMeals} from "../../../../../core/redux/slices/chefSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import PrimaryButtonLoader from "../../../../../components/shared/loaders/primary-button-loader/PrimaryButtonLoader";
import useButton from "../../../../../custom-hooks/useButton";
import SuccessButton from "../../../../../components/shared/buttons/SuccessButton";
import {validatePositiveNumber} from "../../../../../core/utils/InputManipulation";
import CustomizableMeal, {CustomizableMealItems} from "../../../../../model/meal-plans/CustomizableMeal";
import CustomizableMealPlanItems from "../../meal-plan-items/customizable-meal-plan-items/CustomizableMealPlanItems";

export enum MealType {
    FIXED = 'FIXED',
    CUSTOMIZABLE = 'CUSTOMIZABLE',
}

type FixedMealPlanTypeProps = {
    onCloseCreateMealPlan: () => void;
    mealType: MealType;
    mealPlanToEdit?: FixedMeal | CustomizableMeal;
}

const MealPlanForm: React.FC<FixedMealPlanTypeProps> = ({onCloseCreateMealPlan, mealPlanToEdit, mealType}) => {

    const title = useFormInput(mealPlanToEdit ? mealPlanToEdit.title : '', true);
    const price = useFormInput(mealPlanToEdit ? mealPlanToEdit.price : undefined, true);
    const [negativePriceError, setNegativePriceError] = useState(false);
    const [duration, setDuration] = useState<MealPlanDuration>(mealPlanToEdit ? mealPlanToEdit.duration : MealPlanDuration.WEEKLY );
    const [serving, setServing] = useState<MealServing>(mealPlanToEdit ? mealPlanToEdit?.serving : MealServing.ONE);
    const [isDurationMissing, setIsDurationMissing] = useState(false);
    const [availability, setAvailability] = useState<string[]>(mealPlanToEdit ? mealPlanToEdit.availability as string[]: []);
    const { t } = useTranslation('ChefDashboard');
    const { t: tCommon } = useTranslation('common');
    const dispatch = useDispatch<typeof store.dispatch>();
    const id = useSelector(selectChef).id;
    const loadingResponse = useButton('idle');
    const childRef = useRef<{ validateMeals: () => FixedMealItems, resetItemsForm: () => void }>(null);
    const customizableChildRef = useRef<{ validateMeals: () => CustomizableMealItems, resetItemsForm: () => void }>(null);

    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDuration(event.target.value as MealPlanDuration);
    }

    const handleMealServingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServing(event.target.value as MealServing);
    }

    const handleSelectedDaysChange = useCallback((selectedDays: string[]) => {
        setIsDurationMissing(false);
        setAvailability(selectedDays);
    }, []);

    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        price.onChange(event);
        if (+event.target.value > 0) {
            setNegativePriceError(false);
        }
    }

    const priceElement = () => {

        if(duration === MealPlanDuration.ONCE || duration === MealPlanDuration.MULTIPLE) {
            return t('meal-plans.create.meal-form.price-per-meal');
        }
        if (duration === MealPlanDuration.WEEKLY) {
            return t('meal-plans.create.meal-form.price-per-week');
        }
        if (duration === MealPlanDuration.MONTHLY) {
            return t('meal-plans.create.meal-form.price-per-month');
        }
    }

    const ServingDescriptionElement = () => {

        let servingLabel;
        let servingIcon;

        if (serving === MealServing.ONE) {
            servingLabel = t('meal-plans.create.meal-form.one-serving-description');
            servingIcon = oneServingIcon;
        }
        if (serving === MealServing.TWO) {
            servingLabel = t('meal-plans.create.meal-form.two-serving-description');
            servingIcon = twoServingIcon;
        }
        if (serving === MealServing.FOUR) {
            servingLabel = t('meal-plans.create.meal-form.four-serving-description');
            servingIcon = fourServingIcon;
        }

        return (
            <div>
                <p className='grey-2-text'>{servingLabel}</p>
                <div className='serving-icon-wrapper'>
                    <img src={servingIcon} alt='serving icon' className='serving-icon'/>
                </div>
            </div>
        )
    }

    const validateMealItems = (): FixedMealItems | CustomizableMealItems | undefined => {
        if (mealType === MealType.FIXED && childRef.current) {
            return childRef.current.validateMeals();
        } else if (mealType === MealType.CUSTOMIZABLE && customizableChildRef.current) {
            return customizableChildRef.current.validateMeals();
        }
        return undefined;
    }

    const validateDuration = () : boolean => {

        if(duration === MealPlanDuration.ONCE || duration === MealPlanDuration.MULTIPLE) {
            const isAvailabilityDaysSelected = availability?.length > 0;
            if (!isAvailabilityDaysSelected) {
                setIsDurationMissing(true);
            }
            return isAvailabilityDaysSelected;
        }
        return true;
    }

    const validateForm = () => {

        title.validate(title.value);
        const mealItems = validateMealItems();

        const positivePrice = () => {
            if (!price.value) {
                price.setIsMissing(true);
                return false;
            }
            if (!validatePositiveNumber(price.value)){
                setNegativePriceError(true);
                return false;
            }
            return true;
        }

        return validateDuration() && positivePrice() && title.value && price.value && (!!mealItems && Object.keys(mealItems).length > 0);
    }

    const handleMealCreation = async () => {

        if(validateForm()) {


            const mealPlan: FixedMeal | CustomizableMeal = mealType === MealType.FIXED ? {
                title: title.value,
                duration: duration,
                availability: availability,
                price: price.value,
                serving: serving,
                mealItems: validateMealItems() as FixedMealItems
            } : {
                title: title.value,
                duration: duration,
                availability: availability,
                price: price.value,
                serving: serving,
                mealItems: validateMealItems() as CustomizableMealItems
            }
            loadingResponse.setState('loading');

            try {
                let resultAction;
                if (mealPlanToEdit) {
                    resultAction = await dispatch(ChefService.editMealPlan({id: id as string, meal: {...mealPlan, id: mealPlanToEdit.id}, mealType: mealType}));
                } else {
                    resultAction = mealType === MealType.FIXED ?
                        await dispatch(ChefService.addMealPlan({id: id as string, meal: mealPlan, mealType: mealType})) :
                        await dispatch(ChefService.addMealPlan({id: id as string, meal: mealPlan, mealType: mealType}));
                }
                const meals = unwrapResult(resultAction);
                dispatch(setMeals(meals));
                loadingResponse.setState('saved');
                resetForm();
                onCloseCreateMealPlan();
            }catch (error: any) {
                console.log(error.message);
                loadingResponse.setState('idle');
            }
        }
    }

    const resetForm = () => {
        title.reset();
        price.reset();
        setDuration(MealPlanDuration.WEEKLY);
        setServing(MealServing.ONE);
        setAvailability([]);
        childRef.current && childRef.current.resetItemsForm();
    }

    return (
        <div>
            <div className='meal-plan-form-content-wrapper'>
                <div className='meal-plan-form-detail light-border-bottom'>
                    <h3>Meal Plan Details</h3>
                    <div className='flex column gap-20'>
                        <div className='meal-plan-column'>
                            <div className='flex-space-between'>
                                <label>Title</label>
                                {title.isMissing && <SimpleErrorMessage message={tCommon('required-field-error')} />}
                            </div>
                            <input value={title.value} onChange={title.onChange} className='meal-plan-form-input'/>
                        </div>
                        <div className='meal-plan-column'>
                            <div className='flex-space-between'>
                                <label>{t('meal-plans.create.meal-form.serving-heading')}</label>
                            </div>
                            <RadioGroup name="row-radio-buttons-group" className='address-radio-button duration-radio-button full-width-span' onChange={handleMealServingChange}>
                                <FormControlLabel value={MealServing.ONE} control={<Radio checked={serving === MealServing.ONE}/>} label={t('meal-plans.create.meal-form.one-serving-label')} ></FormControlLabel>
                                <FormControlLabel value={MealServing.TWO} control={<Radio checked={serving === MealServing.TWO}/>} label={t('meal-plans.create.meal-form.two-serving-label')} ></FormControlLabel>
                                <FormControlLabel value={MealServing.FOUR} control={<Radio checked={serving === MealServing.FOUR}/>} label={t('meal-plans.create.meal-form.four-serving-label')} ></FormControlLabel>
                            </RadioGroup>
                            <ServingDescriptionElement />
                        </div>
                        <div className='meal-plan-column'>
                            <div className='flex-space-between'>
                                <label>Duration</label>
                                {isDurationMissing && <SimpleErrorMessage
                                    message={t('meal-plans.create.meal-form.availability-missing-message')}/>}
                            </div>
                            <RadioGroup name="row-radio-buttons-group" className='address-radio-button duration-radio-button full-width-span' onChange={handleDurationChange}>
                                <FormControlLabel value={MealPlanDuration.ONCE} control={<Radio checked={duration === MealPlanDuration.ONCE}/>} label={t('meal-plans.create.meal-form.once')} ></FormControlLabel>
                                <FormControlLabel value={MealPlanDuration.MULTIPLE} control={<Radio checked={duration === MealPlanDuration.MULTIPLE}/>} label={t('meal-plans.create.meal-form.multiple')} ></FormControlLabel>
                                <FormControlLabel value={MealPlanDuration.WEEKLY} control={<Radio checked={duration === MealPlanDuration.WEEKLY}/>} label={t('meal-plans.create.meal-form.weekly')} ></FormControlLabel>
                                <FormControlLabel value={MealPlanDuration.MONTHLY} control={<Radio checked={duration === MealPlanDuration.MONTHLY}/>} label={t('meal-plans.create.meal-form.monthly')} ></FormControlLabel>
                            </RadioGroup>
                        </div>
                        {(duration === MealPlanDuration.MULTIPLE || duration === MealPlanDuration.ONCE) &&
                            <Availability onSelectedDaysChange={handleSelectedDaysChange} duration={duration}/>
                        }
                    </div>
                </div>
            </div>
            {mealType === MealType.FIXED && <FixedMealPlanItems ref={childRef} fixedMealItemsToEdit={mealPlanToEdit?.mealItems as FixedMealItems}/>}
            {mealType === MealType.CUSTOMIZABLE && <CustomizableMealPlanItems ref={customizableChildRef} customizableMealItemsToEdit={mealPlanToEdit?.mealItems as CustomizableMealItems}/>}
            <div className='meal-plan-column padding-20'>
                <div className='flex-space-between'>
                    <label>{priceElement()}</label>
                    {price.isMissing && <SimpleErrorMessage message={tCommon('required-field-error')} />}
                    {!price.isMissing && negativePriceError && <SimpleErrorMessage message={tCommon('negative-number-error')} />}
                </div>
                <input value={price.value} onChange={handlePriceChange} className='meal-plan-form-input' type='number'/>
            </div>
            <div className='primary-button-right-end-wrapper padding-20'>
                {loadingResponse.state === 'idle' && (
                    <>
                        <button className='light-grey-button cancel-meal-button' onClick={onCloseCreateMealPlan}>
                            {t('meal-plans.create.meal-form.cancel-meal-button')}
                        </button>
                        <button className='primary-button-200' onClick={handleMealCreation}>
                            {!mealPlanToEdit ? t('meal-plans.create.meal-form.create-meal-button') : t('meal-plans.create.meal-form.update-meal-button')}
                        </button>
                    </>
                )}
                {loadingResponse.state === 'loading' && <PrimaryButtonLoader className='primary-button-200' />}
                {loadingResponse.state === 'saved' && <SuccessButton message='Created' className='primary-button-200'/>}
            </div>
        </div>
    )
}

export default MealPlanForm;
