// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-button-loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /*margin: 15px auto;*/
    position: relative;
    color: black;
    vertical-align: middle;
    display: inline-block;
    animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
    0% {
        box-shadow: -38px -6px, -14px 6px,  14px -6px;
    }
    33% {
        box-shadow: -38px 6px, -14px -6px,  14px 6px;
    }
    66% {
        box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
        box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/loaders/primary-button-loader/PrimaryButtonLoader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,qBAAqB;IACrB,kDAAkD;AACtD;;AAEA;IACI;QACI,6CAA6C;IACjD;IACA;QACI,4CAA4C;IAChD;IACA;QACI,4CAA4C;IAChD;IACA;QACI,2CAA2C;IAC/C;AACJ","sourcesContent":[".primary-button-loader {\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n    /*margin: 15px auto;*/\n    position: relative;\n    color: black;\n    vertical-align: middle;\n    display: inline-block;\n    animation: animloader 1s linear infinite alternate;\n}\n\n@keyframes animloader {\n    0% {\n        box-shadow: -38px -6px, -14px 6px,  14px -6px;\n    }\n    33% {\n        box-shadow: -38px 6px, -14px -6px,  14px 6px;\n    }\n    66% {\n        box-shadow: -38px -6px, -14px 6px, 14px -6px;\n    }\n    100% {\n        box-shadow: -38px 6px, -14px -6px, 14px 6px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
