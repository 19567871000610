import {createAsyncThunk} from "@reduxjs/toolkit";
import Credentials from "../../model/Credentials";
import Customer from "../../model/Customer";
import Chef from "../../model/Chef";
import {setCustomer} from "../redux/slices/customerSlice";
import {setChef} from "../redux/slices/chefSlice";
import {updateTokens} from "../redux/slices/loginSlice";

export type ResponseType = {
    userType: string,
    user: Customer | Chef,
}

export type ResponseError = {
    message: string;
}

class LoginService {

    static validateLogin = createAsyncThunk<ResponseType, Credentials, { rejectValue: ResponseError }>(
    'userLogin',
    async (credentials, { rejectWithValue , dispatch}) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_HOME_EATZ_API_URL}/login`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(credentials),
                });

                const accessToken = response.headers.get('Authorization');
                const refreshToken = response.headers.get('Refresh-Token');

                if(response.ok && accessToken && refreshToken){
                    const data: ResponseType = await response.json();
                    dispatch(updateTokens({accessToken: accessToken, refreshToken: refreshToken}))
                    if(data.userType === 'customer') {
                        dispatch(setCustomer(data.user as Customer));
                    } else if(data.userType === 'chef') {
                        const chefResponse = data.user as Chef;
                        const chef: Chef = {...chefResponse, credentials: {username: chefResponse.emailAddress as string}}
                        dispatch(setChef(chef));
                    }
                    return data;
                }
                else {
                    return rejectWithValue({ message: 'Invalid username or password, please try again'});
                }
            } catch (error) {
                return rejectWithValue({ message: 'Oops! It looks like our service is temporarily unavailable. ' +
                        'We\'re working to get things back to normal quickly .'});
            }
        }
    );

}

export default LoginService;
