import './LoginCard.css';
import React, {FormEvent, useEffect, useState} from 'react';
import SignInOthers from "../../../components/shared/sign-in-others/SignInOthers";
import {useDispatch, useSelector} from "react-redux";
import store from "../../../core/redux/store";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import useFormInput from "../../../custom-hooks/useFormInput";
import {Icon} from "@iconify/react";
import LoginService from "../../../core/services/LoginService";
import {clearError, selectUser} from "../../../core/redux/slices/loginSlice";
import InputError from "../../../error-handling/input-error/InputError";
import LoadingButton from "../../../components/shared/loader-button/LoadingButton";
import PageChangeRouter from "../../../components/shared/loaders/PageChangeLoader/PageChangeRouter";

const LoginCard = () => {

    const username = useFormInput('',true, true);
    const password = useFormInput('',true, true);
    const [showPassword, setShowPassword] = useState(false);
    const {loading, error} = useSelector(selectUser);
    const [animation, setAnimation] = useState(false);
    const [loginError, setLoginError] = useState('');
    const apiDispatch =  useDispatch<typeof store.dispatch>();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [formError, setFormError] = useState(false);
    const requestFromPathname = location.state?.from || '/';


    useEffect(() => {
        if (error) {
            setLoginError(error);
            const timer = setTimeout(() => {
                setLoginError('');
                dispatch(clearError());
            }, 3000);
            setInvalidFormError();

            return () => clearTimeout(timer);
        }
    }, [error, dispatch]);

    useEffect(() => {
        if(requestFromPathname !== '/'){
            setAnimation(true);
        }
        const timer = setTimeout(() => {
            setAnimation(false);
        }, 500);

        return () => clearTimeout(timer);
    }, [requestFromPathname]);

    if (animation) {
        return <PageChangeRouter />;
    }

    const handleUsernameChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        setLoginError('');
        username.onChange(value);
    }

    const handlePasswordChange = (value: React.ChangeEvent<HTMLInputElement>) => {
        setLoginError('');
        password.onChange(value);
    }


    const validateForm = () => {
        username.validate(username.value);
        password.validate(password.value);

        return username.value && password.value;
    }

    const setInvalidFormError = () => {
        setFormError(true);
        setTimeout(() => setFormError(false), 2000);
    }

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        const credentials = {
            username: username.value,
            password: password.value,
        }

        if (!validateForm()){
            setInvalidFormError();
            return;
        }
        const loginResponse = await apiDispatch(LoginService.validateLogin(credentials));

        if (loginResponse.payload && 'userType' in loginResponse?.payload) {
            if (requestFromPathname !== '/') {
                navigate(requestFromPathname, {replace: true});
            } else if (loginResponse.payload.userType === 'chef') {
                navigate('/chef/dashboard', {replace: true})
            }else if(loginResponse.payload.userType === 'customer'){
                navigate('/', {replace: true})
            }
        }
    }


    return (
        <form className={`login-card-container ${formError ? 'shake-animation' : ''}`} onSubmit={handleLogin}>
            <div className='login-heading'>Login to HomeEatz</div>
            <div className='form-input-container'>
                <div className='form-input-row'>
                    <h4>Your Email</h4>
                    <input value={username.value} onChange={handleUsernameChange} placeholder='Enter your email address'/>
                    {username.isMissing && <p className='error-message'>This field is required</p>}
                </div>
                <div className='form-input-row'>
                    <h4>Password</h4>
                    <label>
                        <input value={password.value} onChange={handlePasswordChange} placeholder='Enter your password' className='password-input' type={showPassword ? 'text':'password'}/>
                        <Icon icon={showPassword ? 'mdi:eye-off':'mdi:eye'} fontSize={20} className='toggle-password-icon' onClick={() => setShowPassword(!showPassword)}/>
                    </label>
                    {password.isMissing && <p className='error-message'>This field is required</p>}
                </div>
            </div>
            {loginError && <InputError message={error as string}/>}
            <NavLink to='/forgot-password' className='link-black'><p className='forgot-password-link'>Forgot Password?</p></NavLink>
            {loading === 'idle' || loading === 'failed' || loading === 'succeeded' ?
                <button type='submit' className='primary-button login-form-submit-button' onClick={() => handleLogin}>Login</button> : <div className='loader-button-center primary-button'><LoadingButton /></div>
            }
            <SignInOthers text='Or' />
            <p className='signup-link-wrapper'>Don't have and account? <NavLink className='signup-link link-black' to='/signup'>Signup here</NavLink></p>
        </form>
    );
};

export default LoginCard;
