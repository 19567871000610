import './EmptyMealPlans.css';
import emptyMenuImage from '../../../../assets/images/empty-chef-menu-list.png';
import {useTranslation} from "react-i18next";
import React from "react";

type EmptyMealPlansProps = {
    onClickAdd: () => void;
}

const EmptyMealPlans: React.FC<EmptyMealPlansProps> = ({onClickAdd}) => {

    const { t } = useTranslation('ChefDashboard');

    return (
        <div className='empty-menu-container box-card-shadow'>
            <img src={emptyMenuImage} className='empty-menu-image' alt='empty-menu'/>
            <div className='empty-menu-heading flex column gap-10'>
                <p>{t('empty-meal-plans-heading-part-1')}</p>
                <p>{t('empty-meal-plans-heading-part-2')}</p>
                <p className='primary-button' onClick={onClickAdd}>{t('empty-meal-plans-heading-part-3')}</p>
            </div>
        </div>
    )
}

export default EmptyMealPlans;
