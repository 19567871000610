// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/become-a-chef-roadmap.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/images/become-a-chef-roadmap-mobile.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.become-chef-roadmap-wrapper {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
    background-size: cover;
    padding: 50px 100px 80px 100px;
}

.roadmap-heading {
    margin-bottom: 50px;
}

.become-chef-roadmap {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
    .become-chef-roadmap-wrapper {
        padding: 20px;
        background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center;
        background-size: cover;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/become-chef-page/roadmap/Roadmap.css"],"names":[],"mappings":"AAAA;IACI,oEAAoF;IACpF,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,oEAA2F;QAC3F,sBAAsB;IAC1B;AACJ","sourcesContent":[".become-chef-roadmap-wrapper {\n    background: url(\"../../../assets/images/become-a-chef-roadmap.png\") no-repeat center;\n    background-size: cover;\n    padding: 50px 100px 80px 100px;\n}\n\n.roadmap-heading {\n    margin-bottom: 50px;\n}\n\n.become-chef-roadmap {\n    display: flex;\n    gap: 30px;\n    flex-wrap: wrap;\n}\n\n@media screen and (max-width: 600px) {\n    .become-chef-roadmap-wrapper {\n        padding: 20px;\n        background: url(\"../../../assets/images/become-a-chef-roadmap-mobile.png\") no-repeat center;\n        background-size: cover;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
