import './TailoredOffers.css';
import {useDispatch, useSelector} from "react-redux";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import React, {useEffect} from "react";
import ChefService from "../../../../core/services/ChefService";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import store from "../../../../core/redux/store";
import {Icon} from "@iconify/react";
import DiscountOffer from "../../../../model/offers/DiscountOffer";
import DeleteConfirmation from "../delete-offer-modal/delete-confirmation/DeleteConfirmation";
import Bogof from "../../../../model/offers/Bogof";
import SpendAndGetFreeOffer from "../../../../model/offers/SpendAndGetFreeOffer";
import EmptyOffers from "../empty-offers/EmptyOffers";

type TailoredOfferType = {
    editDiscountOffer: (offer: DiscountOffer | Bogof | SpendAndGetFreeOffer) => void;
    handleOfferDelete: (offer: DiscountOffer | Bogof | SpendAndGetFreeOffer, offerType: string) => void;
    offerDeleted: boolean;
    onExploreOffersClick: () => void;
}

const TailoredOffers: React.FC<TailoredOfferType> = ({editDiscountOffer, handleOfferDelete, offerDeleted, onExploreOffersClick}) => {

    const offers = useSelector(selectChef).offers;
    const username = useSelector(selectChef).credentials?.username;
    const apiDispatch = useDispatch<typeof store.dispatch>();
    const menu = useSelector(selectChef).menu;

    useEffect( () => {

        console.log('calling backend');
        const fetchOffers = async () => {
            apiDispatch(ChefService.getOffers({username: username}));
        }

        fetchOffers();
    },[])

    const matchingItem = (offer: string) => menu?.find(item => {
        return item.id === offer
    });

    return (
        ((offers?.bogofs?.length ?? 0 > 0 )|| (offers?.discounts?.length ?? 0 > 0) || (offers?.spendAndGetFreeOffers?.length ?? 0 > 0)) ? (
        <div className='custom-discounts-container'>
            <div>
                {offerDeleted && <DeleteConfirmation />}
                <div className='custom-discounts-list'>
                    {offers?.discounts?.map((discountOffer, index)=> (
                        <div key={index} className='box-card-shadow tailored-offer-card'>
                            <Icon icon='mdi:delete-outline' className='delete-offer-button custom-offers-action-button' onClick={() => handleOfferDelete(discountOffer, 'discount')}/>
                            <Icon icon='ic:baseline-edit' className='edit-offer-button custom-offers-action-button' onClick={() => editDiscountOffer(discountOffer)}/>
                            <div className='custom-offer-icon-wrapper discount'>
                                <h3>{discountOffer.discountWorth}%</h3>
                            </div>
                            <p className='offer-description'>
                                {`${discountOffer.discountWorth}% discount applied to ${matchingItem(discountOffer.menuItemsId[0])?.name}`}
                            </p>
                        </div>
                    ))}
                    {offers?.bogofs?.map((offer, index)=> (
                        <div key={index} className='box-card-shadow tailored-offer-card'>
                            <Icon icon='mdi:delete-outline' className='delete-offer-button custom-offers-action-button' onClick={() => handleOfferDelete(offer, 'bogof')}/>
                            <Icon icon='ic:baseline-edit' className='edit-offer-button custom-offers-action-button' onClick={() => editDiscountOffer(offer)}/>
                            <div className='custom-offer-icon-wrapper bogof'>
                                <Icon icon='ri:price-tag-3-line' fontSize={24}/>
                            </div>
                            <h4>Buy {offer.purchaseItemsQuantity} get {offer.freeItemsQuantity} free</h4>
                            <div className='offer-description'>
                                {<p>{offer.freeItemsQuantity} free {matchingItem(offer.freeMenuItems[0])?.name} on buying {offer.purchaseItemsQuantity} {matchingItem(offer.purchaseMenuItems[0])?.name}</p>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>) : <EmptyOffers onExploreOffersClick={onExploreOffersClick}/>
    )
}

export default TailoredOffers;
