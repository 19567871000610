import './Logout.css';
import {Icon} from "@iconify/react";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../core/redux/slices/loginSlice";
import store from "../../../core/redux/store";

const Logout = () => {

    const dispatch = useDispatch<typeof store.dispatch>();
    const navigateTo = useNavigate();

    const handleLogout = ()=> {
        dispatch({type: 'logout'});
        navigateTo('/login');
    }

    return (
        <div className='logout cursor' onClick={handleLogout}>
            <span>Logout</span>
            <Icon icon='material-symbols:logout-sharp' fontSize={24} />
        </div>
    )
}

export default Logout;
