// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.availability-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.add-item-heading-container sub {
    color: var(--light-grey);
}

.dish-availability-heading {
    margin-bottom: 0 !important;
}

.flex-wrap {
    flex-wrap: wrap;
    row-gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-dashboard/chef-menu/add-new-item/availability/DishAvailability.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":[".availability-container {\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n}\n\n.add-item-heading-container sub {\n    color: var(--light-grey);\n}\n\n.dish-availability-heading {\n    margin-bottom: 0 !important;\n}\n\n.flex-wrap {\n    flex-wrap: wrap;\n    row-gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
