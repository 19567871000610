import './DiscountOfferForm.css';
import React, {useEffect, useState} from "react";
import MenuItem from "../../../../model/MenuItem";
import {Icon} from "@iconify/react";
import DatePicker from "react-datepicker";
import ChefService from "../../../../core/services/ChefService";
import {useDispatch, useSelector} from "react-redux";
import {selectChef} from "../../../../core/redux/slices/chefSlice";
import MenuItemCard from "../menu-item-checklist/MenuItemCard";
import DiscountOffer from "../../../../model/offers/DiscountOffer";
import {selectToken} from "../../../../core/redux/slices/loginSlice";
import {NavLink} from "react-router-dom";
import {ButtonState} from "../../profile/PersonalDetails/PersonalDetails";
import {unwrapResult} from "@reduxjs/toolkit";
import store from "../../../../core/redux/store";

type DiscountOfferProps = {
    isEditModeOn: boolean,
    discountOffer?: DiscountOffer
    cancelOfferSelection: () => void,
}

const DiscountOfferForm: React.FC<DiscountOfferProps> = ({isEditModeOn, discountOffer, cancelOfferSelection}) => {

    const [discountWorth, setDiscountWorth] = useState(discountOffer?.discountWorth || '');
    const [isDiscountWorthMissing, setIsDiscountWorthMissing] = useState(false);
    const [isDiscountPercentageNotValid, setIsDiscountPercentageNotValid] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const todayDate = new Date();
    const [startDate, setStartDate] = useState(discountOffer?.startDate || todayDate);
    const [expiryDate, setExpiryDate] = useState(discountOffer?.endDate || todayDate);
    const id = useSelector(selectChef)?.id;
    const [menu, setMenu] = useState<MenuItem[]>();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [selectedMenuItems, setSelectedMenuItems] = useState(discountOffer?.menuItemsId || []);
    const [buttonState, setButtonState] = useState<ButtonState>('idle');
    const apiDispatch = useDispatch<typeof store.dispatch>();

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    }

    const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountWorth(event.target.value);
        setIsDiscountPercentageNotValid(false);
        setIsDiscountWorthMissing(false);
    }

    useEffect(  () => {

        const fetchMenu = async () => {
            try {
                const resultAction = await apiDispatch(ChefService.getMenu({
                    id: id as string
                }));
                const menu: MenuItem[] = unwrapResult(resultAction);
                setMenu(menu);
            } catch (error) {
                console.log(error);
            }
        }
        fetchMenu();
    },[])

    const handleSelectedMenuItem = (selectedMenuItem: string) => {

        if(selectedMenuItems.includes(selectedMenuItem)){
            setSelectedMenuItems(selectedMenuItems.filter(item => item !== selectedMenuItem));
        }else {
            setSelectedMenuItems([...selectedMenuItems, selectedMenuItem])
        }
    }

    const handleSaveDiscountOffer = async () => {
        if(!discountWorth || expiryDate < startDate){
            if(!discountWorth){
                setIsDiscountWorthMissing(true)
            }
            if(expiryDate < startDate){
                setDatesError(true);
            }
            return;
        }

        if(+discountWorth < 1 || +discountWorth > 100){
            setIsDiscountPercentageNotValid(true);
            return
        }
        setButtonState('saving');

        const offer: DiscountOffer = {
            discountWorth: discountWorth,
            menuItemsId: selectedMenuItems,
            startDate: startDate,
            endDate: expiryDate,
            id: isEditModeOn ? discountOffer?.id : undefined,
        }

        try {
            isEditModeOn ? await ChefService.editOffer(id as string, offer, 'discount')
                : await ChefService.createOffer(id as string, offer, 'discount');

            setTimeout(()=>{
                setButtonState('idle');
                cancelOfferSelection();
            },1000)

        }catch(error){
            console.log('unable to save');
        }
    }

    return (
        <div className='discount-offer'>
            <div className='offer-heading'>
                <Icon icon='ion:chevron-back' fontSize={28} className='cursor back-to-added-items' onClick={cancelOfferSelection} />
                <h3>{isEditModeOn ? 'Edit discount offer' : 'Add discount offer'}</h3>
            </div>
            <div className='offer-form-content'>
                <div className='discount-offer-wrapper-row'>
                    <div>
                        <label className='discount-offer-label'>Offer Type</label>
                        <p className='discount-offer-input offer-type'>Percentage Off</p>
                    </div>
                    <div>
                        <div className='discount-offer-label'>Discount Percentage</div>
                        <div className='discount-change-input-wrapper'>
                            <input type='number' value={discountWorth} onChange={handleDiscountChange} className='discount-change-input'/>
                            <Icon icon='prime:percentage' fontSize={24} className='percentage-icon'/>
                        </div>
                        {isDiscountPercentageNotValid &&  <div className='error-message'>Enter valid discount percentage</div>}
                        {isDiscountWorthMissing && <div className='error-message'>This field is required</div>}
                    </div>
                </div>
                <div className='discount-offer-wrapper-row'>
                    <div>
                        <h4>Start Date</h4>
                        <div className='date-picker-container'>
                            <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} minDate={todayDate}></DatePicker>
                            <Icon icon='carbon:calendar' fontSize={24} className='datepicker-icon'/>
                        </div>
                    </div>
                    <div>
                        <h4>End Date</h4>
                        <div className='date-picker-container'>
                            <DatePicker selected={expiryDate} onChange={(date: Date) => setExpiryDate(date)} minDate={todayDate}></DatePicker>
                            <Icon icon='carbon:calendar' fontSize={24} className='datepicker-icon'/>
                        </div>
                        {datesError && <div className='error-message'>Dates are not correct</div>}
                    </div>
                </div>
                <div className={`select-offer-items-wrapper cursor ${tooltipOpen ? 'openTooltip' : ''}`} onClick={toggleTooltip}>
                    <p>Select the items to apply the offer</p>
                    {tooltipOpen ? <Icon icon='iconamoon:arrow-up-2-duotone' fontSize={24} /> : <Icon icon='ri:arrow-down-s-line' fontSize={24}/>}
                </div>
                {tooltipOpen &&
                    <div className='discount-menu-items box-card-shadow'>
                        {
                            menu?.length && menu?.length > 0 ?
                                <div className='menu-items-list'>
                                    {menu?.map((menuItem: MenuItem, index) => (
                                        <MenuItemCard menuItem={menuItem} key={index} handleSelectedMenuItem={handleSelectedMenuItem} offerList={selectedMenuItems}/>
                                    ))}
                                </div> :
                                <div className='empty-menu-items'>
                                    <p>Unable to find the menu items</p>
                                    <NavLink to='/chef/dashboard/menu'><button className='add-menu-button'>Add menu items</button></NavLink>
                                </div>
                        }
                    </div>
                }
                <div className='discount-offer-actions'>
                    {(!isEditModeOn) && <button className='primary-button apply-offer-button' disabled={buttonState !== 'idle'} onClick={handleSaveDiscountOffer}>Apply Offer</button>}
                    {isEditModeOn && <button className='primary-button' onClick={handleSaveDiscountOffer}>Save Changes</button>}
                    <button className='cancel-button cursor' onClick={cancelOfferSelection}>Cancel</button>
                </div>
            </div>

        </div>
    )
}

export default DiscountOfferForm;
