import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {setError} from "../core/redux/slices/loginSlice";

const useFormInput = (initialValue: string | undefined, required: boolean, nullifyError?: boolean) => {
    const [value, setValue] = useState(initialValue || '');
    const [isMissing, setIsMissing] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setValue(event.target.value);
        setIsMissing(false);
        if(nullifyError){
            dispatch(setError(''));
        }
    };

    const validate = (value: string) => {
        if (required && value.trim().length === 0) {
            setIsMissing(true);
        } else {
            setIsMissing(false);
        }
    };

    const reset = () => {
        setValue(initialValue || '');
        setIsMissing(false);
    };

    return {
        value,
        onChange: handleChange,
        isMissing,
        validate,
        setValue,
        setIsMissing,
        reset,
    };
};

export default useFormInput;
