import './LoginMenuContainer.css';
import UserProfileContainer from "./user-profile-container/UserProfileContainer";
import {Icon} from "@iconify/react";
import logo from '../../../../assets/images/logos/logo-orange-chef.png';
import {useDispatch, useSelector} from "react-redux";
import {closeMenuModal} from "../../../../core/redux/slices/menuModalSlice";
import {selectUser} from "../../../../core/redux/slices/loginSlice";
import {useNavigate} from "react-router-dom";

const LoginMenuContainer = () => {

    const dispatch = useDispatch();
    const userType = useSelector(selectUser).userType;
    const navigateTo = useNavigate();

    const closeMenu = () => {
        dispatch(closeMenuModal());
    }

    const handleNavigateTo = (path: string) => {
        closeMenu();
        navigateTo(path);
    }

    return (
        <div>
            <div className='menu-logo-row'>
               <Icon icon='line-md:menu-to-close-alt-transition' fontSize={24} onClick={closeMenu} className='cursor'/>
                <img src={logo} alt='logo'/>
            </div>
            <UserProfileContainer userType={userType} />
            <div className='user-menu-options'>
                {userType === 'chef' ?
                    <div className='chef-options'>
                        <div onClick={() => handleNavigateTo('chef/dashboard')}>Go to Dashboard</div>
                        <div onClick={() => handleNavigateTo('chef/dashboard/orders')}>Received Orders</div>
                        <div>Notifications</div>
                    </div> :
                    <div className='customer-options'>
                        <div>Orders</div>
                        <div onClick={() => handleNavigateTo('/becomeChef')}>Become a chef</div>
                    </div>
                }
                <div className='common-options'>
                    <div className='chef-dashboard-link' onClick={() => handleNavigateTo('/#user-address')}>Explore Nearby Chefs</div>
                </div>
            </div>

        </div>
    )
}

export default LoginMenuContainer;
