import './SignInOthers.css';
import { Icon } from '@iconify/react';
import GoogleLoginButtonOld from "./GoogleOAuthButton/GoogleLoginButtonOld";
import React from "react";

type SignInOtherProps ={
    text: string;
}

const SignInOthers = (props: SignInOtherProps) => {

    return (
        <>
            <div className='sign-in-other grey-light-text'>
                <div className="line"></div>
                <div style={{display: 'inline-block'}}>{props.text}</div>
                <div className="line"></div>
            </div>
            <div className='sign-in-others-wrapper'>
                <GoogleLoginButtonOld />
                <div className='sign-in-item cursor'>
                    <Icon icon='logos:facebook' />
                    <span>Facebook</span>
                </div>
                <div className='sign-in-item cursor'>
                    <Icon icon='mdi:apple' />
                    <span>Apple</span>
                </div>
            </div>
        </>

    )
}

export default SignInOthers;
