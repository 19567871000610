import './MenuModal.css';
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { closeMenuModal, selectIsMenuModalOpen } from "../../../core/redux/slices/menuModalSlice";
import DefaultMenuContainer from "./default-menu-container/DefaultMenuContainer";
import LoginMenuContainer from "./user-menu-container/LoginMenuContainer";
import {selectUser} from "../../../core/redux/slices/loginSlice";
import {useCallback, useEffect, useState} from "react";
import {customStyles} from "../../../pages/chef-business-page/chef-menu-items/chef-menu-item-card/ChefMenuItemCard";

const MenuModal = () => {

    const isMenuModalOpen = useSelector(selectIsMenuModalOpen);
    const dispatch = useDispatch();
    const isLoginSuccessfully = useSelector(selectUser).loading === 'succeeded';
    const [animationClass, setAnimationClass] = useState('');


    const closeModal = useCallback(() => {
        setAnimationClass('modal-closed');
        setTimeout(() => {
            dispatch(closeMenuModal());
        }, 500)

    },[dispatch]);

    useEffect(() => {
        if (isMenuModalOpen) {
            setAnimationClass('modal-open');
        } else {
            setAnimationClass('modal-closed');
        }
    }, [isMenuModalOpen]);


    return (
        <Modal isOpen={isMenuModalOpen} className={`menu-modal ${animationClass}`}  onRequestClose={closeModal} style={customStyles}>
            <div className='menu-modal-box'>{!isLoginSuccessfully ?
                <DefaultMenuContainer closeMenuModal={closeModal}/> : <LoginMenuContainer />}</div>
        </Modal>
    )
}

export default MenuModal;
