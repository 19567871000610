// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-button{
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}


@media only screen and (max-width: 600px) {
    .login-button {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/nav-bar/login-button/LoginButtonStyle.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n.login-button{\n    padding: 8px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n\n@media only screen and (max-width: 600px) {\n    .login-button {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
