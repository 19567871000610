// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-options-wrapper {
    display: flex;
    margin: 50px 120px;
    border-radius: 20px;
    padding: 50px;
    background: linear-gradient(90.03deg, #f4c858 8.5%, #dcad3b 96.12%);
    justify-content: space-between;
}

.more-information {
    flex: 1.5 1;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.more-information-links {
    flex: 1 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: space-evenly;
}

@media screen and (max-width: 600px) {
    .more-options-wrapper {
        padding: 15px;
        flex-direction: column;
        margin: 50px 20px;
    }

    .more-information-links {
        margin-top: 16px;
        gap: 4px;
    }
}

@media screen and (max-width: 380px){
    .more-information-links {
        gap: 8px;
        flex-direction: column;
    }

    .more-information-links > * {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/home-page/more-options/MoreOptions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mEAAmE;IACnE,8BAA8B;AAClC;;AAEA;IACI,WAAS;IACT,SAAS;IACT,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,iBAAiB;IACrB;;IAEA;QACI,gBAAgB;QAChB,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,QAAQ;QACR,sBAAsB;IAC1B;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".more-options-wrapper {\n    display: flex;\n    margin: 50px 120px;\n    border-radius: 20px;\n    padding: 50px;\n    background: linear-gradient(90.03deg, #f4c858 8.5%, #dcad3b 96.12%);\n    justify-content: space-between;\n}\n\n.more-information {\n    flex: 1.5;\n    gap: 30px;\n    display: flex;\n    flex-direction: column;\n}\n\n.more-information-links {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    justify-content: space-evenly;\n}\n\n@media screen and (max-width: 600px) {\n    .more-options-wrapper {\n        padding: 15px;\n        flex-direction: column;\n        margin: 50px 20px;\n    }\n\n    .more-information-links {\n        margin-top: 16px;\n        gap: 4px;\n    }\n}\n\n@media screen and (max-width: 380px){\n    .more-information-links {\n        gap: 8px;\n        flex-direction: column;\n    }\n\n    .more-information-links > * {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
