import './DashboardMenu.css';
import {NavLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import blackLogo from '../../../assets/images/logos/logo-orange-chef.png';
import {selectChef} from "../../../core/redux/slices/chefSlice";
import ProfilePhotoPlaceholder from "../profile/edit-profile-picture-modal/profile-photo-placeholder/ProfilePhotoPlaceholder";
import onboardingChecklistIcon from '../../../assets/images/chef-onboarding-icon.png';
import Logout from "../../../components/shared/logout/Logout";


type DashboardMenuProps = {
    isMobileVersion : boolean,
    closeDashboardMenu?: () => void,
}

const DashboardMenu: React.FC<DashboardMenuProps> = ({isMobileVersion, closeDashboardMenu}) => {


    const chef = useSelector(selectChef);

    const [chefProfileImageUrl, setChefProfileImageUrl] = useState('');



    useEffect(() => {
        if(chef.profileImageUrl){
            setChefProfileImageUrl(chef.profileImageUrl+'?'+process.env.REACT_APP_BLOB_SAS_TOKEN);
        }else{
            setChefProfileImageUrl('');
        }
    },[chef.profileImageUrl]);


    return (
        <div className='dashboard-menu box-card-shadow'>
            <div className='chef-dashboard-logo-wrapper'>
                <img src={blackLogo} className='chef-dashboard-logo' alt='chef-dashbaord-logo'/>
            </div>
            <div className='dashboard-wrapper'>
                <div className='dashboard-heading-container'>
                    <div className='profile-image-wrapper'>
                        {chefProfileImageUrl ?
                            <img src={chefProfileImageUrl} alt='chef' className='menu-profile-image'/>
                            : <ProfilePhotoPlaceholder  containerSize='small-size' name={chef.name}/>}
                        <Logout />
                    </div>
                    <div>
                        <p className='menu-profile-name'>{chef.name}</p>
                        <p className='menu-profile-email'>{chef.credentials?.username}</p>
                    </div>
                </div>
                <div className='dashboard-link-wrapper'>
                    <NavLink to={'onboarding'} onClick={closeDashboardMenu}><img src={onboardingChecklistIcon} className='onboarding-icon' alt='onboarding'/>Onboarding Steps</NavLink>
                    <NavLink to={'/chef/dashboard'} end onClick={closeDashboardMenu}><Icon icon='lucide:user' fontSize={24}/>Profile</NavLink>
                    <NavLink to={'schedule'} onClick={closeDashboardMenu}><Icon icon='healthicons:i-schedule-school-date-time' fontSize={24} />Schedule</NavLink>
                    <NavLink to={'menu'} onClick={closeDashboardMenu}><Icon icon='bx:food-menu' fontSize={24} />Menu</NavLink>
                    <NavLink to={'meals'} onClick={closeDashboardMenu}><Icon icon='icon-park-outline:baby-meal' fontSize={24} />Meals</NavLink>
                    <NavLink to={'offers'} onClick={closeDashboardMenu}><Icon icon='mdi:sale-outline' fontSize={24} />Offers</NavLink>
                </div>
                <div className='dashboard-link-wrapper help-links'>
                    <NavLink to={'/'} onClick={closeDashboardMenu}><Icon icon='oi:external-link' fontSize={20} />Back to Homeeatz.com</NavLink>
                    <NavLink to={'support'} onClick={closeDashboardMenu}><Icon icon='streamline:customer-support-1-solid' fontSize={24} />Contact Support</NavLink>
                </div>
            </div>
        </div>
    )
}

export default DashboardMenu;
