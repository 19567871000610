import './CuisineList.css';
import React from "react";
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {selectCuisines, setSpecialityCuisines} from "../../../../core/redux/slices/chefSlice";

type CuisineListProps = {
    cuisineMissingError: boolean,
}

const CuisineList: React.FC<CuisineListProps> = ({cuisineMissingError}) => {

    const cuisines = ['Mexican','Indian','Thai','Chinese','Canadian', 'Japanese', 'Korean', 'Italian', 'Baked'];
    const selectedCuisines = useSelector(selectCuisines);
    const dispatch = useDispatch();

    const handleSelectCuisine = (cuisineName: string) => {
        if(selectedCuisines.includes(cuisineName)){
            const filteredCuisine = selectedCuisines.filter((cuisine) =>  cuisine !== cuisineName )
            dispatch(setSpecialityCuisines(filteredCuisine));
            return;
        }
        dispatch(setSpecialityCuisines([...selectedCuisines, cuisineName]));
    }

    return (
        <div className='cuisines-selector-wrapper'>
            <p className={`speciality-cuisine-heading ${cuisineMissingError ? 'error-message' : ''}`}>Select all your specialities</p>
            <div className='cuisine-list'>
                {cuisines.map((cuisine, index) => (
                    <div onClick={() => handleSelectCuisine(cuisine)} className={`cuisine-button cursor ${selectedCuisines.includes(cuisine) ? 'selected-cuisine': ''}`} key={index}>
                        <p>{cuisine}</p>
                        {selectedCuisines.includes(cuisine) && <Icon icon='gravity-ui:delete' fontSize={20}/>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CuisineList;
