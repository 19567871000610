// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-menu-wrapper {
    padding-top: 20px;
}

.menu-buttons {
    display: flex;
    gap: 16px;
    padding: 20px 20px 30px 20px;
    flex-direction: column;
}

.menu-detail-item {
    padding: 20px;
}

.menu-detail-item:hover {
    background: var(--primary-light-color);
}

.menu-detail-wrapper {
    display: flex;
    flex-direction: column;
}

.menu-detail-wrapper > * {
    text-align: center;
    border-bottom: 1px solid gainsboro;
}

.menu-detail-wrapper > *:hover {
    border-bottom: 1px solid var(--primary-color);
}

.menu-button {
    padding: 16px;
    text-align: center;
    border-radius: 8px;
}

.button-login {
    background: var(--primary-color);
}

.button-login:hover {
    background: var(--primary-color-hover);
}

.button-signup {
    background: var(--primary-light-color);
    border: 1px solid var(--primary-color);
}

.button-signup:hover {
    background: rgba(255, 247, 225, 0.64);
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/menu-modal/default-menu-container/DefaultMenuContainer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,sCAAsC;IACtC,sCAAsC;AAC1C;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".default-menu-wrapper {\n    padding-top: 20px;\n}\n\n.menu-buttons {\n    display: flex;\n    gap: 16px;\n    padding: 20px 20px 30px 20px;\n    flex-direction: column;\n}\n\n.menu-detail-item {\n    padding: 20px;\n}\n\n.menu-detail-item:hover {\n    background: var(--primary-light-color);\n}\n\n.menu-detail-wrapper {\n    display: flex;\n    flex-direction: column;\n}\n\n.menu-detail-wrapper > * {\n    text-align: center;\n    border-bottom: 1px solid gainsboro;\n}\n\n.menu-detail-wrapper > *:hover {\n    border-bottom: 1px solid var(--primary-color);\n}\n\n.menu-button {\n    padding: 16px;\n    text-align: center;\n    border-radius: 8px;\n}\n\n.button-login {\n    background: var(--primary-color);\n}\n\n.button-login:hover {\n    background: var(--primary-color-hover);\n}\n\n.button-signup {\n    background: var(--primary-light-color);\n    border: 1px solid var(--primary-color);\n}\n\n.button-signup:hover {\n    background: rgba(255, 247, 225, 0.64);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
