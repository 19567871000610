import './DeliveryType.css';
import {useDispatch, useSelector} from "react-redux";
import {changeOrderType, selectOrderType} from "../../../../core/redux/slices/customerSlice";

const DeliveryType = () => {

    const orderType = useSelector(selectOrderType);
    const dispatch = useDispatch();

    const handleDeliveryType = (orderType: string) => {
        dispatch(changeOrderType(orderType))
    }

    return (
        <div className='delivery-type-container'>
            <div className='buttons-wrapper'>
                <div className={`delivery-type-button ${orderType === 'pickup' ? 'selected' : ''}`} onClick={() => handleDeliveryType('pickup')}>Pickup</div>
                <div className={`delivery-type-button ${orderType === 'delivery' ? 'selected' : ''}`} onClick={() => handleDeliveryType('delivery')}>Delivery</div>
            </div>
        </div>
    )
}

export default DeliveryType;
