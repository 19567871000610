import { useState } from 'react';

function useModal() {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        document.body.classList.add('body-no-scroll');
        setIsOpen(true)
    };

    const closeModal = () => {
        document.body.classList.remove('body-no-scroll');
        setIsOpen(false);
    };

    const toggleModal = () => setIsOpen(!isOpen);

    return {
        isOpen,
        openModal,
        closeModal,
        toggleModal
    };
}

export default useModal;
