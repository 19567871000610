// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chef-business-page {
    padding: 40px 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 600px) {
    .chef-business-page {
        padding: 30px 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chef-business-page/ChefBusinessPage.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".chef-business-page {\n    padding: 40px 100px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n@media screen and (max-width: 600px) {\n    .chef-business-page {\n        padding: 30px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
